export function transformQueryParamsForExport(queryParams) {
  const {q, ...rest} = queryParams;

  const params = {
    ...rest,
    ...q,
  };

  if (params.g) {
    params.g = [params.g];
  }

  return params;
}
