import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, Field, getFormValues, FormSection} from 'redux-form';

import {selectOptInsFiltersMapped} from 'client/ducks/opt-in-columns/selectors';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

class BCOptinsFiltersForm extends Component {
  static propTypes = {
    init: PropTypes.bool,
    formValues: PropTypes.object,
    optinsFilters: PropTypes.object,
    optIns: PropTypes.array,
    optinsControl: PropTypes.string,
    optinsOperator: PropTypes.string,
    initialize: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    init: false,
    optIns: [],
    formValues: {},
    optinsFilters: {},
    optinsControl: '',
    optinsOperator: '',
  };

  static formName = 'BcOptinsFiltersForm';

  static qsNames = {
    opt_ins: 'opt_ins',
    m: 'm',
    optinsControl: 'optinsControl',
  };

  static fields = {
    m: {
      field: 'm',
      or: 'or',
      and: 'and',
    },
  };

  static controlNames = {
    noMatter: 'no_matter',
    noActive: 'no_active',
    custom: 'custom',
    all: 'all',
  };

  constructor(props) {
    super(props);

    this.state = {
      control: '',
      showOperators: false,
    };

    this.LANGUAGE = props.languageState.payload.BROADCASTING_TASK.MAILING_FILTERS_MODAL;
  }

  componentDidUpdate(prevProps) {
    if (!this.props.init && prevProps.init) {
      this.initForm(this.props.optinsFilters, this.props.optinsControl, this.props.optinsOperator);
    }
  }

  getName = (id) => `opt_ins->>${id}_eq`;

  setFieldsToValue = (optIns, value = true) => {
    return optIns.reduce((acc, optIn) => {
      return {...acc, [this.getName(optIn.id)]: value};
    }, {});
  };

  handleChange = () => {
    const ctrlNames = BCOptinsFiltersForm.controlNames;
    const formOptins = this.props.formValues.opt_ins;
    const keys = this.props.optIns.map((item) => {
      return this.getName(item.id);
    });
    const operator = this.props.formValues.m;
    const isAllTrue = keys.every((key) => formOptins[key]);

    let activeAmount = keys.reduce((prevValue, key) => {
      return formOptins[key] ? prevValue + 1 : prevValue;
    }, 0);

    this.setState({
      showOperators: activeAmount > 1,
      control: isAllTrue ? ctrlNames.all : ctrlNames.custom,
    });

    this.props.onFormChange({
      [BCOptinsFiltersForm.qsNames.opt_ins]: formOptins,
      [BCOptinsFiltersForm.qsNames.m]: operator,
      [BCOptinsFiltersForm.qsNames.optinsControl]: isAllTrue ? ctrlNames.all : ctrlNames.custom,
    });
  };

  handleFormChange = () => {
    Promise.resolve().then(this.handleChange);
  };

  handleControlClick = (name) => () => {
    this.setState({
      control: name,
    });

    this.initForm({}, name);
  };

  initForm(optinsFilters = {}, control = '', operator = '') {
    const ctrlNames = BCOptinsFiltersForm.controlNames;
    const fields = BCOptinsFiltersForm.fields;

    const values = {
      opt_ins: {},
      m: '',
    };

    switch (control) {
      case ctrlNames.all:
        values.opt_ins = this.setFieldsToValue(this.props.optIns);
        values[fields.m.field] = operator || fields.m.or;

        this.setState({
          showOperators: true,
        });
        break;
      case ctrlNames.custom:
        let activeAmount = 0;
        this.props.optIns.forEach((optIn) => {
          values.opt_ins[this.getName(optIn.id)] = !!optinsFilters[this.getName(optIn.id)];
          activeAmount = optinsFilters[this.getName(optIn.id)] ? activeAmount + 1 : activeAmount;
        });
        values[fields.m.field] = operator || fields.m.or;

        this.setState({
          showOperators: activeAmount > 1,
        });
        break;
      case ctrlNames.noActive:
        values.opt_ins = this.setFieldsToValue(this.props.optIns, false);
        values[fields.m.field] = operator || fields.m.and;

        this.setState({
          showOperators: false,
        });

        break;
      case ctrlNames.noMatter:
        values.opt_ins = this.setFieldsToValue(this.props.optIns, false);
        values[fields.m.field] = fields.m.or;

        this.setState({
          showOperators: false,
        });
        break;
      default:
      //
    }

    Promise.resolve(this.props.initialize(values)).then(() => {
      // set opt-ins control button to active state
      this.setState({
        control: control || ctrlNames.noMatter,
      });

      // calling only when reinit form
      // (when click on control buttons: doesn't matter, no active, all)
      if (isEmpty(optinsFilters)) {
        this.props.onFormChange({
          [BCOptinsFiltersForm.qsNames.opt_ins]: control === ctrlNames.noMatter ? {} : values.opt_ins,
          [BCOptinsFiltersForm.qsNames.m]: control === ctrlNames.noMatter ? '' : values.m,
          [BCOptinsFiltersForm.qsNames.optinsControl]: control === ctrlNames.noMatter ? '' : control,
        });
      }
    });
  }

  renderOptins = () => {
    return (
      <div className="content-group">
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 150,
          }}
        >
          <div className="custom-scrollbars__scroll-content list list--view-4">
            <FormSection name="opt_ins" className="list__item">
              {this.props.optIns.map((optin) => {
                return (
                  <div key={optin.id} className="list__item">
                    <Field
                      label={`${optin.level}, ${optin.code}, ${optin.name}`}
                      type="checkbox"
                      onChange={this.handleFormChange}
                      name={this.getName(optin.id)}
                      component={fieldTemplate}
                    />
                  </div>
                );
              })}
            </FormSection>
          </div>
        </CustomScrollbars>
      </div>
    );
  };

  render() {
    const {LANGUAGE} = this;
    const {control, showOperators} = this.state;
    const ctrlNames = BCOptinsFiltersForm.controlNames;
    const fields = BCOptinsFiltersForm.fields;

    return (
      <form>
        <div className="field-group">
          <div className="fake-input__label">{LANGUAGE.OPTIN_LABEL}</div>
          <div className="field-group__row">
            <div className="field-group__field">
              <div
                className={`fake-filter-button theme-color-16 ${
                  control === ctrlNames.noMatter ? 'fake-filter-button--active' : ''
                }`}
                onClick={this.handleControlClick(ctrlNames.noMatter)}
              >
                <div className="fake-filter-button__text">{LANGUAGE.NO_MATTER_RADIO}</div>
              </div>
            </div>
            <div className="field-group__field">
              <div
                className={`fake-filter-button theme-color-16 ${
                  control === ctrlNames.noActive ? 'fake-filter-button--active' : ''
                }`}
                onClick={this.handleControlClick(ctrlNames.noActive)}
              >
                <div className="fake-filter-button__text">{LANGUAGE.NO_ACTIVE_RADIO}</div>
              </div>
            </div>
            <div className="field-group__field">
              <div
                className={`fake-filter-button theme-color-16 ${
                  control === ctrlNames.all ? 'fake-filter-button--active' : ''
                }`}
                onClick={this.handleControlClick(ctrlNames.all)}
              >
                <div className="fake-filter-button__text">{LANGUAGE.ALL_RADIO}</div>
              </div>
            </div>
          </div>
          <div className="field-group__row">
            <div className="field-group__field">{this.renderOptins()}</div>
          </div>
          {showOperators && (
            <div className="field-group__row field-group__row--vert-center">
              <div className="field-group__field">
                <div className="field-group__text">{LANGUAGE.USE_BOOLEAN_LABEL}</div>
              </div>
              <div className="field-group__field">
                <Field
                  label={LANGUAGE.OR_LABEL}
                  name={fields.m.field}
                  value={fields.m.or}
                  type="radio"
                  onChange={this.handleFormChange}
                  component={fieldTemplate}
                />
              </div>
              <div className="field-group__field">
                <Field
                  label={LANGUAGE.AND_LABEL}
                  name={fields.m.field}
                  value={fields.m.and}
                  type="radio"
                  onChange={this.handleFormChange}
                  component={fieldTemplate}
                />
              </div>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const form = reduxForm({
  form: BCOptinsFiltersForm.formName,
})(BCOptinsFiltersForm);

const mapStateToProps = (state) => ({
  formValues: getFormValues(BCOptinsFiltersForm.formName)(state),
  languageState: state.languageState,
  optIns: selectOptInsFiltersMapped(state),
});

export default connect(mapStateToProps)(form);
