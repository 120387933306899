// TODO: to add modals
export const CATALOG_TYPES = {
  GAME: 'CatalogGame',
  FORM: 'CatalogForm',
  QUIZ: 'CatalogQuiz',
  SCENARIO: 'CatalogScenario',
  TEMPLATE: 'CatalogTemplate',
} as const;

export const CATALOG_TEMPLATE_TABS = {
  WEEZIO: 'weezio',
  SHARED: 'shared',
  MY_TEMPLATES: 'my-templates',
} as const;

export const CATALOG_TYPE_MAP = {
  [CATALOG_TYPES.GAME]: 'game',
  [CATALOG_TYPES.QUIZ]: 'quiz',
  [CATALOG_TYPES.FORM]: 'form',
  [CATALOG_TYPES.TEMPLATE]: 'general',
} as const;
