import React, {useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {removeChannel, getCampaignsWithChannels} from 'client/ducks/campaigns/actions';
import {selectedClientGames} from 'client/ducks/games/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';

import AppButton from 'client/common/buttons/app-button';
import {useToast} from 'client/common/hooks/useToast';
import CopyToClipboardPopover from 'client/common/popovers/copy-to-clipboard-popover';

import Icon from 'client/components/common/icon';

import LotteryPrizeCustomizationModal from 'client/components/lottery/modals/lottery-prize-customization-modal';
import PrizeMapSelectingButton from 'client/components/prizes/controls/prize-map-selecting-button';
import {excludePrizeMaps} from 'client/components/prizes/helpers';
import PrizeCustomizationModal from 'client/components/prizes/modals/prize-customization-modal';
import {Interaction} from 'client/models/interactions/types';
import {GAME_TYPES} from 'client/models/prizes/constants';
import {GameType} from 'client/models/prizes/types';
import {ApiDispatch} from 'client/types';

import {DateCell} from './ChannelDateCell';
import {CHANNEL_TYPES} from './constants';

import './campaign-channel-diy-block.scss';

const b = bem('campaign-channel-diy-block');

type CampaignChannelDiyBlockProps = {
  onEdit: () => void;
  onDelete: () => void;
  interaction: Interaction;
  onFetchPrizeMaps: () => Promise<void>;
  disabled: boolean;
};

const CampaignChannelDiyBlock: React.FC<CampaignChannelDiyBlockProps> = (props) => {
  const {interaction, onEdit, onDelete, onFetchPrizeMaps, disabled} = props;
  const [showPrizeModal, setShowPrizeModal] = useState<GameType | null>(null);
  const {appendToastNotification} = useToast();

  const kind = interaction.kind!;
  const {id, name, from, to, url, participations_count, direct_access} = interaction;
  const isRemoveDisabled = !!participations_count || direct_access || disabled;

  const lang = useLanguage('DIY_ONLINE.BLOCKS.CAMPAIGN_CHANNEL_DIY_BLOCK');
  const dispatch: ApiDispatch = useDispatch();
  const {instantWinGame, lotteryGame} = useSelector(selectedClientGames);
  const isIframe = kind === CHANNEL_TYPES.IFRAME;

  const operation = useSelector(selectOperation);

  const onRemove = async () => {
    const response = await dispatch(removeChannel(id));
    if (response.error) {
      appendToastNotification({
        type: 'error',
        description: response.payload?.response?.errors?.base?.[0] || response.payload?.response?.exception,
      });
      return;
    }
    if (operation.client_interface?.id) {
      await dispatch(getCampaignsWithChannels(operation.client_interface?.id));
    }
    onDelete();
  };

  return (
    <>
      <tr className={b()}>
        <td className={b('column', ['col-icon', 'first-item'])}>
          <Icon className={b('type-icon')} name={isIframe ? 'iframe-icon' : 'minisite-icon'} />
        </td>
        <td className={b('column', ['col-type'])}>{kind === CHANNEL_TYPES.IFRAME ? lang.IFRAME : lang.MINISITE}</td>
        <td className={b('column', ['col-name'])}>{name}</td>
        <td className={b('column', ['col-actions'])}>
          <div className={b('action-buttons')}>
            <AppButton classNameIcon={b('edit-icon')} transparent={true} onClick={onEdit} iconName="edit" />
            <PrizeMapSelectingButton
              budget={interaction.prize_maps_modified?.includes('online_prize_map')}
              exclude={excludePrizeMaps({instantWinGame, lotteryGame}, interaction?.prize_maps_present)}
              onSelect={setShowPrizeModal}
              className={b('prize-icon')}
            />
          </div>
        </td>
        <td className={b('column', ['col-from'])}>
          <DateCell date={from} />
        </td>
        <td className={b('column', ['col-to'])}>
          <DateCell date={to} />
        </td>
        <td className={b('column', ['col-ref', 'ref-section'])}>
          <CopyToClipboardPopover
            title={isIframe ? lang.POPOVER_SNIPPET_TITLE : lang.POPOVER_URL_TITLE}
            text={url || ''}
            position="top"
          >
            {isIframe ? lang.SNIPPET : lang.URL}
          </CopyToClipboardPopover>
        </td>
        <td className={b('column', ['col-place'])}>{interaction.store_name || interaction.region_name || ''}</td>
        <td className={b('column', ['col-remove', 'last-item'])}>
          {!isRemoveDisabled && <AppButton transparent={true} onClick={onRemove} iconName="circle-cross" />}
        </td>
      </tr>

      {showPrizeModal === GAME_TYPES.INSTANT_WIN && instantWinGame && (
        <PrizeCustomizationModal
          onClose={() => setShowPrizeModal(null)}
          level="CHANNEL"
          levelName={name}
          game={instantWinGame}
          onFetch={onFetchPrizeMaps}
          sourceIds={[id]}
          disabled={disabled}
        />
      )}

      {showPrizeModal === GAME_TYPES.PRIZE_DRAW && lotteryGame && (
        <LotteryPrizeCustomizationModal
          onClose={() => setShowPrizeModal(null)}
          level="CHANNEL"
          game={lotteryGame}
          sourceIds={[id]}
          sourceName={name}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default CampaignChannelDiyBlock;
