import React from 'react';

import {capitalize} from 'lodash';
import {useSelector} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {selectCurrentLanguage} from 'client/ducks/language/selectors';

import Icon from 'client/components/common/icon';

import {Client, ClientAccount} from 'client/models/client/types';

import AccountCard from '../account-card';

import './account-contact.scss';

type AccountContactProps = {
  clientAccount: ClientAccount | null;
  client: Client;
  loading: boolean;
};

const AccountContact: React.FC<AccountContactProps> = (props) => {
  const {loading, clientAccount, client} = props;
  const lang = useLanguage('ACCOUNT_PARAMETERS.CONTACT');
  const currentLanguage = useSelector(selectCurrentLanguage);

  if (loading) {
    return <AccountCard className="account-contact" loading={loading} />;
  }

  let name = '';
  let organization = '';
  let email = '';
  let phone = '';
  let avatarUrl = '';

  if (client.poc_agency_membership) {
    const poc = client.poc_agency_membership;
    name = poc?.[`client_user_full_name_with_title_${currentLanguage}`];
    organization = clientAccount?.contact?.organization || '';
    email = clientAccount?.contact?.email || '';
    phone = clientAccount?.contact?.phone || '';
    avatarUrl = clientAccount?.contact?.avatar?.url || '';
  } else if (clientAccount?.admin_contact?.name) {
    name = clientAccount?.admin_contact?.name;
    organization = clientAccount?.admin_contact?.qualification?.split('_').map(capitalize).join(' ') || '';
    avatarUrl = clientAccount?.admin_contact?.avatar?.url || '';
  } else {
    name = `${client.subsidiary?.first_name || ''} ${client.subsidiary?.last_name || ''}`;
    organization = client.subsidiary?.name || '';
    email = client.subsidiary?.email || '';
    phone = client.subsidiary?.phone || '';
    avatarUrl = client.subsidiary?.logo?.url || '';
  }

  return (
    <AccountCard className="account-contact" title={lang.TITLE}>
      <div className="account-contact__content">
        {avatarUrl ? (
          <img src={avatarUrl} className="account-contact__avatar" />
        ) : (
          <Icon name="avatar-placeholder-small" className="account-contact__avatar" width={80} height={80} />
        )}
        <div className="account-contact__info">
          <p className="account-contact__info-item">{name}</p>
          <p className="account-contact__info-item">{organization}</p>
          <p className="account-contact__info-item">{email}</p>
          <p className="account-contact__info-item">{phone}</p>
        </div>
      </div>
    </AccountCard>
  );
};

export default AccountContact;
