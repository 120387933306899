import React, {useMemo, useState} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useDeepCompareEffect} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm from 'client/services/hooks/use-redux-form';
import {hasObjectTruthyValues} from 'client/services/validator';

import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import {TextField} from 'client/common/fields';
import Offscreen from 'client/common/offscreen';
import LayoutPanel from 'client/common/panels/layout-panel';
import TabSwitcher from 'client/common/tab-switcher';

import {COMMON_INPUT} from 'client/components/prizes/constants';
import PrizeDiyEmailFieldset from 'client/components/prizes/fieldsets/prize-diy-email-fieldset';
import PrizeDiyTicketFieldset from 'client/components/prizes/fieldsets/prize-diy-ticket-fieldset';
import PrizeDiyWinFieldset from 'client/components/prizes/fieldsets/prize-diy-win-fieldset';
import PrizeDiyModal from 'client/components/prizes/modals/prize-diy-modal';

import './prize-diy-modal-representation.scss';

const tabs = {
  WIN_POPUP: 'win_popup',
  TICKET: 'ticket',
  EMAIL: 'email',
};

const b = bem('prize-diy-modal-representation');

const PrizeDiyModalRepresentation = (props) => {
  const {className, disabled} = props;
  const {formValues, change, registerField, unregisterField, errors, submitFailed} = useReduxForm(
    PrizeDiyModal.formName,
  );
  const {create_ticket, email_send, language, defaultLanguage, prize_names} = formValues.main || {};
  const languages = useSelector(selectPrizeLanguages);
  const currentLanguage = formValues?.main?.language || null;

  const lang = useLanguage('PRIZES.MODALS.PRIZE_DIY_MODAL.PRIZE_DIY_MODAL_REPRESENTATION');
  const [activeTab, setActiveTab] = useState(tabs.WIN_POPUP);

  const hasWinPopupErrors = submitFailed && hasObjectTruthyValues(errors.win_pop_up || {});
  const hasTicketErrors = submitFailed && hasObjectTruthyValues(errors.ticket || {});
  const hasEmailErrors = submitFailed && hasObjectTruthyValues(errors.email || {});

  const tabsConfig = useMemo(
    () => [
      {title: lang.WIN_POPUP, value: tabs.WIN_POPUP, className: b('tab'), hasError: hasWinPopupErrors},
      {title: lang.TICKET, value: tabs.TICKET, className: b('tab'), exclude: !create_ticket, hasError: hasTicketErrors},
      {title: lang.EMAIL, value: tabs.EMAIL, className: b('tab'), exclude: !email_send, hasError: hasEmailErrors},
    ],
    [lang, create_ticket, email_send, hasWinPopupErrors, hasTicketErrors, hasEmailErrors],
  );

  const handlePrizeNameBlur = (event) => {
    const {value} = event.target;

    if (language === COMMON_INPUT) {
      const newNames = languages.reduce(
        (result, key) => ({
          ...result,
          [key]: value,
        }),
        {},
      );
      change('main.prize_names', newNames);
    } else if (currentLanguage === defaultLanguage) {
      const newNames = Object.keys(prize_names).reduce(
        (result, key) => ({
          ...result,
          [key]: prize_names[key]?.trim() ? prize_names[key] : value,
        }),
        {},
      );
      change('main.prize_names', newNames);
    }
  };

  useDeepCompareEffect(() => {
    languages.forEach((key) => {
      registerField(`win_pop_up.win_pop_up_images.${key}`);

      if (email_send) {
        registerField(`email.external_descriptions.${key}`);
        registerField(`email.email_images.${key}`);
      } else {
        unregisterField(`email.external_descriptions.${key}`);
        unregisterField(`email.email_images.${key}`);
      }
      if (create_ticket) {
        registerField(`ticket.visual_types.${key}`);
        registerField(`ticket.ticket_images.${key}`);
      } else {
        unregisterField(`ticket.visual_types.${key}`);
        unregisterField(`ticket.ticket_images.${key}`);
      }
    });
  }, [languages, registerField]);

  return (
    <LayoutPanel bgColor="main-layout-bg" className={cn(b(), className)}>
      <TextField
        key={`main.prize_names.${language === COMMON_INPUT ? defaultLanguage : language}`}
        name={`main.prize_names.${language === COMMON_INPUT ? defaultLanguage : language}`}
        label={lang.PRIZE_NAME}
        inputHeight="small"
        onBlur={handlePrizeNameBlur}
        disabled={disabled}
        withWrap={true}
        required={true}
      />
      <TabSwitcher tabs={tabsConfig} activeTab={activeTab} onTabClick={setActiveTab} className={b('switcher')} />
      <Offscreen hidden={activeTab !== tabs.WIN_POPUP}>
        <PrizeDiyWinFieldset disabled={disabled} />
      </Offscreen>
      <Offscreen hidden={activeTab !== tabs.TICKET}>
        <PrizeDiyTicketFieldset disabled={disabled} />
      </Offscreen>
      <Offscreen hidden={activeTab !== tabs.EMAIL}>
        <PrizeDiyEmailFieldset disabled={disabled} />
      </Offscreen>
    </LayoutPanel>
  );
};

PrizeDiyModalRepresentation.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

PrizeDiyModalRepresentation.defaultProps = {
  className: '',
};

export default PrizeDiyModalRepresentation;
