import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

// components
import Icon from 'client/components/common/icon';
import InfoBlock from 'client/components/common/info-block';

class BasicInformationCard extends Component {
  static propTypes = {
    children: PropTypes.node,
    editMode: PropTypes.bool,
    onEditClick: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  static defaultProps = {
    editMode: false,
  };

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.OPERATION_PAGE;
  }

  render() {
    const {children, onEditClick, editMode} = this.props;
    return (
      <InfoBlock colorTheme="operations">
        <InfoBlock.Title className="info-block--color-operations">
          {this.LANGUAGE.GENERAL_INFORMATION.TITLE}
        </InfoBlock.Title>
        <InfoBlock.Content>
          <InfoBlock.Header className="clearfix">
            {!editMode && (
              <button className="button button--circle button--bg-operations pull-right" onClick={onEditClick}>
                <Icon name="edit" className="button__icon" width={20} height={20} />
              </button>
            )}
          </InfoBlock.Header>
          <InfoBlock.Body>{children}</InfoBlock.Body>
        </InfoBlock.Content>
      </InfoBlock>
    );
  }
}

export default connect(({languageState}) => ({languageState}))(BasicInformationCard);
