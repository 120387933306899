export const WS_TYPES = {
  WELCOME: 'welcome',
  DISCONNECT: 'disconnect',
  REJECT_SUBSCRIPTION: 'reject_subscription',
  CONFIRM_SUBSCRIPTION: 'confirm_subscription',
} as const;

export const WS_CHANNELS = {
  STATISTIC_EXPORTS: '{"channel": "StatisticExportNotificationChannel"}',
} as const;
