import React from 'react';

import arrayMove from 'array-move';
import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {SortableContainer} from 'react-sortable-hoc';

import bem from 'client/services/bem';

import {
  updateGame,
  updatePrizeOrdersInStore,
  updatePrizeMap,
  updatePrizeMapsOrdersInStore,
} from 'client/ducks/games/actions';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Icon from 'client/components/common/icon';

import PrizesTableContainer from './prizes-table-container';

import './prizes-table.scss';

const b = bem('prizes-table');

class PrizesTable extends React.Component {
  static propTypes = {
    game: PropTypes.object.isRequired,
    onAdd: PropTypes.func.isRequired,
    onSelectPrize: PropTypes.func.isRequired,
    isPrizeMap: PropTypes.bool.isRequired,
    onSortEnd: PropTypes.func,
    fetchPrizes: PropTypes.func,
    fetchPrizeMaps: PropTypes.func,
    activatePrizeMap: PropTypes.func.isRequired,
    deletePrize: PropTypes.func.isRequired,
    prizes: PropTypes.array,
    prizeMaps: PropTypes.array,
    disabledActions: PropTypes.bool,
    disabledActivation: PropTypes.bool,

    //  from state:
    updatePrizeOrdersInStore: PropTypes.func.isRequired,
    updatePrizeMapsOrdersInStore: PropTypes.func.isRequired,
    updateGame: PropTypes.func.isRequired,
    updatePrizeMap: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
  };

  static defaultProps = {
    hideSortableGhost: false,
    updateMe: null,
    prizeMaps: [],
    prizes: [],
  };

  onSortEnd = ({oldIndex, newIndex}) => {
    const {prizes, game, isPrizeMap, prizeMaps} = this.props;

    const nextPrizeMaps = arrayMove(isPrizeMap ? prizeMaps : prizes, oldIndex, newIndex);
    const nextPrizes = arrayMove(isPrizeMap ? prizeMaps : prizes, oldIndex, newIndex).map((prize, index) => {
      if (isPrizeMap) {
        nextPrizeMaps[index] = {
          ...nextPrizeMaps[index],
          order: index,
        };
      }
      return {
        ...prize,
        id: isPrizeMap ? prize.prize_id : prize.id,
        order: index,
      };
    });

    this.props.updateGame(game.id, {
      game: {
        id: game.id,
        prizes: nextPrizes.map(({id, order}) => ({
          id,
          order,
        })),
      },
    });
    if (isPrizeMap) {
      this.props.updatePrizeMapsOrdersInStore(nextPrizeMaps);
    } else {
      this.props.updatePrizeOrdersInStore(nextPrizes);
    }
  };

  render() {
    const {
      lang,
      prizes,
      prizeMaps,
      onAdd,
      onSelectPrize,
      isPrizeMap,
      disabledActions,
      disabledActivation,
      deletePrize,
      activatePrizeMap,
    } = this.props;

    return (
      <div className={b()}>
        <div className={b('header')}>
          <span className="title-h2">{lang.TITLE}</span>
          {!isPrizeMap && (
            <button
              type="button"
              className={cn(b('add'), 'button button--bg-4')}
              onClick={onAdd}
              disabled={disabledActions}
            >
              {lang.ADD_PRIZE}
              <Icon name="plus" />
            </button>
          )}
        </div>
        <CustomScrollbars style={{maxHeight: 240}} scrollbarProps={{autoHeightMax: 240}}>
          <PrizesTableContainer
            disabledActions={disabledActions}
            disabledActivation={disabledActivation}
            prizes={isPrizeMap ? prizeMaps : prizes}
            lang={lang}
            onActivate={activatePrizeMap}
            isPrizeMap={isPrizeMap}
            useDragHandle
            onSortEnd={this.onSortEnd}
            onSelectPrize={onSelectPrize}
            onDelete={deletePrize}
          />
        </CustomScrollbars>
      </div>
    );
  }
}

export default connect(
  ({languageState}) => ({
    lang: languageState.payload.GAMES.GAME_CONFIG_MODAL.PRIZES_TABLE,
  }),
  {
    updateGame,
    updatePrizeOrdersInStore,
    updatePrizeMapsOrdersInStore,
    updatePrizeMap,
  },
)(SortableContainer(PrizesTable));
