import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';
import {convertSecondsToDays} from 'client/services/helpers';

import {getParticipationsStatisticsById} from 'client/ducks/participations-statistics/actions';
import {selectParticipationsStatistics} from 'client/ducks/participations-statistics/selectors';
import {selectCurrentMembership} from 'client/ducks/user-clients/selectors';
import {selectViewMode} from 'client/ducks/user/selectors';

import CustomCard from 'client/common/cards/custom-card';
import BarChart from 'client/common/charts/bar-chart';

import StatisticExportsBlock from 'client/components/statistic-exports/blocks/statistic-exports-block';

import cssModule from './participation-info-block.module.scss';

const b = bem('participation-info-block', {cssModule});

const calculateParticipants = (newAmount, oldAmount) => {
  const total = newAmount + oldAmount;
  const percentOfNewParticipants = total ? Math.floor((newAmount / total) * 100) : 0;
  const percentOfOldParticipants = total && percentOfNewParticipants > 0 ? 100 - percentOfNewParticipants : 0;

  return [total, percentOfNewParticipants, percentOfOldParticipants];
};

// eslint-disable-next-line react/prop-types
const CardHeader = ({title, amount, textColor = '#00629C', showZero}) => {
  const noAmountSign = showZero ? '0' : '-';
  const value = amount || +amount[0] ? amount : noAmountSign;

  return (
    <div className={b('header')}>
      <div className={b('header-title')}>{title}</div>
      <div className={b('header-total')} style={{color: textColor}}>
        {value}
      </div>
    </div>
  );
};

class MainInfoBlock extends Component {
  static propTypes = {
    autotask: PropTypes.object,
    common: PropTypes.shape({
      average_duration: PropTypes.number,
      conversation_rate: PropTypes.number,
      opt_ins: PropTypes.shape({
        total: PropTypes.number,
        total_structural: PropTypes.number,
        opt_in_by_types: PropTypes.array,
        rate: PropTypes.number,
      }),
      visits: PropTypes.shape({
        total: PropTypes.number,
        interfaces: PropTypes.array,
      }),
      leads: PropTypes.shape({
        new_leads: PropTypes.number,
        old_leads: PropTypes.number,
      }),
      participations: PropTypes.shape({
        total: PropTypes.number,
        interfaces: PropTypes.array,
      }),
    }),
    language: PropTypes.object,
    getParticipationsStatistics: PropTypes.func.isRequired,
    membership: PropTypes.object.isRequired,
    viewMode: PropTypes.object.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.autotask.id !== prevProps.autotask.id) {
      this.fetchData();
    }
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchData = () => {
    const {autotask, getParticipationsStatistics} = this.props;
    const queryParams = {
      automation_task_id: autotask.id,
    };
    this.toggleLoading();
    getParticipationsStatistics(queryParams).then(this.toggleLoading);
  };

  renderProgressBars = (type) => {
    const {common} = this.props;
    let data;
    if (type === 'visits' || type === 'participations') {
      data = common[type].interfaces.filter((elem) => elem && elem.count > 0);
      let total = data.reduce((acc, item) => acc + item.count, 0);
      let barColor = type === 'participations' ? 'participants' : 'visits';

      return data.map((elem, idx) => (
        <BarChart
          key={idx}
          orientation="horizontal"
          title={elem.name}
          number={elem.count}
          percent={Math.floor((elem.count / total) * 100)}
          color="text-normal"
          textColor="text-normal"
          barColor={barColor}
          className={b('bar-chart')}
          classNameTotal={b('bar-chart-total')}
          classNameStripe={b('bar-chart-stripe')}
        />
      ));
    }
    const {opt_in_by_types} = common[type];
    return opt_in_by_types.map((elem, idx) => {
      let barColor = elem.optin_type === 0 ? 'lead-optin-bar' : 'lead-optin-bar-lighter';

      return (
        <BarChart
          key={idx}
          orientation="horizontal"
          title={elem.name}
          number={elem.count}
          percent={Math.round(elem.rate)}
          color="text-normal"
          textColor="text-normal"
          barColor={barColor}
          bgColor="thumb"
          className={b('bar-chart')}
          classNameTotal={b('bar-chart-total')}
          classNameStripe={b('bar-chart-stripe')}
        />
      );
    });
  };

  render() {
    const {language, common, autotask, membership, viewMode} = this.props;
    const {loading} = this.state;
    const [totalParticipants, percentOfNewParticipants, percentOfOldParticipants] = calculateParticipants(
      common.leads.new_leads,
      common.leads.old_leads,
    );

    // WEEZIO-2602: Temporarily show exports block only in redirection mode
    const showExport = ['client_admin', 'national', 'full'].includes(membership?.access_level) && viewMode.on;

    return (
      <div className={b()}>
        <div className={b('row')}>
          <div className={b('col-4')}>
            <CustomCard
              format="row"
              loading={loading}
              loadingColor="participants"
              header={
                <CardHeader
                  title={language.VISITORS}
                  amount={formatNumberSpace(totalParticipants)}
                  textColor="#5dc4c4"
                  showZero={true}
                />
              }
            >
              <div className={b('bars-section')}>
                <BarChart
                  number={common.leads.new_leads}
                  percent={percentOfNewParticipants}
                  color="text-normal"
                  title={language.NEW}
                  orientation="vertical"
                  barColor="participants"
                  titleColor="text-additional"
                  textColor="text-normal"
                />
                <BarChart
                  number={common.leads.old_leads}
                  percent={percentOfOldParticipants}
                  color="text-normal"
                  title={language.OLD}
                  orientation="vertical"
                  barColor="participants"
                  titleColor="text-additional"
                  textColor="text-normal"
                />
              </div>
            </CustomCard>
          </div>
          <div className={showExport ? b('col-6') : b('col-8')}>
            <CustomCard
              format="column"
              loading={loading}
              header={
                <CardHeader title={language.AVERAGE_DURATION} amount={convertSecondsToDays(common.average_duration)} />
              }
            />
            <CustomCard
              format="column"
              loading={loading}
              header={
                <CardHeader
                  title={language.CONVERSION_RATE}
                  amount={common.conversation_rate ? `${common.conversation_rate}%` : ''}
                />
              }
            />
            <CustomCard
              format="column"
              loading={loading}
              header={
                <CardHeader
                  title={language.OPTIN_RATE}
                  amount={common.opt_ins.rate >= 0 ? `${common.opt_ins.rate}%` : ''}
                />
              }
            />
          </div>
          {showExport && (
            <div className={b('col-2')}>
              <StatisticExportsBlock autotask={autotask} membership={membership} loading={loading} />
            </div>
          )}
        </div>
        <div className={b('row')}>
          <div className={b('col-4')}>
            <CustomCard
              format="column"
              loading={loading}
              loadingColor="visits"
              header={
                <CardHeader
                  title={language.VISITS}
                  amount={formatNumberSpace(common.visits.total)}
                  textColor="#8ed2f8"
                  showZero={true}
                />
              }
            >
              {this.renderProgressBars('visits')}
            </CustomCard>
          </div>
          <div className={b('col-4')}>
            <CustomCard
              format="column"
              loading={loading}
              loadingColor="participants"
              header={
                <CardHeader
                  title={language.PARTICIPATIONS}
                  amount={formatNumberSpace(common.participations.total)}
                  textColor="#5dc4c4"
                  showZero={true}
                />
              }
            >
              {this.renderProgressBars('participations')}
            </CustomCard>
          </div>
          <div className={b('col-4')}>
            <CustomCard
              format="column"
              loading={loading}
              header={<CardHeader title={language.OPTINS} amount={formatNumberSpace(common.opt_ins.total)} />}
            >
              {this.renderProgressBars('opt_ins')}
            </CustomCard>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  ({languageState, ...state}) => ({
    language: languageState.payload.PARTICIPATIONS_TAB,
    common: selectParticipationsStatistics(state),
    membership: selectCurrentMembership(state),
    viewMode: selectViewMode(state),
  }),
  {getParticipationsStatistics: getParticipationsStatisticsById},
)(MainInfoBlock);
