import React from 'react';

import 'chart.js/auto';
import PropTypes from 'prop-types';

import {formatNumberSpace} from 'client/services/formatters';
import {getPercent} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import {TranslationJsx} from 'client/models/language/types';

import {DonutChartDoughnut, DonutChartDoughnutEmpty} from './components';
import {sortData, mapData} from './helpers';

const DonutChart = (props) => {
  const {
    title,
    data,
    legendOnRight,
    showTooltip,
    showLegend,
    showLegendPercent,
    legendValuesOnRight,
    width,
    height,
    legendMaxHeight,
  } = props;
  const isEmpty = !data || !data.length;
  const lang = useLanguage('DONUT_CHART');

  const chartData = sortData(data, lang) || [
    {
      label: lang.UNKNOWN,
      value: 1,
      color: '#DCE1EA',
    },
  ];

  const {labels, values, colors, total} = mapData(chartData);

  const notZeroCount = chartData.reduce((sum, {value}) => (value > 0 ? sum + 1 : sum), 0);

  return (
    <>
      {isEmpty ? (
        <DonutChartDoughnutEmpty title={title} width={width} height={height} />
      ) : (
        <DonutChartDoughnut
          title={title}
          showLegend={showLegend}
          chartData={chartData}
          legendOnRight={legendOnRight}
          total={total}
          showLegendPercent={showLegendPercent}
          legendValuesOnRight={legendValuesOnRight}
          legendMaxHeight={legendMaxHeight}
          width={width}
          height={height}
          data={{
            labels,
            datasets: [
              {
                data: values,
                backgroundColor: colors,
                borderWidth: notZeroCount > 1 ? 2 : 0,
              },
            ],
          }}
          options={{
            cutout: notZeroCount > 1 ? '73%' : '75%',
            animation: {duration: 0},
            plugins: {
              legend: {display: false},

              tooltip: {
                enabled: showTooltip && !!data,
                callbacks: {
                  title: ([{dataIndex}]) => labels[dataIndex]?.toString(),
                  label: ({dataIndex}) => formatNumberSpace(values[dataIndex]),
                  labelTextColor: ({dataIndex}) => colors[dataIndex],
                  afterLabel: ({dataIndex}) => getPercent(total, values[dataIndex]),
                },
                displayColors: false,
                backgroundColor: '#fff',
                titleFont: {
                  size: 16,
                  family: 'barlow, serif',
                  weight: 'normal',
                },
                titleColor: '#404f6b',
                bodyFont: {
                  size: 20,
                  family: 'barlow, serif',
                  weight: 'bold',
                },
                bodyColor: '#404f6b',
                borderColor: '#dce1ea',
                borderWidth: 1,
                xPadding: 10,
                yPadding: 10,
              },
            },
          }}
        />
      )}
    </>
  );
};

DonutChart.propTypes = {
  title: TranslationJsx,
  legendOnRight: PropTypes.bool,
  showTooltip: PropTypes.bool,
  showLegend: PropTypes.bool,
  showLegendPercent: PropTypes.bool,
  legendValuesOnRight: PropTypes.bool,
  legendMaxHeight: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: TranslationJsx,
      value: PropTypes.number,
      color: PropTypes.string,
    }),
  ),
  width: PropTypes.number,
  height: PropTypes.number,
};

DonutChart.defaultProps = {
  data: null,
  title: null,
  legendOnRight: false,
  showTooltip: true,
  showLegend: true,
  showLegendPercent: false,
  legendMaxHeight: 200,
  legendValuesOnRight: false,
  width: 108,
  height: 108,
};

export default DonutChart;
