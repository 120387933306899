import React, {Component} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, reset, change, initialize, updateSyncWarnings} from 'redux-form';

import {addressTransform} from 'client/services/helpers';

import {getClientsCheckSiren} from 'client/ducks/client-account/actions';
import {interpolate} from 'client/ducks/language/helpers';

import {TextField} from 'client/common/fields';

import CustomScrollbars from 'client/components/common/custom-scrollbars';
import fieldTemplate from 'client/components/common/field';

import {TranslationJsx} from 'client/models/language/types';

import './basic-information-form.scss';

class BasicInformationForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    formValues: PropTypes.object,
    languageState: PropTypes.object.isRequired,
    valid: PropTypes.bool,
    edit: PropTypes.bool,
    pristine: PropTypes.bool,
    submitFailed: PropTypes.bool,
    error: TranslationJsx,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
    getPOCsAgency: PropTypes.func.isRequired,
    changeFieldValue: PropTypes.func,
    subsidiaries: PropTypes.array,
    agencies: PropTypes.array,
    POCsAgency: PropTypes.array,
    memberships: PropTypes.array,
    adminUsers: PropTypes.array,
    initialize: PropTypes.func,
    isAdmin: PropTypes.bool.isRequired,
  };

  static formName = 'basicInformationForm';
  static transformFields(company) {
    let streets;
    let res = {
      ...company.company,
      street_address_0: '',
      street_address_1: '',
      street_address_2: '',
      subsidiary_email: '',
      subsidiary_phone: '',
      subsidiary_first_name: '',
      subsidiary_last_name: '',
    };

    if (company.company && company.company.street_address) {
      streets = addressTransform.splitAddress(company.company.street_address);
      res = {
        ...res,
        street_address_0: streets[0],
        street_address_1: streets[1],
        street_address_2: streets[2],
      };
    }

    if (company.company && company.company.subsidiary) {
      res = {
        ...res,
        subsidiary_email: company.company.subsidiary.email,
        subsidiary_phone: company.company.subsidiary.phone,
        subsidiary_first_name: company.company.subsidiary.first_name,
        subsidiary_last_name: company.company.subsidiary.last_name,
      };
    }

    return res;
  }

  static validate({name, brand_name, subsidiary, country}, props) {
    const language = props.languageState.payload.AGENCY;

    let errors = {};

    if (!name || !name.trim()) {
      errors.name = language.NAME_REQUIRED_ERROR;
    }
    if (!brand_name || !brand_name.trim()) {
      errors.brand_name = language.BRAND_NAME_REQUIRED_ERROR;
    }
    if (!subsidiary || !subsidiary.id) {
      errors.subsidiary = language.SUBSIDIARY_REQUIRED_ERROR;
    }
    if (!country || !country.trim()) {
      errors.country = language.REQUIRED;
    }

    return errors;
  }

  static async validateAsync({siren_number}, dispatch, props) {
    let errors = {};
    const langCommon = props.languageState.payload.COMMON;

    if (!siren_number) {
      dispatch(
        updateSyncWarnings(
          BasicInformationForm.formName,
          {
            siren_number: '',
          },
          '',
        ),
      );

      return Promise.resolve();
    }

    if (siren_number?.toString().length !== 9) {
      errors.siren_number = langCommon.SIREN.SIREN_IS_NOT_VALID;
    } else {
      try {
        const response = await props.getClientsCheckSiren(siren_number);
        if (response?.payload?.real) {
          if (response?.payload.clients_count) {
            dispatch(
              updateSyncWarnings(
                BasicInformationForm.formName,
                {
                  siren_number: interpolate(langCommon.SIREN.SIREN_EXISTS?.toString(), {
                    siren: response?.payload.clients_count,
                  }),
                },
                '',
              ),
            );
          }
        } else {
          errors.siren_number = langCommon.SIREN.SIREN_DOES_NOT_EXIST;
          dispatch(
            updateSyncWarnings(
              BasicInformationForm.formName,
              {
                siren_number: '',
              },
              '',
            ),
          );
        }
      } catch {
        errors.siren_number = langCommon.SIREN.SIREN_CHECKING_SERVICE_DOES_NOT_RESPOND_TITLE;
        dispatch(
          updateSyncWarnings(
            BasicInformationForm.formName,
            {
              siren_number: '',
            },
            '',
          ),
        );
      }
    }

    if (Object.values(errors).length) {
      return Promise.reject(errors);
    }

    return Promise.resolve();
  }

  constructor(props) {
    super(props);

    this.LANGUAGE = props.languageState.payload.AGENCY;
    this.langCommon = props.languageState.payload.COMMON;
  }

  componentDidUpdate(prevProps) {
    const {edit, formValues, getPOCsAgency} = this.props;

    if (edit && !prevProps.edit && formValues.values.agency) {
      getPOCsAgency(formValues.values.agency.id);
    }
  }

  handleCancelClick = () => {
    this.props.reset();
    this.props.onCancel();
  };

  handleDetachAgencyClick = () => {
    this.props.getPOCsAgency();
    this.props.changeFieldValue('agency', null);
    this.props.changeFieldValue('poc_agency_membership', null);
  };

  save = (values) => {
    const {
      id,
      brand_name,
      city,
      comment,
      country,
      name,
      street_address_0,
      street_address_1,
      street_address_2,
      website,
      zip,
      poc_admin_user,
      poc_membership_id,
      poc_agency_membership,
      subcategory,
      subsidiary,
      agency,
      siren_number,
    } = values;

    const street_address = addressTransform.joinAddress([street_address_0, street_address_1, street_address_2]);

    this.props
      .onSave({
        company: {
          id,
          brand_name,
          city,
          comment,
          country,
          name,
          street_address,
          website,
          zip,
          siren_number,
          poc_admin_user_id: poc_admin_user && poc_admin_user.id,
          poc_membership_id: poc_membership_id,
          poc_agency_membership_id: poc_agency_membership && poc_agency_membership.id,
          subcategory_id: subcategory && subcategory.id,
          subsidiary_id: subsidiary && subsidiary.id,
          agency_id: (agency && agency.id) || null,
        },
      })
      .then(() => this.props.initialize(this.props.initialValues));
  };

  render() {
    const {
      handleSubmit,
      valid,
      asyncValidating,
      pristine,
      edit,
      formValues,
      subsidiaries,
      memberships,
      adminUsers,
      agencies,
      getPOCsAgency,
      POCsAgency,
      changeFieldValue,
      isAdmin,
    } = this.props;

    let POCBuzzeoExists;
    let POCClientExists;
    let websiteExists;
    let commentExists;
    let zipExists;
    let cityExists;
    let countryExists;
    let streetAddressExists;
    let agencyExists;
    let POCAgencyExists;
    let logo;
    let comment;

    if (formValues && formValues.values) {
      const {values: v} = formValues;
      POCBuzzeoExists = v.poc_admin_user_id;
      POCClientExists = v.poc_membership_id;
      agencyExists = v.agency && v.agency.id;
      POCAgencyExists = v.poc_agency_membership_id;
      websiteExists = v.website;
      commentExists = v.comment;
      zipExists = v.zip;
      cityExists = v.city;
      countryExists = v.country;
      streetAddressExists = v.street_address;
      logo = v.logo;
      comment = v.comment;
    }

    return (
      <div>
        <form
          className="basic-information-form basic-information-form--expand"
          noValidate="noValidate"
          onSubmit={handleSubmit(this.save)}
        >
          <CustomScrollbars
            scrollbarProps={{
              autoHeightMax: edit ? 247 : 300,
            }}
          >
            <div className="basic-information-form__inner">
              {!edit && logo && logo.url && (
                <div className="form-field-wrap">
                  <div className="fake-input__label">{this.LANGUAGE.LOGO_LABEL}</div>
                  <img alt="" src={logo.url} className="basic-information-form__logo" />
                </div>
              )}
              <div className="form-field-wrap">
                <Field
                  cssModifier="input--disabled-view-1 input--view-3 input--ellipsis"
                  disabled={!edit}
                  label={this.LANGUAGE.CLIENT_NAME_LABEL}
                  name="name"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className="form-field-wrap">
                {edit ? (
                  <TextField label={this.langCommon.SIREN.SIREN_LABEL} name="siren_number" maxlength={9} />
                ) : (
                  <Field
                    cssModifier="input--disabled-view-1 input--view-3 input--ellipsis"
                    disabled
                    label={this.langCommon.SIREN.SIREN_LABEL}
                    name="siren_number"
                    type="text"
                    component={fieldTemplate}
                  />
                )}
              </div>
              <div className="form-field-wrap">
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.BRAND_NAME_LABEL}
                  name="brand_name"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !agencyExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="select--disabled-view-1 select--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.AGENCY_LABEL}
                  name="agency"
                  labelKey="name"
                  valueKey="id"
                  type="select"
                  searchable={false}
                  onChange={({id}) => {
                    changeFieldValue('poc_agency_membership', null);
                    getPOCsAgency(id);
                  }}
                  options={agencies}
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !POCAgencyExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="select--disabled-view-1 select--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.POC_AGENCY_LABEL}
                  name="poc_agency_membership"
                  labelKey="title"
                  valueKey="id"
                  type="select"
                  searchable={false}
                  options={POCsAgency}
                  component={fieldTemplate}
                />
              </div>
              {edit && agencyExists && (
                <button
                  type="button"
                  className="button button--bg-1 detach-button"
                  onClick={this.handleDetachAgencyClick}
                >
                  {this.LANGUAGE.DETACH_AGENCY_BUTTON}
                </button>
              )}
              <div className={`form-field-wrap${agencyExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="select--disabled-view-1 select--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.SUBSIDIARY_LABEL}
                  name="subsidiary"
                  labelKey="name"
                  valueKey="id"
                  type="select"
                  searchable={false}
                  options={subsidiaries}
                  component={fieldTemplate}
                />
              </div>

              <div className={`form-field-wrap${!edit && !POCClientExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier={`select--disabled-view-${edit ? 1 : 2} select--view-3`}
                  disabled={!edit}
                  label={this.LANGUAGE.POC_CLIENT_LABEL}
                  name="poc_membership_id"
                  type="select"
                  searchable={false}
                  simpleValue
                  options={memberships.map((item) => ({value: item.id, label: item.client_user?.full_name}))}
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${edit || !POCClientExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-2 input--no-min-height"
                  disabled
                  name="poc_client_phone"
                  type="phone"
                  component={fieldTemplate}
                />
              </div>

              <div className={`form-field-wrap${!edit && !POCBuzzeoExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier={`select--disabled-view-${edit ? 1 : 2} select--view-3`}
                  disabled={!edit}
                  label={this.LANGUAGE.POC_SUBSIDIARY_LABEL.replace('{N}', get(formValues, 'values.subsidiary.name'))}
                  name="poc_admin_user"
                  labelKey="full_name"
                  valueKey="id"
                  type="select"
                  searchable={false}
                  options={adminUsers}
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${edit || !POCBuzzeoExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-2 input--no-min-height"
                  disabled
                  name="poc_buzzeo_email"
                  type="email"
                  component={fieldTemplate}
                />
                <Field
                  cssModifier="input--disabled-view-2"
                  disabled
                  name="poc_buzzeo_phone"
                  type="phone"
                  component={fieldTemplate}
                />
              </div>

              <div className={`form-field-wrap${!edit && !websiteExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.WEBSITE_LABEL}
                  name="website"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !streetAddressExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.ADDRESS_LABEL}
                  name="street_address_0"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !streetAddressExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.ADDRESS_LABEL}
                  name="street_address_1"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !streetAddressExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.ADDRESS_LABEL}
                  name="street_address_2"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !cityExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.CITY_LABEL}
                  name="city"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !countryExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.COUNTRY_LABEL}
                  name="country"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className={`form-field-wrap${!edit && !zipExists ? ' hidden' : ''}`}>
                <Field
                  cssModifier="input--disabled-view-1 input--view-3"
                  disabled={!edit}
                  label={this.LANGUAGE.ZIP_LABEL}
                  name="zip"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              {isAdmin && (
                <div className={`form-field-wrap${!edit && !commentExists ? ' hidden' : ''}`}>
                  {edit ? (
                    <Field
                      cssModifier="input--disabled-view-1 input--view-3"
                      disabled={!edit}
                      label={this.LANGUAGE.COMMENTS_LABEL}
                      name="comment"
                      type="textarea"
                      component={fieldTemplate}
                    />
                  ) : (
                    <div className="fake-input fake-input--disabled">
                      <div className="fake-input__label">{this.LANGUAGE.COMMENTS_LABEL}</div>
                      <CustomScrollbars scrollbarProps={{autoHeightMax: 64}}>
                        <div className="fake-input__textarea">{comment}</div>
                      </CustomScrollbars>
                    </div>
                  )}
                </div>
              )}
            </div>
          </CustomScrollbars>

          {edit && (
            <div className="basic-information-form__bottom">
              <button
                type="button"
                className="basic-information-form__btn button button--bg-11"
                onClick={this.handleCancelClick}
              >
                {this.LANGUAGE.CANCEL_BUTTON}
              </button>
              <button
                className="basic-information-form__btn button button--bg-1"
                type="submit"
                disabled={!valid || asyncValidating || pristine}
              >
                {this.LANGUAGE.SAVE_BUTTON}
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const basicInformationForm = reduxForm({
  form: BasicInformationForm.formName,
  enableReinitialize: true,
  validate: BasicInformationForm.validate,
  asyncValidate: BasicInformationForm.validateAsync,
  asyncChangeFields: ['siren_number'],
})(BasicInformationForm);

export default connect(
  ({languageState, company, form}) => ({
    languageState,
    initialValues: BasicInformationForm.transformFields(company),
    formValues: form.basicInformationForm,
  }),
  {
    reset,
    initialize,
    changeFieldValue: (field, value) => change(BasicInformationForm.formName, field, value),
    getClientsCheckSiren,
  },
)(basicInformationForm);
