import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import sortBy from 'lodash/sortBy';

import {blobToBase64} from 'client/services/blobToBase64';
import {fetchFile, getFileName} from 'client/services/helpers';

import {ACCESS_LEVEL_ORDER} from 'client/common/config';

import {SCREEN_FORMAT_PRIORITY, SCREEN_FORMATS, SPECIAL_SELECTOR_TYPES} from './constants';

import {ANSWER_KEY_MAP, INPUT_TYPES, DEFAULT_COLOR, CHECKABLE_ITEMS} from '../diy-customization-modal/constants';

const isObject = (val) => typeof val === 'object' && !Array.isArray(val) && val !== null;
const isEmpty = (val) =>
  (Array.isArray(val) && !val.length) ||
  (isObject(val) && !Object.keys(val).length) ||
  (isObject(val) && Object.keys(val).length && !Object.values(val).filter((key) => key).length);

function isIncluded(arr, obj, key) {
  if (!(key in obj)) {
    return false;
  }
  const found = arr.find((item) => item[key] === obj[key]);
  return Boolean(found);
}

function updateNewTemplateAnswerList(initialAnswers, answer, {type, itemType}) {
  if (!initialAnswers.length) {
    return [answer];
  }

  switch (type) {
    case INPUT_TYPES.FIELD:
      if (itemType === 'string') {
        return !isIncluded(initialAnswers, answer, 'language')
          ? [...initialAnswers, answer]
          : initialAnswers.map((item) => {
              const {language: lang} = item;
              return lang === answer.language ? answer : item;
            });
      }
      break;

    case INPUT_TYPES.IMAGE:
      const templateAnswer = initialAnswers.find(
        (item) => item.language === answer.language && item.screen_format === answer.screen_format,
      );

      if (templateAnswer) {
        return initialAnswers.map((item) => {
          const {language: lang, screen_format} = item;
          return lang === answer.language && screen_format === answer.screen_format ? answer : item;
        });
      }
      return [...initialAnswers, answer];

    default:
      break;
  }

  return [answer];
}
// function for saving modified template_answer of form_item
export function editFormData(formData, {formPageId, formSectionId, formItemId, value, params}) {
  const formPage = formData.find((page) => page.id === formPageId);
  const formSection = formPage.form_sections.find((section) => section.id === formSectionId);
  const editedFormItem = formSection.form_items.find((item) => item.id === formItemId);

  const {template_answers, display_type, item_type} = editedFormItem;
  let newAnswers;

  if (Array.isArray(value)) {
    newAnswers = [...value];
  } else if (value.id) {
    newAnswers = template_answers.map((answer) => (answer.id === value.id ? value : answer));
  } else {
    newAnswers = updateNewTemplateAnswerList(template_answers, value, {type: display_type, itemType: item_type});
  }

  editedFormItem.template_answers = newAnswers;

  const newFormSection = {
    ...formSection,
    form_items: formSection.form_items.map((item) => (item.id === formItemId ? {...editedFormItem, ...params} : item)),
  };

  const newFormPage = {
    ...formPage,
    form_sections: formPage.form_sections.map((item) => (item.id === formSectionId ? newFormSection : item)),
  };

  return formData.map((item) => (item.id === formPageId ? newFormPage : item));
}

const updatePageAccessesById = (formPageId, formSectionId, formItemId, access, formPage, type) => {
  const updatedFormSections = formPage?.form_sections.map((formSection) => {
    const updatedFormItems = formSection.form_items.map((formItem) => {
      const formItemAccess =
        (type === 'formPage' && formPageId === formPage.id) ||
        (type === 'formSection' && formSectionId === formSection.id) ||
        (type === 'formItem' && formItemId === formItem.id)
          ? access
          : formItem.access;
      return {
        ...formItem,
        access: formItemAccess,
        form_item_access: {
          id: formItem?.form_item_access?.id,
          access: formItemAccess,
        },
      };
    });

    const updatedFormItemsCurrentAccess = updatedFormItems.reduce((max, current) =>
      ACCESS_LEVEL_ORDER[current.access] > ACCESS_LEVEL_ORDER[max.access] ? current : max,
    ).access;

    // eslint-disable-next-line no-nested-ternary
    const formSectionAccess = updatedFormItems.every((item) => item.access === access)
      ? access
      : (type === 'formPage' && formPageId === formPage.id) ||
        (type === 'formSection' && formSectionId === formSection.id) ||
        (ACCESS_LEVEL_ORDER[access] > ACCESS_LEVEL_ORDER[updatedFormItemsCurrentAccess] &&
          formSectionId === formSection.id)
      ? access
      : updatedFormItemsCurrentAccess;

    return {
      ...formSection,
      access: formSectionAccess,
      form_items: updatedFormItems,
    };
  });

  const updatedFormSectionsCurrentAccess = updatedFormSections.reduce((max, current) =>
    ACCESS_LEVEL_ORDER[current.access] > ACCESS_LEVEL_ORDER[max.access] ? current : max,
  ).access;

  return {
    ...formPage,
    // eslint-disable-next-line no-nested-ternary
    access: updatedFormSections.every((item) => item.access === access)
      ? access
      : (type === 'formPage' && formPageId === formPage.id) ||
        (ACCESS_LEVEL_ORDER[access] > ACCESS_LEVEL_ORDER[updatedFormSectionsCurrentAccess] &&
          formPageId === formPage.id)
      ? access
      : updatedFormSectionsCurrentAccess,
    form_sections: updatedFormSections,
  };
};

const updateAccessData = (formPageId, formSectionId, formItemId, access, formPage, type) => {
  const newData = [];
  const formSection = formPage?.form_sections.find((section) => section.id === formSectionId) || null;
  const editedFormItem = formSection?.form_items.find((item) => item.id === formItemId) || null;

  switch (type) {
    case 'formPage':
      if (formPage.id === formPageId) {
        formPage.form_sections.forEach((section) => {
          section.form_items.forEach((item) => {
            newData.push({
              id: item.form_item_access.id,
              access: access,
            });
          });
        });
      }
      break;

    case 'formSection':
      if (formSection.id === formSectionId) {
        formSection.form_items.forEach((item) => {
          newData.push({
            id: item.form_item_access.id,
            access: access,
          });
        });
      }
      break;

    case 'formItem':
      if (editedFormItem.id === formItemId) {
        newData.push({
          id: editedFormItem.form_item_access.id,
          access: access,
        });
      }
      break;

    default:
      break;
  }

  return {
    newFormItemAccess: {form_item_access: newData},
    newPageAccesses: updatePageAccessesById(formPageId, formSectionId, formItemId, access, formPage, type),
  };
};

export const editFormAccessLevels = (formPagesItemAccesses, {formPageId, formSectionId, formItemId, value, type}) => {
  const formPage = formPagesItemAccesses.find((page) => page.id === formPageId);

  return updateAccessData(formPageId, formSectionId, formItemId, value, formPage, type);
};

export const saveTemplateAnswer = (value) => (prevTemplateAnswers) => {
  if (!prevTemplateAnswers.length) {
    return [...([value]?.flat() || [])];
  }

  if (Array.isArray(value)) {
    let filtered = [...prevTemplateAnswers];
    value.forEach((ans) => {
      filtered = filterTemplateAnswers(filtered, ans);
    });
    return [...filtered, ...value];
  }
  const filtered = filterTemplateAnswers(prevTemplateAnswers, value);
  return [...filtered, value];
};

function filterTemplateAnswers(items, value) {
  return items.filter((item) => {
    if (value.display_type === INPUT_TYPES.FIELD) {
      return item.form_item_id !== value.form_item_id ? item : item.language !== value.language;
    }
    if (value.display_type === INPUT_TYPES.IMAGE) {
      return item.form_item_id !== value.form_item_id
        ? item
        : item.language !== value.language || item.screen_format !== value.screen_format;
    }
    return item.form_item_id !== value.form_item_id;
  });
}

const accessMaper = {
  admin: 'admin',
  national: 'full',
  region: 'regions', // region
  store: 'places', // place?
};

const CLIENT_ADMIN = 'client_admin';

const accessLevels = {
  admin: [accessMaper.admin, CLIENT_ADMIN],
  national: [accessMaper.admin, CLIENT_ADMIN, accessMaper.national],
  region: [accessMaper.admin, CLIENT_ADMIN, accessMaper.national, accessMaper.region],
  store: [accessMaper.admin, CLIENT_ADMIN, accessMaper.national, accessMaper.region, accessMaper.store],
};

export function checkVisibility(item, params) {
  const {
    access,
    kiosk_only,
    online_only,
    default_hide,
    form_conditions = [],
    conditions_operator = 'or',
    template_answers = [],
    display_type,
  } = item;
  const {userRole, isSetToKiosk, isSetToOnline, template} = params;

  const isDeviceVisible = isSetToKiosk || (!isSetToKiosk && !kiosk_only);
  const isOnlineVisible = isSetToOnline || (!isSetToOnline && !online_only);

  const globalVisibilityParams =
    accessLevels[access].includes(userRole) &&
    isDeviceVisible &&
    isOnlineVisible &&
    isVisibleByConditionsWithOptions(item, template);

  let isItemVisible;
  if (!form_conditions?.length) {
    isItemVisible = !default_hide;
  } else {
    const isVisible = isVisibleByConditions({
      form_conditions,
      conditions_operator,
      template_answers,
      display_type,
      default_hide,
      template,
    });
    let shouldBeHidden = default_hide;
    if (isVisible) {
      shouldBeHidden = !default_hide;
    }
    isItemVisible = !shouldBeHidden;
  }

  return globalVisibilityParams && isItemVisible;
}

export function isVisibleByConditionsWithOptions(formItem, template) {
  const conditions = [formItem.condition1, formItem.condition2, formItem.condition3];
  const options = [template?.option1, template?.option2, template?.option3];

  return conditions.reduce((acc, condition, index) => {
    if (condition?.length && isNumber(options[index])) {
      return acc && condition.includes(options[index]);
    }
    return acc;
  }, true);
}

function isVisibleByConditions({form_conditions, conditions_operator, template}) {
  const results = form_conditions
    .map(({condition_source, value: defaultValue, operator, form_item_id, option}) => {
      if (form_item_id) {
        const conditionSourceDisplayType = condition_source?.display_type;
        if (
          !CHECKABLE_ITEMS.includes(conditionSourceDisplayType) ||
          (conditionSourceDisplayType === 'field' && condition_source.item_type === 'string')
        ) {
          return true;
        }
        const answer = condition_source?.template_answers?.[0];
        let value = answer?.[getAnswerKeyMap(condition_source)];

        if (Array.isArray(value)) {
          value = value.map((item) => item.value || item.form_choice?.key);
        }

        return checkCondition(operator, defaultValue, value);
      }

      const value = template[`option${option}`];
      return checkCondition(operator, defaultValue, value);
    })
    .filter((result) => Boolean(result?.toString()));

  if (results.length === 1) {
    return results[0];
  }
  const operator = conditions_operator?.toString()?.replaceAll('"', '').toLowerCase();
  if (operator === 'or') {
    return results.some((item) => item);
  } else if (operator === 'and') {
    return results.every((item) => item);
  }

  return true;
}

function checkCondition(operator, defaultValue, value) {
  try {
    const checkNumberOperators = () => {
      if (!isNumber(value) || !isNumber(defaultValue)) {
        throw new Error(`default value or value is not a number. it's incorrect types for ${operator} operator`);
      }
    };

    switch (operator) {
      case 'equal':
        return JSON.stringify(value) === defaultValue;

      case 'not_equal':
        return JSON.stringify(value) !== defaultValue;

      case 'superior':
        checkNumberOperators();
        return +defaultValue > +value;

      case 'inferior':
        checkNumberOperators();
        return +defaultValue < +value;

      case 'superior_and_equal':
        checkNumberOperators();
        return +defaultValue >= +value;

      case 'inferior_and_equal':
        checkNumberOperators();
        return +defaultValue <= +value;

      case 'in_array': {
        const parsedDefaultValue = defaultValue && JSON.parse(defaultValue);

        return Array.isArray(value)
          ? value.every((item) => parsedDefaultValue.includes(item))
          : parsedDefaultValue.includes(value);
      }

      case 'not_in_array': {
        const parsedDefaultValue = defaultValue && JSON.parse(defaultValue);

        return Array.isArray(value)
          ? !value.some((item) => parsedDefaultValue.includes(item))
          : !parsedDefaultValue.includes(value);
      }

      case 'not_null':
        return Boolean(value);

      default:
        break;
    }

    return false;
  } catch (e) {
    console.error('Data of condition is invalid: ', e);

    //  If the value cannot be checked due to the bad format (e.g., string instead)
    //  or other cases when the condition calculation is not possible, the condition should not be taken into consideration at all.
    return;
  }
}

/**
 * Return map of formId and templateId
 * @param {[object]} templates - Array of all template in client_interface
 * @return {Object.<(string|number), object>} - return map of templates grouped by formId
 */
export function getTemplateByFormIds(templates) {
  return templates?.reduce((acc, template) => {
    const {catalog} = template;
    const catalogForms = catalog?.forms || [];
    const formIds = catalogForms.map(({id: formId}) => formId);
    const newAcc = {
      ...acc,
    };
    formIds.forEach((formId) => (newAcc[formId] = template));
    return newAcc;
  }, {});
}

/**
 * Return template for current formId
 * @param {number} id - id of Form
 * @param {object[]} templates - Array of all template in client_interface
 * @return {object} - template for current formId
 */
export function getTemplateByFormId(id, templates) {
  return getTemplateByFormIds(templates)?.[id];
}

/**
 * Return id of template for current formId
 * @param {number} id - id of Form
 * @param {[object]} templates - Array of all template in client_interface
 * @return {number} - id of template for current formId
 */
export function getTemplateIdByFormId(id, templates) {
  return getTemplateByFormId(id, templates)?.id;
}

export function getFormItemAnswer(answers, params) {
  const {type, fieldInputType, language, device} = params;
  if (!answers.length) {
    return {};
  }

  switch (type) {
    case INPUT_TYPES.FIELD:
      return fieldInputType === 'string' ? answers.find(({language: lang}) => lang === language) : answers[0];

    case INPUT_TYPES.IMAGE:
      return device && language
        ? answers.find(({language: lang, screen_format}) => lang === language && device === screen_format) || {}
        : answers;

    case INPUT_TYPES.TOGGLE:
    case INPUT_TYPES.COLOR:
    case INPUT_TYPES.DROPDOWN:
    case INPUT_TYPES.CAROUSEL:
      return answers[0];

    default:
      return answers[0];
  }
}

export const toFile = async (url) => {
  const filename = getFileName(url);
  const res = url?.startsWith('http') ? await fetchFile(url) : await fetch(url);
  const blob = await res.blob();

  return new File([blob], filename, {type: blob.type});
};

export async function getImageProperties(file) {
  const media = typeof file === 'string' ? await toFile(file) : file;

  let img = new Image();
  const data = await blobToBase64(media);

  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      resolve({data, width, height, size: media.size, type: media.type});
    };
    img.onerror = reject;
  });

  img.src = window.URL.createObjectURL(media);

  return promise;
}

export const formatValue = (answer, formItem) => {
  if ([INPUT_TYPES.FIELD, INPUT_TYPES.TOGGLE, INPUT_TYPES.COLOR].includes(answer?.display_type)) {
    return answer;
  }

  if (formItem?.special_selector !== SPECIAL_SELECTOR_TYPES.NONE && answer?.display_type === INPUT_TYPES.DROPDOWN) {
    return {
      template_answer: {
        ...answer,
      },
    };
  }

  if ([INPUT_TYPES.CAROUSEL, INPUT_TYPES.DROPDOWN].includes(answer?.display_type)) {
    const prevChoices = formItem?.template_answers.length
      ? formItem?.template_answers[0].template_choices?.map(({id}) => ({
          id,
          _destroy: true,
        }))
      : [];

    return {
      template_answer: {
        ...answer,
        template_choices: [...prevChoices, ...answer.template_choices],
      },
    };
  }

  return {template_answer: {...answer}};
};

export function removeEmptyKeys(obj) {
  const keys = Object.keys(obj);
  const newObj = {};
  if (!keys.length) {
    return obj;
  }
  keys.forEach((key) => {
    let value = obj[key];
    if (value && !isObject(value)) {
      newObj[key] = value;
    } else if (isObject(value) && !isEmpty(value)) {
      newObj[key] = removeEmptyKeys(value);
    }
  });
  return newObj;
}

export function getFormItemValue(
  answers,
  formChoices = [],
  {type, inputType, language, templateId, display_multi, special_selector},
) {
  if ((type === INPUT_TYPES.FIELD && ['string', 'date', 'number'].includes(inputType)) || type === INPUT_TYPES.IMAGE) {
    return {
      value: answers,
      templateAnswer: null,
    };
  }

  const templateAnswer = getFormItemAnswer(answers, {
    type,
    fieldInputType: inputType,
    templateId,
  });
  let value;

  switch (type) {
    case INPUT_TYPES.FIELD:
      value = inputType === 'string' ? get(templateAnswer, ANSWER_KEY_MAP[type], '') : templateAnswer.number_field;
      break;

    case INPUT_TYPES.COLOR:
      let color = get(templateAnswer, ANSWER_KEY_MAP[type]);
      if (color?.length === 6) {
        color = `${color}FF`;
      }
      value = `#${color || DEFAULT_COLOR}`;
      break;

    case INPUT_TYPES.TOGGLE:
      value = get(templateAnswer, ANSWER_KEY_MAP[type], false);
      break;

    case INPUT_TYPES.DROPDOWN:
      if (special_selector !== SPECIAL_SELECTOR_TYPES.NONE) {
        if (display_multi) {
          value = answers.map(({string_field}) => string_field);
        } else {
          value = get(templateAnswer, 'string_field', '');
        }
        break;
      }
      const dropdownTemplateChoices = get(templateAnswer, ANSWER_KEY_MAP[type], []);
      if (display_multi) {
        value = dropdownTemplateChoices.map(({form_choice_id}) => {
          const choice = formChoices.find(({id}) => form_choice_id === id);
          const label = choice.form_labels.find((form_label) => form_label.language === language);
          return {
            label: label.title,
            value: choice.key,
          };
        });
      } else {
        let selected = dropdownTemplateChoices[0];
        if (selected) {
          let choice = formChoices.find(({id}) => selected?.form_choice_id === id);
          let label = choice?.form_labels.find((form_label) => form_label.language === language);
          value = {
            ...choice,
            label: label.title,
            value: choice.key,
          };
        } else {
          value = {
            label: '',
            value: '',
          };
        }
      }
      break;

    case INPUT_TYPES.CAROUSEL:
      const carouselTemplateChoices = get(templateAnswer, ANSWER_KEY_MAP[type], []);
      if (display_multi) {
        value = carouselTemplateChoices?.map(({form_choice_id}) => {
          const choice = formChoices.find(({id}) => form_choice_id === id);
          return choice.key;
        });
      } else {
        let selected = carouselTemplateChoices?.[0];
        let choice = selected ? formChoices.find(({id}) => selected?.form_choice_id === id) : {key: ''};
        value = choice.key ? [choice.key] : [];
      }
      break;

    default:
      value = get(templateAnswer, ANSWER_KEY_MAP[type], '');
      break;
  }

  return {value, templateAnswer};
}

// FORM ITEM VALUE EXTRACTORS

// when “display_type” = “toggle” or "color"
const extractSimpleValue = (templateAnswer, accessKey) => templateAnswer[accessKey];

// when “display_type” = “field”
const extractFieldValue = (templateAnswer, accessKey, prevValue) => ({
  ...prevValue,
  [templateAnswer.language]: templateAnswer[accessKey],
});

// when “display_type” = “dropdown” or "carousel"
const extractSelectValue = (templateAnswer, accessKey) => templateAnswer[accessKey].map((choice) => choice.value);

// when “display_type” = “image”
const extractImageValue = (templateAnswer, accessKey, prevValue) => ({
  ...prevValue,
  [templateAnswer.language]: {
    ...prevValue[templateAnswer.language],
    [templateAnswer.screen_format]: get(templateAnswer, `${accessKey}.url`, '').slice(-25), // get last 25 symbols of url as key. optimal if url is base64 string
  },
});

const valueExtractorMapper = {
  [INPUT_TYPES.TOGGLE]: extractSimpleValue,
  [INPUT_TYPES.FIELD]: extractFieldValue,
  [INPUT_TYPES.COLOR]: extractSimpleValue,
  [INPUT_TYPES.DROPDOWN]: extractSelectValue,
  [INPUT_TYPES.CAROUSEL]: extractSelectValue,
  [INPUT_TYPES.IMAGE]: extractImageValue,
};

const updateResultValues = ({value, result, page, formSectionId, formItemId, moduleName, formType}) => {
  const {name: pageName, form_sections} = page;
  const {name: sectionName, form_items} = form_sections.find((section) => section.id === formSectionId);
  const {name: itemName} = form_items.find((item) => item.id === formItemId);

  const sectionToUpdate = result[moduleName][formType][pageName][sectionName];
  let extractValue = valueExtractorMapper[value.display_type];
  let key = ANSWER_KEY_MAP[value.display_type];

  if (value.special_selector === SPECIAL_SELECTOR_TYPES.FONT) {
    extractValue = extractSimpleValue;
    key = 'string_field';
  }
  sectionToUpdate[itemName] = extractValue(value, key, sectionToUpdate[itemName]);

  if (value.display_type === INPUT_TYPES.IMAGE) {
    let url = '';

    // for pdf/mp3/mp4
    if (value?.data) {
      url = value?.data?.media_storage?.file?.data;
    } else {
      url = value.media_storage_item?.media_storage?.url;
    }

    if (url) {
      result.media[url.slice(-25)] = url; // get last 25 symbols of url as key. optimal if url is base64 string
    }
  }

  return result;
};

function getAnswerKeyMap({display_type, item_type}) {
  if (display_type === 'field') {
    return ANSWER_KEY_MAP[`${display_type}_${item_type}`];
  }

  return ANSWER_KEY_MAP[display_type];
}

export function editResult({result, value, ...restParams}) {
  let updatedResult = cloneDeep(result);

  if (Array.isArray(value)) {
    value.forEach((item) => {
      updatedResult = updateResultValues({value: item, result: updatedResult, ...restParams});
    });
  } else {
    updatedResult = updateResultValues({value, result: updatedResult, ...restParams});
  }

  return updatedResult;
}

export function rgba2hex(r = 0, g = 0, b = 0, alpha) {
  let rgb =
    (r | (1 << 8)).toString(16).slice(1) + (g | (1 << 8)).toString(16).slice(1) + (b | (1 << 8)).toString(16).slice(1);

  if (alpha) {
    const a = ((alpha * 255) | (1 << 8)).toString(16).slice(1);
    rgb += a;
  }

  return rgb;
}

export function hex2rgba(color) {
  const r = parseInt(color.slice(1, 3), 16);
  const g = parseInt(color.slice(3, 5), 16);
  const b = parseInt(color.slice(5, 7), 16);
  const alpha = parseInt(color.slice(7, 9) || 'ff', 16);
  return `${r}${g}${b}${alpha}`;
}

export const getDefaultScreenFormat = (formats = []) => {
  const sortedFormats = sortBy(formats, (item) => SCREEN_FORMAT_PRIORITY[item?.value]);
  return sortedFormats[0]?.value || sortedFormats[0] || SCREEN_FORMATS.kiosk_vertical;
};

// prettier-ignore
export const roundValue = (value, digit = 2) => Number((value).toFixed(digit));

export const getMaxValue = (obj) => {
  if (!obj || isEmpty(obj)) {
    return 0;
  }

  return Math.max(...Object.values(obj));
};

export const getRatio = (obj) => {
  if (!obj || isEmpty(obj)) {
    return '';
  }

  const ratioArray = [...Object.values(obj)].filter((value, index, array) => array.indexOf(value) === index);

  if (ratioArray.length > 1) {
    return '';
  }

  return ratioArray[0];
};

export const getNotHideCount = (items) => {
  return (
    items?.reduce((result, {default_hide}) => {
      return default_hide ? result : result + 1;
    }, 0) || 0
  );
};

export const hasRightFormat = (value, formats) => {
  if (value?.name && formats?.length > 0) {
    const extension = value.name.split('.').pop();
    return formats.includes(extension);
  }
  return true;
};

export const toggleDefaultStyleTag = (value = '', defaultStyle = false) => {
  const clearedValue = value.replaceAll(/<\/?default-style>/gi, '');

  return defaultStyle ? `<default-style>${clearedValue}</default-style>` : clearedValue;
};

export const hasDefaultStyleTag = (value = '') => {
  return /<default-style>.*<\/default-style>/gi.test(value);
};

export const removeTags = (value = '') => {
  return value?.replaceAll(/<[^>]*>/gi, '');
};

export const getFormByType = (template, formType) => {
  return template?.catalog?.forms?.find((i) => i.form_type === formType) || null;
};
