import React, {Component} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {reduxForm, SubmissionError, getFormMeta} from 'redux-form';

import bem from 'client/services/bem';
import {passwordValidator} from 'client/services/validator';

import AppButton from 'client/common/buttons/app-button';
import {PasswordField, FieldWrap} from 'client/common/fields';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import {passwordChange} from 'client/components/profile/profile.action';

import ProfilePanel from '../profile-panel';

import './profile-password.scss';

const b = bem('profile-password');

class ProfilePassword extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    passwordChange: PropTypes.func.isRequired,
    formMeta: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool.isRequired,
    anyTouched: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    editPassword: PropTypes.bool.isRequired,
  };

  static FORM_NAME = 'ProfilePasswordForm';

  static validate = (values, props) => {
    const {lang} = props;
    const errors = {};

    const matchError =
      get(props.formMeta, 'confirmPassword.touched') &&
      values.confirmPassword &&
      values.newPassword !== values.confirmPassword;

    if (matchError) {
      errors.newPassword = ' ';
      errors.confirmPassword = lang.PASSWORD_MATCH_ERROR;
    }

    if (!values.newPassword) {
      errors.newPassword = ' ';
    } else if (!passwordValidator(values.newPassword)) {
      errors.newPassword = lang.PASSWORD_FORMAT_ERROR;
    }

    return errors;
  };

  constructor(props) {
    super(props);

    this.state = {
      open: props.editPassword,
    };
  }

  toggleOpen = () => this.setState(({open}) => ({open: !open}));

  submit = (values) => {
    const {lang, user} = this.props;

    const data = {
      user: {
        current_password: values.currentPassword,
        password: values.newPassword,
        password_confirmation: values.confirmPassword,
      },
    };

    return this.props.passwordChange(user.id, data).then((response) => {
      if (response.error) {
        let formError = {currentPassword: lang.CURRENT_PASSWORD_ERROR};
        const passwordError = get(response, 'payload.response.errors.password[0]');

        if (passwordError === 'taken_in_past') {
          formError = {newPassword: lang.PASSWORD_USED_ERROR};
        }

        throw new SubmissionError(formError);
      }

      this.toggleOpen();
    });
  };

  handleCancel = () => {
    this.props.reset();
    this.toggleOpen();
  };

  renderTooltip = () => {
    return (
      <Popover
        position="bottom"
        shiftTop={6}
        overlay={<div className={b('tooltip')}>{this.props.lang.TOOLTIP_TEXT}</div>}
      >
        <Icon name="information" className={b('tooltip-icon')} />
      </Popover>
    );
  };

  render() {
    const {lang, handleSubmit, submitting, anyTouched, valid} = this.props;
    const {open} = this.state;

    return (
      <ProfilePanel title={lang.TITLE} icon={this.renderTooltip()}>
        <div className={b('profile-password')}>
          <form onSubmit={handleSubmit(this.submit)}>
            {open ? (
              <>
                <FieldWrap className={b('field-wrap')}>
                  <PasswordField label={lang.CURRENT_PASSWORD} name="currentPassword" />
                </FieldWrap>
                <FieldWrap className={b('field-wrap')}>
                  <PasswordField label={lang.NEW_PASSWORD} name="newPassword" />
                </FieldWrap>
                <FieldWrap className={b('field-wrap')}>
                  <PasswordField label={lang.CONFIRM_PASSWORD} name="confirmPassword" />
                </FieldWrap>
                <div className={b('buttons')}>
                  <AppButton
                    className={b('cancel-button')}
                    label={lang.CANCEL}
                    transparent
                    color="primary"
                    size="small"
                    onClick={this.handleCancel}
                  />
                  <AppButton
                    className={b('save-button')}
                    label={lang.SAVE}
                    disabled={!anyTouched || !valid || submitting}
                    color="primary"
                    size="medium"
                    submit
                  />
                </div>
              </>
            ) : (
              <>
                <FieldWrap className={b('field-wrap')}>
                  <PasswordField label={lang.PASSWORD} name="fakePassword" disabled={true} />
                </FieldWrap>
                <AppButton
                  className={b('open-button')}
                  label={lang.TITLE}
                  onClick={this.toggleOpen}
                  color="primary"
                  size="large"
                />
              </>
            )}
          </form>
        </div>
      </ProfilePanel>
    );
  }
}

const ProfilePasswordForm = reduxForm({
  form: ProfilePassword.FORM_NAME,
  validate: ProfilePassword.validate,
  initialValues: {fakePassword: 'xxxxxxxx'},
  shouldValidate: () => true,
})(ProfilePassword);

export default connect(
  (state) => ({
    lang: state.languageState.payload.PROFILE.PROFILE_PASSWORD,
    formMeta: getFormMeta(ProfilePassword.FORM_NAME)(state),
  }),
  {
    passwordChange,
  },
)(ProfilePasswordForm);
