import React from 'react';

import {ReactNodeLike} from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {addEmailTemplateVariable} from 'client/ducks/email-templates/actions';
import {selectActiveTemplateKind} from 'client/ducks/email-templates/selectors';

import Dropdown from 'client/common/selects/dropdown';

import {getVariableOptions} from './helpers';

import cssModule from './parameters-variable-dropdown.module.scss';

const b = bem('parameters-variable-dropdown', {cssModule});

type ParametersVariableDropdownProps = {
  onSelect: (value: string) => void;
  trigger?: ReactNodeLike;
};

const ParametersVariableDropdown: React.FC<ParametersVariableDropdownProps> = (props) => {
  const {onSelect, trigger} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.INPUTS.PARAMETERS_VARIABLE_DROPDOWN');
  const activeTemplateKind = useSelector(selectActiveTemplateKind);

  const allOptions = getVariableOptions(activeTemplateKind?.email_template_kind_variables || []);

  const options = [
    {label: lang.LEADS, options: allOptions.lead},
    {label: lang.PARTICIPATIONS, options: allOptions.participation},
    {label: lang.PRIZE, options: allOptions.prize},
    {label: lang.DEVICE, options: allOptions.device},
    {label: lang.OPERATION_DATUM, options: allOptions.operation_datum},
  ].filter((i) => i.options.length);

  return (
    <Dropdown
      className={b()}
      optionsClassName={b('options')}
      commonOnClick={(variable) => {
        onSelect(variable);
        dispatch(addEmailTemplateVariable(variable));
      }}
      triggerProps={{
        className: b('trigger'),
        classNameIcon: b('trigger-icon'),
        iconName: 'braces',
      }}
      options={options}
      trigger={trigger}
      showToggleArrow
      showMenuArrow
      grouped
    />
  );
};

export default ParametersVariableDropdown;
