import React from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {reset} from 'redux-form';

import {getIsNational} from 'client/services/cookie-data-source';
import {getHomeDashboard, getHomeOrFirstDashboard, uid} from 'client/services/helpers';

import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getCampaign, deleteCampaign, patchCampaign} from 'client/ducks/campaigns/actions';
import {getGames} from 'client/ducks/games/actions';
import {selectGames} from 'client/ducks/games/selectors';
import {fetchOnlineInteractions, deleteOnlineInteraction} from 'client/ducks/interactions/actions';
import {updateOnlineInteraction} from 'client/ducks/interactions/actions';
import {selectOnlineInteractions} from 'client/ducks/interactions/selectors';
import {addToastNotifications} from 'client/ducks/toast-notification/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';

import {CLIENT_PAGES, CLIENT_LEVEL_TYPES, OPERATION_STATUS_TYPES} from 'client/common/config';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import {PRIZE_MAP_LEVEL_TYPES, PRIZE_MAP_TYPES} from 'client/components/games/game-config-modal/constants';
import PrizeMapCard from 'client/components/games/prize-map-card';

import CampaignSourceModal from './components/modals/camp-source-modal';
import CampSettingsPopover from './components/popovers/camp-settings-popover';
import CampDataGrid from './components/tables/camp-data-grid';

import './campaign.scss';

class Campaign extends ReactQueryParams {
  static propTypes = {
    id: PropTypes.number.isRequired,
    isNational: PropTypes.bool.isRequired,
    onlineInteractions: PropTypes.arrayOf(PropTypes.object).isRequired,
    onlineInteractionsMeta: PropTypes.PropTypes.object.isRequired,
    languageState: PropTypes.object.isRequired,
    campaign: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
    ...withRouter.propTypes,
    setBreadcrumbs: PropTypes.func.isRequired,
    getGames: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    fetchOnlineInteractions: PropTypes.func.isRequired,
    getCampaign: PropTypes.func.isRequired,
    deleteCampaign: PropTypes.func.isRequired,
    patchCampaign: PropTypes.func.isRequired,
    deleteOnlineInteraction: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    updateOnlineInteraction: PropTypes.func.isRequired,
    games: PropTypes.array.isRequired,
    addToastNotifications: PropTypes.func.isRequired,
  };

  static DEFAULT_PER_PAGE = 5;

  static DEFAULT_FIRST_PAGE = 1;

  static DEFAULT_SORT_FIELD = 'name';

  constructor(props) {
    super(props);

    this.state = {
      showDeleteCamp: false,
      showDeleteSource: false,
      activeInteraction: null,
      showSourceModal: false,
    };

    this.LANGUAGE = props.languageState.payload.CAMPAIGN;
  }

  handleInteractionSubmit = () => {
    this.handleCloseSourceModal();
    this.getOnlineInteractions();
    this.getMyCampaign();
  };

  handleDeleteSource = (id) => {
    return () => {
      this.setState({
        showDeleteSource: !this.state.showDeleteSource,
        activeInteraction: id || null,
      });
    };
  };

  handleOpenSourceModal = (id) => {
    return () => {
      this.setState({
        showSourceModal: true,
        activeInteraction: id || null,
      });
    };
  };

  handleCloseSourceModal = () => {
    this.setState({
      showSourceModal: false,
      activeInteraction: null,
    });
  };

  handleFinishedError = (response) => {
    if (response.error) {
      const errors = response?.payload?.response?.errors;
      this.props.addToastNotifications({
        id: uid(),
        type: 'error',
        description: errors?.base?.[0],
      });
    }
  };

  handleRemoveInteraction = async () => {
    const response = await this.props.deleteOnlineInteraction(this.state.activeInteraction);
    this.handleFinishedError(response);
    this.handleDeleteSource()();
    this.getOnlineInteractions();
    this.getMyCampaign();
  };

  handleRemoveCampaign = () => {
    this.props
      .deleteCampaign(this.props.id)
      .then(this.handleDeleteCampaignClick)
      .then(() => this.props.history.push(`${CLIENT_PAGES.AUTOTASK}/${this.props.campaign.automation_task.id}`));
  };

  handleDeleteCampaignClick = () => {
    this.setState({
      showDeleteCamp: !this.state.showDeleteCamp,
    });
  };

  handleArchiveClick = () => {
    this.props
      .patchCampaign(this.props.id, {
        campaign: {
          archived: !this.props.campaign.archived,
        },
      })
      .then(this.getMyCampaign);
  };

  getOnlineInteractions = () => {
    const {queryParams: params} = this;

    this.props.fetchOnlineInteractions({
      include_online_interaction_prize_maps_present: true,
      q: {
        interaction_group_id_eq: this.props.id,
        s: `${(params.sort && params.sort.name) || Campaign.DEFAULT_SORT_FIELD} ${params?.sort?.order || 'asc'}`,
      },
      include: {
        source: null,
        location: {region: null},
        interaction_group: {
          online_prize_maps: true,
        },
        online_prize_maps: true,
        accessible_region: null,
        accessible_store: null,
      },
      page: params.page || Campaign.DEFAULT_FIRST_PAGE,
      per_page: params.perPage || Campaign.DEFAULT_PER_PAGE,
    });
  };

  onPageChange = (params) => {
    this.setQueryParams(params);
    this.getOnlineInteractions();
  };

  onSortChange = (name, order) => {
    this.setQueryParams({sort: {name, order}});
    this.getOnlineInteractions();
  };

  updateOnlineInteraction = async (id, data) => {
    const response = await this.props.updateOnlineInteraction(id, data);
    this.handleFinishedError(response);
    return this.getOnlineInteractions();
  };

  getMyCampaign = () => {
    return this.props
      .getCampaign(this.props.id, {
        include_campaign_prize_maps_present: true,
        include: [
          {
            automation_task: {
              operation: {
                client: ['agency', 'dashboards', {regions: 'places'}],
                dashboards: null,
              },
              dashboards: null,
            },
          },
          'interface',
        ],
      })
      .then(this.updateBreadcrumbs);
  };

  updateBreadcrumbs = () => {
    const {
      isNational,
      languageState: {
        payload: {BREADCRUMBS},
      },
      campaign: {
        name: campaignName,
        automation_task: {
          id: taskId,
          name: taskName,
          type: taskType,
          dashboards: autotaskDashboards,
          operation: {
            name: operationName,
            id: operationId,
            dashboards: operationDashboards,
            client = {},
            status: operationStatus,
            client: {id: clientId, type: clientType} = {},
          } = {},
        } = {},
      } = {},
    } = this.props;

    const agencyBreadcrumb = client?.agency
      ? [
          {
            href: CustomLink.createClientLink({
              clientType: client.agency.type,
              clientId: client.agency.id,
            }),
            name: client.agency.name,
          },
        ]
      : [];

    const basicInfo = {
      taskId,
      taskType,
      clientId,
      clientType,
      operationId,
    };

    const autotaskHomeDashboard = getHomeOrFirstDashboard(autotaskDashboards);
    const operationHomeDashboard = getHomeDashboard(operationDashboards);

    const defaultBreadcrumbs = [
      {
        href: CLIENT_PAGES.VISUALS,
        name: BREADCRUMBS.VISUALS,
      },
      ...agencyBreadcrumb,
      {
        name: client.name,
        href: CustomLink.createClientLink(basicInfo),
      },
      {
        name: BREADCRUMBS.CUSTOM_OPERATION,
        href: CustomLink.createCustomOperationDBLink(basicInfo),
      },
      {
        name: operationName,
        href: CustomLink.createOperationLink(basicInfo),
      },
      {
        name: taskName,
        href: CustomLink.createTaskLink(basicInfo),
      },
      {
        name: campaignName,
      },
    ];

    const nationalBreadcrumbs = [
      {
        name:
          operationStatus !== OPERATION_STATUS_TYPES.ACTIVE
            ? BREADCRUMBS.FINISHED_OPERATIONS
            : BREADCRUMBS.ONGOING_OPERATIONS,
        href:
          operationStatus !== OPERATION_STATUS_TYPES.ACTIVE
            ? CLIENT_PAGES.OPERATIONS_FINISHED
            : CLIENT_PAGES.OPERATIONS_ACTIVE,
      },
      {
        name: get(operationHomeDashboard, 'name'),
        href:
          operationHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            dashboardType: CLIENT_LEVEL_TYPES.OPERATION,
            dashboardId: operationHomeDashboard.id,
          }),
        hidden: !operationHomeDashboard,
      },
      {
        name: get(autotaskHomeDashboard, 'name'),
        href:
          autotaskHomeDashboard &&
          CustomLink.createDashboardsLink({
            ...basicInfo,
            dashboardType: CLIENT_LEVEL_TYPES.AUTOMATION_TASK,
            dashboardId: autotaskHomeDashboard.id,
          }),
        hidden: !autotaskHomeDashboard,
      },
      {
        name: campaignName,
      },
    ];

    return this.props.setBreadcrumbs(isNational ? nationalBreadcrumbs : defaultBreadcrumbs);
  };

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  componentDidMount() {
    this.getMyCampaign();
    this.getOnlineInteractions();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.campaign.id && this.props.campaign.id) {
      this.fetchGames();
    }
  }

  fetchGames = () => {
    return this.props.getGames({
      q: {
        automation_task_id_eq: this.props.campaign.automation_task_id,
      },
      include: ['game_validation_levels', 'automation_task.operation.client'],
    });
  };

  render() {
    const {showDeleteCamp, showDeleteSource, showSourceModal, activeInteraction} = this.state;
    const {id, onlineInteractions, onlineInteractionsMeta, campaign, isAdmin, games} = this.props;

    const deleteLabels = this.LANGUAGE.DELETE_SOURCE_MODAL;
    const deleteCampLabels = this.LANGUAGE.DELETE_CAMP_MODAL;

    if (!onlineInteractionsMeta) {
      return null;
    }

    return (
      <div className="theme-color-10 campaign">
        <ConfirmationModal
          show={showDeleteSource}
          onClose={this.handleDeleteSource()}
          onCancel={this.handleDeleteSource()}
          onConfirm={this.handleRemoveInteraction}
          title={deleteLabels.TITLE}
          message={
            <div>
              {deleteLabels.MESSAGE_1}
              <br />
              <br />
              {deleteLabels.MESSAGE_2}
            </div>
          }
          cancelText={deleteLabels.CANCEL_BUTTON}
          confirmText={deleteLabels.CONFIRM_BUTTON}
          className="theme-color-10"
          buttonConfirmClass="button--bg-6"
        />

        <ConfirmationModal
          show={showDeleteCamp}
          onClose={this.handleDeleteCampaignClick}
          onCancel={this.handleDeleteCampaignClick}
          onConfirm={this.handleRemoveCampaign}
          title={deleteCampLabels.TITLE}
          message={deleteCampLabels.MESSAGE}
          cancelText={deleteCampLabels.CANCEL_BUTTON}
          confirmText={deleteCampLabels.CONFIRM_BUTTON}
          className="theme-color-10"
          buttonConfirmClass="button--bg-6"
        />

        {showSourceModal && (
          <CampaignSourceModal
            show={showSourceModal}
            onClose={this.handleCloseSourceModal}
            interactionId={activeInteraction}
            campaignId={id}
            onSubmit={this.handleInteractionSubmit}
          />
        )}

        <div className="page__title-block campaign__title-block">
          <div>
            <TitleBlock theme>
              <TitleBlock.Item>{campaign.name}</TitleBlock.Item>
            </TitleBlock>
            <div className="page__subtitle">
              {`${this.LANGUAGE.SUBTITLE} ${campaign.interface && campaign.interface.name}`}
            </div>
          </div>
          <PrizeMapCard
            hasContainer
            className="campaign__prize-map"
            levelKey="CAMPAIGN"
            prizeMapParams={{
              type_eq: PRIZE_MAP_TYPES.ONLINE_PRIZE_MAP,
              online_level_eq: PRIZE_MAP_LEVEL_TYPES.CAMPAIGN,
              online_source_id_eq: campaign.id,
            }}
            displayParams={campaign.prize_maps_present}
          />
          {isAdmin && (
            <CampSettingsPopover
              onDelete={this.handleDeleteCampaignClick}
              campaignName={campaign.name}
              ableToArchive={campaign.active_interactions_count === 0}
              ableToDelete={onlineInteractionsMeta.total_count === 0}
              onArchive={this.handleArchiveClick}
              onNameChanged={this.getMyCampaign}
              campaignId={this.props.id}
              archived={campaign.archived}
            >
              <Icon name="settings" />
            </CampSettingsPopover>
          )}
        </div>

        <CampDataGrid
          archived={campaign.archived}
          data={onlineInteractions}
          onOpenSourceModal={this.handleOpenSourceModal}
          onDeleteSource={this.handleDeleteSource}
          perPage={Number(this.queryParams.perPage) || Campaign.DEFAULT_PER_PAGE}
          currentPage={onlineInteractionsMeta.current_page}
          totalPages={onlineInteractionsMeta.total_pages}
          totalItems={onlineInteractionsMeta.total_count}
          onPageChange={this.onPageChange}
          onSortChange={this.onSortChange}
          updateOnlineInteraction={this.updateOnlineInteraction}
          games={games}
          sort={this.queryParams.sort}
        />
      </div>
    );
  }
}

export default withRouter(
  connect(
    ({campaigns, ...state}) => {
      return {
        languageState: state.languageState,
        onlineInteractions: selectOnlineInteractions(state),
        onlineInteractionsMeta: state.interactions.onlineInteractions.meta,
        campaign: campaigns.campaign,
        // I think that in the future it will be taken from the store, so I placed it in this place.
        isNational: getIsNational(),
        isAdmin: selectIsAdmin(state),
        games: selectGames(state),
      };
    },
    {
      setBreadcrumbs,
      clearBreadcrumbs,
      fetchOnlineInteractions,
      deleteOnlineInteraction,
      getCampaign,
      deleteCampaign,
      patchCampaign,
      getGames,
      reset,
      updateOnlineInteraction,
      addToastNotifications,
    },
  )(Campaign),
);
