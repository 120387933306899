import React, {useState, useMemo, useCallback} from 'react';

import {useSortable} from '@dnd-kit/sortable';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import {useQueryParams} from 'client/services/hooks';

import {selectCurrentUser} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons';
import Dropdown from 'client/common/selects/dropdown';

import InstoreHorizontalBarsChart from 'client/components/instore-dashboards/charts/instore-horizontal-bars-chart';
import useDashboardType from 'client/components/instore-dashboards/hooks/useDashboardType';
import InstoreDashboardBlockModal from 'client/components/instore-dashboards/modals/instore-dashboard-block-modal';
import InstoreDashboardStatsTable from 'client/components/instore-dashboards/tables/instore-dashboard-stats-table';
import InstoreDonutChart from 'client/components/instore/charts/instore-donut-chart';
import InstoreWeekDonutChart from 'client/components/instore/charts/instore-week-donut-chart';
import useFetchDashboardBlocks from 'client/components/instore/containers/instore-dashboards-container/hooks/use-fetch-dashboard-blocks';
import {
  INSTORE_DASHBOARD_BLOCK_TYPES,
  INSTORE_DASHBOARD_CONSOLIDATION_MODES,
} from 'client/models/instore-dashboard/constants';

import {transformCssToString, mapHorizontalBarsData, mapConsolidationDonutData, mapMonoDonutData} from './helpers';

import cssModule from './instore-dashboard-block-card.module.scss';

const b = bem('instore-dashboard-block-card', {cssModule});

const InstoreDashboardBlockCard = (props) => {
  const {block, position, onRemove, dashboard} = props;
  const {task_kpis: [{name}] = [{}]} = block || {};
  const currentUser = useSelector(selectCurrentUser);
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id: block?.id});
  const [queryParams] = useQueryParams();
  const {dashboardId, campaignId} = queryParams;
  const {fetch} = useFetchDashboardBlocks({dashboardId, campaignId}, false);
  const lang = useLanguage('INSTORE_DASHBOARDS.CARDS.INSTORE_DASHBOARD_BLOCK_CARD');
  const {isUser} = useDashboardType(dashboard);

  const [modalVisible, toggleModalVisible] = useToggle(false);
  const [editingItem, setEditingItem] = useState(null);

  const editable = currentUser.id === dashboard?.user_id;

  const onEditClick = useCallback(() => {
    setEditingItem(block);
    toggleModalVisible();
  }, [setEditingItem, toggleModalVisible, block]);

  const onModalClose = (positionToSave) => {
    if (positionToSave) {
      fetch();
    }
    toggleModalVisible();
    setEditingItem(null);
  };

  const actionOptions = useMemo(() => {
    const actions = [{label: lang.EDIT_LABEL, onClick: onEditClick}];
    if (editable) {
      actions.push({label: lang.REMOVE_LABEL, onClick: () => onRemove(block)});
    }

    return actions;
  }, [lang, onEditClick, editable, onRemove, block]);

  const style = {
    transform: transformCssToString(transform),
    transition,
  };

  return (
    <>
      {/* id on div is used for anchoring on graph creation */}
      <div style={style} {...attributes} className={b()} id={position}>
        {block ? (
          <>
            <div className={b('controls')}>
              {editable && (
                <AppButton
                  color="text-additional"
                  iconName="drag"
                  transparent
                  className={b('drag-icon')}
                  ref={setNodeRef}
                  {...listeners}
                  tabIndex={0}
                />
              )}
              <span className={b('title')} title={name}>
                {name}
              </span>
              {isUser && (
                <Dropdown
                  className={b('options')}
                  options={actionOptions}
                  showMenuArrow
                  position="bottom-end"
                  offsetParams={{
                    crossAxis: 10,
                  }}
                />
              )}
            </div>
            <div className={b('content')}>
              {block.block_type === INSTORE_DASHBOARD_BLOCK_TYPES.STATS_CONSOLIDATED && (
                <>
                  <InstoreDashboardStatsTable data={block.data} consolidation={block.consolidation} />
                  <div className={b('graph-wrap')}>
                    <p className={b('graph-title')}>{lang.CONSOLIDATION[block.consolidation.toUpperCase()]}</p>
                    {block.consolidation_mode === INSTORE_DASHBOARD_CONSOLIDATION_MODES.TOTAL && (
                      <InstoreWeekDonutChart data={mapConsolidationDonutData(block.data)} />
                    )}
                    {block.consolidation_mode === INSTORE_DASHBOARD_CONSOLIDATION_MODES.AVERAGE && (
                      <div className={b('hb-wrap')}>
                        <InstoreHorizontalBarsChart data={mapHorizontalBarsData(block.data)} />
                      </div>
                    )}
                  </div>
                </>
              )}
              {block.block_type === INSTORE_DASHBOARD_BLOCK_TYPES.MONO_MCQ && (
                <div className={b('graph-wrap', ['full'])}>
                  <InstoreDonutChart
                    data={mapMonoDonutData(block.data)}
                    legendOnRight={true}
                    legendMaxHeight={220}
                    width={138}
                    height={138}
                  />
                </div>
              )}
            </div>
          </>
        ) : (
          <AppButton
            label={lang.CREATE_LABEL}
            iconName="plus-simple"
            className={b('add-button')}
            onClick={toggleModalVisible}
          />
        )}
      </div>
      {modalVisible && (
        <InstoreDashboardBlockModal onClose={onModalClose} editingItem={editingItem} position={position} />
      )}
    </>
  );
};
InstoreDashboardBlockCard.displayName = 'InstoreDashboardBlockCard';

InstoreDashboardBlockCard.propTypes = {
  onRemove: PropTypes.func,
  block: PropTypes.shape({
    id: PropTypes.number.isRequired,
    block_type: PropTypes.string.isRequired,
    consolidation: PropTypes.string,
    consolidation_mode: PropTypes.string,
    task_kpis: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        average: PropTypes.number,
        total: PropTypes.number,
        count: PropTypes.number,
        value: PropTypes.string,
      }),
    ).isRequired,
  }),
  position: PropTypes.number,
  dashboard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    user_id: PropTypes.number,
    sharing_level: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

InstoreDashboardBlockCard.defaultProps = {
  onRemove: null,
  block: null,
  position: null,
  dashboard: null,
};

export default InstoreDashboardBlockCard;
