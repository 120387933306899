import {getDefaultMeta} from 'client/types/common/helpers';

import {updateOperationEmailTemplate} from './mappers';
import types from './types';

const initialState = {
  needToUpdateOperations: false,
  operations: [],
  operation: {},
  operationEmailTemplates: [],
  clients: [],
  meta: getDefaultMeta(),
  metaMyOperations: {
    current_page: 0,
    next_page: 0,
    prev_page: null,
    total_count: 0,
    total_pages: 0,
  },
  activeOperations: {
    operations: [],
    meta: getDefaultMeta(),
  },
  finishedOperations: {
    operations: [],
    meta: getDefaultMeta(),
  },
  configurationOperations: {
    operations: [],
    meta: getDefaultMeta(),
  },
  clientParams: {
    qs: '',
    clientId: null,
  },
  regions: [],
  places: [],
};

export default function (_state = initialState, action) {
  let state = Object.assign({}, _state);

  switch (action.type) {
    case types.GET_OPERATIONS_SUCCESS:
      state.operations = action.payload.operations;
      state.meta = {
        ...state.meta,
        ...action.payload.meta,
      };
      return state;

    case types.GET_MY_OPERATIONS_SUCCESS:
      return {
        ...state,
        metaMyOperations: {
          ...state.metaMyOperations,
          ...action.payload.meta,
        },
      };

    case types.GET_OPERATION_SUCCESS:
      state.operation = action.payload.operation;
      return state;

    case types.GET_OPERATION_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        operationEmailTemplates: action.payload.operation_email_templates,
      };

    case types.UPDATE_OPERATION_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        operationEmailTemplates: updateOperationEmailTemplate(
          state.operationEmailTemplates,
          action.payload.operation_email_template,
        ),
      };

    case types.GET_CLIENTS_SUCCESS:
      state.clients = action.payload.clients;
      return state;

    case types.FLUSH_OPERATIONS_ALL_STATE:
      return initialState;

    case types.GET_ACTIVE_OPERATIONS_SUCCESS:
      state.activeOperations = action.payload;
      return state;

    case types.GET_CONFIGURATION_OPERATIONS_SUCCESS:
      state.configurationOperations = action.payload;
      return state;

    case types.GET_FINISHED_OPERATIONS_SUCCESS:
      state.finishedOperations = action.payload;
      return state;

    case types.SET_CLIENT_PARAMS:
      state.clientParams = action.clientParams;
      return state;

    case types.GET_REGIONS_SUCCESS:
      state.regions = action.payload.regions;
      return state;

    case types.GET_PLACES_SUCCESS:
      state.places = action.payload.places;
      return state;

    default:
      return state;
  }
}
