export default {
  GET_PARTICIPATION_PRIZES_REQUEST: '@@prizes/GET_PARTICIPATION_PRIZES_REQUEST',
  GET_PARTICIPATION_PRIZES_SUCCESS: '@@prizes/GET_PARTICIPATION_PRIZES_SUCCESS',
  GET_PARTICIPATION_PRIZES_ERROR: '@@prizes/GET_PARTICIPATION_PRIZES_ERROR',

  CREATE_DIY_PRIZE_REQUEST: '@@prizes/CREATE_DIY_PRIZE_REQUEST',
  CREATE_DIY_PRIZE_SUCCESS: '@@prizes/CREATE_DIY_PRIZE_SUCCESS',
  CREATE_DIY_PRIZE_ERROR: '@@prizes/CREATE_DIY_PRIZE_ERROR',

  UPDATE_DIY_PRIZE_REQUEST: '@@prizes/UPDATE_DIY_PRIZE_REQUEST',
  UPDATE_DIY_PRIZE_SUCCESS: '@@prizes/UPDATE_DIY_PRIZE_SUCCESS',
  UPDATE_DIY_PRIZE_ERROR: '@@prizes/UPDATE_DIY_PRIZE_ERROR',

  DELETE_DIY_PRIZE_REQUEST: '@@prizes/DELETE_DIY_PRIZE_REQUEST',
  DELETE_DIY_PRIZE_SUCCESS: '@@prizes/DELETE_DIY_PRIZE_SUCCESS',
  DELETE_DIY_PRIZE_ERROR: '@@prizes/DELETE_DIY_PRIZE_ERROR',

  DELETE_DIY_PRIZE_COUPON_REQUEST: '@@prizes/DELETE_DIY_PRIZE_COUPON_REQUEST',
  DELETE_DIY_PRIZE_COUPON_SUCCESS: '@@prizes/DELETE_DIY_PRIZE_COUPON_SUCCESS',
  DELETE_DIY_PRIZE_COUPON_ERROR: '@@prizes/DELETE_DIY_PRIZE_COUPON_ERROR',

  GET_DIY_PRIZE_MAPS_BY_LEVEL_REQUEST: '@@prizes/GET_DIY_PRIZE_MAPS_BY_LEVEL_REQUEST',
  GET_DIY_PRIZE_MAPS_BY_LEVEL_SUCCESS: '@@prizes/GET_DIY_PRIZE_MAPS_BY_LEVEL_SUCCESS',
  GET_DIY_PRIZE_MAPS_BY_LEVEL_ERROR: '@@prizes/GET_DIY_PRIZE_MAPS_BY_LEVEL_ERROR',

  UPDATE_DIY_PRIZE_MAPS_BY_LEVEL_REQUEST: '@@prizes/UPDATE_DIY_PRIZE_MAPS_BY_LEVEL_REQUEST',
  UPDATE_DIY_PRIZE_MAPS_BY_LEVEL_SUCCESS: '@@prizes/UPDATE_DIY_PRIZE_MAPS_BY_LEVEL_SUCCESS',
  UPDATE_DIY_PRIZE_MAPS_BY_LEVEL_ERROR: '@@prizes/UPDATE_DIY_PRIZE_MAPS_BY_LEVEL_ERROR',
};
