import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';

import {set} from 'client/common/meta/meta.actions';

import ClientAccountParameters from 'client/components/client-account-parameters';
import {routePaths} from 'routes/index';

class AccountParametersPage extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    setMetaAction: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({title: this.props.lang.ACCOUNT_PARAMETERS});
  }

  render() {
    const {client} = this.props;

    if (!client.id) {
      return null;
    }

    return <ClientAccountParameters client={client} rootPath={routePaths.client.AccountParametersPage} />;
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.NAVIGATION,
    client: selectCurrentClient(state),
  }),
  {
    setMetaAction: set,
  },
)(AccountParametersPage);
