import {ACCESS_LEVEL_ORDER} from 'client/common/config';

import {AccessLevelType} from 'client/models/common/types';
import {Interfaces} from 'client/models/interfaces/types';
import {CATALOG_TYPES} from 'client/models/templates/constants';
import {CatalogType} from 'client/models/templates/types';

type TemplateCatalogInfoForm = {
  id: number;
  available: boolean;
  form_accesses_editable: boolean;
};

type TemplateCatalogInfoFormMap = {
  design: TemplateCatalogInfoForm;
  param: TemplateCatalogInfoForm;
  general: TemplateCatalogInfoForm;
};

export type TemplateCatalogInfoType = {
  forms?: TemplateCatalogInfoFormMap;
  documentationUrl?: string;
};

type TemplateCatalogInfoMap = Record<CatalogType, TemplateCatalogInfoType>;

export const getCatalogsInfoByType = (client_interface: Interfaces, accessLevel: AccessLevelType) => {
  const templates = client_interface?.templates || [];
  const catalogs = {
    [CATALOG_TYPES.GAME]: {},
    [CATALOG_TYPES.FORM]: {},
    [CATALOG_TYPES.QUIZ]: {},
    [CATALOG_TYPES.SCENARIO]: {},
    [CATALOG_TYPES.TEMPLATE]: {},
  } as TemplateCatalogInfoMap;

  templates
    .filter((template) => template?.catalog && Object.values(CATALOG_TYPES).includes(template.catalog.type))
    .forEach((template) => {
      const forms = template?.catalog?.forms || [];

      if (template?.catalog) {
        catalogs[template.catalog.type] = {
          forms: forms.reduce((acc, {id, form_type, access}) => {
            acc[form_type] = {
              id,
              available: ACCESS_LEVEL_ORDER[access] <= ACCESS_LEVEL_ORDER[accessLevel],
              form_accesses_editable: template?.form_accesses_editable,
            };

            return acc;
          }, {} as TemplateCatalogInfoFormMap),
          documentationUrl:
            template?.catalog?.documentation_file?.url || template?.catalog?.documentation_file?.url,
        };
      }
    });

  return catalogs;
};
