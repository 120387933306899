import React, {useCallback, useState} from 'react';

import {set, cloneDeep} from 'lodash';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import PropTypes from 'prop-types';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {useSetState} from 'react-use';

import {isPrimitive} from 'client/services/helpers';
import {useLanguage, useQueryParams} from 'client/services/hooks';
import useReduxFetch from 'client/services/hooks/use-redux-fetch';

import {
  getLeadsExportReport,
  getLeadsExportReports,
  shareLeadExport,
  deleteFileLeadExport,
  createLeadsExportFile,
  getLeadsExportFile,
} from 'client/ducks/leads-export/actions';
import {selectLeadsExportReport, selectLeadsExportReports} from 'client/ducks/leads-export/selectors';
import {
  shareParticipationExport,
  getParticipationsExportReport,
  getParticipationsExportReports,
  deleteFileParticipationExport,
  getParticipationsExportFile,
  createParticipationsExportFile,
} from 'client/ducks/participation-export/actions';
import {
  selectParticipationExportReport,
  selectParticipationExportReports,
} from 'client/ducks/participation-export/selectors';
import {
  getWinnerExportReport,
  getWinnerExportReports,
  shareWinnerExport,
  deleteFileWinnerExport,
  createWinnerExportFile,
  performWinnerExportFile,
} from 'client/ducks/winner-export/actions';
import {selectWinnerExportReport, selectWinnerExportReports} from 'client/ducks/winner-export/selectors';

import CommonGenerateFileModal from 'client/components/client-autotask/tabs/data-tab/generate-file-modal';
import ShareFileAccessModal from 'client/components/client-autotask/tabs/data-tab/share-file-access-modal';
import ViewFilesModal from 'client/components/client-autotask/tabs/data-tab/view-files-modal';
import LeadsGenerateFileModal from 'client/components/leads/modals/leads-generate-file-modal';

import {DATA_COMPONENTS, DATA_TAB_MODAL_NAMES, DEFAULT_PAGE_SETTINGS, INITIAL_STATE} from './constants';
import {getExportReportsRequestParams, mapTableFields} from './helpers';

import DataLeads from '../data-leads';
import DataParticipations from '../data-participations';
import DataWinners from '../data-winners';
import {mapFilter, clearFilters} from '../helpers';
import LeadsFiltersModal from '../leads-filters-modal';
import FiltersModal from '../participations-filters-modal';
import ToolBar from '../toolbar';
import WinnersFiltersModal from '../winners-filters-modal';

import './data-common.scss';

const LEADS_OPTIONS = {
  component: DataLeads,
  modal: LeadsFiltersModal,
  modalGenerateFile: CommonGenerateFileModal,
  selector: selectLeadsExportReports,
  actionGetReports: getLeadsExportReports,
  actionGetReport: getLeadsExportReport,
  selectReport: selectLeadsExportReport,
  actionShare: shareLeadExport,
  actionGenerate: createLeadsExportFile,
  actionPerform: getLeadsExportFile,
  deleteExportAction: deleteFileLeadExport,
};

const components = {
  [DATA_COMPONENTS.PARTICIPATIONS]: {
    component: DataParticipations,
    modal: FiltersModal,
    modalGenerateFile: CommonGenerateFileModal,
    selector: selectParticipationExportReports,
    actionGetReports: getParticipationsExportReports,
    actionGetReport: getParticipationsExportReport,
    selectReport: selectParticipationExportReport,
    actionShare: shareParticipationExport,
    actionGenerate: createParticipationsExportFile,
    actionPerform: getParticipationsExportFile,
    deleteExportAction: deleteFileParticipationExport,
  },
  [DATA_COMPONENTS.LEADS]: LEADS_OPTIONS,
  [DATA_COMPONENTS.LEADS_DATABASE]: {
    ...LEADS_OPTIONS,
    modalGenerateFile: LeadsGenerateFileModal,
  },
  [DATA_COMPONENTS.WINNERS]: {
    component: DataWinners,
    modal: WinnersFiltersModal,
    modalGenerateFile: CommonGenerateFileModal,
    selector: selectWinnerExportReports,
    actionGetReports: getWinnerExportReports,
    actionGetReport: getWinnerExportReport,
    selectReport: selectWinnerExportReport,
    actionShare: shareWinnerExport,
    actionGenerate: createWinnerExportFile,
    actionPerform: performWinnerExportFile,
    deleteExportAction: deleteFileWinnerExport,
  },
};

const mapper = {
  optInRadio: {
    optInRadio: true,
    true: true,
    no_active: 'no_active',
  },
};

const isFalsyValue = (value) => !isBoolean(value) && (isEmpty(value) || isNull(value));

const mapFilterValues = (params) => {
  const filters = {};

  Object.keys(params).forEach((key) => {
    if (key in mapper) {
      filters[key] = mapper[key][params[key]];
    } else {
      filters[key] = isFalsyValue(params[key]) ? null : params[key];
    }
  });

  return filters;
};

const DataTabContainer = (props) => {
  const {
    autotaskId,
    component,
    prizeNames = [],
    prizeCellRenderer,
    prizeNamePath,
    hideHeader = false,
    isAdmin,
    showReportsButtons = false,
    clientId: externClientId,
    canGenerateFile,
    canShareFiles,
  } = props;
  const dispatch = useDispatch();

  const companyId = useSelector((state) => state.allUserClients.currentOrganization.id);
  const operationClientId = useSelector((state) => state.autotask.payload.operation.client_id);
  const language = useLanguage('DATA_TAB');
  const routeParams = useParams();
  const [exportPage, setExportPage] = useState(1);
  const [exportSearch, setExportSearch] = useState('');
  const [shareFileId, setShareFileId] = useState(null);

  const [queryParams, setQueryParams] = useQueryParams();

  const [activeModal, setActiveModal] = useState(null);
  const [state, setState] = useSetState(INITIAL_STATE);

  const clientId = externClientId || companyId || routeParams.clientId;

  const getAppliedFiltersAmount = () => {
    const filters = state[component].filters;
    const appliedFilters = mapFilter(filters);

    delete appliedFilters.created;
    delete appliedFilters.participated;
    delete appliedFilters.m;
    if (appliedFilters.optInRadio !== 'no_active') {
      delete appliedFilters.optInRadio;
    }
    return Object.keys(clearFilters(appliedFilters)).length;
  };

  const onChangeSettings = useCallback(
    (activeComponent, key, value) => {
      setState((prevState) => {
        const parameter = prevState[activeComponent][key];

        const next = cloneDeep(prevState);
        set(
          next,
          `${activeComponent}.${key}`,
          isPrimitive(parameter)
            ? value
            : {
                ...parameter,
                ...value,
              },
        );

        return next;
      });
    },
    [setState],
  );

  // Handlers
  const onPageChange = ({page, perPage}) => {
    setState({
      [component]: {
        ...state[component],
        pages: {
          perPage: perPage || state[component].pages.perPage,
          current: page || DEFAULT_PAGE_SETTINGS.current,
        },
      },
    });
  };

  const onSearchChange = async (search) => {
    onPageChange({page: 1});
    onChangeSettings(component, 'search', search);
  };

  const onSearchChangeField = async (field) => {
    onChangeSettings(component, 'searchField', field);
    if (state[component]?.search) {
      onPageChange({page: 1});
    }
  };

  const onPerPageChange = (perPage) => {
    onChangeSettings(component, 'pages', {
      current: 1,
      perPage,
    });
  };

  const setQueryFilters = (filters) => {
    try {
      const prevFilters = {...JSON.parse(queryParams.filter || '{}')};
      const str = JSON.stringify(
        clearFilters({
          ...prevFilters,
          ...filters,
        }),
      );
      setQueryParams({filter: str});
    } catch (e) {
      console.error(e);
    }
  };

  const onFiltersChange = async (params) => {
    const filters = {...params};
    delete filters.not;

    onPageChange({page: 1});

    const mappedFilters = mapFilterValues({...state[component].filters, ...filters});
    setQueryFilters(mappedFilters);
    onChangeSettings(component, 'filters', mappedFilters);
  };

  const onSortChange = (params) => {
    onChangeSettings(component, 'sortParams', params);
  };

  const handleCloseModal = () => {
    setActiveModal(null);
    setExportSearch('');
    setExportPage(1);
  };

  const {filters, search, searchField, pages, sortParams, total} = state[component];
  const {
    component: TableComponent,
    modal: Modal,
    modalGenerateFile: GenerateFileModal,
    selector,
    actionGetReports,
    actionShare,
    actionGetReport,
    selectReport,
    deleteExportAction,
    actionGenerate,
    actionPerform,
  } = components[component];
  const options = {};
  const prizeParams = {};
  if (component === DATA_COMPONENTS.WINNERS) {
    options.prizeNames = prizeNames.map(({custom_name}) => ({label: custom_name, value: custom_name}));
    prizeParams.prizeNames = prizeNames;
    prizeParams.prizeCellRenderer = prizeCellRenderer;
    prizeParams.prizeNamePath = prizeNamePath;
    prizeParams.isAdmin = isAdmin;
  }

  const settings = {...filters, search};

  const {data: files = [], fetch: fetchExports} = useReduxFetch({
    fetchOnMount: showReportsButtons,
    selector,
    action: actionGetReports,
    actionArgs: getExportReportsRequestParams(component, {
      autotaskId,
      clientId,
      page: exportPage,
      search: exportSearch,
      sort: `${mapTableFields(state[component].filesModal.sortField, component)} ${
        state[component].filesModal.sortOrder
      }`,
    }),
  });

  const onFilesModalSortChange = (params) => {
    setState({[component]: {...state[component], filesModal: params}});
  };

  const handleDeleteExport = async (id) => {
    await dispatch(deleteExportAction(id));
    fetchExports();
  };

  const handleSearchExports = (nextSearch) => {
    setExportPage(1);
    setExportSearch(nextSearch);
  };

  const handleChangeTotal = useCallback(
    (totalPages) => onChangeSettings(component, 'total', totalPages),
    [component, onChangeSettings],
  );

  return (
    <div className="data-tab-container">
      {!hideHeader && (
        <div className={`data-tab-container__header ${component.toLowerCase()}-header`}>
          {DATA_COMPONENTS[component] === 'WINNERS' && language.WINNERS}
          {DATA_COMPONENTS[component] === 'LEADS' && language.LEADS}
          {DATA_COMPONENTS[component] === 'LEADS_DATABASE' && language.LEADS}
          {DATA_COMPONENTS[component] === 'PARTICIPATIONS' && language.PARTICIPATIONS}
        </div>
      )}
      <ToolBar
        component={component}
        appliedFiltersAmount={getAppliedFiltersAmount()}
        totalFoundItems={total}
        searchDefault={search}
        onSearchChange={onSearchChange}
        onSearchFieldChange={onSearchChangeField}
        searchField={searchField}
        perPage={pages.perPage}
        onPerPageChange={onPerPageChange}
        setActiveModal={setActiveModal}
        filesNumber={files.meta.total_count || 0}
        showReportsButtons={showReportsButtons}
        canGenerateFile={canGenerateFile}
      />
      <TableComponent
        component={component}
        autotaskId={autotaskId}
        clientId={clientId}
        companyId={companyId}
        language={language[`${component}_TABLE`]}
        searchString={search}
        searchField={search && searchField}
        filters={filters}
        pages={pages}
        sortParams={sortParams}
        onFiltersChange={onFiltersChange}
        onSortChange={onSortChange}
        onSetTotal={handleChangeTotal}
        onPageChange={onPageChange}
        {...prizeParams}
      />
      {activeModal === DATA_TAB_MODAL_NAMES.FILTERS_MODAL && (
        <Modal
          clientId={companyId || operationClientId}
          taskId={autotaskId}
          instantApply={true}
          language={language.FILTERS_MODAL}
          filters={filters}
          totalResults={total}
          onClose={handleCloseModal}
          onFiltersChange={onFiltersChange}
          {...options}
        />
      )}

      {activeModal === DATA_TAB_MODAL_NAMES.VIEW_FILES_MODAL && (
        <ViewFilesModal
          onClose={handleCloseModal}
          files={files}
          onPageChange={setExportPage}
          search={exportSearch}
          onSearch={handleSearchExports}
          openAccessModal={(fileId) => {
            setActiveModal(DATA_TAB_MODAL_NAMES.SHARE_FILE_ACCESS_MODAL);
            setShareFileId(fileId);
          }}
          onDelete={handleDeleteExport}
          sortParams={state[component].filesModal}
          onSortChange={onFilesModalSortChange}
          canShareFiles={canShareFiles}
        />
      )}
      {activeModal === DATA_TAB_MODAL_NAMES.GENERATE_FILE_MODAL && (
        <GenerateFileModal
          onClose={handleCloseModal}
          component={component}
          filters={settings}
          onFetch={fetchExports}
          actionGenerate={actionGenerate}
          actionPerform={actionPerform}
          options={options}
          clientId={clientId}
          total={total}
        />
      )}
      {activeModal === DATA_TAB_MODAL_NAMES.SHARE_FILE_ACCESS_MODAL && (
        <ShareFileAccessModal
          onSave={fetchExports}
          onClose={() => setActiveModal(DATA_TAB_MODAL_NAMES.VIEW_FILES_MODAL)}
          fileExportId={shareFileId}
          actionShare={actionShare}
          actionGetReport={actionGetReport}
          selectReport={selectReport}
        />
      )}
    </div>
  );
};

DataTabContainer.propTypes = {
  autotaskId: PropTypes.number,
  clientId: PropTypes.number,
  component: PropTypes.string.isRequired,
  prizeNames: PropTypes.arrayOf(PropTypes.object),
  prizeCellRenderer: PropTypes.func,
  isAdmin: PropTypes.bool,
  hideHeader: PropTypes.bool,
  canGenerateFile: PropTypes.bool,
  prizeNamePath: PropTypes.string,
  searchField: PropTypes.string,
  showReportsButtons: PropTypes.bool,
  canShareFiles: PropTypes.bool,
};

export default DataTabContainer;
