import {LangsByPath} from 'client/services/hooks/useLanguage/types';
import {isEmptyString} from 'client/services/validator';

import {GenerateStatisticFileValues, GenerateStatisticFileErrors} from './types';

export default (
  values: GenerateStatisticFileValues,
  lang: LangsByPath<['STATISTIC_EXPORTS.MODALS.GENERATE_STATISTIC_FILE_MODAL']>,
) => {
  const errors: GenerateStatisticFileErrors = {};

  if (isEmptyString(values.file_name)) {
    errors.file_name = lang.REQUIRED;
  }

  if (!values.export_statistics && !values.export_kpi) {
    errors.export_statistics = lang.REQUIRED;
  }

  return errors;
};
