import React from 'react';

import PropTypes from 'prop-types';
import {SortableElement} from 'react-sortable-hoc';

import bem from 'client/services/bem';

import {headers} from '../helpers';
import PrizesTableCol from '../prizes-table-col';

const b = bem('prizes-table');

const PrizesTableRow = SortableElement(({lang, isPrizeMap, ...rowProps}) => {
  return (
    <div className={b('row', {disabled: rowProps.row.active === false})}>
      {headers(lang, isPrizeMap).map(({fieldName, key}) => {
        const colProps = {
          colKey: key,
          fieldName,
          ...rowProps,
        };
        return (
          <div className={b('col', [key])} key={key}>
            <PrizesTableCol {...colProps} disabledDrag={isPrizeMap} />
          </div>
        );
      })}
    </div>
  );
});

PrizesTableRow.propTypes = {
  lang: PropTypes.object.isRequired,
  row: PropTypes.object.isRequired,
  order: PropTypes.number.isRequired,
  onSelectPrize: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  isPrizeMap: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  disabledActivation: PropTypes.bool,
};

export default PrizesTableRow;
