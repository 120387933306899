import React, {useCallback} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {SortableHandle} from 'react-sortable-hoc';

import bem from 'client/services/bem';

import Icon from 'client/common/icon';

import {PRIZE_TYPES} from '../../prize-form/constants';

const b = bem('prizes-table');

const DragHandle = SortableHandle(() => <Icon name="sandwich" />);

const PrizesTableCol = ({
  fieldName,
  colKey,
  order,
  row,
  onSelectPrize,
  onDelete,
  onActivate,
  disabled,
  disabledActivation,
  disabledDrag,
}) => {
  const deleteHandler = useCallback(
    (e) => {
      e.preventDefault();
      onDelete(row.id);
    },
    [onDelete, row.id],
  );

  const activateHandler = useCallback(
    (e) => {
      e.preventDefault();
      onActivate(row.id, !row.active);
    },
    [onActivate, row.active, row.id],
  );

  switch (colKey) {
    case 'order':
      return (
        <div>
          {!disabledDrag && <DragHandle />}
          <span>{order}</span>
        </div>
      );
    case 'name':
      return (
        <button type="button" onClick={() => onSelectPrize(row)} className={b('selected-button')} disabled={disabled}>
          {get(row, fieldName)}
        </button>
      );
    case 'delete':
      const hasWins = Boolean(
        row?.total_quantities?.total_prize_won_non_validated || row?.total_quantities?.total_prize_won_validated,
      );
      return (
        <button type="button" className={b('delete')} onClick={deleteHandler} disabled={disabled || hasWins}>
          <Icon name="trash" width={17} height={19} />
        </button>
      );
    case 'active':
      return (
        <button
          type="button"
          className={b('active')}
          onClick={activateHandler}
          disabled={disabled || disabledActivation}
        >
          <Icon name={row.active ? 'clear' : 'big-plus'} />
        </button>
      );
    case 'coupon':
      const hasCoupon = get(row, fieldName) === true || get(row, fieldName) === PRIZE_TYPES.COUPON;
      return hasCoupon ? <Icon name="barcode" /> : <div />;
    default:
      return <div>{get(row, fieldName, '')}</div>;
  }
};

PrizesTableCol.propTypes = {
  fieldName: PropTypes.string.isRequired,
  colKey: PropTypes.string.isRequired,
  order: PropTypes.number.isRequired,
  row: PropTypes.object.isRequired,
  onSelectPrize: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  disabledActivation: PropTypes.bool,
  disabledDrag: PropTypes.bool,
};

export default PrizesTableCol;
