import {Route} from 'routes/types';

import {APP_ROLES, CLIENT_PAGES, NAV_GROUPS_MAP, PAGES_BACKGROUND_COLORS} from 'client/common/config';

import HomeClientEmpty from 'client/components/home-client/empty-client';
import AccessDenied from 'client/pages/access-denied';
import AnonymousEmailPage from 'client/pages/anonymous-email';
import Contacts from 'client/pages/contacts';
import CookiesPolicy from 'client/pages/cookies-policy';
import HomeContainer from 'client/pages/home';
import NotFound from 'client/pages/page-not-found';
import ParticipantsConfigPage from 'client/pages/participants-config';
import PrivacyPolicy from 'client/pages/privacy-policy';
import ProfileContainer from 'client/pages/profile';
import Terms from 'client/pages/terms';
import UnsubscribePage from 'client/pages/unsubscribe';
import UpdateOptInPreferencesPage from 'client/pages/update-preferences';

export default [
  {
    component: HomeContainer,
    name: 'HomeContainer',
    path: CLIENT_PAGES.HOME,
    exact: true,
    params: {
      nobreadcrumbs: true,
      navGroup: NAV_GROUPS_MAP.HOME,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN, APP_ROLES.USER, APP_ROLES.TRACKING_USER],
    },
  } as Route<'HomeContainer'>,
  {
    component: ProfileContainer,
    name: 'ProfileContainer',
    path: CLIENT_PAGES.PROFILE,
    params: {
      background: PAGES_BACKGROUND_COLORS.PROFILE,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN, APP_ROLES.USER],
    },
  } as Route<'ProfileContainer'>,

  {
    component: ParticipantsConfigPage,
    name: 'ParticipantsConfigPage',
    path: CLIENT_PAGES.PARTICIPANTS_CONFIG,
    params: {
      background: PAGES_BACKGROUND_COLORS.PARTICIPATIONS,
      navGroup: NAV_GROUPS_MAP.PARTICIPANTS,
      nobreadcrumbs: true,
      permissions: [APP_ROLES.SUPER_ADMIN, APP_ROLES.ADMIN, APP_ROLES.USER],
    },
  } as Route<'ParticipantsConfigPage'>,
  {
    component: UpdateOptInPreferencesPage,
    name: 'UpdateOptInPreferencesPage',
    path: CLIENT_PAGES.UPDATE_PREFERENCES,
    params: {
      isNotNeedLogin: true,
      nobreadcrumbs: true,
    },
  } as Route<'UpdateOptInPreferencesPage'>,
  {
    component: UnsubscribePage,
    name: 'UnsubscribePage',
    path: CLIENT_PAGES.UNSUBSCRIBE,
    params: {
      isNotNeedLogin: true,
      nobreadcrumbs: true,
    },
  } as Route<'UnsubscribePage'>,
  {
    component: PrivacyPolicy,
    name: 'PrivacyPolicy',
    path: CLIENT_PAGES.PRIVACY_POLICY,
    params: {
      background: PAGES_BACKGROUND_COLORS.PRIVACY_POLICY,
      isNotNeedLogin: true,
    },
  } as Route<'PrivacyPolicy'>,
  {
    component: Contacts,
    name: 'Contacts',
    path: CLIENT_PAGES.CONTACTS,
    params: {
      background: PAGES_BACKGROUND_COLORS.PRIVACY_POLICY,
      isNotNeedLogin: true,
    },
  } as Route<'Contacts'>,
  {
    component: Terms,
    name: 'Terms',
    path: CLIENT_PAGES.TERMS,
    params: {
      background: PAGES_BACKGROUND_COLORS.PRIVACY_POLICY,
      isNotNeedLogin: true,
    },
  } as Route<'Terms'>,
  {
    component: CookiesPolicy,
    name: 'CookiesPolicy',
    path: CLIENT_PAGES.COOKIES_POLICY,
    params: {
      background: PAGES_BACKGROUND_COLORS.COOKIES_POLICY,
      isNotNeedLogin: true,
    },
  } as Route<'CookiesPolicy'>,

  {
    component: HomeClientEmpty,
    name: 'HomeClientEmpty',
    path: CLIENT_PAGES.WAS_DELETED,
    exact: true,
    params: {
      nobreadcrumbs: true,
    },
  } as Route<'HomeClientEmpty'>,
  {
    component: AnonymousEmailPage,
    name: 'AnonymousEmailPage',
    path: `${CLIENT_PAGES.EMAILS}/:token`,
    params: {
      isNotNeedLogin: true,
    },
  } as Route<'AnonymousEmailPage'>,
  // TODO: check if it's good to use one comp for two cases
  {
    component: AccessDenied,
    name: 'AccessDenied',
    path: CLIENT_PAGES.ACCESS_DENIED,
    params: {},
  } as Route<'AccessDenied'>,
  {
    path: '*',
    component: NotFound,
    name: 'NotFound',
    params: {},
  },
];
