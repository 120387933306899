import React, {useCallback, memo, useState} from 'react';

import PropTypes from 'prop-types';
import {reduxForm} from 'redux-form';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {CheckboxField} from 'client/common/fields';

import InfoBlock from 'client/components/common/info-block';
import LoadingSpinner from 'client/components/common/loading-spinner';
import Spinner from 'client/components/common/spinner';

import './auto-creation-access-card.scss';

const b = bem('auto-creation-access-card');

const COMPANY_LEVELS = ['national', 'region', 'store', 'client_admin'];
const AGENCY_LEVELS = ['national', 'client_admin'];
const COUPON_TYPES = ['WeezioCoupon', 'SogecCoupon', 'AppCoupon', 'ClientCoupon'];

const AutoCreationAccessCard = memo((props) => {
  const {
    clientType,
    patchClient,
    accessLevels,
    couponTypes,
    permissions,
    autoConfiguration,
    clientId,
    isLoading,
    handleSubmit,
    pristine,
    change,
    isAgency,
  } = props;

  const lang = useLanguage('AGENCY.AUTO_CREATION_ACCESS_CARD');

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {initialize} = useReduxForm('AutoCreationAccessCardForm', {
    initialValues: {
      accessLevels: accessLevels.reduce((values, level) => {
        values[level] = true;
        return values;
      }, {}),
      couponTypes: couponTypes.reduce((values, couponType) => {
        values[couponType] = true;
        return values;
      }, {}),
      permissions: permissions,
      auto_configuration: autoConfiguration,
    },
  });

  const onSubmit = useCallback(
    (formValues) => {
      setIsSubmitting(true);

      const levels = [];
      for (let [accessLevel, isChecked] of Object.entries(formValues.accessLevels)) {
        if (isChecked) {
          levels.push(accessLevel);
        }
      }

      const coupons = [];
      for (let [couponType, isChecked] of Object.entries(formValues.couponTypes)) {
        if (isChecked) {
          coupons.push(couponType);
        }
      }

      const body = {
        user_access_levels: levels,
        available_coupons: coupons,
        auto_configuration: formValues.auto_configuration,
        ...formValues.permissions,
      };

      patchClient(clientId, {
        [clientType]: body,
      }).then(() => setIsSubmitting(false));
      initialize(formValues);
    },
    [initialize, patchClient, clientId, clientType],
  );

  const isSubmitButtonDisabled = pristine || isSubmitting;

  const levels = isAgency ? AGENCY_LEVELS : COMPANY_LEVELS;

  return (
    <InfoBlock className="theme-color-14">
      <InfoBlock.Title>{lang.TITLE}</InfoBlock.Title>
      <InfoBlock.Content className={b()}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <InfoBlock.Body limitedHeight={true} className={b('body')}>
            {!isLoading && (
              <>
                <p className={b('title')}>{lang.ITEMS_NAME}</p>
                <div className={b('fields')}>
                  <div className={b('fields-left')}>
                    {levels.map((level) => (
                      <CheckboxField
                        key={level}
                        name={`accessLevels.${level}`}
                        label={lang[`${level.toUpperCase()}_LABEL`]}
                        color="primary"
                        withWrap
                        inversionColor
                        onChange={(value) => {
                          if (value) {
                            change('auto_configuration', false);
                          }
                        }}
                      />
                    ))}
                  </div>
                  <div className={b('fields-right')}>
                    <CheckboxField
                      name="auto_configuration"
                      label={lang.AUTO_CONFIGURATION_LABEL}
                      color="primary"
                      withWrap
                      inversionColor
                      onChange={(value) => {
                        if (value) {
                          levels.forEach((level) => change(`accessLevels.${level}`, false));
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={b('fields-row')}>
                  <div>
                    <p className={b('title')}>{lang.COUPON_TYPES}</p>
                    <div className={b('fields')}>
                      <div className={b('fields-left')}>
                        {COUPON_TYPES.map((couponType) => (
                          <CheckboxField
                            key={couponType}
                            name={`couponTypes.${couponType}`}
                            label={couponType.replace('Coupon', '').toUpperCase()}
                            color="primary"
                            withWrap
                            inversionColor
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                  {!!permissions && (
                    <div>
                      <p className={b('title')}>{lang.PERMISSIONS}</p>
                      <div className={b('fields')}>
                        <div className={b('fields-left')}>
                          <CheckboxField
                            name="permissions.user_creation_allowed"
                            label={lang.ALLOW_USER_CREATION}
                            color="primary"
                            withWrap
                            inversionColor
                          />
                          <CheckboxField
                            name="permissions.crm_management"
                            label={lang.CRM_MANAGEMENT}
                            color="primary"
                            withWrap
                            inversionColor
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {isLoading && <Spinner centered color="current" />}
          </InfoBlock.Body>
          <button type="submit" className={b('button')} disabled={isSubmitButtonDisabled}>
            {isSubmitting ? <LoadingSpinner loading cssModifier={b('submit-spinner')} /> : lang.SAVE_BUTTON}
          </button>
        </form>
      </InfoBlock.Content>
    </InfoBlock>
  );
});

AutoCreationAccessCard.defaultProps = {
  accessLevels: [],
  couponTypes: [],
  autoConfiguration: false,
  isAgency: false,
};

AutoCreationAccessCard.propTypes = {
  patchClient: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  accessLevels: PropTypes.arrayOf(PropTypes.string),
  couponTypes: PropTypes.arrayOf(PropTypes.string),
  autoConfiguration: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  clientType: PropTypes.oneOf(['agency', 'company']).isRequired,
  permissions: PropTypes.shape({
    user_creation_allowed: PropTypes.bool,
    crm_management: PropTypes.bool,
  }),

  // from redux-form
  pristine: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialize: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  isAgency: PropTypes.bool,
};

export default reduxForm({
  form: 'AutoCreationAccessCardForm',
})(AutoCreationAccessCard);
