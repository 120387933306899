import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {blobToBase64} from 'client/services/blobToBase64';
import {checkImageMimeType} from 'client/services/helpers';

import {patchClient} from 'client/ducks/clients-list/actions';

import LoadingSpinner from 'client/components/common/loading-spinner';

import {TranslationJsx} from 'client/models/language/types';

import './add-logo-button.scss';

class AddLogoButton extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    updateMe: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
    buttonText: TranslationJsx.isRequired,
    patchClient: PropTypes.func.isRequired,
  };

  handleChangeImage = (event) => {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    checkImageMimeType(file)
      .then(async () => {
        const base64 = await blobToBase64(file);

        return this.props.patchClient(this.props.clientId, {
          client: {
            logo_image: {
              data: base64,
            },
          },
        });
      })
      .then(this.props.updateMe);
  };

  render() {
    const {loading, buttonText} = this.props;
    return (
      <div className="pull-right page-clients__add-logo-btn">
        <div className="input-file">
          <label className="input-file__label">
            <div className={`input-file__btn button button--bg-1 ${loading ? 'disabled' : ''}`}>
              <span>
                <LoadingSpinner cssModifier="input-file__btn__loader" loading={loading}>
                  {buttonText}
                </LoadingSpinner>
              </span>
            </div>
            <input
              className="input-file__input"
              type="file"
              disabled={loading}
              accept="image/*"
              onChange={this.handleChangeImage}
            />
          </label>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    loading: state.clientsList.loading,
  }),
  {patchClient},
)(AddLogoButton);
