import {callApi} from 'client/services/call-api';
import {getQueryParams} from 'client/services/helpers';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getDeviceFamiliesAction(queryParams) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICE_FAMILIES}`,
      method: 'GET',
      types: [types.GET_DEVICE_FAMILIES_REQUEST, types.GET_DEVICE_FAMILIES_SUCCESS, types.GET_DEVICE_FAMILIES_ERROR],
      queryParams,
    });
}

export function postDeviceFamilyAction(data) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICE_FAMILIES}`,
      method: 'POST',
      types: [types.POST_DEVICE_FAMILY_REQUEST, types.POST_DEVICE_FAMILY_SUCCESS, types.POST_DEVICE_FAMILY_ERROR],
      body: data,
    });
}

export function getDevicesAction({page, perPage, sort = {}}) {
  return (dispatch) => {
    const queryParams = getQueryParams({
      include: ['device_family'],
      q: {
        s: `${sort.name} ${sort.order}`,
      },
      page,
      per_page: perPage,
    });
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICES}?${queryParams}`,
      method: 'GET',
      types: [types.GET_DEVICES_REQUEST, types.GET_DEVICES_SUCCESS, types.GET_DEVICES_ERROR],
      noCache: true,
    });
  };
}

export function patchDeviceAction(id, data) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICES}/${id}`,
      method: 'PATCH',
      types: [types.PATCH_DEVICE_REQUEST, types.PATCH_DEVICE_SUCCESS, types.PATCH_DEVICE_ERROR],
      body: data,
    });
}

export function deleteDeviceAction(id) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICES}/${id}`,
      method: 'DELETE',
      types: [types.DELETE_DEVICE_REQUEST, types.DELETE_DEVICE_SUCCESS, types.DELETE_DEVICE_ERROR],
    });
}

export function postDeviceAction(device) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICES}`,
      method: 'POST',
      types: [types.POST_DEVICE_REQUEST, types.POST_DEVICE_SUCCESS, types.POST_DEVICE_ERROR],
      isAuth: true,
      body: device,
    });
}

export function unlinkDevice(deviceId, operationId) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICES}/${deviceId}/unlink`,
      method: 'POST',
      types: [types.UNLINK_DEVICE_REQUEST, types.UNLINK_DEVICE_SUCCESS, types.UNLINK_DEVICE_ERROR],
      body: {
        operation_id: operationId,
      },
    });
}

export function linkDevice(operationId, {deviceIds, from, to}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICES}/link`,
      method: 'POST',
      types: [types.LINK_DEVICE_REQUEST, types.LINK_DEVICE_SUCCESS, types.LINK_DEVICE_ERROR],
      body: {
        operation_id: operationId,
        device_ids: deviceIds,
        from,
        to,
      },
    });
}

export function linkAllDevice(operationId, exceptDeviceIds) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.DEVICES}/link_all`,
      method: 'POST',
      types: [types.LINK_ALL_DEVICE_REQUEST, types.LINK_ALL_DEVICE_SUCCESS, types.LINK_ALL_DEVICE_ERROR],
      body: {
        operation_id: operationId,
        device_ids: exceptDeviceIds,
      },
    });
}
