import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export const getAnimations = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ANIMATIONS}`,
        method: 'GET',
        types: [types.GET_ANIMATIONS_REQUEST, types.GET_ANIMATIONS_SUCCESS, types.GET_ANIMATIONS_ERROR],
        queryParams,
      },
      true,
    );
  };
};

export const getAnimation = (id, queryParams = {}) => {
  return (dispatch) => {
    return callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.ANIMATIONS}/${id}`,
      method: 'GET',
      types: [types.GET_ANIMATION_REQUEST, types.GET_ANIMATION_SUCCESS, types.GET_ANIMATION_ERROR],
      queryParams,
    });
  };
};

export const getAnomalyOccurrences = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ANOMALY_OCCURRENCES}`,
        method: 'GET',
        types: [
          types.GET_ANOMALY_OCCURRENCES_REQUEST,
          types.GET_ANOMALY_OCCURRENCES_SUCCESS,
          types.GET_ANOMALY_OCCURRENCES_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
};

export const updateAnomalyOccurrence = (id, data) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ANOMALY_OCCURRENCES}/${id}`,
        method: 'PATCH',
        types: [
          types.UPDATE_ANOMALY_OCCURRENCE_REQUEST,
          types.UPDATE_ANOMALY_OCCURRENCE_SUCCESS,
          types.UPDATE_ANOMALY_OCCURRENCE_ERROR,
        ],
        body: {
          instore_anomaly_occurrence: data,
        },
      },
      true,
    );
  };
};

export const getAnimationStatistics = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ANIMATIONS_STATISTICS}`,
        method: 'GET',
        types: [
          types.GET_ANIMATIONS_STATISTICS_REQUEST,
          types.GET_ANIMATIONS_STATISTICS_SUCCESS,
          types.GET_ANIMATIONS_STATISTICS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
};

export const updateAnimationStatus = (id, status) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ANIMATIONS}/${id}/change_status`,
        method: 'PATCH',
        types: [
          types.CHANGE_ANIMATIONS_STATUS_REQUEST,
          types.CHANGE_ANIMATIONS_STATUS_SUCCESS,
          types.CHANGE_ANIMATIONS_STATUS_ERROR,
        ],
        body: {
          instore_animation: {status},
        },
      },
      true,
    );
  };
};

export const clearAnimations = () => {
  return (dispatch) => dispatch({type: types.GET_ANIMATIONS_SUCCESS, payload: {instore_animations: []}});
};

export const updateAnimationDetails = (id, body) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ANIMATIONS}/${id}/change_details`,
        method: 'PATCH',
        types: [
          types.UPDATE_ANIMATION_DETAILS_REQUEST,
          types.UPDATE_ANIMATION_DETAILS_SUCCESS,
          types.UPDATE_ANIMATION_DETAILS_ERROR,
        ],
        body,
      },
      true,
    );
  };
};

export const getAnimationKpis = (queryParams) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ANIMATION_KPIS}`,
        method: 'GET',
        types: [types.GET_ANIMATION_KPIS_REQUEST, types.GET_ANIMATION_KPIS_SUCCESS, types.GET_ANIMATION_KPIS_ERROR],
        queryParams,
      },
      true,
    );
  };
};

export const getAnimationPhotos = (queryParams = {}) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.INSTORE_TASKS_ANIMATION_PHOTOS}`,
        method: 'GET',
        types: [
          types.GET_ANIMATION_PHOTOS_REQUEST,
          types.GET_ANIMATION_PHOTOS_SUCCESS,
          types.GET_ANIMATION_PHOTOS_ERROR,
        ],
        queryParams,
      },
      true,
    );
  };
};

// taskId are required query params
export const getAnimationQuestions = ({taskId}, queryParams) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ANIMATION_QUESTIONS}`,
        method: 'GET',
        types: [
          types.GET_ANIMATION_QUESTIONS_REQUEST,
          types.GET_ANIMATION_QUESTIONS_SUCCESS,
          types.GET_ANIMATION_QUESTIONS_ERROR,
        ],
        queryParams: {
          instore_task_id: taskId,
          ...queryParams,
        },
      },
      true,
    );
  };
};

export const changeAnimationContacts = (animationId, body) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.ANIMATIONS}/${animationId}/change_contact_info`,
        method: 'PATCH',
        types: [types.CHANGE_CONTACT_INFO_REQUEST, types.CHANGE_CONTACT_INFO_SUCCESS, types.CHANGE_CONTACT_INFO_ERROR],
        body,
      },
      true,
    );
  };
};
