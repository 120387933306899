import React, {useCallback, useMemo, useState} from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {dateToString} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import {selectAutotaskParam} from 'client/ducks/autotask/selectors';

import {useFetchDiyGame} from 'client/components/diy-operation/hooks';
import PrizeDiyModal from 'client/components/prizes/modals/prize-diy-modal';
import {isOperationArchived} from 'client/models/operations/helpers';

import DiyOpPrizesBlockHeader from './diy-op-prizes-block-header';
import DiyOpPrizesBlockTable from './diy-op-prizes-block-table';

import './diy-op-prizes-block.scss';

const b = bem('diy-op-prizes-block');

const DiyOpPrizesBlock = (props) => {
  const {operation, fetchAutotask} = props;
  const clientGames = useSelector(selectAutotaskParam('client_games'));
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_PRIZES_BLOCK');
  const [showPrizeModal, togglePrizeModal] = useToggle(false);
  const [editingPrize, setEditingPrize] = useState(null);

  const isOpArchived = isOperationArchived(operation);

  const clientGameId = useMemo(() => {
    return clientGames?.find((game) => game.game_type === 'instant_win')?.id;
  }, [clientGames]);

  const {fetchGame, prizeBlock} = useFetchDiyGame();

  const publishedAt = prizeBlock.published_at;

  const handleEditClick = useCallback(
    (prize) => {
      setEditingPrize(prize);
      togglePrizeModal();
    },
    [togglePrizeModal],
  );

  const handleClosePrizeModal = () => {
    setEditingPrize(null);
    togglePrizeModal();
  };

  // despite the fetchGame is bound to existance of the autotask value, in this case it's possible to fetch them in parallel,
  // because only prize_maps_present param of autotask is needed in this case to enable prizemaps modal button
  const fetchData = () => Promise.all([fetchAutotask(), fetchGame()]);

  return (
    <div className={b()}>
      <div className={b('title')}>
        <h3 className={b('title-text')}>{lang.TITLE}</h3>
        {publishedAt && <div className={b('publish-text')}>{`${lang.LAST_PUBLISH} ${dateToString(publishedAt)}`}</div>}
      </div>
      {!clientGameId ||
        (prizeBlock.id && (
          <DiyOpPrizesBlockHeader
            key={`${prizeBlock.default_from || ''}${prizeBlock.default_to || ''}`}
            openAddPrizeModal={togglePrizeModal}
            date_from={prizeBlock.default_from || ''}
            date_to={prizeBlock.default_to || ''}
            gameId={prizeBlock.id}
            fetchPrizes={fetchGame}
            isOperationArchived={isOpArchived}
          />
        ))}
      <DiyOpPrizesBlockTable
        prizeList={prizeBlock.prizes}
        onEditClick={handleEditClick}
        gameId={clientGameId}
        disabled={isOpArchived || !operation.editable}
      />
      {showPrizeModal && (
        <PrizeDiyModal
          game={prizeBlock}
          operation={operation}
          fetchData={fetchData}
          editingPrize={editingPrize}
          onClose={handleClosePrizeModal}
          isOperationArchived={isOpArchived}
        />
      )}
    </div>
  );
};

DiyOpPrizesBlock.propTypes = {
  operation: PropTypes.object.isRequired,
  fetchAutotask: PropTypes.func.isRequired,
};

export default DiyOpPrizesBlock;
