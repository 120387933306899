import {EmailTemplateKindVariable} from 'client/models/email-templates/types';
import {SmsTemplateKindVariable} from 'client/models/sms-templates/types';

export const getVariableOptions = (variables: EmailTemplateKindVariable[] | SmsTemplateKindVariable[]) => {
  const participation: string[] = [];
  const lead: string[] = [];
  const prize: string[] = [];
  const device: string[] = [];
  const operationDatum: string[] = [];

  variables.forEach((item) => {
    const family = item.column_adapter.record_type;
    if (family === 'Participation') {
      participation.push(item.name);
    } else if (family === 'Lead') {
      lead.push(item.name);
    } else if (family === 'ParticipationPrize') {
      prize.push(item.name);
    } else if (family === 'Device') {
      device.push(item.name);
    } else if (family === 'OperationDatum') {
      operationDatum.push(item.name);
    }
  });

  const mapOption = (name: string) => ({label: name, value: name});

  return {
    participation: participation.sort().map(mapOption),
    lead: lead.sort().map(mapOption),
    prize: prize.sort().map(mapOption),
    device: device.sort().map(mapOption),
    operation_datum: operationDatum.sort().map(mapOption),
  };
};
