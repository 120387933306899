import {isEmptyString} from 'client/services/validator';

import {PRIZE_TYPES} from 'client/components/prizes/constants';

const validatePrizeNames = (values, lang) => {
  const errors = {};

  if (!values.prize_names) {
    return errors;
  }

  // If default language is present, its translation is required
  if (values.defaultLanguage) {
    if (isEmptyString(values.prize_names[values.defaultLanguage])) {
      errors[values.defaultLanguage] = lang.REQUIRED;
    }

    return errors;
  }

  // If default language is not present, at least one translation for any language is required
  for (let key in values.prize_names) {
    if (!isEmptyString(values.prize_names[key])) {
      return errors;
    }
  }

  const firstLang = Object.keys(values.prize_names)?.[0];

  if (firstLang) {
    errors[firstLang] = lang.REQUIRED;
  }

  return errors;
};

export default (values, lang) => {
  const errors = {prize_names: validatePrizeNames(values, lang)};

  if (values.type === PRIZE_TYPES.LOYALTY && isEmptyString(values.loyalty_amount)) {
    errors.loyalty_amount = lang.REQUIRED;
  }

  return errors;
};
