import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';

import CustomDataRetentionField, {getValue} from 'client/components/common/custom-fields/data-retention-field';

const b = bem('general-information-form');

function DataRetentionField({name, unit, disabled, value, langTimeUnits}) {
  return disabled ? (
    <div className={b('data-retention-item', ['disabled'])}>{getValue(unit, value, langTimeUnits)}</div>
  ) : (
    <CustomDataRetentionField
      cssModifier={cn(b('data-retention-item'), 'select--disabled-view-1')}
      name={name}
      unit={unit}
      disabled={disabled}
    />
  );
}

DataRetentionField.propTypes = {
  name: PropTypes.string,
  unit: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  langTimeUnits: PropTypes.object.isRequired,
};

export default connect(({languageState}) => ({
  langTimeUnits: languageState.payload.COMMON.TIME_UNITS,
}))(DataRetentionField);
