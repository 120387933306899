import React, {Component} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getEmail, getToken} from 'client/services/cookie-data-source';
import {patch} from 'client/services/fetch';
import {transformDate, getClientPage} from 'client/services/helpers';

import {getAutotasks} from 'client/ducks/autotask/actions';
import {selectAutotasks} from 'client/ducks/autotask/selectors';
import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
// import {getUsersAction} from 'client/ducks/agency/actions';
import {getCompanyUsersAction} from 'client/ducks/company/actions';
import {selectUsersFullList} from 'client/ducks/company/selectors';
import {deleteOperationDatumColumn} from 'client/ducks/custom-operation-database/actions';
import {getMessageTasks} from 'client/ducks/message-email-sms-task/actions';
import {selectMessageTasksMapped} from 'client/ducks/message-email-sms-task/selectors';
import {getOperation, updateOperation} from 'client/ducks/operations/actions';
import {selectOperation} from 'client/ducks/operations/selectors';

import {CLIENT_PAGES, CLIENT_TYPES, API_METHODS, API_PATH} from 'client/common/config';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import ToggleButton from 'client/components/common/custom-buttons/toggle-button';
import CustomLink from 'client/components/common/custom-link';
import ImageCard from 'client/components/common/image-card';
import TitleBlock from 'client/components/common/title-block';
import VisualsCard from 'client/components/common/visuals-card';

import BCClicksModal from 'client/components/broadcasting/components/modals/bc-clicks-modal';
import SelectUserModal from 'client/components/modals/select-user-modal';
import {TASK_TYPES} from 'client/models/operations/constants';

import AddNewTaskCard from './components/cards/add-new-task-card';
import CustomDatabaseLabelsCard from './components/cards/custom-database-labels-card';
import GeneralInformationCard from './components/cards/general-information-card';
import GeneralInformationForm from './components/cards/general-information-card/general-information-form';
import OperationCard from './components/cards/operation-card';
import OperationLeadsCard from './components/cards/operation-leads-card';
import OperationMessageTaskCard from './components/cards/operation-message-task-card';
import TemplatesCard from './components/cards/templates-card';
import EditLabelModal from './components/modals/edit-label-modal';
import NewAutotaskModal from './components/modals/new-autotask-modal';
import NewLabelModal from './components/modals/new-label-modal';
import NewMessageBroadcastModal from './components/modals/new-message-broadcast-modal';
import AutotaskList from './components/tables/autotask-list';
import LeadsVisualsList from './components/tables/leads-visuals-list';
import CouponsCard from './coupons-card';

import './operation.scss';

const MAX_IMAGE_SIZE = 5050000;

const checkImageType = (fileType) => {
  const fileTypes = ['jpg', 'jpeg', 'png'];
  return fileTypes.includes(fileType.split('/')[1]);
};

class OperationPage extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
    operation: PropTypes.object.isRequired,
    lang: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setBreadcrumbs: PropTypes.func.isRequired,
    clearBreadcrumbs: PropTypes.func.isRequired,
    deleteOperationDatumColumn: PropTypes.func.isRequired,
    getOperation: PropTypes.func.isRequired,
    messageTasks: PropTypes.array,
    automationTasks: PropTypes.array,
    ...withRouter.propTypes,
    getMessageTasks: PropTypes.func.isRequired,
    getAutotasks: PropTypes.func.isRequired,
    updateOperation: PropTypes.func.isRequired,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
      }),
    ).isRequired,
    getUsers: PropTypes.func.isRequired,
  };

  static defaultProps = {
    messageTasks: [],
    automationTasks: [],
    operation: {
      image: {
        url: null,
      },
    },
  };

  static formNames = {
    OPERATION_DASHBOARDS: 'OperationDashboardsForm',
    AUTO_TASK_DASHBOARDS: TASK_TYPES.AUTOMATION + 'DashboardsForm',
    MESSAGE_TASK_DASHBOARDS: TASK_TYPES.MESSAGE + 'DashboardsForm',
  };

  fileInput = null;

  constructor(props) {
    super(props);

    this.state = {
      isItAgency: false,
      editGeneralInformation: false,
      showNewAutotaskModal: false,
      showNewLabelModal: false,
      showEditLabelModal: false,
      showDeleteLabelModal: false,
      showMessageTaskModal: false,
      showSelectUserModal: false,
      showClicksModal: false,
      editingLabel: {},
      clicksStatistic: [],
      taskType: '',
      isImageLoaded: false,
      image: null,
      automationTasksId: null,
      invalidImageSize: false,
      invalidImageFormat: false,
    };
  }

  componentDidMount() {
    this.getMyOperation().then(() => {
      this.getTasks();

      this.setState(
        {
          isItAgency: this.props.operation?.client?.type === CLIENT_TYPES.AGENCY,
        },
        this.updateBreadcrumbs,
      );
    });
    this.props.getUsers(this.props.clientId);
  }

  componentWillUnmount() {
    this.props.clearBreadcrumbs();
  }

  getMyOperation = () =>
    this.props.getOperation(this.props.id, {
      include: [
        'operation_datum_columns.interfaces',
        {client: 'agency'},
        'email_templates',
        'message_tasks',
        'dashboards',
      ],
      include_operation_leads_count: null,
      include_operation_visuals_count: null,
    });

  getTasks = () => {
    this.getAutotasks();
    this.getMessageTasks();
  };

  getAutotasks = () =>
    this.props.getAutotasks({
      include: ['dashboards', 'scenarios', 'interfaces', 'interaction_groups'],
      q: {
        operation_id_eq: this.props.id,
      },
      include_automation_task_visuals_count: null,
      include_automation_task_leads_count: null,
    });

  getMessageTasks = () =>
    this.props.getMessageTasks({
      include: ['dashboards', 'broadcast_list', 'operation.client', 'email_template'],
      q: {
        operation_id_eq: this.props.id,
      },
    });

  updateBreadcrumbs = () => {
    const {
      operation: {client, ...operation},
      lang: {BREADCRUMBS},
    } = this.props;

    const clientHref = `${getClientPage(client)}/${client?.id}`;
    const agencyBreadcrumb = client?.agency?.id
      ? [
          {
            href: `${getClientPage(client.agency)}/${client.agency.id}`,
            name: client.agency.name,
          },
        ]
      : [];

    const operationHref = `${clientHref}${CLIENT_PAGES.OPERATIONS}/${operation?.id}`;
    const customOpDBHref = `${operationHref}${CLIENT_PAGES.CUSTOM_OPERATION_DATABASE}`;

    this.props.setBreadcrumbs([
      {
        href: CLIENT_PAGES.VISUALS,
        name: BREADCRUMBS.VISUALS,
      },
      {
        name: BREADCRUMBS.CLIENTS,
        href: CLIENT_PAGES.CLIENTS_LIST,
      },
      ...agencyBreadcrumb,
      {
        name: client?.name,
        href: clientHref,
      },
      {
        name: BREADCRUMBS.CUSTOM_OPERATION,
        href: customOpDBHref,
      },
      {
        name: operation?.name,
      },
    ]);
  };

  handleNewTaskClick = (taskType) => {
    const taskModalsMap = {
      [TASK_TYPES.AUTOMATION]: 'showNewAutotaskModal',
      [TASK_TYPES.EMAIL]: 'showMessageTaskModal',
      [TASK_TYPES.SMS]: 'showMessageTaskModal',
    };

    this.setState({
      taskType,
      [taskModalsMap[taskType]]: true,
    });
  };

  handleSaveGeneralInformationClick = () => {
    this.getMyOperation().then(this.updateBreadcrumbs);
    this.setState({editGeneralInformation: false});
  };

  updateOperation = () => {
    this.getMyOperation();
    this.setState({
      showNewAutotaskModal: false,
      showNewLabelModal: false,
      showEditLabelModal: false,
      showDeleteLabelModal: false,
    });
  };

  deleteEditingLabel = () =>
    this.props.deleteOperationDatumColumn(this.state.editingLabel.id).then(this.updateOperation);

  handleDeleteLabel = (containsData) => {
    if (containsData) {
      return this.setState({showDeleteLabelModal: true, showEditLabelModal: false});
    }
    return this.deleteEditingLabel();
  };

  showMessageTaskModal = () => {
    this.setState({
      showMessageTaskModal: !this.state.showMessageTaskModal,
    });
  };

  handleAutotaskCreated = ({type: taskType, id: taskId}) => {
    const {operation} = this.props;

    this.props.history.push(
      CustomLink.createTaskLink({
        taskType,
        taskId,
        clientType: operation.client.type,
        clientId: operation.client.id,
        operationId: operation.id,
      }),
    );
  };

  handleClicksCountModalShow = (clicksStatistic) => {
    this.setState({
      showClicksModal: true,
      clicksStatistic,
    });
  };

  deleteImageHandler = async () => {
    await patch(`/${API_METHODS.OPERATIONS}/${this.props.id}`, {
      operation: {
        remove_image: true,
      },
    });
    await this.getMyOperation();
    this.setState({
      image: null,
    });
  };

  handleChangeImage = async (e) => {
    this.setState({
      invalidImageFormat: false,
      invalidImageSize: false,
    });

    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (file.size < MAX_IMAGE_SIZE && checkImageType(file.type)) {
      const formData = new FormData();
      await formData.append('operation[image]', file);
      this.setState({
        image: URL.createObjectURL(file),
      });
      fetch(`${API_PATH}/${API_METHODS.OPERATIONS}/${this.props.id}`, {
        method: 'PATCH',
        headers: {
          'X-User-Email': getEmail(),
          'X-User-Token': getToken(),
        },
        body: formData,
      }).then((response) => {
        if (get(response, 'status') === 422) {
          this.setState({invalidImageFormat: true});
        } else {
          this.getMyOperation();
        }
      });
    } else {
      this.setState({
        invalidImageSize: true,
      });
    }
  };

  getVisualsLink = () => {
    const {operation} = this.props;
    const operationName = encodeURIComponent(operation.name);

    const page = `${getClientPage(operation.client)}/${this.props.clientId}${CLIENT_PAGES.VISUALS}`;
    const filters = `filters={"operation_in":[{"name":"${operationName}","id":"${operation.id}"}]}`;

    return `${page}?${filters}`;
  };

  toggleVisualsVisible = (value) => {
    const {operation} = this.props;

    const data = {visible_by_default: value};

    return this.props.updateOperation(operation.id, data).then(this.getMyOperation);
  };

  renderImageContainerHeader = (url, isDirectImage) => (
    <div className="operation-image__header-actions">
      {!url || !isDirectImage ? (
        <div>
          <input
            ref={(ref) => (this.fileInput = ref)}
            id="image"
            name="image"
            className="input-file__input"
            style={{display: 'none'}}
            type="file"
            accept="image/*"
            onChange={this.handleChangeImage}
          />
          <button
            className="button button--bg-operations pull-right image-button"
            onClick={() => this.fileInput.click()}
          >
            <div>
              <Icon name="plus" className="button__icon" />
              <Icon name="image" className="button__icon" />
            </div>
          </button>
        </div>
      ) : (
        <button className="button button--circle button--bg-operations pull-right" onClick={this.deleteImageHandler}>
          <Icon name="trash" className="button__icon" width={17} height={19} />
        </button>
      )}
    </div>
  );

  renderImageContainerMessage = () => {
    const {lang} = this.props;

    if (!this.state.image && !this.state.invalidImageSize) {
      return <span>{lang.IMAGE_CONTAINER.NO_IMAGE}</span>;
    }
    if (this.state.invalidImageSize || this.state.invalidImageFormat) {
      return (
        <div className="theme-color-eye-closed error-container">
          <div className="error-container__header">
            <Icon name="image" className="button__icon" />
            {this.state.invalidImageSize && <span>{lang.IMAGE_CONTAINER.SIZE_TOO_LARGE}</span>}
            {this.state.invalidImageFormat && <span>{lang.IMAGE_CONTAINER.INVALID_FORMAT}</span>}
          </div>
          <div className="error-container__message">{lang.IMAGE_CONTAINER.ERROR_UPLOAD_MESSAGE}</div>
        </div>
      );
    }
    return null;
  };

  renderOperationButton = (autotask) => {
    const {lang} = this.props;

    return (
      <button
        className="button button--bg-5 ml-2"
        onClick={() => this.setState({showSelectUserModal: true, automationTasksId: autotask.id})}
      >
        <span className="bold">{lang.ANALYTICS}</span>
      </button>
    );
  };

  render() {
    const {lang, operation, messageTasks, automationTasks, users} = this.props;

    const url = get(operation, 'operation_image.url', null);
    const isDirectImage = get(operation, 'operation_image.direct_operation_image', false);

    return (
      <div className="theme-color-operations operation-page">
        <div className="page__title-block">
          <TitleBlock theme ellipsis="1">
            <TitleBlock.Item>{operation.name}</TitleBlock.Item>
          </TitleBlock>
        </div>

        <BCClicksModal
          links={this.state.clicksStatistic}
          show={this.state.showClicksModal}
          onConfirm={() => this.setState({showClicksModal: false})}
          onClose={() => this.setState({showClicksModal: false})}
        />

        <NewAutotaskModal
          operationId={operation.id}
          show={this.state.showNewAutotaskModal}
          onClose={() => this.setState({showNewAutotaskModal: false})}
          onConfirm={this.handleAutotaskCreated}
        />

        <NewLabelModal
          operationId={operation.id}
          show={this.state.showNewLabelModal}
          onClose={() => this.setState({showNewLabelModal: false})}
          onConfirm={this.updateOperation}
        />

        <EditLabelModal
          show={this.state.showEditLabelModal}
          onClose={() => this.setState({showEditLabelModal: false})}
          onConfirm={this.updateOperation}
          onDeleteLabelClick={this.handleDeleteLabel}
          editingLabel={this.state.editingLabel}
        />

        <ConfirmationModal
          show={this.state.showDeleteLabelModal}
          onClose={() => this.setState({showDeleteLabelModal: false, showEditLabelModal: true})}
          onCancel={() => this.setState({showDeleteLabelModal: false, showEditLabelModal: true})}
          message={lang.DELETE_LABEL_MODAL.BODY_TEXT.replace(/{LABEL_NAME}/g, this.state.editingLabel.name)}
          title={lang.DELETE_LABEL_MODAL.TITLE}
          cancelText={lang.CANCEL_BUTTON}
          confirmText={lang.DELETE_LABEL_MODAL.DELETE_BUTTON}
          className="edit-label-modal"
          buttonConfirmClass="button--bg-opdb-labels"
          buttonCancelClass="button--bg-11"
          onConfirm={this.deleteEditingLabel}
        />

        <SelectUserModal
          show={this.state.showSelectUserModal}
          users={users}
          clientId={this.props.clientId}
          operationId={this.props.id}
          automationTaskId={this.state.automationTasksId}
          onClose={() => this.setState({showSelectUserModal: false})}
        />

        {operation.id && (
          <NewMessageBroadcastModal
            taskType={this.state.taskType}
            operationId={operation.id}
            show={this.state.showMessageTaskModal}
            onClose={this.showMessageTaskModal}
            onCancel={this.showMessageTaskModal}
            onConfirm={(taskId) => {
              const link =
                CustomLink.createTaskLink({
                  clientType: operation.client.type,
                  clientId: operation.client.id,
                  operationId: operation.id,
                  taskType: this.state.taskType,
                  taskId,
                }) + CLIENT_PAGES.MESSAGE_TASK_SETTINGS;
              this.props.history.push(link);
            }}
          />
        )}

        <div className="pull-right operation-page__status">
          <div className="pull-right">
            <div className="indicator">
              <div className={`indicator__inner--${operation.status}`} />
            </div>
            <span className="operation-page__status__description">{lang.OPERATION_STATUS[operation.status]}</span>
          </div>
          <div className="operation-page__status__date">
            {`${transformDate(operation.from)} - ${transformDate(operation.to)}`}
          </div>
        </div>

        <div className="page__block-container">
          <div className="page__row">
            <div
              className={
                'page__block page__block--size-6 page__block' + (this.state.editGeneralInformation ? '--extended' : '')
              }
            >
              <div className="page__row">
                <GeneralInformationCard
                  onEditClick={() => this.setState({editGeneralInformation: !this.state.editGeneralInformation})}
                  editMode={this.state.editGeneralInformation}
                >
                  <GeneralInformationForm
                    clientId={this.props.clientId}
                    edit={this.state.editGeneralInformation}
                    onCancel={() => this.setState({editGeneralInformation: !this.state.editGeneralInformation})}
                    onSave={this.handleSaveGeneralInformationClick}
                    initialValues={operation}
                  />
                </GeneralInformationCard>
              </div>
              <div className="page__row">
                <div className="page__block page__block--size-3 page__block">
                  <OperationLeadsCard leadsCount={operation.leads_count} operation={operation} />
                </div>
                <div className="page__block page__block--size-3 page__block operation-page__visuals-block">
                  <div className="operation-page__visuals-toggle">
                    <div className="operation-page__visuals-toggle-title">
                      <div>{lang.OPERATION_VISUALS_DATABASE.VISIBLE}</div>
                      <div>{lang.OPERATION_VISUALS_DATABASE.BY_DEFAULT}</div>
                    </div>
                    <ToggleButton value={operation.visible_by_default} onToggle={this.toggleVisualsVisible} />
                  </div>
                  <VisualsCard
                    visualsURL={this.getVisualsLink()}
                    visualsCount={operation.visuals_count}
                    title={lang.OPERATION_VISUALS_DATABASE.TITLE}
                    goToLinkText={lang.OPERATION_VISUALS_DATABASE.GO_TO_VISUALS_LINK}
                    totalText={lang.OPERATION_VISUALS_DATABASE.VISUALS_GENERATED}
                    itemText={lang.IN_THIS_OPERATION__DESCRIPTION}
                    underLinkDescription={lang.OF_THIS_OPERATION__DESCRIPTION}
                    noMargin={true}
                  />
                </div>
              </div>
            </div>

            <div className="page__block page__block--size-4 page__block">
              <ImageCard title="Image" header={this.renderImageContainerHeader(url, isDirectImage)}>
                <div className="operation-image__block">
                  {url ? <img className="operation-image" src={url} alt="" /> : this.renderImageContainerMessage()}
                </div>
              </ImageCard>
            </div>
          </div>

          <div className="page__row">
            <div className="page__block page__block--size-4">
              <TemplatesCard clientType={get(operation, 'client.type')} />
            </div>

            <div className="page__block page__block--size-4">
              <CustomDatabaseLabelsCard
                data={operation.operation_datum_columns}
                onCreateClick={() => this.setState({showNewLabelModal: true})}
                onEditClick={(editingLabel) => this.setState({showEditLabelModal: true, editingLabel})}
              />
            </div>

            <div className="page__block page__block--size-4">
              {operation.id && <CouponsCard operationId={operation.id} />}
            </div>
          </div>

          {automationTasks.map((autotask) => (
            <div
              className="page__block page__block--size-5 operation-page__block theme-color-autotask"
              key={autotask.id}
            >
              <OperationCard title={lang.AUTOMATION_TASK.TITLE} button={this.renderOperationButton(autotask)}>
                <div className="operation-page__block__inner-1">
                  <AutotaskList
                    clientType={get(operation, 'client.type', CLIENT_TYPES.AGENCY)}
                    clientId={this.props.clientId}
                    autotask={autotask}
                  />
                </div>
                <div className="operation-page__block__inner-2">
                  <LeadsVisualsList
                    leadsCount={autotask.leads_count}
                    visualsCount={autotask.visuals_count}
                    participationsCount={autotask.participations_count}
                    autotask={autotask}
                    operation={operation}
                    clientId={this.props.clientId}
                  />
                </div>
              </OperationCard>
            </div>
          ))}

          {messageTasks.map((task) => (
            <div className="page__block page__block--size-5 operation-page__block theme-color-16" key={task.taskId}>
              <OperationMessageTaskCard
                formName={OperationPage.formNames.MESSAGE_TASK_DASHBOARDS + task.taskId}
                clientId={operation.client_id}
                clientType={operation.client.type}
                task={task}
                onUpdate={this.getMessageTasks}
                onClicksCountClick={this.handleClicksCountModalShow}
              />
            </div>
          ))}

          <div className="page__block page__block--size-5 operation-page__block theme-color-autotask">
            <AddNewTaskCard onCreateClick={this.handleNewTaskClick} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang: {
    ...state.languageState.payload.OPERATION_PAGE,
    BREADCRUMBS: state.languageState.payload.BREADCRUMBS,
  },
  users: selectUsersFullList(state),
  operation: selectOperation(state),
  messageTasks: selectMessageTasksMapped(state),
  automationTasks: selectAutotasks(state),
});

const mapDispatchToProps = {
  getOperation,
  setBreadcrumbs,
  clearBreadcrumbs,
  deleteOperationDatumColumn,
  getAutotasks,
  getMessageTasks,
  updateOperation,
  getUsers: getCompanyUsersAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OperationPage));
