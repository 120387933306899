import {PrizeMap} from 'client/models/prizes/types';

import {PrizeCustomizationModalForm} from './types';

const ADMIN_COUPON_TYPES = ['AppCoupon', 'SogecCoupon', 'WeezioCoupon'];

export default (prizeMaps: PrizeMap[]): PrizeCustomizationModalForm => {
  const initialPrizeMaps = prizeMaps
    ?.filter(({prize}) => !ADMIN_COUPON_TYPES.includes(prize?.coupon?.type || ''))
    ?.sort((a, b) => (a.prize?.order ?? 1) - (b.prize?.order ?? 1))
    ?.map((prizeMap) => {
      const {prize, ...restPrizeMapParams} = prizeMap;
      const {type = 'Prize', coupon, total_quantities, default_stock_offset} = prize || {};

      return {
        ...restPrizeMapParams,
        prize_type: type,
        coupon: coupon?.internal_name || '',
        coupon_type_name: coupon?.type || '',
        total_prize_won_validated: total_quantities?.total_prize_won_validated || 0,
        stock_offset: prizeMap.stock_offset || default_stock_offset,
      };
    });

  const firstPrizeMap = initialPrizeMaps[0];
  return {
    prizeMaps: initialPrizeMaps,
    from: firstPrizeMap?.from || '',
    to: firstPrizeMap?.to || '',
    disabledTime: !firstPrizeMap?.time_editable || false,
  };
};
