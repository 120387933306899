import {Reducer} from 'redux';

import {CATALOG_TYPES} from 'client/models/templates/constants';
import {Catalog} from 'client/models/templates/types';

import types from './types';

type CatalogReducerState = {
  catalogs: Catalog[];
  clientCatalogs: {
    form: Catalog[];
    game: Catalog[];
    quiz: Catalog[];
  };
};

const initialState: CatalogReducerState = {
  catalogs: [],
  clientCatalogs: {
    form: [],
    game: [],
    quiz: [],
  },
};

const catalogsReducer: Reducer<CatalogReducerState> = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_CATALOGS_SUCCESS:
      return {
        ...state,
        catalogs: action.payload.catalogs,
      };

    case types.GET_CLIENT_CATALOGS_SUCCESS:
      return {
        ...state,
        clientCatalogs: {
          form: action.payload.catalogs.filter((i: Catalog) => i.type === CATALOG_TYPES.FORM),
          game: action.payload.catalogs.filter((i: Catalog) => i.type === CATALOG_TYPES.GAME),
          quiz: action.payload.catalogs.filter((i: Catalog) => i.type === CATALOG_TYPES.QUIZ),
        },
      };

    default:
      return state;
  }
};

export default catalogsReducer;
