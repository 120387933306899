import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import PrizeDiyCouponFieldset from 'client/components/prizes/fieldsets/prize-diy-coupon-fieldset';
import PrizeDiyCouponImportsFieldset from 'client/components/prizes/fieldsets/prize-diy-coupon-imports-fieldset';

import './prize-diy-modal-coupon.scss';

const b = bem('prize-diy-modal-coupon');

const PrizeDiyModalCoupon = (props) => {
  const {operation, disabled} = props;

  return (
    <div className={b()}>
      <div className={b('column-left')}>
        <PrizeDiyCouponFieldset disabled={disabled} />
      </div>
      <div className={b('column-right')}>
        <PrizeDiyCouponImportsFieldset operation={operation} disabled={disabled} />
      </div>
    </div>
  );
};

PrizeDiyModalCoupon.propTypes = {
  operation: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default PrizeDiyModalCoupon;
