import React, {useState, useMemo, useRef, useEffect, useCallback} from 'react';

import classNames from 'classnames';
import debounce from 'lodash/debounce';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectUser} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons';
import StepProgressBar from 'client/common/step-progress-bars/step-progress-bar';

import Spinner from 'client/components/common/spinner';

import LanguageDeviceControl from 'client/components/diy-operation/controls/lang-device-control';
import type {Preview} from 'client/models/diy-customization-form/types';

import {getPreviewHtml} from './helpers';

import cssModule from './web-preview.module.scss';

const b = bem('web-preview', {cssModule});

// TODO: import the type from LanguageDeviceControl as soon as it will be typed
type ControlButton = {
  label: string | React.ReactNode;
  value: string;
};

type WebPreviewProps = {
  preview: Preview;
  devices: ControlButton[];
  languages: ControlButton[];
  defaultLanguage?: string;
  defaultDevice?: string;
  data?: object;
  hasReloadButton?: boolean;
  className?: string;
  pagination?: {
    title: string;
    totalSteps: number;
    currentStep: number;
    setCurrentStep: (step: number) => void;
  };
  pageName?: string;
};

const WebPreview: React.FC<WebPreviewProps> = (props) => {
  const {
    preview: {main_file_url: htmlUrl, preview_assets: assets},
    devices,
    languages,
    data,
    defaultLanguage,
    defaultDevice,
    hasReloadButton,
    className,
    pagination,
    pageName,
  } = props;
  const lang = useLanguage('DIY_OPERATION.MODALS.CONFIGURATION_MODAL.DIY_MODAL_PREVIEW');
  const {locale} = useSelector(selectUser);

  const [hasPreviewBootstrapped, setHasPreviewBootstrapped] = useToggle(false);
  const [isPreviewLoading, setIsPreviewLoading] = useToggle(false);
  const [activeDevice, setActiveDevice] = useState(defaultDevice);
  const [activeLanguage, setActiveLanguage] = useState(defaultLanguage || locale);

  const previewRef = useRef<HTMLIFrameElement>(null);

  const bootstrapPreview = useCallback(async () => {
    if (htmlUrl) {
      setIsPreviewLoading(true);
      const previewHtml = await getPreviewHtml(htmlUrl, assets);

      setHasPreviewBootstrapped(true);
      if (previewRef.current) {
        previewRef.current.srcdoc = previewHtml;
      }
    } else {
      setHasPreviewBootstrapped(false);
    }
  }, [htmlUrl, assets, setHasPreviewBootstrapped, setIsPreviewLoading]);

  useEffect(() => {
    bootstrapPreview();
  }, [bootstrapPreview]);

  const sendDataToPreview = useMemo(
    () =>
      debounce(
        (language, format, formValues, page_name) => {
          previewRef.current?.contentWindow?.postMessage({
            language,
            format,
            formValues,
            page_name,
          });
        },
        500,
        {leading: true},
      ),
    [],
  );

  useEffect(() => {
    if (hasPreviewBootstrapped && !isPreviewLoading && data) {
      sendDataToPreview(activeLanguage, activeDevice, data, pageName);
    }
  }, [isPreviewLoading, hasPreviewBootstrapped, sendDataToPreview, data, activeLanguage, activeDevice, pageName]);

  const showControls = hasReloadButton || languages.length > 1 || devices.length > 1;

  return (
    <div className={classNames(b(), className)}>
      {showControls && (
        <div className={b('controls')}>
          <LanguageDeviceControl
            language={activeLanguage}
            device={activeDevice}
            languages={languages}
            devices={devices}
            onChangeLanguage={setActiveLanguage}
            onChangeDevice={setActiveDevice}
            prefix="preview"
            className={b('lang-device-control')}
            deviceControlClassName={b('device-control', {right: !hasReloadButton})}
          />

          {hasReloadButton && <AppButton label={lang.REFRESH_BUTTON} iconName="rotate" onClick={bootstrapPreview} />}
        </div>
      )}
      {pagination && (
        <StepProgressBar
          title={pagination?.title}
          totalSteps={pagination?.totalSteps}
          currentStep={pagination?.currentStep}
          setCurrentStep={pagination?.setCurrentStep}
        />
      )}

      {hasPreviewBootstrapped && (
        <iframe
          title="Preview iframe"
          className={b('frame', {hidden: isPreviewLoading})}
          ref={previewRef}
          onLoad={() => setIsPreviewLoading(false)}
        />
      )}
      {isPreviewLoading && <Spinner centered className={b('spinner')} />}
    </div>
  );
};

export default WebPreview;
