import React, {useCallback, useEffect, useMemo} from 'react';

import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import {connect, useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';

import {getPermanentSources} from 'client/ducks/campaigns/actions';
import {selectPermanentSources} from 'client/ducks/campaigns/selectors';
import {getRegions} from 'client/ducks/client-users/actions';
import {createOnlineInteraction, updateOnlineInteraction} from 'client/ducks/interactions/actions';
import {selectClient} from 'client/ducks/leads-list/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';
import {getPlacesAction} from 'client/ducks/places/actions';
import {selectPlaces} from 'client/ducks/places/selectors';
import {selectUser} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons';
import {TextField, FieldWrap, RadioGroupField, SelectField} from 'client/common/fields';
import AsyncSelectField from 'client/common/fields/async-select-field';
import DatetimePickerField from 'client/common/fields/datetime-picker-field';
import {useToast} from 'client/common/hooks/useToast';
import Icon from 'client/common/icon';
import {TextInput} from 'client/common/inputs';
import Modal from 'client/common/modals/modal';
import SelectDropdown from 'client/common/selects/select-dropdown';

import {TYPE_LABEL, TYPE_VALUE, CLIENT_SIDE_TYPES} from './constants';
import initial from './initial';
import mapping from './mapping';
import validate from './validate';

import './campaign-channel-diy-modal.scss';

const b = bem('campaign-channel-diy-modal');

const CampaignChannelDiyModal = (props) => {
  const {onClose, show, campaign, channel, onSubmit, handleSubmit, index, disabled} = props;
  const lang = useLanguage('DIY_OPERATION.ONLINE.CAMPAIGN_CHANNEL_MODAL');
  const langErrors = lang.ERRORS;
  const {formValues, change, initialize, errors} = useReduxForm(CampaignChannelDiyModal.formName);
  const client = useSelector(selectClient);
  const user = useSelector(selectUser);
  const operation = useSelector(selectOperation);
  const dispatch = useDispatch();
  const permanentSourcesRaw = useSelector(selectPermanentSources);
  const places = useSelector(selectPlaces);
  const {appendToastNotification} = useToast();

  const isEdit = !!channel;

  const placeOption = useMemo(
    () =>
      places
        .filter(({region}) => !formValues?.region?.id || region?.id === formValues?.region?.id)
        .map((place) => ({
          value: place.id,
          label: place.name,
          ...place,
        })),
    [formValues?.region?.id, places],
  );

  const permanentSources = useMemo(
    () =>
      sortBy(
        permanentSourcesRaw?.map((source) => ({
          value: source.id,
          label: source.name,
          ...source,
        })) || [],
        'name',
      ),
    [permanentSourcesRaw],
  );

  useEffect(() => {
    initialize(initial(channel, operation, index));

    return () => initialize({});
  }, [initialize, channel, operation, show, index]);

  const fetchPermanentSources = useCallback(() => {
    return dispatch(
      getPermanentSources({
        q: {
          client_id_eq: client.id,
          kind_in: ['iframe', 'minisite'],
        },
      }),
    );
  }, [dispatch, client.id]);

  const fetchPlaces = useCallback(
    (inputValue, regionId) => {
      return dispatch(
        getPlacesAction({
          include: {
            region: null,
          },
          q: {
            client_id_eq: client.id,
            client_user_id_eq: user.id,
            region_id_eq: regionId,
            region_id_not_null: 't',
            active_eq: true,
            ...(inputValue
              ? {
                  g: {
                    0: {
                      m: 'or',
                      city_name_cont: inputValue,
                      name_cont: inputValue,
                      street_address_cont: inputValue,
                    },
                  },
                }
              : {}),
          },
        }),
      ).then((response) =>
        response?.payload?.places?.map((item) => ({
          value: item.id,
          label: item.name,
          ...item,
        })),
      );
    },
    [client.id, dispatch, user.id],
  );

  const fetchRegions = useCallback(
    (inputValue) => {
      return dispatch(
        getRegions(client.id, {
          q: {
            client_id_eq: client.id,
            client_user_id_eq: user.id,
            ...(inputValue
              ? {
                  g: {
                    0: {
                      m: 'or',
                      city_name_cont: inputValue,
                      name_cont: inputValue,
                      street_address_cont: inputValue,
                    },
                  },
                }
              : {}),
          },
        }),
      ).then((response) => {
        return response?.payload?.regions?.map((item) => ({
          value: item.id,
          label: item.name,
          ...item,
        }));
      });
    },
    [client.id, dispatch, user.id],
  );

  useEffect(() => {
    if (show) {
      fetchPermanentSources();
      if (!channel?.direct_access) {
        fetchPlaces();
      }
    }
  }, [show, fetchPlaces, fetchPermanentSources, channel?.direct_access]);

  const onSave = async (values) => {
    const body = mapping(values, campaign, channel);
    const fetchRequest = channel ? (rb) => updateOnlineInteraction(channel.id, rb) : createOnlineInteraction;
    const response = await dispatch(fetchRequest({online_interaction: body}));
    if (response.error) {
      appendToastNotification({
        type: 'error',
        description: response.payload?.response?.errors?.base?.[0],
      });
    } else {
      onSubmit();
    }
  };

  const onChangeSource = (source) => {
    change('name', source.label);
    change('kind', source.kind);
    change('permanent_source', source);
  };

  const onChangeRegion = async (region) => {
    if (region.id !== formValues.store?.region?.id) {
      await dispatch(change('store', null));
      fetchPlaces('', region.id);
    }
  };

  const onChangeStore = (place) => {
    if (place) {
      dispatch(
        change('region', {
          value: place.region?.id,
          label: place.region?.name,
          ...place.region,
        }),
      );
    }
  };

  const allowEditFull = !isEdit || !operation.client_interface?.participations_count;

  const channelType =
    !channel || CLIENT_SIDE_TYPES.includes(channel.kind) ? TYPE_LABEL[formValues?.kind] : channel.kind;

  const RegionSelect = channel?.direct_access ? SelectField : AsyncSelectField;

  return (
    <Modal onClose={onClose} show={show} bodyClassName={b()} title={isEdit ? lang.EDIT_CHANNEL : lang.TITLE}>
      <form onSubmit={handleSubmit(onSave)}>
        {allowEditFull && (
          <>
            <RadioGroupField
              name="kind"
              direction="horizontal"
              disabled={!!formValues?.permanent_source || disabled}
              withWrap
              value={formValues?.kind}
              options={[
                {
                  label: lang.MINISITE_LABEL,
                  value: TYPE_VALUE.MINISITE,
                },
                {
                  label: lang.IFRAME_LABEL,
                  value: TYPE_VALUE.IFRAME,
                },
              ]}
            />
            <FieldWrap>
              <TextInput label={lang.TYPE_LABEL} disabled value={channelType} />
            </FieldWrap>
            {formValues?.kind === TYPE_VALUE.IFRAME && !permanentSources?.length && (
              <SelectDropdown
                options={[
                  {
                    label: 'html',
                    value: 'html',
                  },
                ]}
                onChange={() => {}}
                label={lang.WAY_EMBED}
                value="html"
                disabled={disabled}
                withWrap
              />
            )}
            {!!permanentSources?.length && (
              <SelectDropdown
                withWrap
                label={lang.PERMANENT_SOURCE_LABEL}
                name="permanent_source"
                value={formValues?.permanent_source?.id}
                onChange={onChangeSource}
                errorMessage={!permanentSources?.length ? langErrors.NOT_PERMANENT_SOURCE : ''}
                options={permanentSources}
                disabled={disabled}
              />
            )}
          </>
        )}
        <TextField label={lang.NAME_LABEL} name="name" withWrap errorMessage={errors.name} disabled={disabled} />

        <FieldWrap>
          <div className={b('row', ['period'])}>
            <DatetimePickerField
              name="from"
              label={lang.FROM_LABEL}
              className={b('field', ['date'])}
              disabledDateTimeAfter={formValues?.to}
              disabled={disabled}
            />
            <Icon name="arrow" className={b('period-arrow')} />
            <DatetimePickerField
              name="to"
              label={lang.TO_LABEL}
              className={b('field', ['date'])}
              disabledDateTimeBefore={formValues?.from}
              disabled={disabled}
            />
          </div>
          {errors.fromTo && <p className={b('error-message')}>{errors.fromTo}</p>}
        </FieldWrap>

        {allowEditFull && (
          <>
            <RegionSelect
              withWrap
              multi={false}
              label={lang.REGION_LABEL}
              name="region"
              defaultOptions
              cacheOptions={false}
              onChange={onChangeRegion}
              fetchData={channel?.direct_access ? null : fetchRegions}
              disabled={channel?.direct_access || disabled}
            />
            <SelectField
              withWrap
              cacheOptions={false}
              multi={false}
              searchable
              label={lang.STORE_LABEL}
              name="store"
              options={placeOption}
              onChange={onChangeStore}
              disabled={channel?.direct_access || disabled}
            />
          </>
        )}

        {errors.main && <p className={b('error-message')}>{errors.main}</p>}

        <div className={b('footer')}>
          <AppButton
            label="Ok"
            submit
            className={b('button-submit')}
            disabled={!!Object.values(errors).length || disabled}
          />
        </div>
      </form>
    </Modal>
  );
};

CampaignChannelDiyModal.formName = 'CampaignChannelDiyModalForm';

CampaignChannelDiyModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  campaign: PropTypes.shape({
    id: PropTypes.number.isRequired,
    permanent_sources: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  channel: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    kind: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    region_name: PropTypes.string,
    region_id: PropTypes.number,
    store_name: PropTypes.string,
    store_id: PropTypes.number,
  }),
  index: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

CampaignChannelDiyModal.defaultProps = {
  channel: null,
};

/**
 * @typedef {import('client/services/hooks/use-redux-form/types').ReduxFormFC} ReduxFormFC
 */

/**
 * @type ReduxFormFC<CampaignChannelDiyModal.propTypes>
 */
export default connect((state) => ({
  langErrors: state.languageState.payload.DIY_OPERATION.ONLINE.CAMPAIGN_CHANNEL_MODAL.ERRORS,
}))(
  reduxForm({
    form: CampaignChannelDiyModal.formName,
    validate,
  })(CampaignChannelDiyModal),
);
