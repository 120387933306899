import {blobToBase64} from 'client/services/blobToBase64';

export default async (values) => {
  let body = {};

  await Promise.all(
    Object.entries(values || {}).map(async ([key, value]) => {
      if (value) {
        if (typeof value !== 'string') {
          const toSave = {data: await blobToBase64(value)};
          body = {
            ...body,
            [key]: toSave,
          };
        }
      }
    }),
  );

  return body;
};
