// import {bindActionCreators} from 'redux';
import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {getUserId} from 'client/services/cookie-data-source';
import {useLanguage} from 'client/services/hooks';

import {set as setMetaAction} from 'client/common/meta/meta.actions';

import MyProfile from '../components/profile/my-profile';

const ProfileComponent = () => {
  const lang = useLanguage('PROFILE');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMetaAction({title: lang.TITLE}));
  }, [dispatch, lang.TITLE]);

  return (
    <div>
      <div className="page-profile">
        <MyProfile id={getUserId()} isLastSA={true} />
      </div>
    </div>
  );
};

export default ProfileComponent;
