import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams, useReduxFetch} from 'client/services/hooks';

import {selectAutotaskParam} from 'client/ducks/autotask/selectors';
import {getWinnings} from 'client/ducks/winnings/actions';
import {selectNotWinnersData} from 'client/ducks/winnings/selectors';

import AppButton from 'client/common/buttons';
import Icon from 'client/common/icon';
import TabSwitcher from 'client/common/tab-switcher';

import LotteryDrawDropdown from 'client/components/lottery/fields/lottery-draw-dropdown';
import {WinningToolbarSearchForm} from 'client/components/winnings/forms/winning-search-form';
import {WINNING_SEARCH_TYPES} from 'client/components/winnings/forms/winning-search-form/constants';
import WinningSearchModal from 'client/components/winnings/modals/winning-search-modal';

import WinningsTabFiltersModal from './winnings-tab-filters-modal';
import WinningsTabStats from './winnings-tab-stats';
import WinningsTable from './winnings-table';

import './winnings-tab.scss';

const b = bem('winnings-tab');

const queryParamsConfig = {
  parse: {
    parseBooleans: true,
    parseNumbers: true,
    depth: 3,
  },
};

const WinningsTab = (props) => {
  const {task, operation} = props;

  const lang = useLanguage('CLIENT_AUTOTASK.WINNINGS_TAB');

  const [showSearchModal, toggleSearchModal] = useToggle(false);
  const [showFiltersModal, toggleFiltersModal] = useToggle(false);
  const [activeGameTab, setActiveGameTab] = useState('INSTANT_WIN');

  const [queryParams, setQueryParams] = useQueryParams(null, {}, queryParamsConfig);

  const notWinnersData = useSelector(selectNotWinnersData);
  const clientGames = useSelector(selectAutotaskParam('client_games'));

  const lotteryGame = clientGames?.find(({game_type}) => game_type === 'prize_draw');
  const instantGame = clientGames?.find(({game_type}) => game_type === 'instant_win');

  const filters = queryParams?.filters || {};
  filters.offline = filters.offline === 'true' || filters.offline === true;
  filters.online = filters.online === 'true' || filters.online === true;

  const filtersCount = Object.entries(filters).filter(([key, i]) => i && key !== 'draw').length;

  const {data, loading} = useReduxFetch({
    actionArgs: {
      autotaskId: task.id,
      queryParams: {
        kind: filters.source,
        device_id: filters.device,
        campaign_id: filters.campaign,
        store_id: filters.store,
        region_id: filters.region,
        online: filters.online,
        offline: filters.offline,
        game_id: activeGameTab === 'INSTANT_WIN' ? instantGame?.id : lotteryGame?.id,
        game_draw_id: activeGameTab === 'LOTTERY' ? filters.draw : null,
      },
    },
    action: getWinnings,
  });
  const winnings = data?.prizes_data || [];
  const leadsCount = data?.leads_count || 0;

  const onSearch = (values) => {
    const hasFullName =
      values.search_type === WINNING_SEARCH_TYPES.FULL_NAME && values.first_name?.trim() && values.last_name?.trim();
    if (values[values.search_type]?.trim() || hasFullName) {
      toggleSearchModal();
    }
  };

  const onChangeDraw = (draw) => {
    setQueryParams({
      filters: {
        ...filters,
        draw: draw?.id,
      },
    });
  };

  const onChangeTab = (type) => {
    setQueryParams({
      filters: {},
    });
    setActiveGameTab(type);
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        <Icon name="winnings" width={34} height={34} />
        <span className={b('title')}>{lang.TITLE}</span>
      </div>
      {!!lotteryGame && (
        <TabSwitcher
          style="title"
          tabs={[
            {
              title: lang.INSTANT_WIN_TAB,
              value: 'INSTANT_WIN',
            },
            {
              title: lang.LOTTERY_TAB,
              value: 'LOTTERY',
            },
          ]}
          onTabClick={onChangeTab}
          activeTab={activeGameTab}
          className={b('game-switcher')}
        />
      )}

      <div className={b('toolbar')}>
        <div className={b('toolbar-left')}>
          <WinningToolbarSearchForm onSearch={onSearch} validateEmpty={false} inline />
          {!!lotteryGame && activeGameTab === 'LOTTERY' && lotteryGame?.game_draws?.length > 1 && (
            <LotteryDrawDropdown
              className={b('draw-selector')}
              onChange={onChangeDraw}
              game={lotteryGame}
              value={filters?.draw || 'ALL'}
              hideLabel
              hasAllOption
              simpleValue
            />
          )}
        </div>
        <div className={b('toolbar-right')}>
          <WinningsTabStats percent={notWinnersData.percent} number={notWinnersData.number} />
          <AppButton
            label={`${lang.FILTERS} (${filtersCount})`}
            color="primary"
            iconName="filter"
            onClick={toggleFiltersModal}
          />
          {showFiltersModal && (
            <WinningsTabFiltersModal
              task={task}
              operation={operation}
              initialValues={queryParams.filters}
              onClose={toggleFiltersModal}
              onSave={setQueryParams}
            />
          )}
        </div>
      </div>
      <WinningsTable data={winnings} loading={loading} leadsCount={leadsCount} />
      {showSearchModal && <WinningSearchModal task={task} onClose={toggleSearchModal} />}
    </div>
  );
};

WinningsTab.propTypes = {
  task: PropTypes.object.isRequired,
  operation: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default WinningsTab;
