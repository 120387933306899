import {callApi} from 'client/services/call-api';

import types from 'client/ducks/client-account/types';

import {API_METHODS, API_PATH} from 'client/common/config';

import {DataTabAccess} from 'client/models/data-exports/types';

import {ApiAction} from '../../types';

export const getClientAccount: ApiAction = (id, queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.CLIENTS}/${id}/client_account`,
      method: 'GET',
      noCache: true,
      types: [types.GET_CLIENT_ACCOUNT_REQUEST, types.GET_CLIENT_ACCOUNT_SUCCESS, types.GET_CLIENT_ACCOUNT_ERROR],
      queryParams,
    });
};

export const getClientMemberships: ApiAction = ({type, queryParams = {}}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}/${type}_memberships`,
      method: 'GET',
      noCache: true,
      types: [
        types.GET_CLIENT_MEMBERSHIPS_REQUEST,
        types.GET_CLIENT_MEMBERSHIPS_SUCCESS,
        types.GET_CLIENT_MEMBERSHIPS_ERROR,
      ],
      queryParams,
    });
};

export const updateDataTabAccess: ApiAction<{data_tab_access: DataTabAccess}> = (body: DataTabAccess) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.DATA_TAB_ACCESS}/${body.id}`,
        noCache: true,
        method: 'PATCH',
        types: [
          types.UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_REQUEST,
          types.UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_SUCCESS,
          types.UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_ERROR,
        ],
        body: {
          data_tab_access: body,
        },
      },
      true,
    );
};

export const createDataTabAccess: ApiAction<{data_tab_access: DataTabAccess}> = (body: Omit<DataTabAccess, 'id'>) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.DATA_TAB_ACCESS}`,
        noCache: true,
        method: 'POST',
        types: [
          types.UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_REQUEST,
          types.UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_SUCCESS,
          types.UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_ERROR,
        ],
        body: {
          data_tab_access: body,
        },
      },
      true,
    );
};

export const getClientStores: ApiAction = (queryParams = {}) => {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PLACES}`,
      method: 'GET',
      noCache: true,
      types: [types.GET_CLIENT_STORES_REQUEST, types.GET_CLIENT_STORES_SUCCESS, types.GET_CLIENT_STORES_ERROR],
      queryParams,
    });
};

export const getClientMembershipsTeamUsers: ApiAction = (queryParams: Record<string, any> & {client_id: number}) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.MEMBERSHIPS_TEAM_USERS}`,
        noCache: true,
        method: 'GET',
        types: [
          types.GET_MEMBERSHIPS_TEAM_USERS_REQUEST,
          types.GET_MEMBERSHIPS_TEAM_USERS_SUCCESS,
          types.GET_MEMBERSHIPS_TEAM_USERS_ERROR,
        ],
        queryParams,
      },
      true,
    );
};

export const getClientsCheckSiren: ApiAction = (sirenNumber: number) => {
  return (dispatch) =>
    callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.CLIENTS_SIREN_NUMBER}`,
        method: 'GET',
        noCache: true,
        types: [
          types.GET_CLIENT_SIREN_NUMBER_REQUEST,
          types.GET_CLIENT_SIREN_NUMBER_SUCCESS,
          types.GET_CLIENT_SIREN_NUMBER_ERROR,
        ],
        queryParams: {
          siren_number: sirenNumber,
        },
      },
      true,
    );
};

export const uploadLogo: ApiAction = (id, file) => {
  return (dispatch) => {
    return callApi(
      dispatch,
      {
        endpoint: `${API_PATH}${API_METHODS.CLIENTS}/${id}`,
        method: 'PATCH',
        types: [types.UPDATE_LOGO_REQUEST, types.UPDATE_LOGO_SUCCESS, types.UPDATE_LOGO_ERROR],
        body: {
          client: {
            logo_image: {
              data: file,
            },
          },
        },
        queryParams: {
          include_client_logo_url: null,
          include_client_agency_logo_url: null,
        },
      },
      true,
    );
  };
};

export const createTrackingUser: ApiAction = (body) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.PRIZE_TRACKING_USERS}`,
    method: 'POST',
    noCache: true,
    types: [types.CREATE_TRACKING_USER_REQUEST, types.CREATE_TRACKING_USER_SUCCESS, types.CREATE_TRACKING_USER_ERROR],
    body,
  });
};

export const updateTrackingUser: ApiAction = (id, body) => (dispatch) => {
  return callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.PRIZE_TRACKING_USERS}/${id}`,
    method: 'PATCH',
    noCache: true,
    types: [types.UPDATE_TRACKING_USER_REQUEST, types.UPDATE_TRACKING_USER_SUCCESS, types.UPDATE_TRACKING_USER_ERROR],
    body,
  });
};

export const deleteTrackingUser: ApiAction = (id) => (dispatch) => {
  return callApi(
    dispatch,
    {
      endpoint: `${API_PATH}${API_METHODS.PRIZE_TRACKING_USERS}/${id}`,
      method: 'DELETE',
      noCache: true,
      types: [types.DELETE_TRACKING_USER_REQUEST, types.DELETE_TRACKING_USER_SUCCESS, types.DELETE_TRACKING_USER_ERROR],
    },
    true,
  );
};
