import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {flattenDeep} from 'lodash';
import {useWindowSize} from 'react-use';

import bem from 'client/services/bem';
import {useAppMedia, useLanguage, useQueryParams, useReduxFetch} from 'client/services/hooks';

import {getTemplateGroups} from 'client/ducks/catalogs/actions';

import AwaitContainer from 'client/common/await-container';

import useFetchSelectedAgencyClient from 'client/components/agencies/hooks/useFetchSelectedAgencyClient';
import AgencyClientsBar from 'client/components/agencies/various/agency-clients-bar';
import CatalogModulesSection from 'client/components/catalog-modules/components/catalog-modules-section';
import CatalogTemplateToolbar, {
  SORT_BY_VALUES,
} from 'client/components/catalog-templates/components/catalog-template-toolbar';
import CatalogTemplatesNoResultPanel from 'client/components/catalog-templates/panels/catalog-templates-no-result-panel';
import {CLIENT_TYPES} from 'client/models/client/constants';
import {ClientTemplate, TemplateGroup} from 'client/models/templates/types';

import {prepareCatalogs} from './helpers';
import CatalogModulesNoResultPanel from './panels/catalog-modules-no-result-panel';

import cssModule from './catalog-modules.module.scss';

const b = bem('catalog-modules', {cssModule});

type CatalogModulesProps = {
  clientId: number;
};

const CatalogModules: React.FC<CatalogModulesProps> = (props) => {
  const {clientId} = props;
  const lang = useLanguage('CATALOG_MODULES');
  const {width: windowWidth} = useWindowSize();
  const {isTablet} = useAppMedia();
  const [catalogsList, setCatalogsList] = useState<TemplateGroup[]>([]);

  const navigationWidth = isTablet ? 50 : 200;

  const [queryParams, setQueryParams] = useQueryParams(null, {sortBy: SORT_BY_VALUES.DATE});

  const {generalClient, agencyClient} = useFetchSelectedAgencyClient(Number(clientId));

  const templatesClientId = +(queryParams.agencyClientId || clientId);
  const isAgency = generalClient.type === CLIENT_TYPES.AGENCY;

  const {
    loading,
    data: rawCatalogs,
    fetch: fetchCatalogs,
  } = useReduxFetch({
    action: getTemplateGroups,
    actionArgs: {
      clientId: templatesClientId,
      search: queryParams.search,
      sort: ['order', `${queryParams.sortBy} desc`],
    },
    fetchOnMount: true,
  });

  useEffect(() => {
    const catalogs = rawCatalogs?.template_groups;
    if (Array.isArray(catalogs)) {
      setCatalogsList(catalogs);
    }
  }, [rawCatalogs?.template_groups]);

  const updateTemplate = useCallback((templateId: number, patchData: Partial<ClientTemplate>) => {
    setCatalogsList((prevState) => {
      const updatedCatalog = prevState.find(({templates}) => templates.find(({id}) => id === templateId));
      if (updatedCatalog) {
        const updatedTemplate = updatedCatalog.templates.find(({id}) => id === templateId);
        if (updatedTemplate) {
          const updatedCatalogIndex = prevState.indexOf(updatedCatalog);
          const updatedTemplateIndex = updatedCatalog.templates.indexOf(updatedTemplate);

          return [
            ...prevState.slice(0, updatedCatalogIndex),
            {
              ...updatedCatalog,
              templates: [
                ...updatedCatalog.templates.slice(0, updatedTemplateIndex),
                {
                  ...updatedTemplate,
                  ...patchData,
                },
                ...updatedCatalog.templates.slice(updatedTemplateIndex + 1),
              ],
            },
            ...prevState.slice(updatedCatalogIndex + 1),
          ];
        }
      }
      return prevState;
    });
  }, []);

  const preparedCatalogs = useMemo(() => {
    return prepareCatalogs(catalogsList, templatesClientId, windowWidth - navigationWidth);
  }, [catalogsList, templatesClientId, windowWidth, navigationWidth]);

  const data = useMemo(() => {
    return [
      {
        title: lang.GAME_TITLE,
        type: 'game' as const,
        catalogs: preparedCatalogs?.game,
      },
      {
        title: lang.FORM_TITLE,
        type: 'form' as const,
        catalogs: preparedCatalogs?.form,
      },
      {
        title: lang.QUIZ_TITLE,
        type: 'quiz' as const,
        catalogs: preparedCatalogs?.quiz,
      },
    ].filter(({catalogs}) => !!catalogs?.length);
  }, [lang.FORM_TITLE, lang.GAME_TITLE, lang.QUIZ_TITLE, preparedCatalogs]);

  const catalogItems = useMemo(() => flattenDeep(data.map((item) => item.catalogs)), [data]);

  const renderNoResultPanel = () => {
    if (queryParams.search) {
      return <CatalogTemplatesNoResultPanel />;
    }
    return <CatalogModulesNoResultPanel />;
  };

  return (
    <main className={b()}>
      {isAgency && <AgencyClientsBar agencyId={Number(clientId)} title={lang.TITLE} agencyOption />}
      <header className={b('header', {agency: isAgency})}>
        {!isAgency && <h1 className={b('title')}>{lang.TITLE}</h1>}
        <CatalogTemplateToolbar
          loading={loading}
          className={b('toolbar')}
          totalCount={catalogItems.length}
          sort={queryParams.sortBy}
          search={queryParams.search}
          onSearch={(value: string) => setQueryParams({search: value})}
          onSortChange={(value: string) => setQueryParams({sortBy: value})}
          placeholder={lang.SEARCH_PLACEHOLDER}
        />
      </header>
      <section className={b('sections')}>
        <AwaitContainer loading={loading} spinnerClassName={b('spinner')}>
          {catalogItems.length
            ? data.map(({title, type, catalogs}) => (
                <div key={type}>
                  {!!catalogs.length && (
                    <>
                      <h2 className={b('title', ['section'])}>{title}</h2>
                      <AwaitContainer loading={loading} overlay>
                        {catalogs.map((row, index) => (
                          <CatalogModulesSection
                            key={index}
                            clientId={templatesClientId}
                            templateGroup={row}
                            perRow={preparedCatalogs?.perRow || 5}
                            fetchData={fetchCatalogs}
                            updateTemplate={updateTemplate}
                            agencyClient={agencyClient}
                          />
                        ))}
                      </AwaitContainer>
                    </>
                  )}
                </div>
              ))
            : renderNoResultPanel()}
        </AwaitContainer>
      </section>
    </main>
  );
};

export default CatalogModules;
