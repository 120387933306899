import {isPrimitive, isEmpty} from 'client/services/helpers';

const DEFAULT_PARAMS = {lang: null, display: null};

// string format: `pattern/flags`
const parseRegexp = (string) => {
  const sepIndex = string.lastIndexOf('/');

  if (sepIndex > 0) {
    const pattern = string.substring(0, sepIndex);
    const flags = string.substring(sepIndex + 1);

    if (/^[gimsuy]+$/.test(flags)) {
      return {pattern, flags};
    }
  }

  return {pattern: string, flags: ''};
};

const isEmptyObject = (val) => val !== null && typeof val === 'object' && !Object.keys(val).length;
const checkRegexp = (val, reg) => {
  const {pattern, flags} = parseRegexp(reg);
  let newReg = new RegExp(pattern, flags);
  return newReg.test(val);
};
const checkLessThanMax = (val, number) => val <= number;
const checkRatio = (val, ratio) => {
  const aspectRatio = ratio.split(':');
  return val.width / val.height === aspectRatio[0] / aspectRatio[1];
};

const checkMapper = {
  check_error_regex: checkRegexp,
  check_error_width: checkLessThanMax,
  check_error_height: checkLessThanMax,
  check_error_ratio: checkRatio,
  check_error_size: checkLessThanMax,
  check_max_characters: checkLessThanMax,
};

const getPrimitiveValue = (val, key) => {
  switch (true) {
    case key === 'check_error_regex':
      return val;

    case typeof val === 'string' && key !== 'check_error_regex':
      return val.length;

    case typeof val === 'number':
      return val;

    default:
      return Number(val);
  }
};

const getObject = (val, key) => {
  switch (true) {
    case key === 'check_error_ratio':
      return val;

    default:
      return val[key.replace('check_error_', '')];
  }
};

export function createChecks(formItem, labels) {
  const checks = {};
  const messages = {};
  if (labels) {
    Object.keys(formItem)
      .filter((key) => key.match(/check/))
      .forEach((key) => {
        const checkKey = formItem[key];
        if (checkKey && !isEmptyObject(checkKey)) {
          checks[key] = checkKey;
        }
      });
    Object.keys(checks).forEach((key) => {
      const checkKey = key.match(/error/) ? key.replace('check_', '') : key.replace('check_', 'error_');
      messages[key] = labels[checkKey];
    });
    // regex check from label has higher priority
    if (labels.check_regex) {
      checks.check_error_regex = labels.check_regex;
    }
  }
  return {checks, messages: {...messages, error_default: labels.error_default}};
}

export function validate(val, {checks, messages}, params = DEFAULT_PARAMS) {
  let errors = [];
  const {lang, display} = params;

  if (!Object.keys(checks).length || isEmpty(checks)) {
    return '';
  }
  if (checks.check_mandatory && (!val || isEmptyObject(val))) {
    errors.push(messages.check_mandatory);
  }

  // eslint-disable-next-line guard-for-in
  for (let key in checks) {
    const checkFn = checkMapper[key];
    const valueKey = isPrimitive(val) ? getPrimitiveValue(val, key) : getObject(val, key);

    if (checkFn && valueKey) {
      let initialValue;
      const checkValue = checks[key];
      switch (true) {
        case lang && !display:
          initialValue = checkValue[lang];
          break;

        case display && !lang:
          initialValue = checkValue[display];
          break;

        case lang && display:
          initialValue = checkValue[lang][display]; // ?
          break;

        default:
          initialValue = checkValue;
      }

      if (valueKey && initialValue) {
        const check = checkFn(valueKey, initialValue);
        if (!check) {
          errors.push(messages[key] || messages.error_default);
        }
      }
    }
  }

  if (isEmpty(errors)) {
    return null;
  }

  return errors.filter((value, index, array) => array.indexOf(value) === index);
}
