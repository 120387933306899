import {dateToString, prettyJoin} from 'client/services/helpers';

import {PresentWinner} from 'client/models/winnings';

export const getCardTitle = (prize: PresentWinner['prize']) => {
  const {coupon} = prize;
  if (coupon) {
    const loyalty = prettyJoin([coupon.loyalty_amount, coupon.loyalty_unit]);
    const from = dateToString(coupon.token_validity_from);
    const to = dateToString(coupon.token_validity_to);
    const period = prettyJoin([from, to], ' - ');
    return [loyalty, prize.prize_name, period].filter(Boolean).flat();
  }
  return [prize.prize_name];
};
