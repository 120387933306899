import React from 'react';

import placeholder from 'assets/icons/colored/pic-placeholder.svg';
import cn from 'classnames';

import bem from 'client/services/bem';

import FileButton from 'client/common/buttons/file-button';
import MediaContainer from 'client/common/containers/media-container';
import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {Translation} from 'client/models/language/types';

import cssModule from './media-input.module.scss';

const b = bem('media-input', {cssModule});

type MediaInputProps = {
  className?: string;
  classNames?: {
    label?: string;
    filename?: string;
  };
  buttonClassName?: string;
  label?: Translation;
  filename?: string;
  name: string;
  onChange: (file: any) => void;
  errorMessage?: string | Translation | (string | Translation)[];
  warningMessage?: Translation;
  width?: number | string;
  height?: number | string;
  acceptFormats?: string;
  onBlur?: () => void;
  disabled?: boolean;
  value?: any;
  mediaType: string;
  keepRatio?: boolean;
  imageStyle?: React.CSSProperties;
  required?: boolean;
  placeholderIcon?: string;
};

const MediaInput: React.FC<MediaInputProps> = (props) => {
  const {
    className,
    classNames,
    buttonClassName,
    label,
    filename,
    name,
    onChange,
    errorMessage,
    warningMessage = '',
    width = 0,
    height = 0,
    acceptFormats,
    onBlur,
    disabled,
    value,
    mediaType,
    keepRatio,
    imageStyle,
    required = false,
    placeholderIcon = placeholder,
  } = props;

  const handleChange = (file: any) => {
    onChange(file);
    onBlur?.();
  };

  return (
    <div className={cn(b({error: !!errorMessage, warning: !!warningMessage}), className)}>
      {label && (
        <label htmlFor={name} className={cn(b('label'), classNames?.label)}>
          {label}
        </label>
      )}
      <div className={b('controls')}>
        <FileButton
          className={buttonClassName}
          name={name}
          disabled={disabled}
          label={
            <MediaContainer
              sources={[{src: value, type: mediaType}]}
              width={value ? width : 80}
              height={value ? height : 80}
              keepRatio={!!value && keepRatio}
              imageStyle={imageStyle}
              placeholderIcon={placeholderIcon as string}
            />
          }
          onChange={handleChange}
          acceptFormats={acceptFormats}
          asWrap
        />
        {filename && (
          <label htmlFor={name} className={cn(b('filename'), classNames?.filename)}>
            {filename}
          </label>
        )}
      </div>
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
    </div>
  );
};

export default MediaInput;
