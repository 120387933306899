import React from 'react';

import PropTypes from 'prop-types';
import Select from 'react-select/async';

import SelectDropdown from 'client/common/selects/select-dropdown';
import {SelectCommonProps, SelectOption} from 'client/common/selects/types';

import '../select-dropdown/select-dropdown.scss';

const AsyncDropdown = (props) => {
  const {fetchData, cacheOptions = true, ...selectProps} = props;

  const loadOptions = (inputValue, callback) => {
    fetchData(inputValue).then(callback);
  };

  return (
    <SelectDropdown
      {...selectProps}
      cacheOptions={cacheOptions}
      searchable
      options={[]}
      Component={Select}
      loadOptions={loadOptions}
    />
  );
};

AsyncDropdown.propTypes = {
  ...SelectCommonProps,
  value: PropTypes.oneOfType([PropTypes.arrayOf(SelectOption), SelectOption, PropTypes.number, PropTypes.string]),
  fetchData: PropTypes.func.isRequired,
};

AsyncDropdown.defaultProps = {
  ...SelectDropdown.defaultProps,
};

export default AsyncDropdown;
