import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';
import {ReactNode} from 'client/types';

import './article-panel.scss';

const b = bem('article-panel');

/**
 *
 * @type {React.FC<ArticlePanel.PropTypes>}
 */

// TODO: from jsx => tsx
const ArticlePanel = (props) => {
  const {className, title, color, children} = props;

  return (
    <article className={cn(b([color]), className)}>
      {title && <h3 className={b('title')}>{title}</h3>}
      <div className={b('children')}>{children}</div>
    </article>
  );
};

export const ArticlePanelColorType = PropTypes.oneOf(['main-layout-bg']);

ArticlePanel.propTypes = {
  title: PropTypes.oneOfType([TranslationJsx, ReactNode]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  // can be extended with color variable names from ui-kit
  color: ArticlePanelColorType,
};

ArticlePanel.defaultProps = {
  className: '',
  color: 'main-layout-bg',
};

export default ArticlePanel;
