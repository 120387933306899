import React from 'react';

import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';

import {set} from 'client/common/meta/meta.actions';

import NotFound from './page-not-found';

import Unsubscribe from '../components/unsubscribe';

class UnsubscribePage extends ReactQueryParams {
  static propTypes = {
    setMetaAction: PropTypes.func.isRequired,
    languageState: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.props.setMetaAction({
      title: this.props.languageState.payload.UNSUBSCRIBE.TITLE,
    });
  }

  render() {
    const token = this.queryParams.token;

    return token ? <Unsubscribe leadToken={token} /> : <NotFound />;
  }
}

export default connect(
  (state) => ({
    languageState: state.languageState,
  }),
  {
    setMetaAction: set,
  },
)(UnsubscribePage);
