import React from 'react';

import arrayMove from 'array-move';
import {useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';
import {useLanguage} from 'client/services/hooks';

import {updateGame} from 'client/ducks/diy-operation/actions';
import {changeLotteryPrizeOrder} from 'client/ducks/lottery/actions';

import AppButton from 'client/common/buttons/app-button';

import ClientTable from 'client/components/common/client-table';

import {PRIZE_SEND_STATES} from 'client/models/prizes/constants';
import {Prize, Game} from 'client/models/prizes/types';

import cssModule from './lottery-block-table.module.scss';

const b = bem('lottery-block-table', {cssModule});

type LotteryBlockTableProps = {
  prizes: Prize[];
  loading: boolean;
  game: Game;
  openPrizeModal: (prize: Prize | null) => void;
  disabled: boolean;
};

const renderSendState = ({value}: {value: string}) => {
  switch (value) {
    case PRIZE_SEND_STATES.SET_FOR_ALL:
      return <figure className={b('circle-full')} />;
    case PRIZE_SEND_STATES.SET_FOR_SEVERAL:
      return <figure className={b('circle-part')} />;
    default:
      return null;
  }
};

const LotteryBlockTable: React.FC<LotteryBlockTableProps> = (props) => {
  const {prizes, loading, game, openPrizeModal, disabled} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('DIY_OPERATION.BLOCKS.DIY_OP_LOTTERY_BLOCK.LOTTERY_BLOCK_TABLE');

  const handleDragEnd = ({oldIndex, newIndex}: {oldIndex: number; newIndex: number}) => {
    const newList = arrayMove(prizes, oldIndex, newIndex);
    dispatch(changeLotteryPrizeOrder(newList));
    dispatch(
      updateGame(game.id, {
        game: {
          id: game.id,
          prizes: newList.map((prize: Prize, index: number) => ({
            id: prize.id,
            order: index,
          })),
        },
      }),
    );
  };

  return (
    <ClientTable
      className={b({scrollable: prizes.length > 5})}
      iconClassName={b('icon')}
      data={prizes}
      columns={[
        {
          name: 'index',
          label: '#',
          render: ({index}: {index: number}) => index + 1,
        },
        {
          name: 'prizeName',
          label: lang.PRIZE_NAME,
          render: (row: {item: Prize}) => (
            <AppButton
              className={b('prize-name-button')}
              label={row.item.external_name}
              onClick={() => openPrizeModal(row.item)}
              asWrap
            />
          ),
        },
        {
          name: 'currentStock',
          label: lang.CURRENT_STOCK,
          path: 'total_quantities.total_current_stock',
          align: 'right',
          formatter: formatNumberSpace,
        },
        {
          name: 'initialStock',
          label: lang.INITIAL_STOCK,
          path: 'total_quantities.total_initial_stock',
          align: 'right',
          formatter: formatNumberSpace,
        },
        {
          name: 'winAnnouncement',
          label: lang.WIN_ANNOUNCEMENT,
          subcolumns: [
            {
              name: 'email',
              label: lang.EMAIL,
              path: 'email_send_state',
              align: 'center',
              render: renderSendState,
            },
            {
              name: 'sms',
              label: lang.SMS,
              path: 'sms_send_state',
              align: 'center',
              render: renderSendState,
            },
          ],
        },
      ]}
      draggable={!disabled}
      onDragEnd={handleDragEnd}
      loading={loading}
    />
  );
};

export default LotteryBlockTable;
