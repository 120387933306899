export const GAME_TYPES = {
  INSTANT_WIN: 'instant_win',
  PRIZE_DRAW: 'prize_draw',
  ATTRIBUTION_STEP: 'attribution_step',
} as const;

export const PRIZE_SEND_STATES = {
  SET_FOR_ALL: 'set_for_all',
  SET_FOR_SEVERAL: 'set_for_several',
  NOT_SET: 'not_set',
} as const;

export const PRIZE_TYPES = {
  COUPON: 'CouponPrize',
  LOYALTY: 'LoyaltyPrize',
  COMMON: 'Prize',
} as const;
