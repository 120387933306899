import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {numberWithSpaces} from 'client/services/helpers';

import LayoutPanel from 'client/common/panels/layout-panel';

import {TranslationJsx} from 'client/models/language/types';

import './table-info.scss';

const b = bem('table-info');

const TableInfo = (props) => {
  const {columns, rowNames, data, noDataText} = props;

  if (!data || !data.length) {
    return (
      <LayoutPanel>
        <div className={b()}>
          <div className={b('no-data')}>{noDataText}</div>
        </div>
      </LayoutPanel>
    );
  }

  const lastRowIndex = data.length - 1;
  const lastColumnIndex = columns.length - 1;

  return (
    <LayoutPanel>
      <div className={b()}>
        <div className={b('col-headers')}>
          {columns.map((column, index) => (
            <div
              key={index}
              className={b('col-header', {
                bold: index === lastColumnIndex,
              })}
            >
              {column.header}
            </div>
          ))}
        </div>
        <div className={b('columns')}>
          <div className={b('row-names')}>
            {rowNames.map((rowName, index) => (
              <div
                key={index}
                className={b('row-name', {
                  highlighted: !index || index === lastRowIndex,
                  bold: !index || index === lastRowIndex,
                })}
              >
                {rowName}
              </div>
            ))}
          </div>
          <div className={b('rows')}>
            {data.map((row, rowIndex) => (
              <div key={rowIndex} className={b('row')}>
                {columns.map((column, colIndex) => (
                  <div
                    key={colIndex}
                    className={b('item', {
                      highlighted:
                        (!rowIndex && colIndex === lastColumnIndex) ||
                        (rowIndex === lastRowIndex && colIndex === lastColumnIndex),
                      bold: !rowIndex || rowIndex === lastRowIndex,
                    })}
                  >
                    {numberWithSpaces(row[column.path])}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </LayoutPanel>
  );
};

TableInfo.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      device: PropTypes.string,
      online: PropTypes.string,
      total: PropTypes.string,
    }),
  ),
  noDataText: TranslationJsx,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      header: TranslationJsx,
      path: PropTypes.string,
    }),
  ).isRequired,
  rowNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

TableInfo.defaultProps = {
  noDataText: 'No data',
};

export default TableInfo;
