/* eslint-disable no-unused-vars */

/* eslint-disable react/prop-types */
import React, {Component} from 'react';

import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import {SubmissionError, updateSyncWarnings, change as changeFieldValue} from 'redux-form';
import {matches, equals} from 'validator';

import {getUserId} from 'client/services/cookie-data-source';
import {getAuthHeaders, passwordRegExp, findInArrayOfObj} from 'client/services/helpers';
import {EMAIL_REGEX, PHONE_REGEX} from 'client/services/validator';

import {getAdminSubsidiary} from 'client/ducks/admin-users/actions';
import {blockAdmin, deleteAdmin} from 'client/ducks/admins-list/admins-list.action';
import {setBreadcrumbs, clearBreadcrumbs} from 'client/ducks/breadcrumbs/actions';
import {getSubsidiaries} from 'client/ducks/subsidiaries/actions';
import {getUserById, logout} from 'client/ducks/user/actions';

import {NAV_LINKS} from 'client/common/config';
import {API_PATH, API_METHODS, CLIENT_PAGES, APP_ROLES, PROFILE} from 'client/common/config';

import Icon from 'client/components/common/icon';
import TitleBlock from 'client/components/common/title-block';

import {TranslationJsx} from 'client/models/language/types';

import ProfileAdmin from './profile-admin';
import ProfileClient from './profile-client';
import ProfileForm from './profile-form';

import AdminDeleteModal from '../modals/admin-delete-modal';
import EditPhotoModal from '../modals/edit-photo-modal';
import {getProfile, updateProfile, passwordChange, clearProfile} from '../profile/profile.action';

import './my-profile.scss';

let isEditPassword = false;

class MyProfile extends Component {
  static propTypes = {
    getProfile: PropTypes.func,
    reset: PropTypes.func,
    deleteProfile: PropTypes.func,
    profile: PropTypes.object,
    initialValues: PropTypes.object,
    updateProfile: PropTypes.func,
    getSubsidiaries: PropTypes.func,
    getAdminSubsidiary: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    submitting: PropTypes.bool,
    valid: PropTypes.bool,
    pristine: PropTypes.bool,
    submitFailed: PropTypes.bool,
    error: TranslationJsx,
    lang: PropTypes.object.isRequired,
    breadcrumbs: PropTypes.object.isRequired,
    subsidiaries: PropTypes.object,
    passwordChange: PropTypes.func,
    id: PropTypes.string,
    deleteAdmin: PropTypes.func,
    blockAdmin: PropTypes.func,
    userState: PropTypes.object,
    clearProfile: PropTypes.func,
    changeFieldValue: PropTypes.func,
    getUserById: PropTypes.func,
    logout: PropTypes.func,
    setBreadcrumbs: PropTypes.func,
    clearBreadcrumbs: PropTypes.func,
    allUserClients: PropTypes.object,
    isLastSA: PropTypes.bool,
    updateSyncWarnings: PropTypes.func.isRequired,
  };

  static FormName = 'MyProfile';

  static validate({password, current_password, password_confirmation, first_name, last_name, phone, email}) {
    let errors = {};

    if (!first_name || !first_name.trim()) {
      errors.first_name = this.props.lang.FIRST_NAME_REQUIRED;
    }

    if (!last_name || !last_name.trim()) {
      errors.last_name = this.props.lang.LAST_NAME_REQUIRED;
    }

    switch (true) {
      case phone && !PHONE_REGEX.test(phone):
        errors.phone = this.props.lang.PHONE_INVALID;
        break;
      default:
      //
    }

    switch (true) {
      case !email || !email.trim():
        errors.email = this.props.lang.EMAIL_REQUIRED;
        break;
      case email && !EMAIL_REGEX.test(email):
        errors.email = this.props.lang.EMAIL_INVALID;
        break;
      default:
      //
    }

    if (isEditPassword) {
      if (!current_password) {
        errors.current_password = this.props.lang.PASSWORD_REQUIRED;
      } else if (matches(current_password, passwordRegExp())) {
        errors.current_password = this.props.lang.PASSWORD_LENGTH;
      }

      if (!password) {
        errors.password = this.props.lang.CONFIRMATION_REQUIRED;
      } else if (matches(password, passwordRegExp())) {
        errors.password = this.props.lang.CONFIRMATION_LENGTH;
      }

      if (current_password === password) {
        errors.password = this.props.lang.PASSWORD_ALREADY_USED;
      }

      if (!password_confirmation) {
        errors.password_confirmation = this.props.lang.CONFIRMATION_REQUIRED;
      } else if (matches(password_confirmation, passwordRegExp())) {
        errors.password_confirmation = this.props.lang.CONFIRMATION_LENGTH;
      } else if (!equals(password_confirmation, password)) {
        errors.password_confirmation = this.props.lang.PASSWORD_MATCH;
      }
    }

    return errors;
  }

  static warn({password, password_confirmation, current_password}) {
    const warnings = {};

    if (
      password &&
      password_confirmation &&
      equals(password_confirmation, password) &&
      !equals(password, current_password)
    ) {
      warnings.password = this.props.lang.PASSWORD_MATCHED;
      warnings.password_confirmation = this.props.lang.PASSWORD_MATCHED;
    }
    return warnings;
  }

  constructor(props) {
    super(props);

    isEditPassword = location.hash === '#editPassword';

    this.state = {
      edit: false,
      editPassword: isEditPassword,
      showModalDelete: false,
      updateSuccess: false,
      showModalPhoto: false,
      userQual: '',
      userName: '',
      role: [
        {value: 'admin', label: this.props.lang.ROLES_ADMIN},
        {value: 'super_admin', label: this.props.lang.ROLES_SUPER_ADMIN},
      ],
    };
    this.isQualificationInit = false;

    this.popoverOldPassword = <span id="old-password">{this.props.lang.OLD_PASSWORD_TOOLTIP}</span>;
    this.popoverDoubleFactorAuthNotification = (
      <span id="2fa-notify">
        <div>{this.props.lang.PROFILE_OTP.OTP_DESCRIPTION}</div>

        <div style={{marginTop: `${20}px`}}>{this.props.lang.PROFILE_OTP.OTP_NOTE}</div>
      </span>
    );
  }

  componentDidMount() {
    const {breadcrumbs, id} = this.props;

    this.props.getSubsidiaries();
    this.props.getProfile(id).then(() => {
      this.props.setBreadcrumbs([
        {
          name: breadcrumbs.ADMINISTRATORS,
          href: CLIENT_PAGES.ADMINS_LIST,
        },
        {
          name: this.props.profile.payload.user?.full_name,
        },
      ]);
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.initialValues &&
      !this.isQualificationInit &&
      this.props.initialValues.role !== prevProps.initialValues?.role
    ) {
      this.handleRoleChange({value: this.props.initialValues.role});
    }
  }

  componentWillUnmount() {
    this.props.clearProfile();
    this.props.clearBreadcrumbs();
  }

  getValidators = (kind) => {
    let validators = [];

    validators.push(this.rules.required);
    validators.push(this.rules[kind]);

    return validators;
  };

  isClient = () => {
    const {userState} = this.props;
    return userState.payload.role !== APP_ROLES.SUPER_ADMIN && userState.payload.role !== APP_ROLES.ADMIN;
  };

  onAuthMethodChange = ({target: {name, checked}}) => {
    this.save({
      [name]: checked,
    });
  };

  handleEditPhotoClick = () => {
    this.setState({
      showModalPhoto: !this.state.showModalPhoto,
    });
  };

  handleEditClick = () => {
    this.setState({
      edit: true,
      updateSuccess: false,
    });
  };

  handleCancelClick = () => {
    isEditPassword = false;
    this.setState({
      editPassword: false,
      showModalDelete: false,
      edit: false,
    });
  };

  displayDeletePopUp = () => {
    this.setState({showModalDelete: true});
  };

  handleDeleteClick = () => {
    const {id} = this.props;
    return fetch(`${API_PATH}${API_METHODS.ADMIN_USERS}/${id}`, {
      method: 'DELETE',
      headers: getAuthHeaders(),
    })
      .then((res) => res.text())
      .then((response) => {
        if (response || response.errors) {
          throw new SubmissionError({_error: this.props.lang.CANT_DELETE_MESSAGE});
        }
        this.props.deleteAdmin(id);
        this.setState({showModalDelete: false});
        const currentLoggedUserId = this.props.userState.payload.user.id;
        const userProfileId = this.props.profile.payload.user.id;

        if (currentLoggedUserId === userProfileId) {
          this.props.logout().then(() => {
            window.location = CLIENT_PAGES.LOGIN;
          });
        } else {
          this.props.history.push(CLIENT_PAGES.ADMINS_LIST);
        }
      });
  };

  handleEditPasswordChange = (e) => {
    // this.props.reset();
    e.preventDefault();
    isEditPassword = true;
    this.setState({
      editPassword: true,
    });
  };

  updateForm = (data) => {
    this.props.updateProfile(data);
    isEditPassword = false;

    this.setState({
      showModalPhoto: false,
      edit: false,
      editPassword: false,
      updateSuccess: true,
    });
  };

  changePassword = (data) => {
    const {
      admin_user: {current_password, password, password_confirmation, ...restData},
    } = data;
    const id = restData.id || this.props.profile.payload.user.id;

    const passwordObj = {
      user: {
        current_password,
        password,
        password_confirmation,
      },
    };

    return this.props.passwordChange(id, passwordObj).then((response) => {
      if (response.error) {
        let formError = {current_password: this.props.lang.INVALID_PASSWORD};
        const passwordError = get(response, 'payload.response.errors.password[0]');

        if (passwordError === 'taken_in_past') {
          formError = {password: this.props.lang.PASSWORD_ALREADY_USED};
        }

        this.props.updateSyncWarnings(MyProfile.FormName);
        this.setState({showModalDelete: false});
        throw new SubmissionError(formError);
      }
      this.updateForm(restData);
    });
  };

  handleRoleChange = ({value}) => {
    const qualification = [
      {value: PROFILE.PROJECT_MANAGER, label: this.props.lang.QUALIFICATION_PM},
      {value: PROFILE.ACCOUNT_MANAGER, label: this.props.lang.QUALIFICATION_AM},
    ];
    if (value === PROFILE.SUPER_ADMIN) {
      qualification.push({value: PROFILE.TECHNICAL_STAFF, label: this.props.lang.QUALIFICATION_TS});
    } else if (this.isQualificationInit) {
      this.props.changeFieldValue('MyProfile', 'qualification', PROFILE.PROJECT_MANAGER);
    }
    this.setState({qualification});
    this.isQualificationInit = true;
  };

  save = (values) => {
    const {editPassword} = this.state;
    const data = this.isClient() ? values : this.mapAdminData(values);
    const endpoint = this.isClient() ? API_METHODS.USERS : API_METHODS.ADMIN_USERS;

    const {id} = this.props.profile.payload.user;
    if (editPassword) {
      return this.changePassword(data);
    }
    return fetch(`${API_PATH}${endpoint}/${id}`, {
      method: 'PUT',
      headers: getAuthHeaders(),
      body: JSON.stringify(data),
    })
      .then((res) => res.text())
      .then(() => {
        data.subsidiary_id = Number(data.subsidiary_id);
        this.props.getProfile(this.props.id).then(() => this.updateForm(data));
        const BC = this.props.breadcrumbs;
        this.props.setBreadcrumbs([
          {
            name: BC.ADMINISTRATORS,
            href: CLIENT_PAGES.ADMINS_LIST,
          },
          {
            name: data.full_name,
          },
        ]);
      })
      .then(() => !this.isClient() && this.props.getAdminSubsidiary(getUserId()));
  };

  getUserInfo = (user, allUserClients) => {
    // const {user} = this.props;
    const userName = user && user.full_name ? user.full_name : '';
    const isLast = user && user.role === 'super_admin' && this.props.isLastSA;
    let userQual = '';
    let userRole = '';
    let memberships = null;

    if (user && user.qualification && this.state.qualification) {
      userQual = findInArrayOfObj(this.state.qualification, user.qualification, 'value', 'label');
    }

    if (user && user.role && this.state.role) {
      userRole = findInArrayOfObj(this.state.role, user.role, 'value', 'label');
    }

    const clientUser = allUserClients.payload.client_user;

    if (clientUser) {
      memberships = clientUser.memberships;
    }

    return {
      userName,
      isLast,
      userQual,
      userRole,
      memberships,
    };
  };

  mapAdminData(obj) {
    const {qualification, role, first_name, last_name, subsidiary_id, title} = obj;

    return {
      admin_user: {
        ...obj,
        full_name: `${first_name} ${last_name}`,
        role: (role && role.value) || role,
        qualification: (qualification && qualification.value) || qualification,
        subsidiary_id: (subsidiary_id && subsidiary_id.value) || subsidiary_id,
        title: title.value || title,
      },
    };
  }

  renderIcon = (key, type) => {
    const dict = {
      role: {
        admin: 'nav-admin',
        user: 'nav-clients',
        super_admin: 'nav-settings',
      },
      qual: {
        project_manager: 'project-manager',
        account_manager: 'account-manager',
        technical_staff: 'tech-staff',
      },
    };

    if (dict[type] && dict[type][key]) {
      return <Icon name={dict[type][key]} />;
    }

    return null;
  };

  render() {
    const {initialValues, subsidiaries, profile, userState, allUserClients} = this.props;

    const {edit, showModalDelete, editPassword, showModalPhoto, updateSuccess, qualification, role} = this.state;

    const {user = null} = profile.payload;
    const isSuperAdmin = userState.payload.role === APP_ROLES.SUPER_ADMIN;
    const isClient = this.isClient();

    const subsidiariesList = subsidiaries.payload.length
      ? subsidiaries.payload.map(({id, name}) => ({value: String(id), label: name}))
      : [];

    const {userName, isLast, userQual, userRole, memberships} = this.getUserInfo(user, allUserClients);
    // const isElementDisabled = isSuperAdmin && (!edit || editPassword) || !isSuperAdmin && !(!edit || editPassword) ||
    //   !isSuperAdmin && (!edit || editPassword);

    if (initialValues && initialValues.subsidiary_id) {
      initialValues.subsidiary_id = String(this.props.initialValues.subsidiary_id);
    }

    const block = () => {
      this.props.blockAdmin(initialValues.id, !user.blocked).then(() => {
        this.props.getProfile(this.props.id);
      });
    };

    return (
      <div>
        {isClient ? (
          <div>
            <ProfileClient
              user={initialValues}
              onEditPhotoClick={this.handleEditPhotoClick}
              updateProfile={this.save}
              editPassword={editPassword}
            >
              <EditPhotoModal
                id={this.props.id}
                show={showModalPhoto}
                onConfirm={this.handleEditPhotoClick}
                onClose={this.handleEditPhotoClick}
              />
            </ProfileClient>
          </div>
        ) : (
          <div>
            <div className="page__title">
              <TitleBlock small ellipsis="2">
                <TitleBlock.Item>{userName}</TitleBlock.Item>
                <TitleBlock.Item>
                  <TitleBlock.Icon className="profile__header-icon">
                    {user && user.role && this.renderIcon(user.role, 'role')}
                  </TitleBlock.Icon>
                  {userRole}
                </TitleBlock.Item>
                <TitleBlock.Item>
                  <TitleBlock.Icon className="profile__header-icon">
                    {user && user.qualification && this.renderIcon(user.qualification, 'qual')}
                  </TitleBlock.Icon>
                  {userQual}
                </TitleBlock.Item>
              </TitleBlock>
            </div>
            <ProfileAdmin
              lang={this.props.lang}
              user={initialValues}
              edit={edit}
              avatarSrc={profile?.payload?.user?.avatar?.url}
              onEditClick={this.handleEditClick}
              onEditPhotoClick={this.handleEditPhotoClick}
              editPassword={editPassword}
              hideEditBtn={isClient}
              isBlocked={user && user.blocked}
              onBlock={block}
            >
              <EditPhotoModal
                id={this.props.id}
                show={showModalPhoto}
                onConfirm={this.handleEditPhotoClick}
                onClose={this.handleEditPhotoClick}
              />
              <AdminDeleteModal
                show={showModalDelete}
                onConfirm={this.handleDeleteClick}
                onCancel={this.handleCancelClick}
              />
              <ProfileForm
                LANGUAGE={this.props.lang}
                initialValues={initialValues}
                // isClient={isClient}
                edit={edit}
                isSuperAdmin={isSuperAdmin}
                isLast={isLast}
                editPassword={editPassword}
                memberships={memberships}
                role={role}
                qualification={qualification}
                subsidiariesList={subsidiariesList}
                updateSuccess={updateSuccess}
                displayDeletePopUp={this.displayDeletePopUp}
                handleRoleChange={this.handleRoleChange}
                handleEditPasswordChange={this.handleEditPasswordChange}
                onAuthMethodChange={this.onAuthMethodChange}
                popoverOldPassword={this.popoverOldPassword}
                popoverDoubleFactorAuthNotification={this.popoverDoubleFactorAuthNotification}
                cancel={this.handleCancelClick}
                save={this.save}
              />
            </ProfileAdmin>
            <div className="my-profile__policy-navbar">
              <Link to={NAV_LINKS.PRIVACY_POLICY}>
                <button className="button button--bg-1">{this.props.lang.PRIVACY_POLICY_BUTTON}</button>
              </Link>
              <Link to={NAV_LINKS.COOKIES_POLICY}>
                <button className="button button--bg-1">{this.props.lang.COOKIES_POLICY_BUTTON}</button>
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  ({languageState, profile, subsidiaries, userState, allUserClients}) => ({
    lang: languageState.payload.PROFILE,
    breadcrumbs: languageState.payload.BREADCRUMBS,
    initialValues: profile.payload.user,
    subsidiaries,
    profile,
    userState,
    allUserClients,
  }),
  {
    getProfile,
    updateProfile,
    getSubsidiaries,
    passwordChange,
    deleteAdmin,
    blockAdmin,
    clearProfile,
    getUserById,
    logout,
    setBreadcrumbs,
    getAdminSubsidiary,
    clearBreadcrumbs,
    changeFieldValue,
    updateSyncWarnings,
  },
)(withRouter(MyProfile));
