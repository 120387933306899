import React, {useState, useCallback, useMemo} from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {isEmpty} from 'client/services/helpers';

import {selectOperation} from 'client/ducks/operations/selectors';
import {
  createTemplateAnswer,
  updateFormItemAccess,
  updateTemplateAnswer,
  setFormError,
  setFormItem,
  saveResult,
  updateResult,
} from 'client/ducks/templates/actions';
import {
  selectCurrentTemplateErrors,
  selectInitialFormItems,
  selectModifiedFormItems,
} from 'client/ducks/templates/selectors';

import WebPreview from 'client/common/web-preview';

import Spinner from 'client/components/common/spinner';

import useClientFonts from 'client/components/diy-operation/modals/diy-customization-modal/useClientFonts';
import useCustomizationPreviewLanguagesDevices from 'client/components/diy-operation/modals/diy-customization-modal/useCustomizationPreviewLanguagesDevices';
import useFetchCustomizationForm from 'client/components/diy-operation/modals/diy-customization-modal/useFetchCustomizationForm';
import DiyOperationNoAccess from 'client/components/diy-operation/panels/diy-operation-no-access-panel';

import DIYCustomizationForm from '../diy-customization-form';
import {useFormElementDisabilityCheck} from '../diy-customization-form/useFormElementVisibleCheck';
import DIYCustomizationModalHeaderClassic from '../diy-customization-modal-header-сlassic';
import {editFormData, formatValue, saveTemplateAnswer, editResult, editFormAccessLevels} from '../helpers';

import './diy-customization-modal-container.scss';

const b = bem('diy-customization-modal-container');

// TODO: from jsx => tsx
const DIYCustomizationModalContainer = (props) => {
  const {
    onClose,
    formId,
    clientId,
    template,
    disabled: disabledGlobal,
    online,
    device,
    isAccessGranted = false,
  } = props;

  const dispatch = useDispatch();
  const {
    form,
    templateId,
    result,
    loadingResult,
    page,
    formPages,
    setFormPages,
    currentPage,
    setCurrentPage,
    loading,
    headerInfo,
    pagesAccesses,
    setPagesAccesses,
    checkFormItem,
  } = useFetchCustomizationForm({
    formId,
    clientId,
    template,
    disabled: disabledGlobal,
    online,
    device,
  });
  const pageAccesses = useMemo(() => pagesAccesses.find(({id}) => id === page?.id), [page, pagesAccesses]);
  useClientFonts({fetchOnMount: true});
  const operation = useSelector(selectOperation);
  const formErrors = useSelector(selectCurrentTemplateErrors);
  const initialFormItems = useSelector(selectInitialFormItems);
  const modifiedlFormItems = useSelector(selectModifiedFormItems);

  const {disabled} = useFormElementDisabilityCheck();
  const [templateAnswers, setTemplateAnswers] = useState([]);
  const [updatedAccessLevels, setUpdatedAccessLevels] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {languages, devices, defaultLanguage, defaultDevice} = useCustomizationPreviewLanguagesDevices({template});

  const pageErrors = formErrors?.[`'${page?.id}'`];
  const hasErrors = pageErrors ? !isEmpty(pageErrors) : false;

  const saveAnswers = useCallback(
    async (createdItems, modifiedItems) => {
      setIsSubmitting(true);
      if (createdItems.length) {
        const created = createdItems.map((item) =>
          dispatch(createTemplateAnswer(formatValue(item, initialFormItems[item?.form_item_id]))),
        );
        await Promise.all(created).catch((e) => {
          throw new Error(e);
        });
      }
      if (modifiedItems.length) {
        const modified = modifiedItems.map((item) => {
          dispatch(updateTemplateAnswer(item.id, formatValue(item, initialFormItems[item?.form_item_id])));
        });
        await Promise.all(modified).catch((e) => {
          throw new Error(e);
        });
      }
    },
    [dispatch, initialFormItems],
  );

  const saveAccessLevels = useCallback(async () => {
    await dispatch(updateFormItemAccess(updatedAccessLevels)).catch((e) => {
      throw new Error(e);
    });
  }, [dispatch, updatedAccessLevels]);

  const handleSaveForm = useCallback(() => {
    const created = [];
    const modified = [];
    const saveFormItemAccess = () => !isEmpty(updatedAccessLevels) && saveAccessLevels();
    let updatedTemplateAnswers = templateAnswers.filter(
      (item) => !item.media_storage_item?.id || item.media_storage_item?._destroy,
    ); // filter out image answers that were not changed

    updatedTemplateAnswers = updatedTemplateAnswers.map((updatedAnswer) => {
      if (updatedAnswer.media_storage_item?._destroy) {
        const initialFormItem = initialFormItems[updatedAnswer.form_item_id];
        if (initialFormItem) {
          const initialAnswer = initialFormItem.template_answers.find((i) => i.id === updatedAnswer.id);
          const initialItemId = initialAnswer?.media_storage_item?.id;
          if (initialItemId) {
            return {
              ...updatedAnswer,
              media_storage_item: {
                ...updatedAnswer.media_storage_item,
                id: initialItemId,
              },
            };
          }
          return {
            ...updatedAnswer,
            media_storage_item: null,
          };
        }
      }
      return updatedAnswer;
    });

    updatedTemplateAnswers.forEach((item) => (item.id ? modified.push(item) : created.push(item)));

    Promise.all([saveAnswers(created, modified), dispatch(saveResult(), saveFormItemAccess())]).then(() => {
      setIsSubmitting(false);
      onClose();
    });
  }, [templateAnswers, saveAnswers, dispatch, updatedAccessLevels, saveAccessLevels, onClose, initialFormItems]);

  const setFormItemValue = useCallback(
    ({formPageId, formSectionId, formItemId, value}, err) => {
      const newFormData = editFormData(formPages, {formPageId, formSectionId, formItemId, value});
      setFormPages(newFormData);

      if (!err && result) {
        const updatedResult = editResult({
          result,
          page,
          moduleName: form.module_name,
          formType: form.form_type,
          formSectionId,
          formItemId,
          value,
        });
        dispatch(updateResult(updatedResult));
      }

      setTemplateAnswers(saveTemplateAnswer(value));

      const initialFormItem = initialFormItems?.[formItemId];
      const modifiedFormItem = modifiedlFormItems?.[formItemId];

      const formItemTemplateAnswers = modifiedFormItem?.template_answers || initialFormItem?.template_answers || [];
      dispatch(
        setFormItem({
          [formItemId]: {
            ...initialFormItem,
            template_answers: value.id
              ? formItemTemplateAnswers.map((answer) => (answer.id === value.id ? value : answer))
              : value,
          },
        }),
      );
      dispatch(setFormError({formPageId, formSectionId, formItemId, err}));
    },
    [
      formPages,
      initialFormItems,
      modifiedlFormItems,
      dispatch,
      form.form_type,
      form.module_name,
      page,
      result,
      setFormPages,
    ],
  );

  const setFormAccessLevel = useCallback(
    ({formPageId, formSectionId, formItemId, value, type}) => {
      const {newFormItemAccess, newPageAccesses} = editFormAccessLevels(pagesAccesses, {
        formPageId,
        formSectionId,
        formItemId,
        value,
        type,
      });

      const newFormPagesItemAccesses = pagesAccesses.map((item) =>
        item.id === newPageAccesses.id ? newPageAccesses : item,
      );

      setUpdatedAccessLevels((prev) => ({
        form_item_access: [...(prev.form_item_access || []), ...newFormItemAccess.form_item_access],
      }));

      setPagesAccesses(newFormPagesItemAccesses);
    },
    [pagesAccesses, setPagesAccesses],
  );

  return (
    <>
      <div className={b('header')}>
        <DIYCustomizationModalHeaderClassic
          blocked={hasErrors}
          disabled={disabled}
          headerInfo={{...headerInfo, formTitle: operation?.name}}
          totalSteps={formPages.length}
          currentStep={currentPage + 1}
          setCurrentStep={(pageIndex) => setCurrentPage(pageIndex - 1)}
          onSave={handleSaveForm}
          onClose={onClose}
          loading={loading}
          submitting={isSubmitting}
          isAccessGranted={isAccessGranted}
          pagesAccesses={pagesAccesses}
          setFormAccessLevel={setFormAccessLevel}
        />
      </div>
      <div className={b('content')}>
        {loading || loadingResult ? (
          <div className={b('spinner-wrap')}>
            <Spinner color="clients" centered />
          </div>
        ) : (
          <>
            {formPages.length > 0 ? (
              <>
                {page.form_sections.length > 0 ? (
                  <DIYCustomizationForm
                    page={page}
                    pageAccesses={pageAccesses}
                    currentPage={currentPage}
                    setFormData={setFormItemValue}
                    setFormAccessLevel={setFormAccessLevel}
                    checkFormItem={checkFormItem}
                    templateId={templateId}
                    isAccessGranted={isAccessGranted}
                  />
                ) : (
                  <DiyOperationNoAccess className={b('no-access')} />
                )}
                {page.form_preview && (
                  <WebPreview
                    preview={page.form_preview}
                    languages={languages}
                    devices={devices}
                    defaultLanguage={defaultLanguage}
                    defaultDevice={defaultDevice}
                    data={result}
                    pageName={page.name}
                    hasReloadButton
                  />
                )}
              </>
            ) : (
              <DiyOperationNoAccess />
            )}
          </>
        )}
      </div>
    </>
  );
};

DIYCustomizationModalContainer.propTypes = {
  onClose: PropTypes.func.isRequired,
  formId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  isAccessGranted: PropTypes.bool,
  template: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  device: PropTypes.bool.isRequired,
  online: PropTypes.bool.isRequired,
};

export default DIYCustomizationModalContainer;
