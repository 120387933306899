import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError, reset, change} from 'redux-form';

import {normalizeStringToUpperCase} from 'client/services/formatters';
import {getAuthHeaders, transformDate} from 'client/services/helpers';

import {getAvailableForAttributionUsers} from 'client/ducks/client-users/actions';
import {selectAvailableForAttributionUsers} from 'client/ducks/client-users/selectors';

import {API_PATH, API_METHODS} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import DataRetentionField from 'client/components/common/custom-fields/data-retention-field';
// components
import fieldTemplate from 'client/components/common/field';
import DataRetentionTooltip from 'client/components/common/tooltips/data-retention-tooltip';

import {TranslationJsx} from 'client/models/language/types';

import './add-new-operation-modal.scss';

const initialValues = {
  status: {
    value: 'active',
  },
  common_retention_years: '0',
  common_retention_months: '6',
  leads_retention_years: '3',
  leads_retention_months: '0',
};

class AddNewOperationModal extends Component {
  static updateSyncErrors(values, props) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'AddNewOperationModalForm',
      },
      payload: {
        syncErrors: AddNewOperationModal.validate(values, props),
      },
    };
  }

  static validate(values, props) {
    const {name, code, from, to, client_user_id} = values;
    const {lang, isClientNetwork} = props;

    const errors = {};

    if (!name || !name.trim()) {
      errors.name = lang.NAME_IS_REQUIRED_ERROR;
    }
    if (!code) {
      errors.code = lang.CODE_IS_REQUIRED_ERROR;
    }

    const startDate = new Date(transformDate(from, false));
    if (!from) {
      errors.from = lang.START_DATE_IS_REQUIRED_ERROR;
    } else if (startDate.toString() === 'Invalid Date') {
      errors.from = lang.START_DATE_IS_INVALID_ERROR;
    }

    const endDate = new Date(transformDate(to, false));
    if (!to) {
      errors.to = lang.END_DATE_IS_REQUIRED_ERROR;
    } else if (endDate.toString() === 'Invalid Date') {
      errors.to = lang.END_DATE_IS_INVALID_ERROR;
    }

    if (isClientNetwork && !client_user_id?.value) {
      errors.client_user_id = lang.ATTRIBUTED_TO_IS_REQUIRED_ERROR;
    }

    return errors;
  }

  static mapData(clientId, data) {
    return {
      operation: {
        ...data,
        common_retention_years: parseInt(data.common_retention_years, 10),
        common_retention_months: parseInt(data.common_retention_months, 10),
        leads_retention_years: parseInt(data.leads_retention_years, 10),
        leads_retention_months: parseInt(data.leads_retention_months, 10),
        from: transformDate(data.from, false),
        to: transformDate(data.to, false),
        status: 'active',
        client_id: clientId,
        client_user_id: data.client_user_id?.value,
      },
    };
  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.updateErrors(initialValues, this.props);
    this.props.getUsers(this.props.clientId);
  }

  componentDidUpdate(prevProps) {
    const {show, updateErrors, clientId, getUsers} = this.props;

    if (show && !prevProps.show) {
      updateErrors(initialValues, this.props);
      getUsers(clientId);
    }
  }

  handleClose = () => {
    this.props.onClose();
    this.props.reset();
  };

  save = async (data) => {
    const res = await fetch(`${API_PATH}${API_METHODS.OPERATIONS}`, {
      method: 'POST',
      headers: getAuthHeaders(),
      body: JSON.stringify(AddNewOperationModal.mapData(this.props.clientId, data)),
    });
    const {errors} = await res.json();
    if (errors) {
      const submitErrors = {};

      const {lang} = this.props;

      if (errors.name) {
        submitErrors.name = lang.NAME_IS_TAKEN_ERROR;
      }
      if (errors.code) {
        submitErrors.code = lang.CODE_IS_TAKEN_ERROR;
      }
      if (errors.to) {
        submitErrors.to = lang.END_DATE_SMALLER_THEN_START_DATE_ERROR;
      }
      throw new SubmissionError(submitErrors);
    }
    this.props.reset();
    this.props.onConfirm();
  };

  render() {
    const {handleSubmit, formValues, lang, users} = this.props;

    const endDate = formValues && formValues.values && new Date(transformDate(formValues.values.to, false));
    const startDate = formValues && formValues.values && new Date(transformDate(formValues.values.from, false));

    if (endDate) {
      endDate.setDate(endDate.getDate() - 1);
    }
    if (startDate) {
      startDate.setDate(startDate.getDate() + 1);
    }

    return (
      <Modal
        show={this.props.show}
        onClose={this.handleClose}
        title={lang.NEW_OPERATION_MODAL_TITLE}
        className="add-new-operation-modal"
        dialogClassName="modal-window--width-1 modal-window--theme-4 operations-filters-modal"
      >
        <form onSubmit={handleSubmit(this.save)}>
          <div className="form-field-wrap">
            <Field
              label={lang.NEW_OPERATION_MODAL__NAME}
              cssModifier="input--no-min-height"
              name="name"
              type="text"
              component={fieldTemplate}
            />
          </div>
          <div className="form-field-wrap">
            <Field
              label={lang.NEW_OPERATION_MODAL__CODE}
              cssModifier="input--no-min-height"
              name="code"
              type="text"
              component={fieldTemplate}
              normalize={normalizeStringToUpperCase}
              onChange={(e, newValue) => (/^[A-Z0-9]*$/.test(newValue) && newValue.length <= 10) || e.preventDefault()}
            />
          </div>
          <div className="form-field-wrap filters__row">
            <Field
              cssModifier="input--no-min-height filters__item filters__item--half"
              name="from"
              disabledDayAfter={endDate}
              label={lang.NEW_OPERATION_MODAL__START_DATE}
              type="datepicker"
              component={fieldTemplate}
            />
            <Field
              cssModifier="input--no-min-height filters__item filters__item--half"
              name="to"
              disabledDayBefore={startDate}
              label={lang.NEW_OPERATION_MODAL__END_DATE}
              type="datepicker"
              component={fieldTemplate}
            />
          </div>

          <div className="form-field-wrap filters__row">
            <div className="select__label">{lang.PARTICIPATION_DATA_RETENTION}</div>
            <DataRetentionField
              cssModifier="input--no-min-height filters__item--quarter filters__select--no-min-height"
              name="common_retention_years"
              unit="year"
            />
            <DataRetentionField
              cssModifier="input--no-min-height filters__item--quarter filters__select--no-min-height"
              name="common_retention_months"
              unit="month"
            />
            <DataRetentionTooltip />
          </div>
          <div className="form-field-wrap filters__row">
            <div className="select__label">{lang.LEADS_DATA_RETENTION}</div>
            <DataRetentionField
              cssModifier="input--no-min-height filters__item--quarter filters__select--no-min-height"
              name="leads_retention_years"
              unit="year"
            />
            <DataRetentionField
              cssModifier="input--no-min-height filters__item--quarter filters__select--no-min-height"
              name="leads_retention_months"
              unit="month"
            />
            <DataRetentionTooltip />
          </div>
          <div className="form-field-wrap">
            <Field
              label={lang.ATTRIBUTED_TO_LABEL}
              cssModifier="select select--no-min-height"
              name="client_user_id"
              type="select"
              options={users.map((i) => ({label: i.full_name, value: i.id}))}
              component={fieldTemplate}
            />
          </div>
          <div className="modal-window__footer modal-window__footer--centered">
            <button className="button button--bg-11 modal-window__footer-btn" type="button" onClick={this.handleClose}>
              {lang.NEW_OPERATION_MODAL__CANCEL_BUTTON}
            </button>
            <button className="button button--bg-4 modal-window__footer-btn" type="submit">
              {lang.NEW_OPERATION_MODAL__CREATE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

AddNewOperationModal.defaultProps = {
  show: false,
  isClientNetwork: false,
};

AddNewOperationModal.propTypes = {
  clientId: PropTypes.number.isRequired,
  show: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  updateErrors: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  formValues: PropTypes.shape({
    values: PropTypes.shape({
      to: PropTypes.string,
      from: PropTypes.string,
      code: PropTypes.string,
    }),
  }),
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: TranslationJsx.isRequired,
    }),
  ).isRequired,
  isClientNetwork: PropTypes.bool,
};

const addNewOperationForm = reduxForm({
  form: 'AddNewOperationModalForm',
  validate: AddNewOperationModal.validate,
})(AddNewOperationModal);

export default connect(
  (state) => ({
    initialValues,
    lang: state.languageState.payload.OPERATIONS,
    formValues: state.form.AddNewOperationModalForm,
    users: selectAvailableForAttributionUsers(state),
  }),
  {
    reset,
    updateErrors: AddNewOperationModal.updateSyncErrors,
    changeFieldValue: change,
    getUsers: getAvailableForAttributionUsers,
  },
)(addNewOperationForm);
