import React, {useCallback, useEffect, useState} from 'react';

import debounce from 'lodash/debounce';
import {useDispatch, useSelector} from 'react-redux';
import {generatePath} from 'react-router';
import {useHistory, useParams} from 'react-router-dom';
import {useToggle, useMount} from 'react-use';
import {routePaths} from 'routes/index';

import bem from 'client/services/bem';
import {useLanguage, useReduxFetch} from 'client/services/hooks';
import {apiAction} from 'client/services/hooks/use-redux-fetch/requests';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {deleteClientTemplate} from 'client/ducks/templates/actions';
import {saveTemplateToLibrary, updateClientTemplate} from 'client/ducks/templates/actions';
import {selectCurrentMembership} from 'client/ducks/user-clients/selectors';
import {selectClientUser} from 'client/ducks/user-clients/selectors';
import {selectViewMode} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons/app-button';
import FileButton from 'client/common/buttons/file-button';
import CheckboxField from 'client/common/fields/checkbox-field';
import ImageField from 'client/common/fields/image-field';
import TextField from 'client/common/fields/text-field';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';
import TextEditor from 'client/common/text-editor';
import {FONT_FAMILY_OPTIONS} from 'client/common/text-editor/constants';
import StaticToolbar from 'client/common/text-editor/static-toolbar';
import {MEMBERSHIPS_TYPES} from 'client/common/config';

import useClientFonts from 'client/components/diy-operation/modals/diy-customization-modal/useClientFonts';
import {Client} from 'client/models/client/types';
import {Translation} from 'client/models/language/types';
import {CATALOG_TEMPLATE_TABS} from 'client/models/templates/constants';
import {ClientTemplate} from 'client/models/templates/types';
import {ApiDispatch} from 'client/types';

import getInitialValues from './getInitialValues';
import {getLabels, getSearchNameArgs, getFetchClientsArgs, getShareToVisibility} from './helpers';
import mapFormValues from './mapFormValues';
import ShareWithClientsStep from './share-with-clients-step';
import {DiyOpTemplateValues, DiyOpTemplateMode, TemplateData} from './types';
import validateValues from './validateValues';

import cssModule from './diy-op-template-modal.module.scss';

const b = bem('diy-op-template-modal', {cssModule});

const DiyOpTemplateFormName = 'DiyOpTemplateForm';

type DiyOpTemplateModalProps = {
  onClose: () => void;
  type: string;
  mode: DiyOpTemplateMode;
  template: TemplateData;
  isModule?: boolean;
  onSave?: () => Promise<void>;
};

const DiyOpTemplateModal: ReduxFormFC<DiyOpTemplateModalProps, DiyOpTemplateValues> = (props) => {
  const {handleSubmit, onClose, type, mode, template, onSave, isModule} = props;
  const dispatch: ApiDispatch = useDispatch();
  const history = useHistory();

  const lang = useLanguage('DIY_OPERATION.MODALS.DIY_OP_TEMPLATE_MODAL');
  const langCommon = useLanguage('COMMON');
  const [saving, toggleSaving] = useToggle(false);
  const [showSharing, toggleSharing] = useToggle(false);
  const {appendToastNotification} = useToast();
  const {clientId} = useParams<{clientId: string}>();
  const membership = useSelector(selectCurrentMembership);
  const {client_user: user} = useSelector(selectClientUser);
  const {id: viewUserId} = useSelector(selectViewMode);
  const [shouldCheckName, setShouldCheckName] = useState(false);
  const [isNameUnique, setIsNameUnique] = useState(true);

  const isSave = mode === 'save';
  const isShare = mode === 'share';
  const isAgency = membership?.type === MEMBERSHIPS_TYPES.AGENCY;

  const accessLevel = membership.access;

  const {fontNames = []} = useClientFonts({fetchOnMount: true});
  const fontFamilyOptions = [...fontNames, ...FONT_FAMILY_OPTIONS].sort();
  const colorsAccessKey = `client-template-colors-${template.id}`;

  const {invalid, change, formValues} = useReduxForm(DiyOpTemplateFormName, {
    initialValues: getInitialValues(template, {mode, accessLevel, locale: user.locale}),
    validate: (values) =>
      validateValues(values, lang.ERRORS, {
        isNameUnique,
        mode,
        initialName: isSave ? '' : template.title.toLowerCase(),
      }),
  });

  const {fetch: searchName, data: nameData} = useReduxFetch<{client_templates: ClientTemplate[]}>({
    action: apiAction,
    actionArgs: getSearchNameArgs({isShare, clientId, search: formValues.name}),
    skip: true,
  });

  useMount(searchName);

  useEffect(() => {
    setIsNameUnique(!nameData?.client_templates?.length);
    change('names', nameData);
    setShouldCheckName(false);
  }, [nameData, change]);

  const {data: {clients = []} = {}} = useReduxFetch<{clients: Client[]}>({
    action: apiAction,
    actionArgs: getFetchClientsArgs(clientId),
    skip: !isShare,
  });

  const shareToVisibility = getShareToVisibility({accessLevel, isSave, isShare, clients, isAgency});

  const labels = getLabels({mode, type}, lang);

  const handleDeleteTemplate = async (id: number) => {
    await dispatch(deleteClientTemplate(id));
    await onSave?.();
  };

  const handleSave = async (values: DiyOpTemplateValues) => {
    toggleSaving();

    const data = await mapFormValues(values, isSave, user.locale);

    if (mode === 'duplicate' && isAgency) {
      data.user_access_levels = [];
    }

    try {
      let response: {payload: {client_template: ClientTemplate}};
      if (mode === 'edit') {
        response = await dispatch(updateClientTemplate(template.id, {client_template: data}));
      } else {
        const queryParams: Record<string, any> = {};
        if (viewUserId) {
          queryParams.fake_client_user_id = viewUserId;
        }
        response = await dispatch(saveTemplateToLibrary(template.id, {...data, client_id: clientId}, queryParams));
      }

      await onSave?.();

      const toastActions = [];

      if (mode === 'duplicate') {
        toastActions.push({
          label: langCommon.UNDO,
          onClick: () => handleDeleteTemplate(response.payload?.client_template?.id),
        });
      }

      appendToastNotification({
        type: 'success',
        title: labels.saveToast as Translation,
        description: values.name,
        hideCloseButton: mode === 'duplicate',
        actions: toastActions,
      });

      if (mode === 'duplicate' && !isModule) {
        history.push(
          generatePath(routePaths.client.CatalogTemplatesPage, {clientId, type: CATALOG_TEMPLATE_TABS.MY_TEMPLATES}),
        );
      }
      onClose();
    } catch {
      appendToastNotification({type: 'error', title: langCommon.ERROR});
    }

    toggleSaving();
  };

  const handleChangeDescription = useCallback(
    (value) => {
      change('description', value);
    },
    [change],
  );

  if (showSharing) {
    return (
      <ShareWithClientsStep
        onClose={onClose}
        onSave={onSave}
        formValues={formValues}
        count={formValues.clients?.length}
        templateId={template.id}
        allClients={clients}
      />
    );
  }

  return (
    <Modal className={b()} onClose={onClose} title={labels.titleModal} isCloseHidden={true}>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className={b('buttons')}>
          <AppButton color="clients" transparent label={lang.CANCEL} onClick={onClose} />
          <AppButton
            color="clients"
            label={labels.saveButton}
            loading={saving}
            submit={!isShare && !shouldCheckName}
            onClick={isShare && !invalid && !shouldCheckName ? toggleSharing : null}
          />
          {invalid && <p className={b('save-message')}>{lang.ERRORS.FILL_ALL_FIELDS}</p>}
        </div>
        <div className={b('columns')}>
          <div className={b('column', ['left'])}>
            <TextField
              name="name"
              label={labels.templateName}
              classNames={{label: b('label')}}
              onBlur={() => formValues.name?.trim() && searchName()}
              onFocus={() => {
                setShouldCheckName(true);
                setIsNameUnique(true);
              }}
              alwaysShowError
              required
            />
            <div>
              <p className={b('label')}>{lang.DESCRIPTION}</p>
              <TextEditor
                value={formValues.description}
                onChange={debounce(handleChangeDescription, 500)}
                editableClassName={b('editable', {edit: !isSave})}
                commonColorsAccessKey={colorsAccessKey}
                isLinesLimited
                isToolbarVisible
              >
                <StaticToolbar colorsAccessKey={colorsAccessKey} fontFamilyOptions={fontFamilyOptions} />
              </TextEditor>
            </div>
          </div>
          <div className={b('column', ['right'])}>
            <div className={b('languages')}>
              <p className={b('label', ['languages'])}>
                {lang.TEMPLATE_LANGUAGES}
                <span className={b('required-mark')}>{lang.ERRORS.REQUIRED}</span>
              </p>
              <div className={b('languages-row')}>
                {Object.keys(formValues.languages).map((languageKey) => (
                  <CheckboxField
                    key={languageKey}
                    name={`languages[${languageKey}]`}
                    label={lang.LANGUAGES[languageKey.toUpperCase() as keyof typeof lang.LANGUAGES]}
                    labelIconName={`flag-${languageKey}`}
                    inversionColor
                  />
                ))}
              </div>
            </div>
            <div className={b('broadcast')}>
              <p className={b('label', ['broadcast'])}>
                {lang.BROADCAST_CHANNELS}
                <span className={b('required-mark')}>{lang.ERRORS.REQUIRED}</span>
              </p>
              {'online' in formValues.broadcasts && (
                <>
                  <p className={b('label', ['small'])}>{lang.ONLINE}</p>
                  <CheckboxField
                    name="broadcasts.online"
                    label={lang.MOBILE_DESKTOP}
                    labelIconName="mobile-desktop"
                    inversionColor
                  />
                </>
              )}
              {('vertical' in formValues.broadcasts || 'horizontal' in formValues.broadcasts) && (
                <>
                  <p className={b('label', ['small', 'device'])}>{lang.DEVICE}</p>
                  <div className={b('checkbox-row')}>
                    {'vertical' in formValues.broadcasts && (
                      <CheckboxField
                        name="broadcasts.vertical"
                        label={lang.VERTICAL}
                        labelIconName="kiosk-vertical"
                        className={b('checkbox')}
                        inversionColor
                      />
                    )}
                    {'horizontal' in formValues.broadcasts && (
                      <CheckboxField
                        name="broadcasts.horizontal"
                        label={lang.HORIZONTAL}
                        labelIconName="kiosk-horizontal"
                        className={b('checkbox')}
                        inversionColor
                      />
                    )}
                  </div>
                </>
              )}
              {shareToVisibility.showBlock && (
                <>
                  <p className={b('label', ['share'])}>{lang.SHARE_TO}</p>
                  <div className={b('checkbox-row')}>
                    {shareToVisibility.showRegion && (
                      <CheckboxField
                        name="sharing.region"
                        label={lang.REGIONAL}
                        labelIconName="share-region"
                        className={b('checkbox')}
                        inversionColor
                      />
                    )}
                    {shareToVisibility.showStore && (
                      <CheckboxField
                        name="sharing.store"
                        label={lang.STORE}
                        labelIconName="share-store"
                        className={b('checkbox')}
                        inversionColor
                      />
                    )}
                  </div>
                </>
              )}
            </div>
            <div className={b('icon-row')}>
              <ImageField
                classNames={{label: b('label')}}
                name="icon"
                label={lang.ICON}
                width={220}
                height={220}
                placeholderIcon={null}
                disabled
                withWrap
              />
              <FileButton
                name="icon"
                label={lang.CLICK_TO_SELECT}
                className={b('upload-button')}
                onChange={(file: File) => change('icon', file)}
                color="primary"
                acceptFormats=".png,.jpg,.jpeg"
                link
              />
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<DiyOpTemplateModalProps, DiyOpTemplateValues>({
  form: DiyOpTemplateFormName,
})(DiyOpTemplateModal);
