import React from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {CLIENT_PAGES} from 'client/common/config';

import './password-expiration-notification.scss';

function PasswordExpirationNotification(props) {
  const {languageState, history, days} = props;

  const LANGUAGE = languageState.payload.NOTIFICATIONS.PASSWORD_EXPIRATION;

  const onReset = () => {
    history.push(CLIENT_PAGES.PROFILE + '#editPassword');
  };

  return (
    <div className="password-expiration-notification">
      <span className="password-expiration-notification__message">{LANGUAGE.MESSAGE.replace('{days}', days)}</span>
      <button className="password-expiration-notification__button button button--bg-1" onClick={onReset}>
        {LANGUAGE.RESET}
      </button>
    </div>
  );
}

PasswordExpirationNotification.propTypes = {
  ...withRouter.propTypes,
  languageState: PropTypes.object.isRequired,
  days: PropTypes.number.isRequired,
};

export default withRouter(
  connect((state) => ({
    languageState: state.languageState,
  }))(PasswordExpirationNotification),
);
