import {blobToBase64} from 'client/services/blobToBase64';

import {Translation} from 'client/models/language/types';

import {WinParamsValues} from './types';

const mapTranslations = async (
  values: WinParamsValues,
  prefix: string,
  fieldName: 'name' | 'description' | 'image',
) => {
  const result: Record<string, Translation | {data: string}> = {};

  for (const key of Object.keys(values[fieldName])) {
    if (fieldName === 'image' && typeof values[fieldName][key] === 'string') {
      continue;
    }
    result[`${prefix}_${fieldName}_${key}`] =
      fieldName === 'image' ? {data: await blobToBase64(values[fieldName][key])} : values[fieldName][key];
  }

  return result;
};

export default async (values: WinParamsValues) => {
  const external_name =
    (values.language && values.name[values.language]) || Object.values(values.name).filter((i) => i)[0];

  const nameTranslations = await mapTranslations(values, 'external', 'name');
  const descriptionTranslations = await mapTranslations(values, 'external', 'description');
  const imageTranslations = await mapTranslations(values, 'email', 'image');

  return {
    external_name,
    internal_name: external_name,
    language_unique_mode: values.langUniqueMode,
    ...nameTranslations,
    ...descriptionTranslations,
    ...imageTranslations,
  };
};
