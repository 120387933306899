import React, {useCallback, useEffect} from 'react';

import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useToggle, useUnmount} from 'react-use';

import {useQueryParams, useAppMedia} from 'client/services/hooks';

import {getAnimationStatistics} from 'client/ducks/animations/actions';
import {clearAnimations} from 'client/ducks/animations/actions';
import {selectStatistics} from 'client/ducks/animations/selectors';
import {getInstoreTaskCampaigns, clearInstoreTaskCampaigns} from 'client/ducks/instore-tasks/actions';
import {selectInstoreTaskCampaigns} from 'client/ducks/instore-tasks/selectors';

import Offscreen from 'client/common/offscreen';

import Spinner from 'client/components/common/spinner';

import {getPeriod} from 'client/components/instore/helpers';
import useSetDefaultCampaign from 'client/components/instore/hooks/use-set-default-campaign';
import InstoreCampaignSelect from 'client/components/instore/inputs/instore-campaign-select';
import InstorePlanningAnimationList from 'client/components/instore/lists/instore-planning-animation-list';
import InstoreStatisticsPanel from 'client/components/instore/panels/instore-statistics-panel';

const queryParamsConfig = {
  parse: {
    parseNumbers: true,
  },
};

const InstorePlanningContainer = (props) => {
  const {task} = props;
  const dispatch = useDispatch();
  const {isTablet} = useAppMedia();
  const [queryParams, setQueryParams] = useQueryParams(null, {}, queryParamsConfig);
  const campaigns = useSelector(selectInstoreTaskCampaigns);
  const statistics = useSelector(selectStatistics);
  const [loadingCampaigns, toggleLoadingCampaigns] = useToggle(false);
  const [loadingStats, toggleLoadingStats] = useToggle(false);
  const loading = loadingCampaigns || loadingStats;

  const fetchCampaigns = useCallback(async () => {
    if (task) {
      const params = {
        q: {instore_task_id_eq: task.id},
      };
      toggleLoadingCampaigns();
      await dispatch(getInstoreTaskCampaigns(params));
      toggleLoadingCampaigns();
    }
  }, [dispatch, task, toggleLoadingCampaigns]);

  const fetchAnimationsStatistics = useCallback(async () => {
    if (!isTablet && queryParams.campaignId) {
      const params = {
        first_animation_day_between: getPeriod(queryParams?.filters?.from, queryParams?.filters?.to),
        q: {
          interaction_group_id_eq: queryParams.campaignId,
          status_in: queryParams?.filters?.status,
          place_network_id_eq: queryParams?.filters?.network,
          instore_task_access_membership_regions_id_eq: queryParams?.filters?.region,
          kit_mapping_kit_id_eq: queryParams?.filters?.kit,
          instore_task_access_user_id_eq: queryParams?.filters?.user,
        },
      };
      toggleLoadingStats();
      await dispatch(getAnimationStatistics(params));
      toggleLoadingStats();
    }
  }, [toggleLoadingStats, dispatch, isTablet, queryParams]);

  useUnmount(() => dispatch(clearInstoreTaskCampaigns()));

  useEffect(() => {
    fetchCampaigns();
  }, [fetchCampaigns]);

  useEffect(() => {
    fetchAnimationsStatistics();
  }, [fetchAnimationsStatistics]);

  useSetDefaultCampaign({campaigns, setFirst: true});

  const onChangeCampaign = (campaignId) => {
    if (campaignId !== queryParams.campaignId) {
      dispatch(clearAnimations());
      setQueryParams({campaignId}, {replace: true});
    }
  };

  return (
    <div>
      {loading && <Spinner color="primary" centered />}
      <Offscreen hidden={loading}>
        <>
          {!isTablet && campaigns.length > 1 && (
            <InstoreCampaignSelect campaigns={campaigns} value={queryParams.campaignId} onChange={onChangeCampaign} />
          )}
          {!isTablet && <InstoreStatisticsPanel statistics={statistics} loading={loading} />}
          <InstorePlanningAnimationList
            campaignId={queryParams.campaignId}
            fetchAnimationsStatistics={fetchAnimationsStatistics}
            campaigns={campaigns}
            onChangeCampaign={onChangeCampaign}
            taskId={task.id}
          />
        </>
      </Offscreen>
    </div>
  );
};

InstorePlanningContainer.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
};

InstorePlanningContainer.defaultProps = {
  task: null,
};

export default InstorePlanningContainer;
