import React, {useMemo, useRef} from 'react';

import classNames from 'classnames';
import {cloneDeep} from 'lodash';

import bem from 'client/services/bem';

import DateTimeInput, {
  DateTimeInputTypeOptionType,
  DateTimeInputTypeOptions,
} from 'client/common/inputs/datetime-input';

import {addEmptyValueSafely, filterEmptyValues, validateInput} from './helpers';

import cssModule from './datetime-list-input.module.scss';

const b = bem('datetime-list-input', {cssModule});

export type DateTimeValueType = {
  value: string;
  id: string | number;
};
type DateTimeListInputProps = {
  value: DateTimeValueType[];
  onChange: (value: DateTimeValueType[]) => void;
  period?: boolean;
  type?: DateTimeInputTypeOptionType;
  maxLength?: number;
  className?: string;
  inputClassName?: string;
  disabled?: boolean;
  onBlur?: (value: DateTimeValueType[]) => void;
  validationPattern?: RegExp | string;
  errorMessage?: string;
};

const DateTimeListInput: React.FC<DateTimeListInputProps> = (props) => {
  const {
    value: values = [],
    onChange,
    type = 'time',
    period = false,
    maxLength = Number.POSITIVE_INFINITY,
    className,
    inputClassName,
    disabled,
    onBlur,
    validationPattern,
    errorMessage,
    ...inputProps
  } = props;

  const inputValues = useMemo(() => addEmptyValueSafely(values, maxLength), [values, maxLength]);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (newValue: string, id: string | number) => {
    const newValues = cloneDeep(inputValues); // cloned to have new reference on all levels for correct work of redux-form validation
    const timeStampToChange = newValues.find((timeStamp) => timeStamp.id === id);

    if (!timeStampToChange) {
      return;
    }

    timeStampToChange.value = newValue;
    onChange(filterEmptyValues(newValues));
  };

  const handleWrapperBlur = (updatedValues: DateTimeValueType[]) => onBlur?.(filterEmptyValues(updatedValues));

  const handleInputBlur = (id: string | number) => {
    let updatedValues = inputValues; // pass to handleWrapperBlur the reference of the same object if the value is not changed

    const clonedPreviousValues = cloneDeep(inputValues); // cloned to have new reference on all levels for correct work of redux-form validation
    const blurredInput = clonedPreviousValues.find((input: {id: string; value: string}) => input.id === id);
    const isInputValid = blurredInput && validateInput(blurredInput.value, period, type, validationPattern);

    if (!isInputValid) {
      updatedValues = clonedPreviousValues.filter((input) => input.id !== id);
      onChange(filterEmptyValues(updatedValues));
    }

    const wrapperNode = wrapperRef.current;
    if (wrapperNode && !wrapperNode.contains(document.activeElement)) {
      handleWrapperBlur(updatedValues);
    }
  };

  return (
    <div className={classNames(b({disabled, error: errorMessage}), className)} ref={wrapperRef}>
      {inputValues.map((input) => (
        <DateTimeInput
          className={inputClassName}
          classNames={{field: b('field')}}
          key={input.id}
          type={type}
          value={input.value}
          onChange={(value) => handleInputChange(value, input.id)}
          onBlur={() => handleInputBlur(input.id)}
          period={period}
          disabled={disabled}
          {...inputProps}
          errorMessage="" // disable to preserve UI
        />
      ))}
    </div>
  );
};

export const DateTimeListInputTypeOptions = DateTimeInputTypeOptions;

export default DateTimeListInput;
