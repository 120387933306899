import React from 'react';

import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import FileButton from 'client/common/buttons/file-button';
import ImageField from 'client/common/fields/image-field';
import TextField from 'client/common/fields/text-field';
import TextareaField from 'client/common/fields/textarea-field';

import LanguageDevicePanel from 'client/components/diy-operation/controls/language-device-panel';
import {LotteryPrizeModalFormName} from 'client/components/lottery/modals/lottery-prize-modal';

import cssModule from './win-params-fieldset.module.scss';

const b = bem('win-params-fieldset', {cssModule});

type WinParamsFieldsetProps = {
  disabled: boolean;
};

const WinParamsFieldset: React.FC<WinParamsFieldsetProps> = (props) => {
  const {disabled} = props;
  const lang = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_MODAL.WIN_PARAMS_FIELDSET');
  const languages = useSelector(selectPrizeLanguages);

  const languageOptions = languages.map((i) => ({value: i, label: i}));

  const {
    formValues: {winParams = {}},
    change,
  } = useReduxForm(LotteryPrizeModalFormName);
  const {language, defaultLanguage, langUniqueMode} = winParams;

  const copyDefaultValue = (field: string, newValue?: string | File) =>
    language !== defaultLanguage
      ? null
      : () => {
          const fieldValues = winParams[field];
          const defaultValue = newValue || fieldValues[defaultLanguage];

          if (defaultValue) {
            for (const [key, value] of Object.entries(fieldValues)) {
              if (key !== defaultLanguage && !value) {
                change(`winParams.${field}.${key}`, defaultValue);
              }
            }
          }
        };

  const onUpload = (file: File) => {
    change(`winParams.image.${language}`, file);
    copyDefaultValue('image', file)?.();
  };

  return (
    <div className={b()}>
      {typeof langUniqueMode === 'boolean' && (
        <LanguageDevicePanel
          className={b('lang-group')}
          languages={languageOptions}
          language={language}
          onChangeLanguage={(value) => change('winParams.language', value)}
          langInitiallyCommon={langUniqueMode}
          onLanguageCommonToggle={(value) => change('winParams.langUniqueMode', value)}
          prefix="win-params-language"
        />
      )}
      <TextField
        name={`winParams.name.${language}`}
        label={lang.PRIZE_NAME}
        onBlur={copyDefaultValue('name')}
        disabled={disabled}
        withWrap={true}
        required={true}
      />
      <div className={b('image-row')}>
        <ImageField
          name={`winParams.image.${language}`}
          label={lang.IMAGE}
          width={460}
          height={360}
          placeholderIcon={null}
          disabled
          withWrap={true}
        />
        <FileButton
          key={`winParams.image.${language}`}
          label={lang.CHOOSE_IMAGE}
          className={b('upload-button')}
          onChange={onUpload}
          color="devices"
          acceptFormats=".png,.jpg,.jpeg"
          link
          disabled={disabled}
        />
      </div>
      <TextareaField
        name={`winParams.description.${language}`}
        label={lang.DESCRIPTION}
        textareaClassName={b('description')}
        onBlur={copyDefaultValue('description')}
        disabled={disabled}
      />
    </div>
  );
};

export default WinParamsFieldset;
