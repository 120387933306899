import React from 'react';

import AudioPreview from 'assets/icons/colored/audio-preview.svg';
import PdfPreview from 'assets/icons/colored/pdf-preview.svg';
import VideoPreview from 'assets/icons/colored/video-preview.svg';
import cn from 'classnames';

import bem from 'client/services/bem';

import {MEDIA_MIME_TYPES} from 'client/common/config';
import Player from 'client/common/media-player';

import {Translation} from 'client/models/language/types';

import cssModule from './media-container.module.scss';

const b = bem('media-container', {cssModule});

type Source = {
  src: string;
  type: string;
};

type MediaContainerProps = {
  sources: Source[];
  label?: Translation;
  className?: string;
  testId?: string;
  isMediaPreview?: boolean;
  placeholderIcon?: string;
  width?: string | number;
  height?: string | number;
  keepRatio?: boolean;
  imageStyle?: React.CSSProperties;
};

const componentPreviewMap = {
  [MEDIA_MIME_TYPES.mp4]: VideoPreview,
  [MEDIA_MIME_TYPES.mp3]: AudioPreview,
  [MEDIA_MIME_TYPES.pdf]: PdfPreview,
};

const MediaContainer: React.FC<MediaContainerProps> = (props) => {
  const {
    sources,
    width = 0,
    height = 0,
    keepRatio = true,
    imageStyle,
    label = '',
    className = '',
    testId,
    isMediaPreview = true,
    placeholderIcon,
  } = props;

  if (!sources.length) {
    return (
      <div className={cn(b(), className)} data-testid={testId}>
        {placeholderIcon && <img src={placeholderIcon} alt="" />}
      </div>
    );
  }

  const previewSrc = String(componentPreviewMap[sources[0].type]);

  return (
    <div className={cn(b({preview: isMediaPreview}), className)} data-testid={testId}>
      {label && <span className={b('label')}>{label}</span>}
      {isMediaPreview ? (
        <img src={previewSrc} alt="" />
      ) : (
        <Player
          mediaType={sources[0].type}
          sources={sources}
          width={width}
          height={height}
          keepRatio={keepRatio}
          style={imageStyle}
        />
      )}
    </div>
  );
};

export default MediaContainer;
