export default {
  GET_CLIENT_ACCOUNT_REQUEST: '@@client-account/GET_CLIENT_ACCOUNT_REQUEST',
  GET_CLIENT_ACCOUNT_SUCCESS: '@@client-account/GET_CLIENT_ACCOUNT_SUCCESS',
  GET_CLIENT_ACCOUNT_ERROR: '@@client-account/GET_CLIENT_ACCOUNT_ERROR',

  GET_CLIENT_MEMBERSHIPS_REQUEST: '@@client-account/GET_CLIENT_MEMBERSHIPS_REQUEST',
  GET_CLIENT_MEMBERSHIPS_SUCCESS: '@@client-account/GET_CLIENT_MEMBERSHIPS_SUCCESS',
  GET_CLIENT_MEMBERSHIPS_ERROR: '@@client-account/GET_CLIENT_MEMBERSHIPS_ERROR',

  UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_REQUEST: '@@client-account/UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_REQUEST',
  UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_SUCCESS: '@@client-account/UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_SUCCESS',
  UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_ERROR: '@@client-account/UPDATE_MEMBERSHIP_DATA_TAB_ACCESS_ERROR',

  GET_CLIENT_STORES_REQUEST: '@@client-account/GET_CLIENT_STORES_REQUEST',
  GET_CLIENT_STORES_SUCCESS: '@@client-account/GET_CLIENT_STORES_SUCCESS',
  GET_CLIENT_STORES_ERROR: '@@client-account/GET_CLIENT_STORES_ERROR',

  GET_MEMBERSHIPS_TEAM_USERS_REQUEST: '@@client-account/GET_MEMBERSHIPS_TEAM_USERS_REQUEST',
  GET_MEMBERSHIPS_TEAM_USERS_SUCCESS: '@@client-account/GET_MEMBERSHIPS_TEAM_USERS_SUCCESS',
  GET_MEMBERSHIPS_TEAM_USERS_ERROR: '@@client-account/GET_MEMBERSHIPS_TEAM_USERS_ERROR',

  GET_CLIENT_SIREN_NUMBER_REQUEST: '@@client-account/GET_CLIENT_SIREN_NUMBER_REQUEST',
  GET_CLIENT_SIREN_NUMBER_SUCCESS: '@@client-account/GET_CLIENT_SIREN_NUMBER_SUCCESS',
  GET_CLIENT_SIREN_NUMBER_ERROR: '@@client-account/GET_CLIENT_SIREN_NUMBER_ERROR',

  UPDATE_LOGO_REQUEST: '@@client-account/UPDATE_LOGO_REQUEST',
  UPDATE_LOGO_SUCCESS: '@@client-account/UPDATE_LOGO_SUCCESS',
  UPDATE_LOGO_ERROR: '@@client-account/UPDATE_LOGO_ERROR',

  CREATE_TRACKING_USER_REQUEST: '@@client-account/CREATE_TRACKING_USER_REQUEST',
  CREATE_TRACKING_USER_SUCCESS: '@@client-account/CREATE_TRACKING_USER_SUCCESS',
  CREATE_TRACKING_USER_ERROR: '@@client-account/CREATE_TRACKING_USER_ERROR',

  UPDATE_TRACKING_USER_REQUEST: '@@client-account/UPDATE_TRACKING_USER_REQUEST',
  UPDATE_TRACKING_USER_SUCCESS: '@@client-account/UPDATE_TRACKING_USER_SUCCESS',
  UPDATE_TRACKING_USER_ERROR: '@@client-account/UPDATE_TRACKING_USER_ERROR',

  DELETE_TRACKING_USER_REQUEST: '@@client-account/DELETE_TRACKING_USER_REQUEST',
  DELETE_TRACKING_USER_SUCCESS: '@@client-account/DELETE_TRACKING_USER_SUCCESS',
  DELETE_TRACKING_USER_ERROR: '@@client-account/DELETE_TRACKING_USER_ERROR',
};
