import React, {useMemo} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import TableInfo from 'client/common/table-info';

import './prize-diy-quantities-table.scss';

const b = bem('prize-diy-quantities-table');
const TABLE_ROWS = ['initial_stock', 'prize_won_non_validated', 'prize_won_validated', 'current_stock'];
const TABLE_COLS = ['device', 'online', 'total'];
const DEFAULT_QUANTITY = '0';

export const PrizeDiyQuantitiesTable = ({prize}) => {
  const lang = useLanguage('PRIZES.MODALS.PRIZE_DIY_MODAL.QUANTITIES');
  const tableData = useMemo(() => {
    // Init table structure filled with default value
    const data = TABLE_ROWS.reduce((acc, row) => {
      if (!acc[row]) {
        acc[row] = {};
      }
      TABLE_COLS.forEach((col) => (acc[row][col] = DEFAULT_QUANTITY));

      return acc;
    }, {});

    // Fill in data from prize object
    if (prize) {
      const prizeQuantities = {
        ...prize.device_quantities,
        ...prize.online_quantities,
        ...prize.total_quantities,
      };

      Object.entries(prizeQuantities).forEach(([key, value]) => {
        const keySplit = key.split('_');
        const col = keySplit.shift();
        const row = keySplit.join('_');

        if (typeof data[row]?.[col] !== 'undefined') {
          data[row][col] = String(value);
        }
      });
    }

    return Object.values(data);
  }, [prize]);

  const columns = [
    {
      name: 'device',
      header: lang.COL_DEVICES,
      path: 'device',
    },
    {
      name: 'online',
      header: lang.COL_ONLINE,
      path: 'online',
    },
    {
      name: 'total',
      header: lang.COL_TOTAL,
      path: 'total',
    },
  ];
  const rowNames = [
    lang.ROW_TOTAL_DOTATION,
    lang.ROW_NOT_VALIDATED_WINS,
    lang.ROW_VALIDATED_WINS,
    lang.ROW_TOTAL_STOCK,
  ];

  return (
    <div className={b()}>
      <div className={b('title')}>{lang.TITLE}</div>
      <TableInfo columns={columns} rowNames={rowNames} data={tableData} />
    </div>
  );
};

PrizeDiyQuantitiesTable.propTypes = {
  prize: PropTypes.shape({
    device_quantities: PropTypes.object,
    online_quantities: PropTypes.object,
    total_quantities: PropTypes.object,
  }),
};

PrizeDiyQuantitiesTable.defaultProps = {
  prize: null,
};

export default PrizeDiyQuantitiesTable;
