import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import DatetimeCell from 'client/components/tables/common/cells/datetime-cell';

import './device-diy-parameters-modal-interactions.scss';

const b = bem('device-diy-parameters-modal-interactions');

const DeviceDiyParametersModalInteractions = ({interactions, device}) => {
  const lang = useLanguage('CLIENT_DEVICES.DIY_PARAMETERS_MODAL');

  return (
    <div className={b()}>
      <p className={b('text-information')}>
        {lang.HAS_BOOKED_INTERACTIONS.split('<DeviceName>')[0]}
        <strong>{device?.name}</strong>
        {lang.HAS_BOOKED_INTERACTIONS.split('<DeviceName>')[1]}
      </p>
      <div className={b('list')}>
        {interactions?.slice(0, 3).map((interaction) => (
          <div className={b('interaction')} key={interaction.id}>
            <div className={b('interaction-image-container')}>
              {interaction.operation.operation_image?.url && (
                <img alt="" className={b('interaction-image')} src={interaction.operation.operation_image?.url} />
              )}
            </div>

            <div className={b('interaction-name')}>{interaction.operation?.name}</div>

            <DatetimeCell value={interaction.from} />
            <DatetimeCell value={interaction.to} />
          </div>
        ))}
      </div>
    </div>
  );
};

DeviceDiyParametersModalInteractions.displayName = 'DeviceDiyParametersModalInteractions';

DeviceDiyParametersModalInteractions.propTypes = {
  interactions: PropTypes.arrayOf(
    PropTypes.shape({
      operation: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        operation_image: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  ).isRequired,
  device: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default DeviceDiyParametersModalInteractions;
