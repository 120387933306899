import React, {Fragment, useMemo} from 'react';

import cn from 'classnames';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {useMount} from 'react-use';

import bem from 'client/services/bem';
import {getUserId} from 'client/services/cookie-data-source';
import {transformDate, uid} from 'client/services/helpers';
import {useLanguage, useReduxFetch, useReduxForm} from 'client/services/hooks';
import {apiAction} from 'client/services/hooks/use-redux-fetch/requests';
import {useReduxFormValidation} from 'client/services/hooks/useReduxFormValidation';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectUser} from 'client/ducks/user/selectors';

import {API_METHODS} from 'client/common/config';
import {CheckboxField, DatepickerField, SelectField, TextField} from 'client/common/fields';
import {CheckboxInput} from 'client/common/inputs';

import TemplateCard from 'client/components/common/cards/template-card';
import Icon from 'client/components/common/icon';

import {SCREEN_FORMATS} from 'client/components/diy-operation/modals/diy-customization-modal/constants';
import {DiyOperationModalFormName} from 'client/components/operations-client/components/modals/diy-operation-modal/diy-operation-modal-form';
import {
  filterTemplatesByLangs,
  unionBroadcasts,
} from 'client/components/operations-client/components/modals/diy-operation-modal/helpers';

import {addDay, getMembershipsQueryParams, getInitialDefaultLanguage} from './helpers';
import {validate} from './validate';

import DiyOperationModalFooter from '../../diy-operation-modal-footer';
import {OperationTemplatePopoverContent} from '../diy-operation-template-step/operation-template-popover-content';

import cssModule from './diy-operation-summary-step.module.scss';

const b = bem('diy-operation-summary-step', {cssModule});

const DiyOperationSummaryStep = (props) => {
  const {onFinish, goToPrevStep, className, updateFormState, isFormValid, loading, client} = props;

  const lang = useLanguage('OPERATIONS.DIY_OPERATION_MODAL');
  const langStep = useLanguage('OPERATIONS.DIY_OPERATION_MODAL.STEPS.SUMMARY');

  const {formValues} = useReduxForm(DiyOperationModalFormName);

  const currentClient = useSelector(selectCurrentClient);
  const user = useSelector(selectUser);

  const shouldAttribute = currentClient.type === 'Agency' && currentClient.id !== client.id;

  const {data: {memberships = []} = {}} = useReduxFetch({
    action: apiAction,
    actionArgs: {
      url: API_METHODS.MEMBERSHIPS,
      queryParams: getMembershipsQueryParams(client.id),
    },
    skip: !shouldAttribute,
  });

  const broadcasts = useMemo(
    () => unionBroadcasts(Object.values(formValues.templates || {}).filter(Boolean)),
    [formValues.templates],
  );

  const commonLanguages = useMemo(() => {
    const filteredLangs = filterTemplatesByLangs(
      Object.values(formValues.templates || {})
        .flat()
        .filter(Boolean),
    );
    const langsWithLabels = filteredLangs.map((key) => ({key, label: langStep.LANGUAGES[key.toUpperCase()]}));
    return sortBy(langsWithLabels, 'label').map((i) => i.key);
  }, [formValues.templates, langStep.LANGUAGES]);

  useMount(() => {
    // initialize languages and default language fields
    if (commonLanguages.length) {
      const initialDefaultLanguage = getInitialDefaultLanguage(commonLanguages, user.locale);
      updateFormState('languages', [initialDefaultLanguage]);
      updateFormState('default_language', initialDefaultLanguage);
    }

    updateFormState('online', broadcasts.online);
    updateFormState('device_horizontal', broadcasts.horizontal);
    updateFormState('device_vertical', broadcasts.vertical);
  });

  useReduxFormValidation(
    {
      formName: DiyOperationModalFormName,
      validate: () => validate(formValues, {lang: langStep, shouldAttribute}),
    },
    [formValues],
  );

  const languageOptions = useMemo(() => {
    return (
      sortBy(
        formValues.languages?.map((language) => ({
          label: (
            <span className={b('language-label')}>
              {langStep.LANGUAGES[language.toUpperCase()]}
              <Icon name={`flag-${language}`} className={b('flag-icon')} />
            </span>
          ),
          value: language,
        })),
        'label',
      ) || []
    );
  }, [langStep, formValues.languages]);

  if (!formValues) {
    return null;
  }

  const getScreenFormats = () => {
    const result = formValues.online ? [SCREEN_FORMATS.phone, SCREEN_FORMATS.desktop] : [];

    if (formValues.device_horizontal) {
      result.push(SCREEN_FORMATS.kiosk_horizontal);
    }

    if (formValues.device_vertical) {
      result.push(SCREEN_FORMATS.kiosk_vertical);
    }

    return result;
  };

  const handleFinishButtonClick = () => {
    const templateIds = Object.values(formValues.templates)
      .filter(Boolean)
      .map((template) => template.id);

    onFinish({
      name: formValues.name,
      code: `DIY_${client?.name.substring(0, 5)}_${uid().substring(0, 6)}`,
      default_language: formValues.default_language,
      languages: formValues.languages,
      from: transformDate(formValues.from, false),
      to: transformDate(formValues.to, false),
      device: formValues.device_horizontal || formValues.device_vertical,
      online: formValues.online,
      template_ids: templateIds,
      client_id: String(client?.id),
      client_user_id: formValues.client_user_id || getUserId(),
      status: 'active',
      screen_formats: getScreenFormats(),
    });
  };

  const handleLanguagesChange = (event) => {
    const {name, checked} = event.target;

    if (checked) {
      updateFormState('languages', [...formValues.languages, name]);
    } else {
      updateFormState(
        'languages',
        formValues.languages.filter((language) => language !== name),
      );
    }
  };

  return (
    <Fragment>
      <h3 className={b('title')}>{langStep.TITLE}</h3>
      <div className={cn(b('content'), className)}>
        <TextField name="name" label={langStep.NAME_LABEL} className={b('field', ['name'])} required={true} />

        <div className={b('templates')}>
          {Object.entries(formValues.templates || {}).map(([step, template]) => {
            const placeholder = lang.STEPS[step.toUpperCase()].NO_STEP;

            return (
              <TemplateCard
                size="small"
                key={step}
                className={b('card')}
                placeholder={placeholder}
                name={template?.title}
                image={template?.icon_url}
                info={<OperationTemplatePopoverContent template={template} />}
              />
            );
          })}
        </div>

        <div className={b('parameters')}>
          <div className={b('row')}>
            <div className={b('controls-wrapper')}>
              <DatepickerField
                name="from"
                label={langStep.FROM_LABEL}
                className={b('datepicker')}
                disabledDayAfter={addDay(formValues.to, -1)}
                required={true}
              />
              <DatepickerField
                name="to"
                label={langStep.TO_LABEL}
                className={b('datepicker')}
                disabledDayBefore={addDay(formValues.from, 1)}
                required={true}
              />
            </div>

            <div className={b('controls-wrapper')}>
              <div>
                {broadcasts.online && (
                  <div className={b('broadcast')}>
                    <span className={b('broadcast-title')}>{langStep.ONLINE_LABEL}</span>
                    <div className={b('broadcast-body')}>
                      <CheckboxField
                        color="primary"
                        inversionColor
                        name="online"
                        label={langStep.MOBILE_DESKTOP}
                        labelIconName="mobile-desktop"
                      />
                    </div>
                  </div>
                )}
                {(broadcasts.horizontal || broadcasts.vertical) && (
                  <div className={b('broadcast')}>
                    <span className={b('broadcast-title')}>{langStep.DEVICES_LABEL}</span>
                    <div className={b('broadcast-body')}>
                      {broadcasts.vertical && (
                        <CheckboxField
                          color="primary"
                          inversionColor
                          name="device_vertical"
                          label={langStep.VERTICAL}
                          labelIconName="kiosk-vertical"
                        />
                      )}
                      {broadcasts.horizontal && (
                        <CheckboxField
                          color="primary"
                          inversionColor
                          name="device_horizontal"
                          label={langStep.HORIZONTAL}
                          labelIconName="kiosk-horizontal"
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className={b('row')}>
            <div>
              <SelectField
                name="default_language"
                label={langStep.LANGUAGE_LABEL}
                options={languageOptions}
                className={b('default-language')}
                normalize={(option) => option.value}
              />
              {shouldAttribute && (
                <SelectField
                  className={b('attributed')}
                  name="client_user_id"
                  label={langStep.ATTRIBUTED_TO}
                  options={memberships.map((i) => ({
                    value: i.client_user_id,
                    label: i.client_user_full_name,
                  }))}
                  simpleValue
                  searchable
                />
              )}
            </div>
            <div>
              <p className={b('languages-title')}>{langStep.LANGUAGES_TITLE}</p>
              <div className={b('languages-container')}>
                {commonLanguages.map((language) => (
                  <CheckboxInput
                    key={language}
                    color="primary"
                    inversionColor
                    checked={formValues.languages?.includes(language)}
                    onChange={handleLanguagesChange}
                    name={language}
                    disabled={formValues.default_language === language}
                    label={
                      <div className={b('language-label')}>
                        <Icon name={`flag-${language}`} className={b('flag-icon')} />
                        <span>{langStep.LANGUAGES[language.toUpperCase()]}</span>
                      </div>
                    }
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DiyOperationModalFooter
        onFinish={handleFinishButtonClick}
        onReturn={goToPrevStep}
        isProceedButtonDisabled={!isFormValid}
        loading={loading}
      />
    </Fragment>
  );
};

DiyOperationSummaryStep.defaultProps = {
  className: '',
  isFormValid: false,
  loading: false,
};

DiyOperationSummaryStep.propTypes = {
  onFinish: PropTypes.func.isRequired,
  goToPrevStep: PropTypes.func.isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  client: PropTypes.object.isRequired,

  // redux form
  updateFormState: PropTypes.func.isRequired,
  isFormValid: PropTypes.bool,
};

export default DiyOperationSummaryStep;
