import {generateColor} from 'client/services/helpers';

import {pieCartColors, statusesChartColors} from 'client/ducks/results-graphs/constants';

const getColor = (key, index) => {
  if (Object.keys(statusesChartColors).includes(key)) {
    return statusesChartColors[key];
  }

  return pieCartColors[index] || generateColor();
};

export const getDonutChartData = (values, lang) =>
  Object.entries(values).map(([key, val], index) => ({
    label: lang[key] || key,
    value: val,
    color: getColor(key, index),
  }));
