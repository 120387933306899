import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, change, touch} from 'redux-form';

import fieldTemplate from 'client/components/common/field';

import {
  getClientsAction,
  getRegionsAction,
  toggleSubmittedAction,
} from '../../../../../ducks/link-to-client-form/actions';

let LANGUAGE = {};

class LinkToClientForm extends Component {
  static validate({client}) {
    let errors = {};

    if (!client || !client.id) {
      errors.client = LANGUAGE.ADD_PLACE_MODAL_REQUIRED_FIELD_ERROR;
    }

    return errors;
  }

  static updateSyncErrors(values) {
    return {
      type: '@@redux-form/UPDATE_SYNC_ERRORS',
      meta: {
        form: 'linkToClientForm',
      },
      payload: {
        syncErrors: LinkToClientForm.validate(values),
      },
    };
  }

  componentDidMount() {
    const {initialValues, getRegions, updateErrors} = this.props;
    this.props.getClients();
    if (this.props.isStore) {
      getRegions(initialValues.client_id);
      updateErrors({});
    } else if (initialValues.client && initialValues.client.id) {
      getRegions(initialValues.client.id);
      this.props.initialize(initialValues);
      updateErrors(initialValues);
    } else {
      getRegions();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.linkToClientForm.submitted && !prevProps.linkToClientForm.submitted) {
      const toTouch = Object.keys(this.props.formState.syncErrors);
      this.props.touchField('linkToClientForm', ...toTouch);
      this.props.toggleSubmitted();
    }
  }

  render() {
    const {clients, regions} = this.props.linkToClientForm;
    const {
      lang,
      getRegions,
      changeFieldValue,
      isStore,
      initialValues,
      onDetachRegionButtonClick,
      onUnlinkClientButtonClick,
    } = this.props;

    const regionsDisabled = !isStore && regions.length === 0;

    return (
      <form>
        <div className={isStore ? 'places__row--hidden' : 'places__row'}>
          <Field
            name="client"
            label={lang.ADD_PLACE_MODAL_CLIENT_LABEL}
            type="select"
            searchable={true}
            options={clients}
            valueKey="id"
            labelKey="name"
            placeholder=""
            onChange={({id}) => {
              changeFieldValue('region', null);
              getRegions(id);
            }}
            component={fieldTemplate}
          />
        </div>
        <div className="places__row">
          <Field
            name="region"
            label={lang.ADD_PLACE_MODAL_REGION_LABEL}
            type="select"
            searchable={true}
            options={regions}
            disabled={regionsDisabled}
            valueKey="id"
            labelKey="name"
            placeholder=""
            component={fieldTemplate}
          />
        </div>
        <div className={isStore && initialValues.region ? 'places__row__button' : 'places__row--hidden'}>
          <button type="button" className="button button--bg-3" onClick={onDetachRegionButtonClick}>
            {lang.DETACH_REGION_BUTTON}
          </button>
        </div>
        <div className={!isStore && initialValues.client ? 'places__row__button' : 'places__row--hidden'}>
          <button type="button" className="button button--bg-10" onClick={onUnlinkClientButtonClick}>
            {lang.UNLINK_CLIENT_BUTTON}
          </button>
        </div>
      </form>
    );
  }
}

LinkToClientForm.propTypes = {
  formState: PropTypes.object,
  initialValues: PropTypes.object,
  getClients: PropTypes.func,
  getRegions: PropTypes.func,
  changeFieldValue: PropTypes.func,
  touchField: PropTypes.func,
  toggleSubmitted: PropTypes.func,
  onDetachRegionButtonClick: PropTypes.func,
  onUnlinkClientButtonClick: PropTypes.func,
  updateErrors: PropTypes.func,
  initialize: PropTypes.func,
  isStore: PropTypes.bool,
  linkToClientForm: PropTypes.shape({
    clients: PropTypes.array,
    regions: PropTypes.array,
    submitted: PropTypes.bool,
  }),
  lang: PropTypes.object.isRequired,
};

LinkToClientForm.defaultProps = {
  initialValues: {
    client: null,
    region: null,
  },
  isStore: false,
};

const linkToClientFormComponent = reduxForm({
  form: 'linkToClientForm',
  validate: LinkToClientForm.validate,
})(LinkToClientForm);

const mapStateToProps = ({languageState, linkToClientForm, form}) => ({
  lang: languageState.payload.PLACES,
  linkToClientForm,
  formState: form.linkToClientForm,
});

const mapDispatchToProps = {
  getClients: getClientsAction,
  getRegions: getRegionsAction,
  changeFieldValue: (field, value) => change('linkToClientForm', field, value),
  touchField: touch,
  toggleSubmitted: toggleSubmittedAction,
  updateErrors: LinkToClientForm.updateSyncErrors,
};

export default connect(mapStateToProps, mapDispatchToProps)(linkToClientFormComponent);
