import React, {useCallback} from 'react';

import PropTypes from 'prop-types';
import {SortableContainer} from 'react-sortable-hoc';

import bem from 'client/services/bem';

import {headers} from 'client/components/games/game-config-modal/prizes-table/helpers';
import PrizesTableRow from 'client/components/games/game-config-modal/prizes-table/prizes-table-row';

const b = bem('prizes-table');

const PrizesTableContainer = SortableContainer(
  ({prizes, lang, onSelectPrize, onDelete, onActivate, isPrizeMap, disabledActions, disabledActivation}) => {
    const emptyHandler = useCallback(() => {}, []);

    return (
      <div className={b('content')}>
        <div className={b('row', ['header'])}>
          {headers(lang, isPrizeMap).map((header) => (
            <div className={b('col', ['header', header.key])} key={header.key}>
              {header.title}
            </div>
          ))}
        </div>

        {prizes
          .sort((aEl, bEl) => aEl.order - bEl.order)
          .map((row, index) => (
            <PrizesTableRow
              disabled={disabledActions}
              disabledActivation={disabledActivation}
              isPrizeMap={isPrizeMap}
              row={row}
              onDelete={disabledActions ? emptyHandler : onDelete}
              onActivate={disabledActions ? emptyHandler : onActivate}
              lang={lang}
              key={row.id}
              index={index}
              order={index + 1}
              onSelectPrize={disabledActions ? emptyHandler : onSelectPrize}
            />
          ))}
      </div>
    );
  },
);

PrizesTableContainer.propTypes = {
  lang: PropTypes.object.isRequired,
  prizes: PropTypes.array.isRequired,
  onSortEnd: PropTypes.func.isRequired,
  onSelectPrize: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onActivate: PropTypes.func.isRequired,
  isPrizeMap: PropTypes.bool.isRequired,
  disabledActions: PropTypes.bool.isRequired,
  disabledActivation: PropTypes.bool,
};

export default PrizesTableContainer;
