import React, {Component} from 'react';

import cn from 'classnames';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import bem from 'client/services/bem';

import {checkCampaignSourcesSelector} from 'client/ducks/participations-statistics/selectors';
import {selectClientUserMembership} from 'client/ducks/user-clients/selectors';

import {CLIENT_PAGES, USER_MEMBERSHIP} from 'client/common/config';
import Popover from 'client/common/popovers/popover';
import Dropdown from 'client/common/selects/dropdown';

import CustomLink from 'client/components/common/custom-link';

import menuConfig from './menuConfig';

import './client-autotask-menu.scss';

const b = bem('client-autotask-menu');

class ClientAutotaskMenu extends Component {
  static propTypes = {
    autotask: PropTypes.object.isRequired,
    operation: PropTypes.shape({
      id: PropTypes.number.isRequired,
      client_id: PropTypes.number.isRequired,
      client_user_id: PropTypes.number,
      editable: PropTypes.bool,
    }).isRequired,
    activeTab: PropTypes.string.isRequired,
    lang: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    accessLevel: PropTypes.string.isRequired,
    isCampaignTabBlocked: PropTypes.bool,
    currentMembership: PropTypes.object,
  };

  static defaultProps = {
    currentMembership: null,
  };

  tabs = CLIENT_PAGES.CLIENT_AUTOTASK_TABS;

  goToTab = (tab) => {
    const {autotask, operation, history} = this.props;

    const link = CustomLink.createClientAutotaskTabLink({
      clientId: operation.client_id,
      operationId: operation.id,
      autotaskId: autotask.id,
      tab,
    });

    history.push(link);
  };

  renderDisabledMenuItem = (item) => {
    const {lang} = this.props;

    return (
      <Popover
        key={item.tab}
        position="bottom"
        className={b('item', {disabled: true})}
        shiftTop={15}
        overlay={lang.MENU_HINTS[item.langKey]}
      >
        {lang.MENU_ITEMS[item.langKey]}
      </Popover>
    );
  };

  renderSubMenu = (item) => {
    const {lang} = this.props;
    const active = !!find(item.subItems, {tab: this.props.activeTab});

    return (
      <Dropdown
        key={item.langKey}
        className={cn(b('submenu'), b('item', {active}))}
        trigger={<span className={b('submenu-name', {active})}>{lang.MENU_ITEMS[item.langKey]}</span>}
        options={item.subItems.map((subItem) => {
          return {
            key: subItem.langKey,
            onClick: () => this.goToTab(subItem.tab),
            label: <div className={b('submenu-item')}>{this.renderMenuItem(subItem, false)}</div>,
          };
        })}
        position="bottom"
        showToggleArrow
      />
    );
  };

  renderMenuItem = (item, handleClick = true) => {
    const {lang} = this.props;

    if (item.subItems) {
      return this.renderSubMenu(item);
    }

    if (this.isMenuItemDisabled(item.tab)) {
      return this.renderDisabledMenuItem(item);
    }

    const active = item.tab === this.props.activeTab;

    return (
      <div key={item.langKey} onClick={() => handleClick && this.goToTab(item.tab)} className={b('item', {active})}>
        {lang.MENU_ITEMS[item.langKey]}
      </div>
    );
  };

  getMenuItems = () => {
    const {accessLevel, currentMembership} = this.props;
    const access = currentMembership?.data_tab_access || {};
    const hasDataAccess = access.participations || access.leads || access.winners;
    const isClientAdmin = accessLevel === USER_MEMBERSHIP.CLIENT_ADMIN;
    const result = [];

    menuConfig.forEach((item) => {
      if (!item.check || isClientAdmin) {
        result.push(item);
      } else if (item.check === 'data_tab_access' && hasDataAccess) {
        const newItem = {
          ...item,
          subItems: item.subItems.filter((subItem) => access[subItem.check]),
        };
        result.push(newItem);
      }
    });

    return result;
  };

  isMenuItemDisabled(name) {
    const {autotask, operation} = this.props;

    switch (name) {
      case this.tabs.CAMPAIGNS:
        return this.props.isCampaignTabBlocked;
      case this.tabs.PLANS:
        return !autotask.contains_offline_interactions;
      case this.tabs.VISUALS:
        return autotask.client_visuals_count < 1;
      case this.tabs.RESULTS:
        return autotask.visible_diagrams_count < 1;
      case this.tabs.CONFIGURATION:
        return !operation.show_configuration || operation.client_automation_task_id !== autotask.id;
      default:
        return false;
    }
  }

  render() {
    return <div className={b()}>{this.getMenuItems().map((item) => this.renderMenuItem(item))}</div>;
  }
}

export default withRouter(
  connect((state) => ({
    lang: state.languageState.payload.CLIENT_AUTOTASK_MENU,
    isCampaignTabBlocked: checkCampaignSourcesSelector(state),
    currentMembership: selectClientUserMembership(state),
  }))(ClientAutotaskMenu),
);
