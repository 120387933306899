import React, {useEffect, useCallback} from 'react';

import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {useToggle, useIdle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {setWinningHeaderData} from 'client/ducks/prize-distribution/actions';
import {selectWinningHeaderData} from 'client/ducks/prize-distribution/selectors';
import {logout} from 'client/ducks/user/actions';

import AppButton from 'client/common/buttons/app-button';
import {WINNING_PAGES, WINNING_IDLE_TIME} from 'client/common/config';
import Icon from 'client/common/icon';
import ConfirmationModal from 'client/common/modals/confirmation-modal';

import WinningBackButton from 'client/components/winnings/buttons/winning-back-button';

import cssModule from './winning-header.module.scss';

const b = bem('winning-header', {cssModule});

type WinningHeaderProps = {
  isMobile?: boolean;
  isUserLogged?: boolean;
};

const WinningHeader: React.FC<WinningHeaderProps> = (props) => {
  const {isMobile = false, isUserLogged = false} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('WINNINGS.VARIOUS.WINNING_HEADER');
  const history = useHistory();
  const data = useSelector(selectWinningHeaderData);
  const [showLogoutModal, toggleLogoutModal] = useToggle(false);
  const isIdle = useIdle(WINNING_IDLE_TIME);

  useEffect(() => {
    if (data.path === history.location.pathname) {
      dispatch(setWinningHeaderData({}));
    }
  }, [data.path, dispatch, history.location.pathname]);

  const handleLogout = useCallback(async () => {
    await dispatch(logout('winning'));
    history.push(WINNING_PAGES.LOGIN);
    location.reload();
  }, [dispatch, history]);

  useEffect(() => {
    if (isIdle && isUserLogged) {
      handleLogout();
    }
  }, [handleLogout, isIdle, isUserLogged]);

  const logoutIconSize = isMobile ? 24 : 16;

  return (
    <div className={b({mobile: isMobile && isUserLogged})}>
      {isMobile && (data.icon || data.label) ? (
        <WinningBackButton {...data} />
      ) : (
        <div className={b('brief')}>
          <Icon name="weezio-logo-colored" />
          <span className={b('brief-title')}>{lang.BRIEF_TITLE}</span>
        </div>
      )}
      {isUserLogged && (
        <div className={b('logout-wrap')}>
          <AppButton
            onClick={() => toggleLogoutModal()}
            label={
              <div className={b('logout')}>
                <Icon className={b('logout-icon')} name="logout" height={logoutIconSize} width={logoutIconSize} />
                {!isMobile && <span className={b('logout-label')}>{lang.LOGOUT}</span>}
              </div>
            }
            asWrap
          />
        </div>
      )}
      <ConfirmationModal
        show={showLogoutModal}
        className={b('logout-modal')}
        classNames={{
          body: b('logout-modal-body'),
        }}
        title={lang.LOGOUT_CONFIRMATION.TITLE}
        message={lang.LOGOUT_CONFIRMATION.MESSAGE}
        cancelText={lang.LOGOUT_CONFIRMATION.CANCEL}
        confirmText={lang.LOGOUT_CONFIRMATION.CONFIRM}
        onCancel={toggleLogoutModal}
        onConfirm={handleLogout}
        clientSide
      />
    </div>
  );
};

export default WinningHeader;
