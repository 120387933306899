import {blobToBase64} from 'client/services/blobToBase64';

export default async (values, createTicket) => {
  if (!createTicket) {
    return {visual_type: 'none'};
  }

  let body = {};

  await Promise.all(
    Object.entries(values || {}).map(async ([key, value]) => {
      if (value) {
        if (typeof value !== 'string' || key.startsWith('visual_type')) {
          const toSave = key.startsWith('visual_type') ? value : {data: await blobToBase64(value)};
          body = {
            ...body,
            [key]: toSave,
          };
        }
      }
    }),
  );

  return body;
};
