import types from './types';

const initialState = {
  animations: {
    data: [],
    meta: {
      total_pages: 1,
    },
  },
  animation: null,
  anomalyOccurrences: [],
  statistics: [],
  animation_kpis: {
    data: [],
    meta: {
      total_pages: 1,
    },
  },
  photos: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_ANIMATIONS_SUCCESS:
      return {
        ...state,
        animations: {
          data: action?.payload?.instore_animations || initialState.animations.data,
          meta: action?.payload?.meta || initialState.animations.meta,
        },
      };
    case types.GET_ANIMATIONS_ERROR:
      return {
        ...state,
        animations: initialState.animations,
      };
    case types.GET_ANIMATION_KPIS_SUCCESS:
      const kpiData = action?.payload?.instore_animation_kpis || initialState.animation_kpis.data;
      return {
        ...state,
        animation_kpis: {
          data: action?.payload?.meta ? [...state.animation_kpis.data, ...kpiData] : kpiData,
          meta: action?.payload?.meta || initialState.animation_kpis.meta,
        },
      };

    case types.GET_ANIMATION_SUCCESS:
      return {
        ...state,
        animation: action?.payload?.instore_animation || initialState.animation,
      };

    case types.GET_ANOMALY_OCCURRENCES_SUCCESS:
      return {
        ...state,
        anomalyOccurrences: action?.payload?.instore_anomaly_occurrences || initialState.anomalyOccurrences,
      };

    case types.GET_ANIMATIONS_STATISTICS_SUCCESS:
      return {
        ...state,
        statistics: Object.entries(action?.payload).map(([key, val]) => ({[key]: val})),
      };

    case types.GET_ANIMATION_PHOTOS_SUCCESS:
      return {
        ...state,
        photos: action?.payload?.instore_animation_photos || initialState.photos,
      };

    case types.UPDATE_ANIMATION_DETAILS_SUCCESS:
      const animationIndex = state.animations.data.findIndex(({id}) => id === action.payload.instore_animation?.id);
      state.animations.data[animationIndex] = {
        ...(state.animations.data[animationIndex] || {}),
        ...(action?.payload.instore_animation || {}),
      };

      return {
        ...state,
      };

    case types.CHANGE_CONTACT_INFO_SUCCESS:
      const foundIndex = state.animations.data.findIndex(({id}) => id === action.payload.instore_animation?.id);
      state.animations.data[foundIndex] = {
        ...(state.animations.data[foundIndex] || {}),
        ...(action?.payload.instore_animation || {}),
      };

      return {
        ...state,
      };

    default:
      return state;
  }
}
