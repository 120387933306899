import {Translation} from 'client/models/language/types';
import {PRIZE_TYPES} from 'client/models/prizes/constants';

import {MainParamsValues} from './types';

export default (values: MainParamsValues, lang: Record<string, Translation>) => {
  const errors: MainParamsValues = {type: '', loyalty_amount: ''};

  if (values.type === PRIZE_TYPES.LOYALTY && !values.loyalty_amount) {
    errors.loyalty_amount = lang.REQUIRED;
  }

  return errors;
};
