import {COMMON_INPUT} from 'client/components/prizes/constants';
import {PRIZE_TYPES} from 'client/components/prizes/constants';

export default (values) => {
  const external_name = values.prize_names[values.language] || Object.values(values.prize_names).filter((i) => i)[0];

  const translations = Object.keys(values.prize_names).reduce(
    (result, key) => ({
      ...result,
      [`external_name_${key}`]: values.prize_names[key],
    }),
    {},
  );

  return {
    type: values.type === PRIZE_TYPES.COMMON ? null : values.type,
    email_send: values.email_send,
    sms_send: values.sms_send,
    ticket_print: values.create_ticket,
    external_name,
    internal_name: values.internal_name || external_name,
    loyalty_coupon_type: values.loyalty_coupon_type,
    level: values.level,
    distribution: values.distribution,
    loyalty_only: values.loyalty_only,
    language_unique_mode: values.language === COMMON_INPUT,
    ...translations,
    ...(values.type === PRIZE_TYPES.LOYALTY && {
      loyalty_unit: values.loyalty_unit,
      loyalty_amount: values.loyalty_amount,
    }),
  };
};
