import React, {useMemo, useState, Fragment, memo, useCallback, useEffect} from 'react';

import cn from 'classnames';
import {get} from 'lodash';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {getFormValues} from 'redux-form';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {selectGroupedClientTemplatesByTabs} from 'client/ducks/templates/selectors';

import Icon from 'client/common/icon';
import TabSwitcher from 'client/common/tab-switcher';

import TemplateCard from 'client/components/common/cards/template-card';
import CustomScrollbars from 'client/components/common/custom-scrollbars';

import DiyOperationModalFooter from 'client/components/operations-client/components/modals/diy-operation-modal/diy-operation-modal-footer';
import {DiyOperationModalFormName} from 'client/components/operations-client/components/modals/diy-operation-modal/diy-operation-modal-form';
import {
  mapBroadcasts,
  filterTemplatesByLangs,
  STEPS,
  unionBroadcasts,
} from 'client/components/operations-client/components/modals/diy-operation-modal/helpers';
import {CATALOG_TEMPLATE_TABS} from 'client/models/templates/constants';

import {OperationTemplatePopoverContent} from './operation-template-popover-content';

import './diy-operation-template-step.scss';

const b = bem('diy-operation-template-step');

const getDefaultActiveTab = (groupedTemplates) => {
  if (groupedTemplates[CATALOG_TEMPLATE_TABS.MY_TEMPLATES]?.length) {
    return CATALOG_TEMPLATE_TABS.MY_TEMPLATES;
  } else if (groupedTemplates[CATALOG_TEMPLATE_TABS.WEEZIO]?.length) {
    return CATALOG_TEMPLATE_TABS.WEEZIO;
  } else if (groupedTemplates[CATALOG_TEMPLATE_TABS.SHARED]?.length) {
    return CATALOG_TEMPLATE_TABS.SHARED;
  }
  return CATALOG_TEMPLATE_TABS.WEEZIO;
};

const DiyOperationTemplateStep = (props) => {
  const {updateFormState, className, setSteps, currentStep, goToNextStep, goToPrevStep, prevStep} = props;

  const {templates: templatesValues = {}} = useSelector(getFormValues(DiyOperationModalFormName));

  const lang = useLanguage('OPERATIONS.DIY_OPERATION_MODAL');
  const langStep = lang.STEPS[currentStep.toUpperCase()];

  const groupedTemplates = useSelector(selectGroupedClientTemplatesByTabs(currentStep));
  const [activeTab, setActiveTab] = useState(getDefaultActiveTab(groupedTemplates));

  useEffect(() => {
    setActiveTab(getDefaultActiveTab(groupedTemplates));

    // because of changing of groupedTemplates setActive is called every time,
    // so it's not possible to set activeTab at the right moment
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const setTemplate = (template) => {
    updateFormState(`templates.${currentStep}`, template);

    const modules = get(template, 'catalog.catalog_modules', []);
    if (currentStep === STEPS.GENERAL) {
      setSteps([STEPS.GENERAL, ...modules, STEPS.SUMMARY]);
    }
  };

  const handleSkipButtonClick = () => {
    setTemplate(null);
    goToNextStep();
  };

  // General step has no "back" button as it is the first step
  const handleReturnButtonClick = currentStep !== STEPS.GENERAL ? goToPrevStep : null;

  const selectedTemplate = templatesValues[currentStep];
  const prevSelectedTemplate = templatesValues[prevStep];
  const filterTemplates = useCallback(
    (template) => {
      const prevBroadcasts = mapBroadcasts(prevSelectedTemplate?.image_formats || []);
      return (
        !prevSelectedTemplate ||
        Object.entries(mapBroadcasts(template.image_formats)).some(([level, value]) => value && prevBroadcasts[level])
      );
    },
    [prevSelectedTemplate],
  );

  const tabs = useMemo(() => {
    return [
      {
        title: lang.TAB_WEEZIO_LABEL,
        value: CATALOG_TEMPLATE_TABS.WEEZIO,
        exclude: !groupedTemplates[CATALOG_TEMPLATE_TABS.WEEZIO]?.filter(filterTemplates).length,
      },
      {
        title: lang.TAB_SHARED_LABEL,
        value: CATALOG_TEMPLATE_TABS.SHARED,
        exclude: !groupedTemplates[CATALOG_TEMPLATE_TABS.SHARED]?.filter(filterTemplates).length,
      },
      {
        title: lang.TAB_MY_TEMPLATES_LABEL,
        value: CATALOG_TEMPLATE_TABS.MY_TEMPLATES,
        exclude: !groupedTemplates[CATALOG_TEMPLATE_TABS.MY_TEMPLATES]?.filter(filterTemplates).length,
      },
    ];
  }, [filterTemplates, groupedTemplates, lang.TAB_MY_TEMPLATES_LABEL, lang.TAB_SHARED_LABEL, lang.TAB_WEEZIO_LABEL]);

  const commonLanguages = useMemo(() => {
    return filterTemplatesByLangs(
      Object.values(templatesValues || {})
        .flat()
        .filter(Boolean),
    );
  }, [templatesValues]);

  const commonBroadcasts = useMemo(
    () => unionBroadcasts(Object.values(templatesValues).filter(Boolean)),
    [templatesValues],
  );

  const filteredTemplates =
    currentStep === STEPS.GENERAL
      ? groupedTemplates[activeTab]
      : groupedTemplates[activeTab].filter(
          (template) =>
            template.languages?.some((lng) => commonLanguages?.includes(lng)) &&
            Object.entries(mapBroadcasts(template.image_formats)).some(
              ([key, value]) => value && commonBroadcasts[key],
            ),
        );

  return (
    <Fragment>
      <h3 className={b('title')}>{langStep.TITLE}</h3>

      <TabSwitcher tabs={tabs} activeTab={activeTab} onTabClick={setActiveTab} className={b('tabs')} />

      <CustomScrollbars
        scrollbarProps={{
          autoHeightMin: 500,
          autoHeightMax: 500,
        }}
        cssModifier={cn(b('scrollbar'), className)}
      >
        {filteredTemplates?.length > 0 ? (
          <div className={b('cards')}>
            {filteredTemplates?.map((template) => {
              const {title, icon_url, id} = template;

              const isSelected = selectedTemplate?.id === id;

              return (
                <TemplateCard
                  key={id}
                  className={b('card')}
                  name={title}
                  image={icon_url}
                  info={<OperationTemplatePopoverContent template={template} />}
                  onClick={() => setTemplate(template)}
                  isSelected={isSelected}
                />
              );
            })}
          </div>
        ) : (
          <div className={b('placeholder')}>
            <Icon name="tiles" className={b('placeholder-icon')} />
            <span>{lang.PLACEHOLDER}</span>
          </div>
        )}
      </CustomScrollbars>

      <DiyOperationModalFooter
        skipButtonLabel={langStep.NO_STEP}
        onSkip={handleSkipButtonClick}
        isProceedButtonDisabled={!selectedTemplate}
        onReturn={handleReturnButtonClick}
        onProceed={goToNextStep}
      />
    </Fragment>
  );
};

DiyOperationTemplateStep.defaultProps = {
  className: '',
};

DiyOperationTemplateStep.propTypes = {
  currentStep: PropTypes.oneOf([STEPS.GENERAL, STEPS.FORM, STEPS.QUIZ, STEPS.GAME, STEPS.SUMMARY]).isRequired,
  updateFormState: PropTypes.func.isRequired,
  setSteps: PropTypes.func.isRequired,
  goToNextStep: PropTypes.func.isRequired,
  goToPrevStep: PropTypes.func.isRequired,
  className: PropTypes.string,
  prevStep: PropTypes.string,
};

export default memo(DiyOperationTemplateStep);
