import React, {Component} from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {required} from 'client/services/validator';

import {getAllAdmins} from 'client/ducks/admin-users/actions';
import {selectAllAdmins} from 'client/ducks/admin-users/selectors';
import {patchAutotask} from 'client/ducks/autotask/actions';

import DatepickerField from 'client/common/fields/datepicker-field';
import SelectField from 'client/common/fields/select-field';
// components
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import ToggleField from 'client/components/common/fields/toggle-field';
import Icon from 'client/components/common/icon';

import getInitialValues from './initialValues';

import './at-settings-modal.scss';

class AtSettingsModal extends Component {
  static propTypes = {
    children: PropTypes.any,
    lang: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    fetchAutotask: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    patchAutotask: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    autotask: PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      archived: PropTypes.bool.isRequired,
      api_key: PropTypes.string.isRequired,
      participations_count: PropTypes.number.isRequired,
      custom_prize_type_names: PropTypes.array,
      validation_limit: PropTypes.string,
      csm_user_id: PropTypes.number,
    }).isRequired,
    getAllAdmins: PropTypes.func.isRequired,
    admins: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        full_name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    client: PropTypes.shape({
      auto_configuration: PropTypes.bool.isRequired,
    }).isRequired,
  };

  static defaultProps = {
    children: null,
  };

  static formName = 'AtSettingsPopoverForm';

  constructor(props) {
    super(props);

    this.rules = {
      name: required(this.props.lang.ERRORS.NAME_IS_REQUIRED),
    };
  }

  componentDidMount() {
    if (this.props.client.auto_configuration) {
      this.props.getAllAdmins();
    }
  }

  save = (values) => {
    const {autotask} = this.props;
    const winConfig = autotask.custom_prize_type_names || [];

    const win1 = find(winConfig, {prize_type_number: 1}) || {};
    const win2 = find(winConfig, {prize_type_number: 2}) || {};
    const win3 = find(winConfig, {prize_type_number: 3}) || {};

    const wins = [
      {
        id: win1.id,
        prize_type_number: 1,
        custom_name: values.win1_name,
        validation_required: values.win1_validation,
      },
      {
        id: win2.id,
        prize_type_number: 2,
        custom_name: values.win2_name,
        validation_required: values.win2_validation,
      },
      {
        id: win3.id,
        prize_type_number: 3,
        custom_name: values.win3_name,
        validation_required: values.win3_validation,
      },
    ].filter((item) => item.id || item.custom_name || item.validation_required);

    wins.forEach((item) => {
      if (item.id && !item.custom_name && !item.validation_required) {
        item._destroy = true;
      }
    });

    const data = {
      automation_task: {
        custom_prize_type_names: wins,
        name: values.name,
        validation_limit: values.validation_limit,
        csm_user_id: values.csm_user_id,
      },
    };

    return this.props.patchAutotask(autotask.id, data).then(({payload: {response: {errors} = {}}}) => {
      if (errors) {
        throw new SubmissionError({name: this.LANGUAGE.ERRORS.NAME_IS_TAKEN});
      }

      this.props.fetchAutotask();
      this.props.onClose();
    });
  };

  render() {
    const {autotask, onDelete, onArchive, handleSubmit, lang, admins, client, onClose} = this.props;

    return (
      <Modal
        show={true}
        dialogClassName="at-settings-modal"
        backdropClassName="modal-window__backdrop"
        bodyClassName="at-settings-modal__body"
        onClose={onClose}
        title={lang.TITLE}
      >
        <form onSubmit={handleSubmit(this.save)}>
          <div className="main-text main-text--bold">
            <div className="at-settings-modal__block">
              <Field
                label={lang.NAME_LABEL}
                name="name"
                type="text"
                component={fieldTemplate}
                validate={this.rules.name}
              />
            </div>
          </div>
          <hr className="at-settings-modal__delimiter" />
          <div className="at-settings-modal__block">
            <div className="at-settings-modal__win">
              <Field label={lang.WIN_1} name="win1_name" type="text" component={fieldTemplate} />
              <ToggleField name="win1_validation" label={lang.VALIDATION} />
            </div>
          </div>
          <div className="at-settings-modal__block">
            <div className="at-settings-modal__win">
              <Field label={lang.WIN_2} name="win2_name" type="text" component={fieldTemplate} />
              <ToggleField name="win2_validation" label={lang.VALIDATION} />
            </div>
          </div>
          <div className="at-settings-modal__block">
            <div className="at-settings-modal__win">
              <Field label={lang.WIN_3} name="win3_name" type="text" component={fieldTemplate} />
              <ToggleField name="win3_validation" label={lang.VALIDATION} />
            </div>
          </div>
          <hr className="at-settings-modal__delimiter" />
          {client.auto_configuration && (
            <>
              <div className="at-settings-modal__block">
                <DatepickerField
                  label={lang.VALIDATION_LIMIT_DATE}
                  labelClassName="at-settings-modal__label"
                  inputClassName="at-settings-modal__input"
                  name="validation_limit"
                  disabledDayBefore={String(new Date())}
                  withWrap
                />
                <SelectField
                  label={lang.CSM}
                  name="csm_user_id"
                  options={admins.map(({id, full_name}) => ({value: id, label: full_name}))}
                  simpleValue
                  searchable
                  withWrap
                />
              </div>
              <hr className="at-settings-modal__delimiter" />
            </>
          )}
          <div className="main-text main-text--bold">
            <div className="input__label">{lang.API_KEY_LABEL}</div>
            <div className="at-settings-modal__block flex-container flex-align-center flex-justify-between">
              <span>{autotask.api_key}</span>
              <CopyToClipboard text={autotask.api_key}>
                <button className="button button--circle button--bg-13">
                  <Icon name="copy" />
                </button>
              </CopyToClipboard>
            </div>
          </div>
          <div className="at-settings-modal__links">
            <div className="link" onClick={onArchive}>
              {autotask.archived ? lang.RESTORE_LABEL : lang.ARCHIVE_LABEL}
            </div>
            {autotask.participations_count === 0 && (
              <div className="link link--color-1" onClick={onDelete}>
                {lang.DELETE_LABEL}
              </div>
            )}
          </div>
          <div className="at-settings-modal__buttons">
            <button onClick={onClose} className="button button--bg-11 at-settings-modal__button">
              {lang.CANCEL_BUTTON}
            </button>
            <button type="sudmit" className="button button--bg-13 at-settings-modal__button">
              {lang.SAVE_BUTTON}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

export default connect(
  (state, {autotask}) => ({
    lang: state.languageState.payload.AUTOTASK.SETTINGS_POPOVER,
    admins: selectAllAdmins(state),
    initialValues: getInitialValues(autotask),
  }),
  {
    patchAutotask,
    getAllAdmins,
  },
)(
  reduxForm({
    form: AtSettingsModal.formName,
  })(AtSettingsModal),
);
