import React, {useMemo} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {formatNumberSpace} from 'client/services/formatters';
import {useLanguage} from 'client/services/hooks';

import Popover from 'client/common/popovers/popover';

import ClientTable from 'client/components/common/client-table';

import {formatSegmentation} from './helpers';
import WinningsTableRateCell from './winnings-table-rate-cell';
import WinningsTableTypeCell from './winnings-table-type-cell';

import './winnigs-table.scss';

const b = bem('winnings-table');

const WinningsTable = (props) => {
  const {data, loading, leadsCount} = props;
  const lang = useLanguage('CLIENT_AUTOTASK.WINNINGS_TAB.WINNINGS_TABLE');

  const columns = useMemo(() => {
    return [
      {
        name: 'prize',
        label: lang.PRIZE,
        path: 'name',
      },
      {
        name: 'type',
        label: lang.TYPE,
        width: 120,
        render: ({item}) => <WinningsTableTypeCell item={item} />,
      },
      {
        name: 'segmentation',
        label: (
          <Popover overlay={lang.SEGMENTATION} position="top">
            {lang.SEGMENTATION_SHORT}
          </Popover>
        ),
        path: 'segmentation',
        width: 126,
        formatter: (value) => formatSegmentation(value, lang.SEGMENTATION_TYPES),
      },
      {
        name: 'initial_stock',
        label: lang.INITIAL_STOCK,
        path: 'initial_stock',
        headerClassName: b('head-right'),
        cellClassName: b('cell-right'),
        formatter: formatNumberSpace,
        width: 80,
        noWrap: true,
      },
      {
        name: 'prizes',
        label: lang.PRIZES,
        subcolumns: [
          {
            name: 'prize_won',
            label: lang.PRIZE_WON,
            path: 'prizes_won',
            headerClassName: b('head-right'),
            cellClassName: b('cell-right'),
            formatter: formatNumberSpace,
            width: 80,
            noWrap: true,
          },
          {
            name: 'prizes_distributed',
            path: 'prizes_distributed',
            label: (
              <Popover overlay={`${lang.DISTRIBUTION.toString()} (%)`} position="top">
                {`${lang.DISTRIBUTION_SHORT?.toString()} (%)`}
              </Popover>
            ),
            distribution: b('head-right'),
            cellClassName: b('cell-right'),
            render: ({value}) => leadsCount && Math.round((value / leadsCount) * 100),
            width: 80,
            noWrap: true,
          },
          {
            name: 'prize_in_stock',
            label: lang.PRIZE_IN_STOCK,
            path: 'prizes_on_stock',
            headerClassName: b('head-right'),
            cellClassName: cn(b('prize-in-stock'), b('cell-right')),
            formatter: formatNumberSpace,
            width: 80,
            noWrap: true,
          },
        ],
      },
      {
        name: 'total_wins',
        label: lang.TOTAL_WINS,
        path: 'total_wins',
        headerClassName: b('head-right'),
        cellClassName: cn(b('total-wins'), b('highlight-cell'), b('cell-right')),
        formatter: formatNumberSpace,
        width: 80,
        noWrap: true,
      },
      {
        name: 'wins_validated',
        label: lang.WINS_VALIDATED,
        subcolumns: [
          {
            name: 'wins_validated_yes',
            label: lang.YES,
            path: 'wins_validated',
            headerClassName: b('head-right'),
            cellClassName: cn(b('highlight-cell'), b('cell-right')),
            sortable: false,
            width: 80,
            formatter: formatNumberSpace,
            noWrap: true,
          },
          {
            name: 'wins_validated_no',
            label: lang.NO,
            path: 'wins_non_validated',
            headerClassName: b('head-right'),
            cellClassName: cn(b('highlight-cell'), b('cell-right')),
            sortable: false,
            width: 80,
            formatter: formatNumberSpace,
            noWrap: true,
          },
        ],
      },
      {
        name: 'wins_distributed',
        label: (
          <Popover overlay={`${lang.DISTRIBUTION.toString()} (%)`} position="top">
            {`${lang.DISTRIBUTION_SHORT?.toString()} (%)`}
          </Popover>
        ),
        path: 'wins_distributed',
        distribution: b('head-right'),
        cellClassName: b('cell-right'),
        render: ({value}) => leadsCount && Math.round((value / leadsCount) * 100),
        width: 80,
        noWrap: true,
      },
      {
        name: 'rate',
        label: lang.RATE,
        path: 'rate',
        width: 170,
        cellClassName: cn(b('rate'), b('highlight-cell')),
        render: WinningsTableRateCell,
      },
    ];
  }, [lang, leadsCount]);

  return (
    <ClientTable className={b()} commonHeaderClassName={b('th')} data={data} columns={columns} loading={loading} />
  );
};

WinningsTable.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  leadsCount: PropTypes.number.isRequired,
};

export default WinningsTable;
