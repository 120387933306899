export const sortData = (data, language) => {
  if (!data || !data.length) {
    return data;
  }

  const noDataLabelsCustom = ['Undefined', 'No data', 'None']; // if label is not translated
  const noDataLabels = [language.NO_DATA, language.UNKNOWN, language.NONE, ...noDataLabelsCustom];
  const results = data.filter((item) => !noDataLabels.includes(item.label));
  const noData = data.filter((item) => noDataLabels.includes(item.label) && item.value !== 0);

  return [...results, ...noData];
};

export const mapData = (data) => {
  return {
    labels: data.map(({label}) => label),
    values: data.map(({value}) => value),
    colors: data.map(({color}) => color),
    total: data.reduce((acc, {value}) => acc + value, 0),
  };
};
