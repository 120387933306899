import React from 'react';

import find from 'lodash/find';
import PropTypes from 'prop-types';

import {TextField, DropdownField, ToggleField} from 'client/components/common/fields';

import {GAME_TYPES} from '../constants';

import './game-header-block.scss';

const GameHeaderBlock = (props) => {
  const {lang, formValues, game, levels, isPrizeMap, levelKey} = props;

  const gameTypeOptions = [
    {value: GAME_TYPES.INSTANT_WIN, label: lang.INSTANT_WIN},
    {value: GAME_TYPES.PRIZE_DRAW, label: lang.PRIZE_DRAW},
    {value: GAME_TYPES.ATTRIBUTION, label: lang.ATTRIBUTION},
  ];

  const gameTypeLabel = find(gameTypeOptions, {value: game.game_type}).label;

  return (
    <div className="game-header-block">
      <span className="game-header-block__title">
        {lang.GAME} {game.win_number}
      </span>
      {isPrizeMap ? (
        <span className="game-header-block__name-label">{game.name}</span>
      ) : (
        <TextField name="name" cssModifier="game-header-block__name-input" withWrap={false} />
      )}
      {game.with_real_participations || isPrizeMap ? (
        <span className="game-header-block__game-type-label">{gameTypeLabel}</span>
      ) : (
        <DropdownField
          name="game_type"
          cssModifier="game-header-block__game-type-input"
          options={gameTypeOptions}
          withWrap={false}
        />
      )}
      {!isPrizeMap && (
        <ToggleField
          name="validation"
          cssModifier="game-header-block__validation-input"
          label={lang.VALIDATION}
          withWrap={false}
          inline
        />
      )}
      {!isPrizeMap && formValues.validation && (
        <DropdownField
          name="levels"
          cssModifier="game-header-block__levels-input"
          label={lang.VALIDATION_LEVEL}
          options={levels}
          valueKey="id"
          labelKey="name"
          multi
          simpleValue
          withWrap={false}
          inline
        />
      )}
      {isPrizeMap && (
        <span className="game-header-block__level-label">
          {lang.LEVELS[levelKey]} {lang.LEVELS.LEVEL}
        </span>
      )}
    </div>
  );
};

GameHeaderBlock.propTypes = {
  lang: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  levels: PropTypes.array.isRequired,
  game: PropTypes.shape({
    game_type: PropTypes.string,
    name: PropTypes.string,
    win_number: PropTypes.number,
    with_real_participations: PropTypes.bool,
  }).isRequired,
  isPrizeMap: PropTypes.bool.isRequired,
  levelKey: PropTypes.string,
};

GameHeaderBlock.defaultProps = {
  levelKey: '',
};

export default GameHeaderBlock;
