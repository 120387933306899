import React, {Component} from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';

import {formatToSelectOption, normalizeStringToUpperCase} from 'client/services/formatters';
import {getAuthHeaders, transformDate} from 'client/services/helpers';

import {getAvailableForAttributionUsers} from 'client/ducks/client-users/actions';
import {selectAvailableForAttributionUsers} from 'client/ducks/client-users/selectors';

import {API_METHODS, API_PATH} from 'client/common/config';

import fieldTemplate from 'client/components/common/field';
import DataRetentionTooltip from 'client/components/common/tooltips/data-retention-tooltip';

import DataRetentionField from './data-retention-field';
import {updateSyncErrors, validate} from './validate';

import './general-information-form.scss';

class GeneralInformationForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    formValues: PropTypes.object,
    lang: PropTypes.object.isRequired,
    langOperationPage: PropTypes.object.isRequired,
    invalid: PropTypes.bool,
    edit: PropTypes.bool,
    pristine: PropTypes.bool,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
    updateErrors: PropTypes.func,
    initialize: PropTypes.func,
    users: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string.isRequired,
      }),
    ).isRequired,
    getUsers: PropTypes.func.isRequired,
    clientId: PropTypes.number.isRequired,
  };

  static mapData(data) {
    return {
      operation: {
        name: data.name,
        code: data.code,
        from: transformDate(data.from, false),
        to: transformDate(data.to, false),
        status: data.status.value,
        client_user_id: data.client_user_id.value,
        common_retention_years: +data.common_retention_years,
        common_retention_months: +data.common_retention_months,
        leads_retention_years: +data.leads_retention_years,
        leads_retention_months: +data.leads_retention_months,
      },
    };
  }

  componentDidMount() {
    const {clientId, getUsers} = this.props;

    getUsers(clientId);
  }

  componentDidUpdate(prevProps) {
    const {edit, initialValues = {}, reset, initialize, updateErrors} = this.props;

    if (!edit && prevProps.edit) {
      reset();
    }

    if (prevProps.initialValues !== initialValues) {
      const {
        from,
        to,
        status,
        common_retention_years,
        common_retention_months,
        leads_retention_years,
        leads_retention_months,
        client_user_id,
        client: {name} = {},
      } = initialValues;

      const newValues = {
        ...initialValues,
        client_name: name,
        from: transformDate(from),
        to: transformDate(to),
        status: formatToSelectOption(status),
        client_user_id: formatToSelectOption(client_user_id),
        common_retention_years: String(common_retention_years),
        common_retention_months: String(common_retention_months),
        leads_retention_years: String(leads_retention_years),
        leads_retention_months: String(leads_retention_months),
      };

      initialize(newValues);
      updateErrors(newValues);
    }
  }

  handleCancelClick = () => {
    const {onCancel, reset} = this.props;

    reset();
    onCancel();
  };

  save = (data) => {
    const {lang, initialValues, onSave} = this.props;

    return fetch(`${API_PATH}${API_METHODS.OPERATIONS}/${initialValues.id}`, {
      method: 'PATCH',
      headers: getAuthHeaders(),
      body: JSON.stringify(GeneralInformationForm.mapData(data)),
    })
      .then((res) => res.json())
      .then(({errors}) => {
        if (errors) {
          const submitErrors = {};
          if (errors.name) {
            submitErrors.name = lang.NAME_IS_TAKEN_ERROR;
          }
          if (errors.code) {
            submitErrors.code = lang.CODE_IS_TAKEN_ERROR;
          }
          throw new SubmissionError(submitErrors);
        }
        onSave();
      });
  };

  render() {
    const {handleSubmit, pristine, edit, formValues = {}, lang, langOperationPage, users} = this.props;
    const {values = {}} = formValues;

    const endDate = values.to && new Date(transformDate(values.to, false));
    const startDate = values.from && new Date(transformDate(values.from, false));

    if (endDate) {
      endDate.setDate(endDate.getDate() - 1);
    }
    if (startDate) {
      startDate.setDate(startDate.getDate() + 1);
    }

    return (
      <div>
        <form
          className="general-information-form general-information-form--expand"
          // noValidate='noValidate'
          onSubmit={handleSubmit(this.save)}
        >
          <div className={`general-information-form__inner ${edit ? 'general-information-form__inner_expand' : ''}`}>
            <div className="form-field-wrap">
              <Field
                cssModifier="input--disabled-view-1 input--view-4"
                disabled={!edit}
                label={langOperationPage.GENERAL_INFORMATION.NAME_LABEL}
                name="name"
                type="text"
                component={fieldTemplate}
              />
              <Field
                cssModifier={`select--disabled-view-${edit ? 1 : 2} select--view-5`}
                disabled={!edit}
                label={langOperationPage.GENERAL_INFORMATION.STATUS_LABEL}
                name="status"
                type="select"
                searchable={false}
                options={[
                  {value: 'active', label: lang.OPERATION_STATUS.active},
                  {value: 'finished', label: lang.OPERATION_STATUS.finished},
                ]}
                component={fieldTemplate}
              />
              <Field
                cssModifier={cn('datepicker--disabled-view-1 datepicker--view-4', {
                  disabled: !edit,
                })}
                disabled={!edit}
                label={langOperationPage.GENERAL_INFORMATION.START_DATE_LABEL}
                placeholder={langOperationPage.GENERAL_INFORMATION.DATE_PLACEHOLDER}
                name="from"
                disabledDayAfter={endDate}
                type="datepicker"
                component={fieldTemplate}
              />
            </div>
            <div className="form-field-wrap">
              <Field
                cssModifier="input--disabled-view-1 input--view-4"
                disabled={!edit}
                label={langOperationPage.GENERAL_INFORMATION.CODE_LABEL}
                name="code"
                type="text"
                normalize={normalizeStringToUpperCase}
                onChange={(e, newValue) =>
                  (/^[A-Z0-9]*$/.test(newValue) && newValue.length <= 10) || e.preventDefault()
                }
                component={fieldTemplate}
              />
              <Field
                cssModifier="input--disabled-view-1 input--view-4"
                disabled
                label={langOperationPage.GENERAL_INFORMATION.CLIENT_LABEL}
                name="client_name"
                type="text"
                component={fieldTemplate}
              />
              <Field
                cssModifier={cn('datepicker--disabled-view-1 datepicker--view-4', {
                  disabled: !edit,
                })}
                disabled={!edit}
                label={langOperationPage.GENERAL_INFORMATION.END_DATE_LABEL}
                placeholder={langOperationPage.GENERAL_INFORMATION.DATE_PLACEHOLDER}
                name="to"
                disabledDayBefore={startDate}
                type="datepicker"
                component={fieldTemplate}
              />
            </div>
            <div className="form-field-wrap">
              <div className="input--view-4">
                <div className="select__label">{lang.PARTICIPATION_DATA_RETENTION}</div>
                <DataRetentionField
                  name="common_retention_years"
                  value={values.common_retention_years}
                  unit="year"
                  disabled={!edit}
                />
                <DataRetentionField
                  name="common_retention_months"
                  value={values.common_retention_months}
                  unit="month"
                  disabled={!edit}
                />
                {edit && <DataRetentionTooltip />}
              </div>
              <div className="input--view-4">
                <div className="select__label">{lang.LEADS_DATA_RETENTION}</div>
                <DataRetentionField
                  value={values.leads_retention_years}
                  name="leads_retention_years"
                  unit="year"
                  disabled={!edit}
                />
                <DataRetentionField
                  value={values.leads_retention_months}
                  name="leads_retention_months"
                  unit="month"
                  disabled={!edit}
                />
                {edit && <DataRetentionTooltip />}
              </div>
              <Field
                cssModifier={`select--disabled-view-${edit ? 1 : 2} select--view-5 select--no-min-height`}
                component={fieldTemplate}
                type="select"
                label={lang.ATTRIBUTED_TO_LABEL}
                name="client_user_id"
                disabled={!edit}
                options={users.map((i) => ({label: i.full_name, value: i.id}))}
              />
            </div>
          </div>
          {edit && (
            <div className="general-information-form__bottom">
              <button className="button button--bg-11 general-information-form__btn" onClick={this.handleCancelClick}>
                {langOperationPage.CANCEL_BUTTON}
              </button>
              <button
                className="button button--bg-operations general-information-form__btn"
                type="submit"
                disabled={pristine}
              >
                {langOperationPage.SAVE_BUTTON}
              </button>
            </div>
          )}
        </form>
      </div>
    );
  }
}

const generalInformationForm = reduxForm({
  form: 'generalInformationForm',
  validate,
})(GeneralInformationForm);

export default connect(
  (state) => {
    return {
      users: selectAvailableForAttributionUsers(state),
      lang: state.languageState.payload.OPERATIONS,
      langOperationPage: state.languageState.payload.OPERATION_PAGE,
      formValues: state.form.generalInformationForm,
    };
  },
  {
    updateErrors: updateSyncErrors,
    getUsers: getAvailableForAttributionUsers,
  },
)(generalInformationForm);
