import React, {Component} from 'react';

import PropTypes from 'prop-types';
import Cropper from 'react-cropper';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import 'cropperjs/dist/cropper.css';

import {addHashToImage, checkImageMimeType} from 'client/services/helpers';

import {updateAdminUserProfile} from 'client/ducks/admin-users/actions';
import {updateUserProfile} from 'client/ducks/users/actions';

import {APP_ROLES} from 'client/common/config';
import Modal from 'client/common/modals/modal';

import fieldTemplate from 'client/components/common/field';
import Icon from 'client/components/common/icon';

import {getProfile} from 'client/components/profile/profile.action';

import './edit-photo-modal.scss';

const ACCEPTED_TYPES = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif'];

class EditPhotoModal extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    userState: PropTypes.object.isRequired,
    getProfile: PropTypes.func.isRequired,
    updateUserProfile: PropTypes.func.isRequired,
    updateAdminUserProfile: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    show: PropTypes.bool,
  };

  static defaultProps = {
    show: false,
  };

  static MAX_FILE_SIZE = 1024 * 1024 * 10; // 10Mb

  constructor(props) {
    super(props);

    this.state = {
      src: '',
      imageParams: {},
      cropResult: null,
      hash: '',
      disableSubmit: true,
      showErrorText: false,
      errorText: '',
    };
  }

  checkResolution = async (file) => {
    const url = URL.createObjectURL(file);
    const img = new Image();
    img.src = url;
    await img.decode();
    return img.width > 1 && img.height > 1;
  };

  handleChange = async (e) => {
    const file = e.target.files[0];

    this.setState({
      showErrorText: false,
      errorText: '',
    });

    if (!file) {
      return;
    }

    if (!ACCEPTED_TYPES.includes(file?.type)) {
      this.showError(this.props.lang.INCORRECT_FILE_TYPE);

      return;
    }

    if (file.size > EditPhotoModal.MAX_FILE_SIZE) {
      this.showError(this.props.lang.TOO_BIG_FILE);
      this.resetCrop();
      return;
    }

    const isResolutionValid = await this.checkResolution(file);
    if (!isResolutionValid) {
      this.showError(this.props.lang.INCORRECT_FILE_TYPE);
      this.resetCrop();
      return;
    }

    checkImageMimeType(file)
      .then(() => {
        const reader = new FileReader();

        reader.onload = () => {
          this.setState({
            src: reader.result,
            imageParams: {
              name: file.name,
              mimeType: file.type,
            },
            disableSubmit: false,
          });
        };

        reader.readAsDataURL(file);
        this.resetError();
      })
      .catch(() => {
        this.showError(this.props.lang.INCORRECT_FILE_TYPE);
        this.resetCrop();
      });
  };

  cropImage = (isUserNotAdmin) => {
    const query = isUserNotAdmin ? 'user[avatar]' : 'admin_user[avatar]';

    return new Promise((resolve) => {
      const croppedCanvas = this.cropper.getCroppedCanvas();

      if (croppedCanvas !== null) {
        croppedCanvas.toBlob((blob) => {
          const formData = new FormData();
          const now = new Date();
          const hash = String(now.getTime());
          const hashedName = addHashToImage(this.state.imageParams.name, hash);

          this.setState(
            {
              imageParams: {
                hashedName: hashedName,
              },
              disableSubmit: true,
            },
            () => {
              formData.append(query, blob, `${encodeURIComponent(this.state.imageParams.hashedName)}`);
              resolve(formData);
            },
          );
        }, this.state.imageParams.mimeType);
      }
    });
  };

  handleCancel = (e) => {
    e.preventDefault();
    this.resetCrop();
    this.resetError();
    this.props.onClose();
  };

  resetError = () => {
    this.setState({
      showErrorText: false,
      errorText: '',
    });
  };

  showError = (text) => {
    this.setState({
      showErrorText: true,
      errorText: text,
    });
  };

  handleClose = () => {
    this.resetCrop();
    this.resetError();
    this.props.onClose();
  };

  handleConfirm = () => {
    this.resetCrop();
    this.props.onConfirm();
  };

  resetCrop = () => {
    this.setState({
      src: '',
      cropResult: null,
      imageParams: {},
      hash: '',
      disableSubmit: true,
    });
  };

  save = () => {
    const {
      id,
      userState: {
        payload: {role},
      },
    } = this.props;
    const isUserNotAdmin = role !== APP_ROLES.ADMIN && role !== APP_ROLES.SUPER_ADMIN;
    return this.cropImage(isUserNotAdmin)
      .then((data) =>
        isUserNotAdmin ? this.props.updateUserProfile(id, data) : this.props.updateAdminUserProfile(id, data),
      )
      .then(() => {
        this.props.getProfile(id);
        this.handleConfirm();
      })
      .catch(() => {
        this.showError(this.props.lang.NO_IMAGE_LOADED);
        this.resetCrop();
      });
  };

  render() {
    const {lang, handleSubmit} = this.props;

    return (
      <Modal show={this.props.show} dialogClassName="edit-photo-modal" title={lang.TITLE} onClose={this.handleClose}>
        <form onSubmit={handleSubmit(this.save)}>
          <div className="edit-photo__content">
            <div className="edit-photo-modal__input">
              <Field
                htmlFor="profile-avatar"
                type="file-single"
                name="avatar"
                buttonText={<Icon name="camera-2" />}
                fileName={this.state.imageParams.name}
                placeholder={lang.PLACEHOLDER}
                component={fieldTemplate}
                input={{
                  onChange: this.handleChange,
                  accept: ACCEPTED_TYPES.join(','),
                }}
              />
            </div>
            {this.state.showErrorText && <div className="message-error-text">{this.state.errorText}</div>}
            <p className="edit-photo-modal__descr">{lang.DESCRIPTION}</p>
            <div className="edit-photo-modal__cropper">
              <Cropper
                src={this.state.src}
                ref={(cropper) => {
                  this.cropper = cropper;
                }}
                guides={false}
                aspectRatio={1}
                style={{
                  height: 202,
                  width: 202,
                  borderRadius: 2,
                  backgroundColor: '#FFFFFF',
                  margin: '0 auto',
                }}
              />
              {!this.state.src && (
                <div className="edit-photo-modal__placeholder">
                  <Icon name="avatar-placeholder" />
                </div>
              )}
            </div>
          </div>
          <div className="edit-photo-modal__buttons">
            <div className="edit-photo-modal__cancel-button" onClick={this.handleClose}>
              {lang.CANCEL}
            </div>
            <button type="submit" className="edit-photo-modal__save-button" disabled={this.state.disableSubmit}>
              {lang.CONFIRM}
            </button>
          </div>
        </form>
      </Modal>
    );
  }
}

const component = reduxForm({
  form: 'EditPhotoForm',
})(EditPhotoModal);

export default connect(
  (state) => ({
    lang: state.languageState.payload.PROFILE.PROFILE_EDIT_PHOTO_MODAL,
    userState: state.userState,
  }),
  {
    getProfile,
    updateUserProfile,
    updateAdminUserProfile,
  },
)(component);
