import React from 'react';

import bem from 'client/services/bem';

import {TranslationJsx} from 'client/models/language/types';

import './donut-chart-title.scss';

const b = bem('donut-chart-title');

const DonutChartTitle = ({title}) => title && <p className={b()}>{title}</p>;

DonutChartTitle.propTypes = {
  title: TranslationJsx,
};

DonutChartTitle.defaultProps = {
  title: '',
};

export default DonutChartTitle;
