import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getLevelStatistic} from 'client/ducks/participations/actions';
import {selectLevelStatistic} from 'client/ducks/participations/selectors';

import StackedBarsChart from 'client/components/common/charts/stacked-bars-chart';

import {mapData} from './helpers';

import ParticipationTabCard from '../participation-tab-card';
import ParticipationTabNotAvailable from '../participation-tab-not-available';

import './participation-tunnel.scss';

class ParticipationTunnel extends Component {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    autotask: PropTypes.object.isRequired,
    getLevelStatistic: PropTypes.func.isRequired,
    levelStatistic: PropTypes.array.isRequired,
  };

  state = {
    loading: false,
  };

  componentDidMount() {
    this.fetchLevelStatistic();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const isAutotaskChanged = this.props.autotask.id !== nextProps.autotask.id;
    const isLevelStatisticChanged = this.props.levelStatistic !== nextProps.levelStatistic;
    const loadingChanged = this.state.loading !== nextState.loading;

    return isAutotaskChanged || isLevelStatisticChanged || loadingChanged;
  }

  componentDidUpdate(prevProps) {
    if (this.props.autotask.id !== prevProps.autotask.id) {
      this.fetchLevelStatistic();
    }
  }

  toggleLoading = () => this.setState(({loading}) => ({loading: !loading}));

  fetchLevelStatistic = () => {
    this.toggleLoading();
    this.props.getLevelStatistic({automation_task_id: this.props.autotask.id}).then(this.toggleLoading);
  };

  getWidth = (data, notAvailable) => {
    const count = data.length;

    if (count > 3 || notAvailable) {
      return '50%';
    }

    if (count === 3) {
      return '40%';
    }

    return '35%';
  };

  render() {
    const {lang, autotask, levelStatistic} = this.props;
    const {loading} = this.state;

    const notAvailable = autotask.participations_count < 1;
    const data = mapData(levelStatistic);

    return (
      <div className="participation-tunnel" style={{width: this.getWidth(data, notAvailable)}}>
        <ParticipationTabCard
          title={lang.TITLE}
          iconName="participation-tunnel-colored"
          loading={loading}
          loadingColor="participants"
        >
          {notAvailable ? <ParticipationTabNotAvailable text={lang.NOT_AVAILABLE} /> : <StackedBarsChart data={data} />}
        </ParticipationTabCard>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.PARTICIPATION_TUNNEL,
    levelStatistic: selectLevelStatistic(state),
  }),
  {
    getLevelStatistic,
  },
)(ParticipationTunnel);
