import React, {Component} from 'react';

import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {selectBroadcast, selectBroadcastRecipientsLeads, selectRecipientsMeta} from 'client/ducks/broadcast/selectors';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';

import BCEmailStepsDataGrid from '../../tables/bc-email-steps-data-grid';

import '../bc-step.scss';

class BCMailingStep extends Component {
  static propTypes = {
    optInsCodes: PropTypes.object,
    recipientsLeadsList: PropTypes.array,
    taskType: PropTypes.string,
    clientType: PropTypes.string,
    isNoBroadcastSelected: PropTypes.bool.isRequired,
    languageState: PropTypes.object.isRequired,
    recipientsLeadsMeta: PropTypes.object.isRequired,
    perPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onSortChange: PropTypes.func.isRequired,
    taskId: PropTypes.string.isRequired,
    clientId: PropTypes.string.isRequired,
    operationId: PropTypes.string.isRequired,
    broadcastId: PropTypes.string.isRequired,
    disabledIfNotNew: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    taskType: '',
    clientType: '',
    optInsCodes: {},
    recipientsLeadsList: [],
  };

  constructor(props) {
    super(props);

    const langPayload = props.languageState.payload;
    this.LANGUAGE = langPayload.BROADCASTING_TASK.MAILING_STEP;
  }

  renderLink = () => {
    const {recipientsLeadsList, taskId, operationId, clientId, broadcastId, clientType, taskType} = this.props;

    const noLeads = !recipientsLeadsList.length;
    const link = CustomLink.createMailingListLink({
      taskType,
      clientType,
      operationId,
      clientId,
      taskId,
      broadcastId,
    });

    return noLeads ? (
      <CustomLink to={link} className="button button--bg-14">
        <Icon name="plus" className="button__icon" />
        <span>{this.LANGUAGE.ADD_BUTTON}</span>
      </CustomLink>
    ) : (
      <CustomLink to={link} className="button button--bg-14">
        <Icon name="spanner" className="button__icon" />
        <span>{this.LANGUAGE.MODIFY_BUTTON}</span>
      </CustomLink>
    );
  };

  render() {
    const {
      recipientsLeadsList: tableData,
      recipientsLeadsMeta,
      onSortChange,
      onPageChange,
      perPage,
      isNoBroadcastSelected,
      disabledIfNotNew: disabled,
    } = this.props;

    return (
      <div className="bc-step__inner">
        {!isNoBroadcastSelected ? (
          <div className="bc-step__header">
            <div className="bc-step__header-text">
              {recipientsLeadsMeta.total_count} {this.LANGUAGE.LEADS_LABEL}
            </div>
            {!disabled && this.renderLink()}
          </div>
        ) : (
          <div style={{marginTop: '2.5rem'}} />
        )}
        <div className="pos-rel">
          <BCEmailStepsDataGrid
            className="z_100"
            data={tableData}
            perPage={perPage}
            currentPage={recipientsLeadsMeta.current_page}
            totalPages={recipientsLeadsMeta.total_pages}
            totalItems={recipientsLeadsMeta.total_count}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  languageState: state.languageState,
  recipientsLeadsList: selectBroadcastRecipientsLeads(state),
  recipientsLeadsMeta: selectRecipientsMeta(state),
  isNoBroadcastSelected: isEmpty(selectBroadcast(state)),
});

export default connect(mapStateToProps)(BCMailingStep);
