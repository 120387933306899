import get from 'lodash/get';
import set from 'lodash/set';
import moment from 'moment';

import {isNumberString} from 'client/services/helpers';

import {PRIZE_TYPES, REGEXP_SPLIT} from './constants';
import {getWeekdays} from './helpers';

// Check valid of timestamp string (Example: 11/10/2023 11:21:00 )
const checkValidTimeStamp = (str) =>
  /^(3[01]|[12][0-9]|0?[1-9])\/(1[012]|0?[1-9])\/((?:19|20)\d{2})\s([0-1]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/.test(
    str,
  ) && moment(str, 'DD/MM/YYYY HH:mm:ss');
// Check valid of time string (Example: 11:21 )
const checkValidTime = (str) => /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(str) && moment(str, 'HH:mm');
// Check valid of date string (Example: 11/10/2023 )
const checkValidDate = (str) =>
  /^(3[01]|[12][0-9]|0?[1-9])\/(1[012]|0?[1-9])\/((?:19|20)\d{2})$/.test(str) && moment(str, 'DD/MM/YYYY');

const setJoin = (object, path, arrayValues) => {
  return set(object, path, arrayValues.filter(Boolean).join('. '));
};

const validateScheduleOffDays = (formValues, {lang}) => {
  const errors = {};
  const offDaysValue = get(formValues, 'schedule_instant_win_configuration.dates_off')
    ?.split(REGEXP_SPLIT)
    .filter(Boolean);

  offDaysValue?.some((str) => {
    let error = null;
    if (!moment(str, 'DD/MM/YYYY').isValid()) {
      error = lang.INVALID_DATE;
    } else if (!checkValidDate(str)) {
      error = lang.SPECIAL_OFF_DAYS_EXAMPLE;
    }
    if (error) {
      set(errors, 'schedule_instant_win_configuration.dates_off', error);
    }
    return !!error;
  });

  return errors;
};

const validateScheduleFixWeek = (formValues, {lang, isPrizeMap}) => {
  const errors = {
    ...validateScheduleOffDays(formValues, {lang}),
  };
  const weekdaysRaw = get(formValues, 'schedule_instant_win_configuration.fix_week.schedule_win_timestamps', []);

  // for concatenation errors of all table
  const errorTable = get(errors, 'schedule_instant_win_configuration.fix_week.schedule_win_timestamps_table', '');

  const weekdays = weekdaysRaw.filter((d = {}) => !!Object.keys(d).length && !!d.win_time);

  if (!weekdays.length && !isPrizeMap) {
    setJoin(errors, 'schedule_instant_win_configuration.fix_week.schedule_win_timestamps_table', [
      errorTable,
      lang.REQUIRED,
    ]);
  }
  weekdays.forEach((day, index) => {
    const value = get(day, 'win_time');
    const valueArray = value.split(REGEXP_SPLIT).filter(Boolean);
    if (value) {
      valueArray.some((str) => {
        let error = null;
        if (str) {
          if (!moment(str, 'HH:mm').isValid()) {
            error = lang.INVALID_DATE;
          } else if (!checkValidTime(str)) {
            error = lang.WRONG_FORMAT;
          }
          if (error) {
            set(
              errors,
              `schedule_instant_win_configuration.fix_week.schedule_win_timestamps[${index}].win_time`,
              error,
            );
            setJoin(errors, 'schedule_instant_win_configuration.fix_week.schedule_win_timestamps_table', [
              errorTable,
              error,
            ]);
            return !!error;
          }
        }
        return false;
      });
    }
  });
  return errors;
};

const validateScheduleCalculateRules = (formValues, {lang, isPrizeMap}) => {
  const errors = {
    ...validateScheduleOffDays(formValues, {lang}),
  };
  const weekdays = get(formValues, 'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps', []);
  const prizesNumber = get(formValues, 'schedule_instant_win_configuration.calculated_rule.prizes_number', {});
  const errorTable = get(
    errors,
    'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps_table',
    '',
  );

  if (!(weekdays.length || Object.values(prizesNumber).filter(Boolean).length) && !isPrizeMap) {
    setJoin(errors, 'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps_table', [
      errorTable,
      lang.REQUIRED,
    ]);
  }
  getWeekdays({}).forEach(({value}, index) => {
    const winTime = get(weekdays[index], 'win_time');
    const prizeNumber = get(prizesNumber, value);
    if ([winTime, prizeNumber].filter(Boolean).length === 1) {
      setJoin(errors, 'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps_table', [
        errorTable,
        lang.BOTH_INPUTS,
      ]);
      if (!winTime) {
        set(
          errors,
          `schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps[${index}].win_time`,
          lang.BOTH_INPUTS,
        );
      } else if (!prizeNumber) {
        set(errors, `schedule_instant_win_configuration.calculated_rule.prizes_number.${value}`, lang.BOTH_INPUTS);
      }
    }
    if (prizeNumber) {
      if (!isNumberString(prizeNumber)) {
        set(
          errors,
          `schedule_instant_win_configuration.calculated_rule.prizes_number.${value}`,
          lang.NUMBER_IS_REQUIRED,
        );
        setJoin(errors, 'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps_table', [
          errorTable,
          lang.NUMBER_IS_REQUIRED,
        ]);
      }
    }

    if (winTime) {
      const winTimeArray = winTime.split(REGEXP_SPLIT).filter(Boolean);
      if (winTimeArray.length % 2 !== 0) {
        set(
          errors,
          `schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps[${index}].win_time`,
          lang.ODD_NUMBER,
        );
        setJoin(errors, 'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps_table', [
          errorTable,
          lang.ODD_NUMBER,
        ]);
      }
      const failedFormat = winTimeArray.some((str) => {
        let error = null;
        if (!moment(str, 'HH:mm').isValid()) {
          error = lang.INVALID_DATE;
        } else if (!checkValidTime(str)) {
          error = lang.WRONG_FORMAT;
        }
        if (error) {
          set(
            errors,
            `schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps[${index}].win_time`,
            error,
          );
          setJoin(errors, 'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps_table', [
            errorTable,
            error,
          ]);
        }
        return !!error;
      });
      if (!failedFormat) {
        const checkInvalidSomeOrder = (str, indexHour, arr) => {
          const next = arr[indexHour + 1];
          return next && moment(next, 'HH:mm').isBefore(moment(str, 'HH:mm'));
        };

        if (winTimeArray.some(checkInvalidSomeOrder)) {
          set(
            errors,
            `schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps[${index}].win_time`,
            lang.ORDER_HOURS,
          );
          setJoin(errors, 'schedule_instant_win_configuration.calculated_rule.schedule_win_timestamps_table', [
            errorTable,
            lang.ORDER_HOURS,
          ]);
        }
      }
    }
  });
  return errors;
};

const validateScheduleInstant = (formValues, {lang, isPrizeMap}) => {
  let errors = {};
  if (get(formValues, 'schedule_instant_win_configuration.schedule_type') === 'manual') {
    const value = get(formValues, 'schedule_instant_win_configuration.manual.schedule_win_timestamps');

    if (!value && !isPrizeMap) {
      set(errors, 'schedule_instant_win_configuration.manual.schedule_win_timestamps', lang.REQUIRED);
    } else {
      value
        .split(REGEXP_SPLIT)
        .filter(Boolean)
        .some((str) => {
          let error = null;
          if (!moment(str, 'DD/MM/YYYY HH:mm').isValid()) {
            error = lang.INVALID_DATE;
          }
          if (!checkValidTimeStamp(str)) {
            error = lang.INSTANT_WIN_MOMENTS_PLACEHOLDER;
          }
          if (error) {
            set(errors, 'schedule_instant_win_configuration.manual.schedule_win_timestamps', error);
          }
          return !!error;
        });
    }
  } else if (get(formValues, 'schedule_instant_win_configuration.schedule_type') === 'fix_week') {
    errors = {
      ...errors,
      ...validateScheduleFixWeek(formValues, {lang, isPrizeMap}),
    };
  } else if (get(formValues, 'schedule_instant_win_configuration.schedule_type') === 'calculated_rule') {
    errors = {
      ...errors,
      ...validateScheduleCalculateRules(formValues, {lang, isPrizeMap}),
    };
  }
  return errors;
};

export const validate = (formValues, {lang, isPrizeMap, game}) => {
  let errors = {};

  if (formValues.type === PRIZE_TYPES.COUPON && !formValues.coupon && !isPrizeMap) {
    errors.coupon = lang.REQUIRED;
  }
  if (formValues.type === PRIZE_TYPES.LOYALTY && !formValues.loyalty_amount && !isPrizeMap) {
    errors.loyalty_amount = lang.REQUIRED;
  }
  if (!formValues.internal_name && !isPrizeMap) {
    errors.internal_name = lang.INTERNAL_PRIZE_NAME_IS_REQUIRED;
  }
  if (!formValues.external_name && !isPrizeMap) {
    errors.external_name = lang.EXTERNAL_PRIZE_NAME_IS_REQUIRED;
  }
  if (!formValues.code && !isPrizeMap) {
    errors.code = lang.PRIZE_CODE_IS_REQUIRED;
  }

  if (isPrizeMap) {
    if (formValues.initial_stock) {
      if (!isNumberString(formValues.initial_stock)) {
        errors.initial_stock = lang.NUMBER_IS_REQUIRED;
      } else if (formValues.initial_stock < 1) {
        errors.initial_stock = lang.INITIAL_STOCK_LESS;
      }
    }
    if (formValues.stock_offset && !isNumberString(formValues.stock_offset)) {
      errors.stock_offset = lang.NUMBER_IS_REQUIRED;
    }
  } else {
    if (!formValues.default_initial_stock) {
      errors.default_initial_stock = lang.REQUIRED;
    } else if (!isNumberString(formValues.default_initial_stock)) {
      errors.default_initial_stock = lang.NUMBER_IS_REQUIRED;
    } else if (formValues.default_initial_stock < 1) {
      errors.default_initial_stock = lang.INITIAL_STOCK_LESS;
    }
    if (formValues.default_stock_offset && !isNumberString(formValues.default_stock_offset)) {
      errors.default_stock_offset = lang.NUMBER_IS_REQUIRED;
    }
  }

  if (game && game.game_type === 'instant_win') {
    // Instant win configuration:
    if (formValues.instant_win_type === 'time_instant_win') {
      if (!get(formValues, 'time_instant_win_configuration.units_number') && !isPrizeMap) {
        set(errors, 'time_instant_win_configuration.units_number', lang.REQUIRED);
      } else if (!isNumberString(get(formValues, 'time_instant_win_configuration.units_number') || 0)) {
        set(errors, 'time_instant_win_configuration.units_number', lang.NUMBER_IS_REQUIRED);
      }

      if (!get(formValues, 'time_instant_win_configuration.time_unit') && !isPrizeMap) {
        set(errors, 'time_instant_win_configuration.time_unit', lang.REQUIRED);
      }
    } else if (formValues.instant_win_type === 'participation_instant_win') {
      const unitsNumber = get(formValues, 'participation_instant_win_configuration.units_number');
      if (!unitsNumber && !isPrizeMap) {
        set(errors, 'participation_instant_win_configuration.units_number', lang.REQUIRED);
      } else if (!isNumberString(unitsNumber || 0)) {
        set(errors, 'participation_instant_win_configuration.units_number', lang.NUMBER_IS_REQUIRED);
      }
    } else if (formValues.instant_win_type === 'schedule_instant_win') {
      errors = {
        ...errors,
        ...validateScheduleInstant(formValues, {lang, isPrizeMap}),
      };
    }
  }

  if (formValues.substitution) {
    formValues.substitution.forEach((item, index) => {
      const count = formValues.substitution.filter(({order}) => item.order && +item.order === +order).length;
      if (count > 1) {
        set(errors, `substitution[${index}].order`, lang.UNIQUE_VALUES_REQUIRED);
      }
    });
  }

  return errors;
};
