import React, {useCallback, useMemo, useState} from 'react';

import moment from 'moment';
import {useSelector, useDispatch} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {patchTranslationsItem} from 'client/ducks/translations/actions';
import {selectTranslationsArray, selectLanguages} from 'client/ducks/translations/selectors';

import AppButton from 'client/common/buttons';
import SearchInput from 'client/common/inputs/search-input';
import SelectDropdown from 'client/common/selects/select-dropdown';
import PaginationBar from 'client/common/paginations/pagination-bar';

import ClientTable from 'client/components/common/client-table';

import {getUnapprovedTranslations} from '../mappers';
import TranslationsItemModal from '../translations-item-modal';

import './translations-approval.scss';

const PER_PAGE = 10;

const b = bem('translations-approval');

const isIncludedString = (str1, str2) => !!str1?.toLocaleLowerCase().includes(str2?.toLocaleLowerCase());

const TranslationsApproval = () => {
  const lang = useLanguage('TRANSLATIONS.APPROVAL');
  const translations = useSelector(selectTranslationsArray);
  const languages = useSelector(selectLanguages);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState(null);
  const [approvingItemId, setApprovingItemId] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [sort, setSort] = useState({sortField: 'id', sortOrder: 'asc'});
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  const translationsToApprove = useMemo(() => {
    let result = getUnapprovedTranslations(translations);
    if (searchValue) {
      result = result.filter(
        (item) => isIncludedString(item.path, searchValue) || isIncludedString(item.value, searchValue),
      );
    }

    if (selectedLanguages.length) {
      result = result.filter(({language}) => selectedLanguages.includes(language));
    }

    result.sort((aItem, bItem) => {
      if (sort.sortOrder === 'asc') {
        return aItem[sort.sortField] > bItem[sort.sortField] ? -1 : 1;
      }
      return aItem[sort.sortField] < bItem[sort.sortField] ? -1 : 1;
    });

    return result;
  }, [searchValue, selectedLanguages, sort.sortField, sort.sortOrder, translations]);

  const languagesOptions = useMemo(() => {
    const unapprovedTranslations = getUnapprovedTranslations(translations);
    const involvedLanguages = unapprovedTranslations.reduce((acc, {language}) => {
      if (!acc.includes(language)) {
        acc.push(language);
      }
      return acc;
    }, []);

    return languages
      .filter(({name}) => involvedLanguages.includes(name))
      .map(({name, full_name}) => ({label: full_name, value: name}));
  }, [languages, translations]);

  const handleTranslationApprove = useCallback(
    async (id) => {
      const approvedIndex = translationsToApprove.findIndex((el) => el.id === id);
      if (approvedIndex === -1) {
        return;
      }

      setApprovingItemId(id);
      await dispatch(patchTranslationsItem({id, approved: true}));
      setApprovingItemId(null);
    },
    [dispatch, translationsToApprove],
  );

  const handleTranslationsItemSave = useCallback(
    async (formValues) => {
      await dispatch(patchTranslationsItem(formValues));
      setSelectedItem(null);
    },
    [dispatch],
  );

  const columns = useMemo(
    () => [
      {
        name: 'id',
        label: lang.COLUMN_ID,
        sortable: true,
        width: 68,
        path: 'id',
      },
      {
        name: 'updated_at',
        label: lang.COLUMN_UPDATED_AT,
        path: 'updated_at',
        sortable: true,
        width: 114,
        render: ({value}) => moment(value).format('DD/MM/YY'),
      },
      {
        name: 'language',
        label: lang.COLUMN_LANGUAGE,
        path: 'language',
        width: 98,
      },
      {
        name: 'path',
        path: 'path',
        sortable: true,
        width: 250,
        label: lang.COLUMN_PATH,
        render: ({value}) => <div className={b('cell')}>{value}</div>,
      },
      {
        name: 'value',
        label: lang.COLUMN_TRANSLATION,
        path: 'value',
        width: 250,
        render: ({value}) => <div className={b('cell')}>{value}</div>,
      },
      {
        name: 'approve',
        width: 100,
        render: ({item}) => {
          return (
            <div className={b('action-buttons')}>
              <AppButton
                className={b('action-button')}
                iconName="edit"
                color="light-clients"
                onClick={() => setSelectedItem(item)}
              />
              <AppButton
                className={b('action-button')}
                iconName="check"
                onClick={() => handleTranslationApprove(item.id)}
                loading={approvingItemId === item.id}
              />
            </div>
          );
        },
      },
    ],
    [approvingItemId, handleTranslationApprove, lang],
  );

  const tableData = useMemo(() => {
    const page = currentPage - 1;
    return translationsToApprove.slice(page * PER_PAGE, page * PER_PAGE + PER_PAGE);
  }, [currentPage, translationsToApprove]);

  const handleChangeSearch = (nextSearch) => {
    setSearchValue(nextSearch);
    setCurrentPage(1);
  };

  return (
    <div className={b()}>
      <div className={b('filters-container')}>
        <SearchInput
          searchDefault={searchValue}
          onSearch={handleChangeSearch}
          className={b('search')}
          placeholder={lang.SEARCH_TRANSLATION}
        />
        <SelectDropdown
          placeholder={lang.SELECT_LANGUAGES}
          options={languagesOptions}
          value={selectedLanguages}
          onChange={(value) => setSelectedLanguages(value)}
          simpleValue={true}
          multi={true}
          className={b('languages-filter')}
          classNames={{
            control: () => b('languages-filter-control'),
            menu: () => b('languages-filter-menu'),
            menuList: () => b('languages-filter-menu-list'),
            option: () => b('languages-filter-option'),
            multiValue: () => b('languages-filter-value'),
            placeholder: () => b('languages-filter-placeholder'),
          }}
        />
      </div>

      <ClientTable columns={columns} data={tableData} noDataText={lang.NO_DATA_TEXT} {...sort} onSortChange={setSort} />
      <PaginationBar
        data={tableData}
        currentPage={currentPage}
        totalPages={Math.ceil(translationsToApprove.length / PER_PAGE)}
        totalItems={translationsToApprove.length}
        perPage={PER_PAGE}
        onPageChange={({page}) => setCurrentPage(page)}
      />
      {!!selectedItem && (
        <TranslationsItemModal
          translationsItem={selectedItem}
          onSubmit={handleTranslationsItemSave}
          onClose={() => setSelectedItem(null)}
        />
      )}
    </div>
  );
};

export default TranslationsApproval;
