import React, {useCallback, useEffect} from 'react';

import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {clearCurrentTemplateForm, resetResult} from 'client/ducks/templates/actions';

import ErrorBoundary from 'client/common/error-boundary';
import Modal from 'client/common/modals/modal';

import DIYCustomizationModalContainer from './diy-customization-modal-container';

import './diy-customization-modal.scss';

const b = bem('diy-customization-modal');

/**
 *
 * @type {React.FC<DIYCustomizationModal.PropTypes>}
 */

// TODO: from jsx => tsx
const DIYCustomizationModal = (props) => {
  const {onClose} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('DIY_OPERATION.MODALS.CONFIGURATION_MODAL');
  const [isCloseHidden, toggleCloseHidden] = useToggle(true);

  const handleClose = useCallback(() => {
    dispatch(clearCurrentTemplateForm());
    dispatch(resetResult());
    onClose();
  }, [dispatch, onClose]);

  useEffect(() => () => toggleCloseHidden(true), [toggleCloseHidden]);

  return (
    <Modal
      onClose={handleClose}
      isCloseHidden={isCloseHidden}
      className={b()}
      classNames={{
        body: b('body'),
        content: b('content'),
      }}
    >
      <ErrorBoundary message={lang.ERROR_FORM} onChangeError={toggleCloseHidden}>
        <DIYCustomizationModalContainer {...props} onClose={handleClose} />
      </ErrorBoundary>
    </Modal>
  );
};

DIYCustomizationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  formId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  isAccessGranted: PropTypes.bool,
  template: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  device: PropTypes.bool.isRequired,
  online: PropTypes.bool.isRequired,
};

export default DIYCustomizationModal;
