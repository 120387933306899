import {useCallback, useMemo, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useMount} from 'react-use';

import {uid} from 'client/services/helpers';

import {selectAutotaskParam} from 'client/ducks/autotask/selectors';
import {
  editorSetDocData,
  editorCreateContentBlock,
  editorCreateSection,
  editorDeleteSection,
  editorUpdateContentBlock,
  editorDeleteContentBlock,
  editorMoveContentBlock,
  editorMoveSection,
  editorSetDndData,
  editorUpdateSection,
  editorSetActiveBlockId,
  addEmailTemplateImagesForDelete,
} from 'client/ducks/email-templates/actions';
import {selectActiveTemplate, selectEmailTemplateEditor} from 'client/ducks/email-templates/selectors';

import {EmailTemplateImage} from 'client/models/email-templates/types';
import {Game} from 'client/models/prizes/types';

import {
  AvailableBlockContent,
  AvailableBlockLayout,
  Column,
  ContentBlock,
  ContentBlockData,
  ContentBlockTypes,
  ImageBlockData,
  Section,
  TemplateData,
} from '../types';

const SECTION_TYPE = 'section';
const COLUMN_TYPE = 'column';
const TEXT_TYPE = 'text';
const IMAGE_TYPE = 'image';
const PRIZE_TYPE = 'prize';

const SINGLE_COLUMN_SECTION = 'column-1';
const DOUBLE_COLUMN_SECTION = 'column-2';
const TRIPLE_COLUMN_SECTION = 'column-3';
const QUADRUPLE_COLUMN_SECTION = 'column-4';

type AvailableBlocks = {
  layout: AvailableBlockLayout[];
  content: AvailableBlockContent[];
};

const DEFAULT_LAYOUT_WIDTH = 600;

const availableBlocksRaw: AvailableBlocks = {
  layout: [
    {
      id: uid(),
      type: SINGLE_COLUMN_SECTION,
      label: '1_COLUMN',
      icon: 'layout-column-1',
    },
    {
      id: uid(),
      type: DOUBLE_COLUMN_SECTION,
      label: '2_COLUMNS',
      icon: 'layout-column-2',
    },
    {
      id: uid(),
      type: TRIPLE_COLUMN_SECTION,
      label: '3_COLUMNS',
      icon: 'layout-column-3',
    },
    {
      id: uid(),
      type: QUADRUPLE_COLUMN_SECTION,
      label: '4_COLUMNS',
      icon: 'layout-column-4',
    },
  ],
  content: [
    {
      id: uid(),
      type: IMAGE_TYPE,
      label: 'IMAGE',
      icon: 'image-2',
    },
    {
      id: uid(),
      type: TEXT_TYPE,
      label: 'TEXT',
      icon: 'text',
    },
    {
      id: uid(),
      type: PRIZE_TYPE,
      label: 'PRIZE',
      icon: 'prize',
    },
  ],
};

export const useEditor = (initialDate?: TemplateData) => {
  const {doc, dndData, activeBlockId} = useSelector(selectEmailTemplateEditor);
  const clientGames: Game[] = useSelector(selectAutotaskParam('client_games'));
  const activeTemplate = useSelector(selectActiveTemplate);
  const [docId, setDocId] = useState<number | null>(null);

  const width = activeTemplate?.width || DEFAULT_LAYOUT_WIDTH;

  const availableBlocks = useMemo(
    () => ({
      ...availableBlocksRaw,
      content: availableBlocksRaw.content.filter(
        ({type}) => type !== PRIZE_TYPE || (clientGames?.length && !!clientGames?.some((game) => !!game.prizes_count)),
      ),
    }),
    [clientGames],
  );

  const dispatch = useDispatch();

  const setDocData = useCallback(
    (docData) => {
      dispatch(editorSetDocData(docData));
    },
    [dispatch],
  );

  useMount(() => {
    if (initialDate) {
      setDocData(initialDate);
    }
  });

  const addSection = useCallback(
    (type, beforeSectionId) => {
      const columns: Column[] = [];
      const section: Section = {
        id: uid(),
        type: SECTION_TYPE,
        columns,
        isLocked: false,
      };

      let columnsNum;
      switch (type) {
        case SINGLE_COLUMN_SECTION:
          columnsNum = 1;
          break;
        case DOUBLE_COLUMN_SECTION:
          columnsNum = 2;
          break;
        case TRIPLE_COLUMN_SECTION:
          columnsNum = 3;
          break;
        case QUADRUPLE_COLUMN_SECTION:
          columnsNum = 4;
          break;
        default:
          columnsNum = 1;
      }

      for (let i = 0; i < columnsNum; i++) {
        const column: Column = {
          id: uid(),
          type: COLUMN_TYPE,
          contentBlocks: [],
        };

        section.columns.push(column);
      }

      dispatch(editorCreateSection(section, beforeSectionId));
    },
    [dispatch],
  );

  const moveSection = useCallback(
    (sectionId, afterSectionId) => {
      dispatch(editorMoveSection(sectionId, afterSectionId));
    },
    [dispatch],
  );

  const updateSection = useCallback(
    (section) => {
      dispatch(editorUpdateSection(section));
    },
    [dispatch],
  );

  const deleteSection = useCallback(
    (section: Section) => {
      const imagesForDelete: EmailTemplateImage[] = [];

      section.columns?.forEach((column) => {
        column.contentBlocks?.forEach((contentBlock) => {
          if (contentBlock.type === 'image') {
            const contentBlockData = contentBlock.content as ImageBlockData;
            if (contentBlockData.emailTemplateImage?.id) {
              imagesForDelete.push(contentBlockData.emailTemplateImage);
            }
          }
        });
      });

      if (imagesForDelete.length) {
        dispatch(addEmailTemplateImagesForDelete(imagesForDelete));
      }

      dispatch(editorDeleteSection(section.id));
    },
    [dispatch],
  );

  const getInitialContent = (type: ContentBlockTypes) => {
    switch (type) {
      case 'image':
        return {fluidOnMobile: true};
      default:
        return {};
    }
  };

  const createContentBlock = useCallback(
    (columnId, type, afterContentBlockId) => {
      const contentBlock: ContentBlock = {
        id: uid(),
        type,
        content: getInitialContent(type) as ContentBlockData,
        attributes: [],
      };
      dispatch(editorCreateContentBlock(columnId, contentBlock, afterContentBlockId));
    },
    [dispatch],
  );

  const updateContentBlock = useCallback(
    (contentBlock) => {
      dispatch(editorUpdateContentBlock(contentBlock));
    },
    [dispatch],
  );

  const moveContentBlock = useCallback(
    (contentBlockId, targetColumnId, afterContentBlockId) => {
      dispatch(editorMoveContentBlock(contentBlockId, targetColumnId, afterContentBlockId));
    },
    [dispatch],
  );

  const deleteContentBlock = useCallback(
    (contentBlock: ContentBlock) => {
      if (contentBlock.type === 'image') {
        const contentBlockData = contentBlock.content as ImageBlockData;
        if (contentBlockData.emailTemplateImage?.id) {
          dispatch(addEmailTemplateImagesForDelete([contentBlockData.emailTemplateImage]));
        }
      }
      dispatch(editorDeleteContentBlock(contentBlock.id));
    },
    [dispatch],
  );

  const setDndData = useCallback(
    (data) => {
      dispatch(editorSetDndData(data));
    },
    [dispatch],
  );

  const setActiveBlockId = useCallback(
    (value: string | null) => {
      dispatch(editorSetActiveBlockId(value));
    },
    [dispatch],
  );

  const hasPrize = useCallback(() => {
    return doc.body.some((bodyItem) => {
      return bodyItem.columns.some((column) => {
        return column.contentBlocks.some((block) => block.type === 'prize');
      });
    });
  }, [doc]);

  return {
    availableBlocks,
    doc,
    dndData,
    activeBlockId,
    setDocData,
    addSection,
    moveSection,
    deleteSection,
    createContentBlock,
    updateContentBlock,
    deleteContentBlock,
    moveContentBlock,
    setDndData,
    setActiveBlockId,
    hasPrize,
    updateSection,
    width,
    docId,
    setDocId,
  };
};
