import React from 'react';

import cn from 'classnames';

import bem from 'client/services/bem';

import Icon from 'client/components/common/icon';

import cssModule from './button-arrow.module.scss';

const b = bem('button-arrow', {cssModule});

type ButtonArrowProps = {
  direction: 'right' | 'left' | 'up' | 'down';
  onClick: () => void;
  disabled?: boolean;
  className?: string;
  submit?: 'submit' | 'button';
  variant: 'arrow' | 'arrow-alt';
};

const ButtonArrow: React.FC<ButtonArrowProps> = (props) => {
  const {submit = 'button', direction, onClick, disabled, className, variant = 'arrow'} = props;

  return (
    <button
      type={submit ? 'submit' : 'button'}
      onClick={onClick}
      className={cn(b([direction, variant]), className)}
      disabled={disabled}
    >
      <Icon name={`${variant}-${direction}`} />
    </button>
  );
};

export default ButtonArrow;
