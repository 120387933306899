import React from 'react';

import {get} from 'lodash';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage, useReduxForm} from 'client/services/hooks';

import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import {ToggleField, TextField, SelectField, NumberField, CheckboxField} from 'client/common/fields';
import LayoutPanel from 'client/common/panels/layout-panel';

import LanguageDevicePanel from 'client/components/diy-operation/controls/language-device-panel';
import {
  PRIZE_TYPES,
  LOYALTY_COUPON_TYPES,
  PRIZE_CODE_TYPES,
  PRIZE_BARCODE_FORMAT,
} from 'client/components/prizes/constants';
import {COMMON_INPUT} from 'client/components/prizes/constants';
import {PrizeDiyModalFormName} from 'client/components/prizes/modals/prize-diy-modal';

import {getPrizeTypeOptions} from './helpers';

import './prize-diy-main-fieldset.scss';

const b = bem('prize-diy-main-fieldset');

const PrizeDiyMainFieldset = (props) => {
  const {disabled, langUniqueMode} = props;
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_MAIN_FIELDSET');
  const languages = useSelector(selectPrizeLanguages);
  const client = useSelector(selectCurrentClient);

  const languageOptions = languages.map((i) => ({value: i, label: i}));

  const {formValues, change} = useReduxForm(PrizeDiyModalFormName);
  const {language, defaultLanguage, type: prizeType} = formValues?.main || {};

  const handleLanguageCommonToggle = (isCommon) => {
    // copy the value of field for defaultLanguage to all other language fields
    if (isCommon) {
      const email = {...formValues?.email};
      const ticket = {...formValues?.ticket};
      const win = {};
      const prizeNames = {};
      languages.forEach((langKey) => {
        // email
        email[`email_image_${langKey}`] = get(formValues, `email.email_image_${defaultLanguage}`);
        email[`external_description_${langKey}`] = get(formValues, `email.external_description_${defaultLanguage}`);

        // ticket
        ticket[`ticket_image_${langKey}`] = get(formValues, `ticket.ticket_image_${defaultLanguage}`);

        // win pop-up
        win[`win_pop_up_image_${langKey}`] = get(formValues, `win_pop_up.win_pop_up_image_${defaultLanguage}`);

        // prize name
        prizeNames[langKey] = get(formValues, `main.prize_names.${defaultLanguage}`);
      });
      change('email', email);
      change('ticket', ticket);
      change('win_pop_up', win);
      change('main.prize_names', prizeNames);
    }

    change('main.language', isCommon ? COMMON_INPUT : defaultLanguage);
  };

  const handlePrizeTypeChange = (type) => {
    let loyaltyOnlyValue = false;
    if (type === PRIZE_TYPES.LOYALTY) {
      loyaltyOnlyValue = true;
    } else if (type === PRIZE_TYPES.COUPON) {
      if ([formValues.ticket.visual_type, formValues.email.email_code_type].includes(PRIZE_CODE_TYPES.BAR_CODE)) {
        change('coupon.barcode_format', PRIZE_BARCODE_FORMAT.EAN_8);
      } else {
        change('coupon.barcode_format', PRIZE_BARCODE_FORMAT.NONE);
      }
    } else if (type === PRIZE_TYPES.COMMON) {
      change('coupon.barcode_format', PRIZE_BARCODE_FORMAT.NONE);
    }

    change('main.loyalty_only', loyaltyOnlyValue);
  };

  return (
    <div className={b()}>
      <LayoutPanel bgColor="main-layout-bg" className={b('type-block')}>
        <div className={b('type-row')}>
          <SelectField
            label={lang.PRIZE_TYPE}
            name="main.type"
            inputHeight="small"
            className={b('prize-type')}
            options={getPrizeTypeOptions(client, lang)}
            disabled={disabled}
            onChange={handlePrizeTypeChange}
            simpleValue
          />
          {prizeType === PRIZE_TYPES.COUPON && (
            <span className={b('coupon-name')}>{get(formValues, 'coupon.internal_name')}</span>
          )}
          <NumberField name="main.level" label={lang.LEVEL} className={b('level-field')} inputHeight="small" />
          <div className={b('toggle-distribution')}>
            <span className={b('distribution-label')}>{lang.DISTRIBUTION}</span>
            <ToggleField name="main.distribution" rounded />
          </div>
        </div>
        {prizeType === PRIZE_TYPES.LOYALTY && (
          <div className={b('loyalty-fields')}>
            <NumberField name="main.loyalty_amount" label={lang.AMOUNT} required={true} inputHeight="small" />
            <TextField name="main.loyalty_unit" label={lang.UNIT} inputHeight="small" />
          </div>
        )}
      </LayoutPanel>
      {prizeType === PRIZE_TYPES.COUPON && client.loyalty_program_id && (
        <SelectField
          label={lang.LOYALTY_MODE}
          name="main.loyalty_coupon_type"
          className={b('loyalty-mode')}
          options={[
            {label: lang.NONE, value: LOYALTY_COUPON_TYPES.NONE},
            {label: lang.OPTIONAL, value: LOYALTY_COUPON_TYPES.OPTIONAL},
            {label: lang.MANDATORY, value: LOYALTY_COUPON_TYPES.MANDATORY},
          ]}
          disabled={disabled}
          simpleValue
        />
      )}
      {client.loyalty_program_id && (
        <CheckboxField label={lang.LOYALTY_ONLY} name="main.loyalty_only" disabled={disabled} />
      )}
      <div className={b('representation-row')}>
        <ToggleField
          name="main.email_send"
          label={lang.SEND_EMAIL}
          backgroundColor="current"
          borderColor="current"
          disabled={disabled}
          rounded
        />
        <ToggleField
          name="main.sms_send"
          label={lang.SEND_SMS}
          backgroundColor="current"
          borderColor="current"
          disabled={disabled}
          rounded
        />
        <ToggleField
          name="main.create_ticket"
          label={lang.CREATE_TICKET}
          backgroundColor="current"
          borderColor="current"
          disabled={disabled}
          rounded
        />
      </div>
      <LanguageDevicePanel
        className={b('lang-group')}
        languages={languageOptions}
        language={language}
        onChangeLanguage={(value) => change('main.language', value)}
        langInitiallyCommon={langUniqueMode}
        onLanguageCommonToggle={handleLanguageCommonToggle}
        prefix="main-language"
      />
    </div>
  );
};

PrizeDiyMainFieldset.propTypes = {
  disabled: PropTypes.bool.isRequired,
  langUniqueMode: PropTypes.bool,
};

PrizeDiyMainFieldset.defaultProps = {
  langUniqueMode: false,
};

export default PrizeDiyMainFieldset;
