import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

import LayoutPanel from 'client/common/panels/layout-panel';

import PrizeDiyMainFieldset from 'client/components/prizes/fieldsets/prize-diy-main-fieldset';
import PrizeDiySegmentationFieldset from 'client/components/prizes/fieldsets/prize-diy-segmentation-fieldset';
import PrizeDiyQuantitiesTable from 'client/components/prizes/tables/prize-diy-quantities-table';

import PrizeDiyModalRepresentation from '../prize-diy-modal-representation';

import './prize-diy-modal-general.scss';

const b = bem('prize-diy-modal-general');

const PrizeDiyModalGeneral = ({prize, disabled, hasWins}) => {
  return (
    <div className={b()}>
      <div className={b('column-left')}>
        <PrizeDiyMainFieldset disabled={disabled} langUniqueMode={prize?.language_unique_mode} />
        <PrizeDiyModalRepresentation disabled={disabled} className={b('representation')} />
      </div>
      <LayoutPanel bgColor="main-layout-bg" className={b('column-right')}>
        <PrizeDiyQuantitiesTable prize={prize} />
        <PrizeDiySegmentationFieldset hasWins={hasWins} disabled={disabled} />
      </LayoutPanel>
    </div>
  );
};

PrizeDiyModalGeneral.propTypes = {
  prize: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
};

PrizeDiyModalGeneral.defaultProps = {
  prize: null,
};

export default PrizeDiyModalGeneral;
