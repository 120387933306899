import React from 'react';

import placeholder from 'assets/icons/colored/pic-placeholder.svg';
import cn from 'classnames';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {createObjectUrl} from 'client/services/helpers';

import FileButton from 'client/common/buttons/file-button';
import ImageContainer from 'client/common/image-container';
import {ErrorMessage, RequiredLabel, WarningMessage} from 'client/common/inputs';

import {TranslationJsx} from 'client/models/language/types';

import cssModule from './image-input.module.scss';

const b = bem('image-input', {cssModule});

const ImageInput = (props) => {
  const {
    className,
    classNames,
    buttonClassName,
    label,
    name,
    value,
    onChange,
    errorMessage,
    warningMessage = '',
    width = 0,
    height = 0,
    acceptFormats,
    placeholderIcon,
    keepRatio,
    onBlur,
    disabled,
    disableClassNamePreview,
    imageStyle,
    classNamePreview,
    required = false,
    requirementsComment = '',
  } = props;

  const handleChange = (file) => {
    onChange(file);
    onBlur?.();
  };

  return (
    <div className={cn(b({error: !!errorMessage, warning: !!warningMessage}), className)}>
      {label && (
        <label htmlFor={name} className={cn(b('label'), classNames?.label)}>
          {label}
        </label>
      )}
      <div className={b('controls')}>
        <FileButton
          className={buttonClassName}
          name={name}
          disabled={disabled}
          label={
            <ImageContainer
              classNamePreview={cn(
                !disableClassNamePreview && b('preview', {'min-width': !width, 'min-height': !height}),
                classNamePreview,
              )}
              value={value ? createObjectUrl(value) : placeholderIcon}
              width={width}
              height={height}
              keepRatio={!!value && keepRatio}
              imageStyle={imageStyle}
            />
          }
          onChange={handleChange}
          acceptFormats={acceptFormats}
          asWrap
        />
        {requirementsComment && (
          <p className={cn(b('requirements'), classNames?.requirements)}>{requirementsComment}</p>
        )}
      </div>
      {!errorMessage && required && <RequiredLabel />}
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      {warningMessage && !errorMessage && <WarningMessage warningMessage={warningMessage} />}
    </div>
  );
};

ImageInput.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.shape({
    label: PropTypes.string,
  }),
  buttonClassName: PropTypes.string,
  label: TranslationJsx,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  errorMessage: PropTypes.oneOfType([PropTypes.array, TranslationJsx]),
  warningMessage: TranslationJsx,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  acceptFormats: PropTypes.string,
  imageStyle: PropTypes.object,
  placeholderIcon: PropTypes.string,
  keepRatio: PropTypes.bool,
  disabled: PropTypes.bool,
  disableClassNamePreview: PropTypes.bool,
  classNamePreview: PropTypes.string,
  onBlur: PropTypes.func,
  requied: PropTypes.bool,
  requirementsComment: PropTypes.string,
};

ImageInput.defaultProps = {
  className: null,
  classNames: null,
  buttonClassName: null,
  label: null,
  value: null,
  errorMessage: null,
  width: 86,
  height: 86,
  acceptFormats: '',
  placeholderIcon: placeholder,
  keepRatio: false,
  disabled: false,
  disableClassNamePreview: false,
  classNamePreview: '',
  onBlur: null,
  imageStyle: {},
};

export default ImageInput;
