import fileDownloadNative from 'js-file-download';
import {last} from 'lodash';
import find from 'lodash/find';
import moment from 'moment';
import shortid from 'shortid';

import {downLoadFileByLink} from 'client/services/fetch';

import {getToken, getEmail} from './cookie-data-source';

import store from '../../store';
import {API_URL, CLIENT_PAGES, NAV_GROUPS_MAP, SCENARIO_STEP_TYPES, MEDIA_MIME_TYPES} from '../common/config';

export {paginateList} from './utils/paginateList';

export function getAuthHeaders() {
  return {
    'Content-Type': 'application/json',
    'X-User-Email': getEmail(),
    'X-User-Token': getToken(),
  };
}

export function passwordRegExp() {
  const specialChars = '!?@#$%&^*.,:;()<>[]{}|\\/~_-=+`\'"'
    .split('')
    .map((c) => '\\' + c)
    .join();

  const length = '.{0,7}';
  const digit = '[^0-9]*';
  const upper = '[^A-Z]*';
  const lower = '[^a-z]*';
  const special = `[^${specialChars}]*`;

  const pattern = `^(${length}|${digit}|${upper}|${lower}|${special})$`;

  return new RegExp(pattern, 'g');
}

export function addHashToImage(name, hash) {
  const pattern = /\.(gif|jpg|jpeg|tiff|png)$/i;

  return name.replace(pattern, (str) => {
    return `.${hash}${str}`;
  });
}

export function checkFileType(file) {
  return new Promise((resolve, reject) => {
    let type;
    let isValid;
    const extension = file.name.split('.')[1];

    switch (extension) {
      case 'csv':
        type = 'text/csv';
        isValid = true;
        break;
      case 'xlsx':
        type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        isValid = true;
        break;
      default:
        type = file.type;
        isValid = false;
        break;
    }
    if (isValid) {
      resolve(type);
    } else {
      reject(new Error(type));
    }
  });
}

export function checkImageMimeType(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    let type;
    let isImage;

    reader.onload = (e) => {
      const arr = new Uint8Array(e.target.result).subarray(0, 4);
      const header = arr.reduce((sum, current) => {
        return sum + current.toString(16);
      }, '');

      switch (header) {
        case '89504e47':
          type = 'image/png';
          isImage = true;
          break;
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
          type = 'image/jpeg';
          isImage = true;
          break;
        case '47494638':
          type = 'image/gif';
          isImage = true;
          break;
        default:
          type = file.type;
          isImage = false;
          break;
      }

      if (isImage) {
        resolve(type);
      } else {
        reject(new Error(type));
      }
    };

    reader.onerror = () => {
      reject(new Error());
    };

    reader.readAsArrayBuffer(file);
  });
}

export function findInArrayOfObj(arr, searchField, objSearchField, objReturnField) {
  const resultArray = arr.filter((obj) => {
    return obj[objSearchField] === searchField;
  });
  return resultArray.length ? resultArray[0][objReturnField] : '';
}

export function transformAgencyData({poc_admin_user, poc_membership, poc_agency_membership, ...agency}) {
  return {
    ...agency,
    poc_admin_user,
    poc_buzzeo_email: poc_admin_user && poc_admin_user.email,
    poc_buzzeo_phone: poc_admin_user && poc_admin_user.phone,
    poc_membership,
    poc_client_phone: poc_membership && poc_membership.phone,
    poc_agency_membership,
    poc_agency_membership_phone: poc_agency_membership && poc_agency_membership.phone,
  };
}

export function mapSelectList(list, field) {
  const idlist = list?.map(({id}) => id) || [];

  return (
    list
      ?.map((el) => {
        let res = {};

        res = {
          value: String(el.id),
          label: field ? el[field] : el.name,
        };

        if (el.custom) {
          res.selectCustomOption = el.custom;
        }

        if (el.type) {
          res.type = el.type;
        }

        return res;
      })
      .filter((el, index) => idlist.indexOf(Number(el.value)) === index) || []
  );
}

/**
 * Transforms date from 'YYYY-MM-DD' to 'DD:MM:YYYY'.
 * @param {String} date Date in 'YYYY-MM-DD'.
 * @returns {*|string} Transformed date.
 */
export function formatDate(date) {
  return `${date.substr(8, 2)}.${date.substr(5, 2)}.${date.substr(0, 4)}`;
}

/**
 * Transforms date from 'YYYY-MM-DDZHH:mm:ss' to 'HH:mm'.
 * @param {String} date Date in 'YYYY-MM-DDZHH:mm:ss'.
 * @returns {*|string} Transformed date.
 */
export function formatTime(date) {
  if (!date) {
    return '';
  }
  return `${date.substr(11, 2)}:${date.substr(14, 2)}`;
}

/**
 * Transforms date from 'YYYY-MM-DDZHH:mm:ss' to 'HH:mm:ss'.
 * @param {String} date Date in 'YYYY-MM-DDZHH:mm:ss'.
 * @returns {*|string} Transformed date.
 */
export function formatTimeWithSeconds(date) {
  const localTime = transformDateTimeZone(date);
  return `${localTime.substr(11, 2)}:${localTime.substr(14, 2)}:${localTime.substr(17, 4)}`;
}

export function getQueryParams(params, obj) {
  let query = '';
  for (let key of Object.keys(params)) {
    if (Array.isArray(params[key])) {
      for (let p of params[key]) {
        query += `${key}[]=${p}&`;
      }
    } else if (typeof params[key] === 'object') {
      query += getQueryParams(params[key], key);
    } else if (typeof params[key] !== 'undefined') {
      query += `${obj ? obj + '[' + key + ']' : key}=${params[key]}&`;
    }
  }

  return query;
}

export function mapFilter(data) {
  let obj = Object.assign({}, data);

  if (obj.no_operations && obj.no_operations.value === '0') {
    delete obj.no_operations;
  }

  if (
    (obj.no_operations_num && !obj.no_operations) ||
    (obj.no_operations_num && obj.no_operations && obj.no_operations.value === '0')
  ) {
    delete obj.no_operations_num;
    delete obj.no_operations;
  }

  if (obj.poc_admin_user_id && obj.poc_admin_user_id.value === '0') {
    delete obj.poc_admin_user_id;
  }
  return obj;
}

export function compareFilters(filter1, filter2, cb) {
  const state1 = cb ? cb(filter1) : mapFilter(filter1);
  const state2 = cb ? cb(filter2) : mapFilter(filter2);
  return JSON.stringify(state1) === JSON.stringify(state2);
}

export function mapRegions(user, regions) {
  const result = regions.map((i) => ({...i, checked: false}));
  if (user.region_accesses?.length) {
    for (let region_access of user.region_accesses) {
      const region_id = doesValueExist(region_access.region) ? region_access.region.id : region_access.region_id;
      const found = find(result, (i) => i.id === region_id);

      if (found) {
        found.checked = true;
      }
    }
  }
  return result;
}

export function mapFreeStores(user, places) {
  const result = places.map((i) => ({...i, checked: false}));
  if (user.place_accesses && user.place_accesses.length) {
    for (let place_access of user.place_accesses) {
      const place_id = doesValueExist(place_access.place) ? place_access.place.id : place_access.place_id;
      const found = find(result, (i) => i.id === place_id);

      if (found) {
        found.checked = true;
      }
    }
  }
  return result;
}

export function mapRegionsWithStores(user, regions) {
  const result = regions.map((i) => ({...i, places: i.places.map((p) => ({...p, checked: false}))}));
  if (user.place_accesses && user.place_accesses.length) {
    const places = result.reduce((array, region) => array.concat(region.places), []);
    for (let place_access of user.place_accesses) {
      const place_id = doesValueExist(place_access.place) ? place_access.place.id : place_access.place_id;
      const found = find(places, (i) => i.id === place_id);

      if (found) {
        found.checked = true;
      }
    }
  }
  return result;
}

export const addressTransform = (() => {
  const DELIMETER = '###';

  /**
   * @param {Array.<string>} addressArray
   * @return {string}
   */
  function joinAddress(addressArray) {
    return addressArray.join(DELIMETER);
  }

  /**
   * @param {string} addressString
   * @return {Array.<string>}
   */
  function splitAddress(addressString) {
    return addressString.split(DELIMETER);
  }

  return {
    joinAddress,
    splitAddress,
  };
})();

export function doesValueExist(value) {
  return typeof value !== 'undefined' && value !== null;
}

export function replaceText(regex, text, replace) {
  let res;

  try {
    res = text.replace(regex, replace);
  } catch (e) {
    res = '';
  }

  return res;
}

/**
 * Transforms date from 'YYYY-MM-DD' to 'DD/MM/YYYY' and vice versa.
 * @param {String} date Date in 'YYYY-MM-DD' or 'DD/MM/YYYY' format.
 * @param {Boolean} forFrontend True => 'DD/MM/YYYY'; false => 'YYYY-MM-DD'.
 * @param {Boolean} withTime True => 'DD/MM/YYYY hh:mm:ss'; false => 'DD/MM/YYYY'.
 * @returns {*|string} Transformed date.
 */
export function transformDate(date, forFrontend = true, withTime = false) {
  if (forFrontend) {
    let result = '';
    if (date && date !== 'N/A') {
      result = withTime ? transformDateTimeZone(date) : dateToString(date);
    }
    return result || '';
  }
  return date && date.split('/').reverse().join('-');
}

export function transformDateTimeZone(date) {
  return moment(date).format('DD/MM/YYYY HH:mm:ss');
}

export function isEmail(str = '') {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return regex.test(str?.toLowerCase());
}

export function isPhone(str = '') {
  if (str?.lastIndexOf('+') > 0) {
    return false;
  }
  const normalPhone = str?.replace(/[ \-)(]+/g, '').replace(/^0/, '+33') || '';
  return /^[+0-9][0-9]+$/.test(normalPhone) && normalPhone;
}

export function uid(prefix = '') {
  return prefix.length ? `${prefix}-${shortid()}` : shortid();
}

export function updateInArray(array, data, id) {
  return [...array].map((v) => {
    if (v.id === (id || data.id)) {
      return {...v, ...data};
    }

    return v;
  });
}

export function removeFromArray(array, id) {
  return [...array].filter((v) => v.id !== id);
}

// TODO: mb move this function?!
export function getScenarioStepDescription(implementation, variables, stepData = {}) {
  const {languageState} = store({}).getState();
  const language = languageState.payload.AUTOTASK_SCENARIO.STEP;

  switch (implementation.type) {
    case SCENARIO_STEP_TYPES.branch: //eslint-disable-line
      const {operator, branch_scenario_step_conditions, scenario_id, scenario} = implementation;
      const labels = {
        any: language.BRANCH.CONDITION.ANY,
        all: language.BRANCH.CONDITION.ALL,
        '==': language.BRANCH.CONDITION.EQUALS,
        '>': language.BRANCH.CONDITION.IS_GREATER_THAN,
        '<': language.BRANCH.CONDITION.IS_LESS_THAN,
        '>=': language.BRANCH.CONDITION.IS_GREATER_THAN_OR_EQUALS,
        '<=': language.BRANCH.CONDITION.IS_LESS_THAN_OR_EQUALS,
        '!=': language.BRANCH.CONDITION.DOES_NOT_EQUALS_OPTION,
        is_null: language.BRANCH.CONDITION.IS_NULL_OPTION,
        is_not_null: language.BRANCH.CONDITION.IS_NOT_NULL_OPTION,
        in: language.BRANCH.CONDITION.IN_OPTION,
        includes: language.BRANCH.CONDITION.INCLUDES_OPTION,
      };

      const end = scenario_id ? `${language.BRANCH.GO_TO_SCENARIO} ${scenario.name}` : language.BRANCH.STOP;

      const conditionsString = branch_scenario_step_conditions.map((v) => {
        const variable = variables.find((_variable) => _variable.id === v.variable_id);

        if (variable) {
          const valueVariable = variables.find((_variable) => _variable.id === v.value_variable_id);
          let value;

          if (v.value_variable_id && valueVariable) {
            value = valueVariable.full_name || valueVariable.name;
          } else if (['is_null', 'is_not_null'].includes(v.operator)) {
            value = null;
          } else {
            value = v.value;
          }

          return [variable.full_name || variable.name, labels[v.operator], value].filter((item) => item).join(' ');
        }

        return '';
      });

      if (conditionsString.length > 0) {
        return `${language.BRANCH.CONDITION.IF} ${conditionsString.join(` ${labels[operator]} `)}, ${end}`;
      }

      return end;

    case SCENARIO_STEP_TYPES.calculation:
      return stepData.comment;

    default:
      return '';
  }
}

// TODO: maybe delete this function and use <CustomLink> component instead?
export function getClientPage(client = {}) {
  if (client.type === 'Agency') {
    return CLIENT_PAGES.AGENCIES;
  }
  return CLIENT_PAGES.COMPANIES;
}

export function getFileSize(sizeInBytes, digits = 2) {
  const measurements = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  let measurementIndex = 0;
  let size = sizeInBytes || 0; // backend don't know 'undefined'

  while (size > 1023 && measurementIndex < measurements.length - 1) {
    size /= 1024;
    measurementIndex++;
  }

  return Number(size.toFixed(digits)) + measurements[measurementIndex];
}

/**
 *
 * @param {string} birthday in format YYYY-MM-DDThh:mm:ss.mlsZ
 * @returns {number | null} age
 */
export function calculateAge(birthday) {
  if (!birthday) {
    return null;
  }
  const ageDifMs = Date.now() - new Date(birthday).getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function downloadFile(url) {
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.download = url;
  a.href = url;
  a.click();
  document.body.removeChild(a);
}

export function downloadFileWithAuth(link, name = null) {
  const linkURLArray = link.split('?')[0].split('/');
  const fileName = name || linkURLArray[linkURLArray.length - 1];
  downLoadFileByLink(link).then((url) => {
    let tempLink = document.createElement('a');
    tempLink.href = url;
    tempLink.setAttribute('download', fileName);
    tempLink.click();
  });
}

/**
 * Takes deep value from object.
 * @param {Object} object source
 * @param {Array<String>} keys sequence of keys
 * @returns {*} object.key1.key2.<...>.keyN
 */
export function getValueIfExists(object, keys = []) {
  let result = object;
  for (let key of keys) {
    result = result && result[key];
  }
  return result;
}

export function swap(array = [], index1, index2) {
  const result = [...array];

  const tmp = result[index1];
  result[index1] = result[index2];
  result[index2] = tmp;

  return result;
}

export function urlWithoutLastSlash(url = '') {
  return url.endsWith('/') ? url.slice(0, -1) : url;
}

/**
 * Creates string from values.
 * Values are taken from "map" object by keys from "keys" array.
 * @param {Array<String>} keys sequence of keys
 * @param {Object} mappedObj source
 * @returns {String} string
 */
export function getStringFromArrayMap(keys = [], mappedObj = {}) {
  const str = keys
    .reduce((result, current) => {
      return mappedObj[current] ? result + mappedObj[current] + ', ' : result;
    }, '')
    .trim()
    .slice(0, -1);

  return str;
}

/**
 * Creates new array with element
 * @param {Array} arr Initial array
 * @param {Any} item Element
 * @returns {Array} New array with item
 */
export function getArrayWithElement(arr = [], item) {
  return [...arr, item];
}

/**
 * Reverses given string.
 * @param {String} str input string
 * @returns {String} reversed string
 */
export function reverseString(str) {
  return str.split('').reverse().join('');
}

/**
 * Leaves only certain keys in the object
 * @param {Object} obj
 * @param {Array} removedKeys
 * @return {Object}
 */

export function filterOnKeys(obj = {}, removedKeys = []) {
  const result = {...obj};
  removedKeys.forEach((key) => delete result[key]);

  return result;
}

export function getHomeDashboard(dashboards = []) {
  return dashboards.find((dashboard) => dashboard.kind === NAV_GROUPS_MAP.HOME && dashboard.visible);
}

export function getHomeOrFirstDashboard(dashboards = []) {
  return getHomeDashboard(dashboards) || dashboards.find((dashboard) => dashboard.visible);
}

export function dateToString(date, format) {
  if (!date) {
    return '';
  }

  return moment(date).format(format || 'DD/MM/YYYY');
}

export function timeToString(date, format) {
  if (!date) {
    return '';
  }

  return moment(date).format(format || 'HH:mm:ss');
}

export function dayToString(date, format) {
  if (!date) {
    return '';
  }

  return moment(date).format(format || 'dddd');
}

export function isDateValid(date) {
  return moment(date).isValid();
}

export function maskEmail(email) {
  const firstPart = email.split('@');
  const secondPart = firstPart[1].split('.');

  const name = firstPart[0];
  const host = secondPart[0];
  const domain = secondPart[1];

  const maskedName = name
    .split('')
    .map((char, index) => {
      return index < 3 ? char : '*';
    })
    .join('');

  const maskedHost = host
    .split('')
    .map((char, index) => {
      return index === 0 ? char : '*';
    })
    .join('');

  return `${maskedName}@${maskedHost}.${domain}`;
}

export function convertSecondsToDays(secs) {
  const timeArr = [
    Math.floor(secs / (3600 * 24)),
    Math.floor((secs % (3600 * 24)) / 3600),
    Math.floor((secs % 3600) / 60),
    Math.floor(secs % 60),
  ];
  return (
    timeArr
      // eslint-disable-next-line consistent-return
      .map((elem, idx) => {
        if (elem) {
          switch (idx) {
            case 0:
              return `${elem} d `;

            case 1:
              return `${elem} h `;

            case 2:
              return `${elem} m `;

            case 3:
              return `${elem} s`;

            default:
              break;
          }
        }
      })
      .join('')
  );
}

export function getDaysDiff(date) {
  const now = moment(new Date());
  const target = moment(new Date(date));

  const diffDays = target.diff(now, 'days');
  const diffMinutes = target.diff(now, 'minutes');

  if (diffDays < 1 && diffMinutes > 1) {
    return 1;
  }

  return diffDays;
}

export function getMinutesDiff(date) {
  const now = moment(new Date());
  const target = moment(new Date(date));

  return target.diff(now, 'minutes');
}

export function getDatesDiff(date1, date2) {
  let transformedFirstDate = moment(date1, 'DD-MM-YYYY');
  let transformedLastDate = moment(date2, 'DD-MM-YYYY');

  return transformedFirstDate.diff(transformedLastDate, 'days');
}

export const getPercent = (total, value, percentSign = true, roundMethod = 'round') => {
  if (!total || !value) {
    return percentSign ? 0 + '%' : 0;
  }

  const result = Math[roundMethod]((value / total) * 100);

  return percentSign ? result + '%' : result;
};

export const getCeilPercent = (total, value, percentSign = true) => {
  return getPercent(total, value, percentSign, 'ceil');
};

export const getFloorPercent = (total, value, percentSign = true) => {
  return getPercent(total, value, percentSign, 'floor');
};

export function generateColor() {
  return '#' + Math.floor(Math.random() * 16777215).toString(16);
}

export function getOrGenerateColor(colors, index) {
  return colors[index] || generateColor();
}

export const prettyJoin = (array, delimiter = ' ') => {
  return array.filter((item) => item || item === 0).join(delimiter);
};

// sorting names. Sort an Array of Objects in JavaScript
export function sortNames(a, b) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

export const isNumberString = (value) => {
  return /^-?\d+$/.test(value);
};

export function isPrimitive(test) {
  return test !== Object(test);
}

export const isObject = (val) => typeof val === 'object' && !Array.isArray(val) && val !== null;

export const isEmpty = (val) =>
  (Array.isArray(val) && !val.length) ||
  (isObject(val) && !Object.keys(val).length) ||
  (isObject(val) &&
    Object.keys(val).length &&
    !Object.values(val).filter((value) => {
      return isPrimitive(value) ? value : !isEmpty(value);
    }).length);

export const generateRandomString = (length = 5) => {
  return Math.random()
    .toString(36)
    .slice(2, length + 2);
};

export const createObjectUrl = (fileOrUrl) => {
  if (typeof fileOrUrl === 'string') {
    return fileOrUrl;
  } else if (fileOrUrl) {
    return URL.createObjectURL(fileOrUrl);
  }
  return '';
};

export const numberWithSpaces = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const fetchFile = async (url) => {
  const urlObject = new URL(url);
  const response = await fetch(url, {
    headers: {
      'Cache-Control': 'no-cache',
      Connection: 'keep-alive',
      Host: urlObject.host,
      Origin: API_URL,
      Pragma: 'no-cache',
      Referer: API_URL,
      'Sec-Fetch-Dest': 'empty',
      'Sec-Fetch-Mode': 'cors',
      'Sec-Fetch-Site': 'cross-site',
    },
  });
  return response;
};

/**
 * Method to download any file
 * @param {Blob} blob - Blob object
 * @param {string?} filename - Name of file
 * @return {void}
 */
export const fileDownload = (blob, filename) => {
  const type = blob.type;
  const ext = last(type.split('/'));

  fileDownloadNative(blob, filename || `download.${ext}`);
};

/**
 * Method to fetch and download any file
 * @param {string} url - url of file's source
 * @param {string?} filename - Name of file
 * @return {void}
 */
export const getAndDownloadFile = async (url, filename = '') => {
  const response = await fetchFile(url);
  const blob = await response.blob();

  fileDownload(blob, filename);
};

export const wait = (n) => new Promise((resolve) => setTimeout(resolve, n));
export const getFileName = (url) => {
  const regex = /filename=([^&]*)/;
  const match = url?.match(regex);

  if (!match) {
    return;
  }

  return url.match(/filename=([^&]*)/)[1];
};

export const getMediaType = (value) => {
  const filename = value?.data?.media_storage?.file?.filename || getFileName(value) || '';
  const extension = filename.split('.').pop().toLowerCase();

  if (MEDIA_MIME_TYPES[extension]) {
    return {
      filename: filename,
      mediaType: MEDIA_MIME_TYPES[extension],
    };
  }

  return {
    filename: null,
    mediaType: null,
  };
};
