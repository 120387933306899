import React, {Component} from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {dateToString} from 'client/services/helpers';

import {setHeaderLinkBack, clearHeaderLinkBack} from 'client/ducks/header-link-back/actions';
import {selectClientParams} from 'client/ducks/operations/selectors';

import CustomLink from 'client/components/common/custom-link';
import Icon from 'client/components/common/icon';

import ClientAutotaskMenu from 'client/components/client-autotask/client-autotask-menu';

import './client-autotask-subheader.scss';

class ClientAutotaskSubheader extends Component {
  static propTypes = {
    autotask: PropTypes.object.isRequired,
    operation: PropTypes.shape({
      client_id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    }).isRequired,
    activeTab: PropTypes.string.isRequired,
    lang: PropTypes.object.isRequired,
    ...withRouter.propTypes,
    accessLevel: PropTypes.string.isRequired,
    clientParams: PropTypes.string,
    setHeaderLinkBack: PropTypes.func,
    clearHeaderLinkBack: PropTypes.func,
  };

  static defaultProps = {
    clientParams: '',
  };

  constructor(props) {
    super(props);

    this.state = {
      scrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    this.props.setHeaderLinkBack({
      name: this.props.autotask.name,
      rootPage: false,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.props.clearHeaderLinkBack();
  }

  handleScroll = () => {
    const offset = window.pageYOffset;

    if (offset > 1 && !this.state.scrolled) {
      this.setState({scrolled: true});
    } else if (offset === 0 && this.state.scrolled) {
      this.setState({scrolled: false});
    }
  };

  handleBackClick = () => {
    const {operation, history, clientParams} = this.props;

    const link = CustomLink.createClientOperationsLink({
      clientId: clientParams?.clientId || operation.client_id,
    });
    history.push(link + clientParams?.qs || '');
  };

  render() {
    const {autotask, operation, activeTab, accessLevel, lang} = this.props;
    const {scrolled} = this.state;
    const imageUrl = get(autotask, 'image.url');

    return (
      <div className={cn('client-autotask-subheader', {'client-autotask-subheader--scrolled': scrolled})}>
        <div className="client-autotask-subheader__content">
          <div className="client-autotask-subheader__autotask">
            <div className="client-autotask-subheader__image">
              {imageUrl ? (
                <img className="client-autotask-subheader__image-pic" src={imageUrl} alt="" />
              ) : (
                <Icon className="client-autotask-subheader__image-pic" name="task-image-placeholder" />
              )}
            </div>
            <div className="client-autotask-subheader__autotask-name" title={autotask.name}>
              <Icon name="arrow-left" className="client-autotask-subheader__back" onClick={this.handleBackClick} />
              {autotask.name}
            </div>
            <ClientAutotaskMenu
              autotask={autotask}
              operation={operation}
              activeTab={activeTab}
              accessLevel={accessLevel}
            />
          </div>
          <div className="client-autotask-subheader__operation">
            <div className="client-autotask-subheader__operation-name">
              <span className="client-autotask-subheader__operation-label">{lang.OPERATION}</span>
              <span className="client-autotask-subheader__operation-value" title={operation.name}>
                {operation.name}
              </span>
            </div>
            <div className="client-autotask-subheader__operation-period">
              <span className="client-autotask-subheader__operation-label">{lang.PERIOD}</span>
              <span className="client-autotask-subheader__operation-value">
                {dateToString(operation.from)} - {dateToString(operation.to)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({
      lang: state.languageState.payload.CLIENT_AUTOTASK_SUBHEADER,
      clientParams: selectClientParams(state),
    }),
    {
      setHeaderLinkBack,
      clearHeaderLinkBack,
    },
  )(ClientAutotaskSubheader),
);
