import React from 'react';

import cn from 'classnames';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import ReactQueryParams from 'react-query-params';
import {connect} from 'react-redux';
import {reset, initialize, change, formValueSelector} from 'redux-form';

import {getAvailableColumnAdaptersByOperation} from 'client/ducks/column-adapters/actions';
import {getInterfaceTemplateItemsAction} from 'client/ducks/interface-templates/actions';
import {selectActiveTemplateItems} from 'client/ducks/interface-templates/selectors';
import {
  getInterfacesAction,
  createInternalInterface,
  clearInterfacesState,
  patchInterfaceLevels,
} from 'client/ducks/interfaces/actions';
import {selectInterfaces, selectInterfacesMeta} from 'client/ducks/interfaces/selectors';
import {getOptInsAction} from 'client/ducks/opt-in-columns/actions';
import {OPT_IN_TYPES} from 'client/ducks/opt-in-columns/constants';
import {createSGInterfaceAction, clearSGInterfacesState} from 'client/ducks/sg-interfaces/actions';
import {getNewSGInterfaceAction} from 'client/ducks/sg-interfaces/actions';
import {selectActiveSGInterfaceItems} from 'client/ducks/sg-interfaces/selectors';
import {createUnbounceInterfaceAction, clearUnbounceInterfacesState} from 'client/ducks/unbounce-interface/actions';
import {getNewUnbounceInterfaceAction} from 'client/ducks/unbounce-interface/actions';
import {selectActiveUnbounceInterfaceItems} from 'client/ducks/unbounce-interface/selectors';

import {INTERFACE_FAMILIES} from 'client/common/config';

import Icon from 'client/components/common/icon';

import UpdateSettingsModal, {
  UpdateSettingsModalFormName,
} from 'client/components/autotask/autotask-interfaces/components/modals/update-settings-modal';

import InterfaceList from './components/lists/interface-list';
import InterfaceIdModal from './components/modals/interface-id-modal';
import MapItemsModal from './components/modals/map-items-modal';
import SetInterfaceFamilyModal from './components/modals/set-interface-family-modal';
import SetInterfaceLevelsModal from './components/modals/set-interface-levels-modal';
import {mapLevelsForSave} from './components/modals/set-interface-levels-modal/helpers';
import SetInterfaceNameModal from './components/modals/set-interface-name-modal';
import SetItemsModal from './components/modals/set-items-modal';
import UpdatedItemsModal from './components/modals/updated-items-modal';

import AutotaskCard from '../components/cards/autotask-card/autotask-card';

class AutotaskInterfaces extends ReactQueryParams {
  static propTypes = {
    archived: PropTypes.bool,
    languageState: PropTypes.object.isRequired,
    mappingItems: PropTypes.array,
    interfaces: PropTypes.arrayOf(PropTypes.object).isRequired,
    autotaskId: PropTypes.number.isRequired,
    reset: PropTypes.func.isRequired,
    initialize: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    fetchTemplateItems: PropTypes.func.isRequired,
    clearInterfacesState: PropTypes.func.isRequired,
    clearSGInterfacesState: PropTypes.func.isRequired,
    clearUnbounceInterfacesState: PropTypes.func.isRequired,
    fetchInterfaces: PropTypes.func.isRequired,
    getOptIns: PropTypes.func.isRequired,
    fetchNewSGInterface: PropTypes.func.isRequired,
    fetchNewUnbounceInterface: PropTypes.func.isRequired,
    fetchColumnAdapters: PropTypes.func.isRequired,
    interfaceName: PropTypes.string,
    interfaceFamily: PropTypes.string.isRequired,
    templateId: PropTypes.number,
    operationId: PropTypes.number,
    clientId: PropTypes.number,
    companiesId: PropTypes.number.isRequired,
    interfaceFormValues: PropTypes.object.isRequired,
    createInternalInterface: PropTypes.func.isRequired,
    meta: PropTypes.object.isRequired,
    patchInterfaceLevels: PropTypes.func.isRequired,
  };

  static Modals = {
    SET_INTERFACE_NAME: {
      id: 'SET_INTERFACE_NAME',
      form: SetInterfaceNameModal.formName,
    },
    SET_INTERFACE_FAMILY_NAME: {
      id: 'SET_INTERFACE_FAMILY_NAME',
      form: SetInterfaceFamilyModal.formName,
    },
    SET_INTERFACE_LEVELS: {
      id: 'SET_INTERFACE_LEVELS',
      form: SetInterfaceLevelsModal.formName,
    },
    SET_INTERFACE_ITEMS: {
      id: 'SET_INTERFACE_ITEMS',
      form: SetItemsModal.formName,
    },
    SET_SURVEY_INTERFACE_ITEMS: {
      id: 'SET_SURVEY_INTERFACE_ITEMS',
      form: SetItemsModal.formName,
    },
    SET_UNBOUNCE_INTERFACE_ITEMS: {
      id: 'SET_UNBOUNCE_INTERFACE_ITEMS',
      form: SetItemsModal.formName,
    },
    MAP_ITEMS: {
      id: 'MAP_ITEMS',
      form: MapItemsModal.formName,
    },
    UPDATE_SETTINGS_MODAL: {
      id: 'UPDATE_SETTINGS_MODAL',
      form: UpdateSettingsModalFormName,
    },
    INTERFACE_ID_MODAL: {
      id: 'INTERFACE_ID_MODAL',
      form: InterfaceIdModal.formName,
    },
    UPDATED_SURVEY_ITEMS: {
      id: 'UPDATED_SURVEY_ITEMS',
    },
    UPDATED_UNBOUNCE_ITEMS: {
      id: 'UPDATED_UNBOUNCE_ITEMS',
    },
  };

  static DEFAULT_FIRST_PAGE = 1;
  static DEFAULT_PER_PAGE = 5;

  constructor(props) {
    super(props);

    this.state = {
      activeModal: null,
      initialTemplateItems: [],
      activeInterfaceItemIndex: null,
      canCreateInterface: false,
      levelsValues: null,
      interfaceId: null,
    };

    this.LANGUAGE = props.languageState.payload.AUTOTASK;
  }

  componentDidMount() {
    Promise.all([
      this.fetchInterfaces(),
      this.props.fetchColumnAdapters(this.props.operationId),
      this.props.getOptIns({
        q: {
          client_id_eq: this.props.clientId,
          opt_in_type_in: [OPT_IN_TYPES.STRUCTURAL, OPT_IN_TYPES.PUNCTUAL],
        },
        include: ['opt_in_column_adapter'],
      }),
    ]);
  }

  componentWillUnmount() {
    this.props.clearInterfacesState();
    this.props.clearSGInterfacesState();
    this.props.clearUnbounceInterfacesState();
  }

  fetchInterfaces = () => {
    const {interfacesPage} = this.queryParams;

    return this.props.fetchInterfaces({
      include: [
        {
          web_app: null,
          interface_items: {
            scenario_variables: null,
            mapping_items: [
              'scenario',
              {
                column_adapter: 'opt_in_column',
              },
            ],
          },
        },
        {
          interface_levels: {
            scenarios: null,
            interface_level_scenarios: null,
            screenshots: null,
          },
        },
      ],
      q: {
        s: 'updated_at DESC',
        automation_task_id_eq: this.props.autotaskId,
      },
      page: interfacesPage || AutotaskInterfaces.DEFAULT_FIRST_PAGE,
      per_page: AutotaskInterfaces.DEFAULT_PER_PAGE,
    });
  };

  handlePageChange = (page) => {
    this.setQueryParams({
      interfacesPage: page,
    });
    return this.fetchInterfaces();
  };

  handleModalOpen = (key) =>
    this.setState({
      activeModal: key,
    });

  handleSetItemsModalOpen = () => {
    return this.setState({canCreateInterface: true}, () => {
      switch (this.props.interfaceFamily) {
        case INTERFACE_FAMILIES.SURVEY_GIZMO:
          return this.handleModalOpen(AutotaskInterfaces.Modals.SET_SURVEY_INTERFACE_ITEMS.id);
        case INTERFACE_FAMILIES.UNBOUNCE:
          return this.handleModalOpen(AutotaskInterfaces.Modals.SET_UNBOUNCE_INTERFACE_ITEMS.id);
        default:
          return this.handleModalOpen(AutotaskInterfaces.Modals.SET_INTERFACE_ITEMS.id);
      }
    });
  };

  handleActiveModalClose = () => {
    this.setState(
      {
        activeModal: null,
        levelsValues: null,
      },
      () => Object.values(AutotaskInterfaces.Modals).forEach((item) => this.props.reset(item.form)),
    );
  };

  handleConfirmUpdateSettings = () => {
    return this.createInterface().then((data) => {
      if (data.error) {
        this.handleActiveModalClose();
      } else {
        this.fetchInterfaces().then(() => this.handleModalOpen(AutotaskInterfaces.Modals.INTERFACE_ID_MODAL.id));
      }
    });
  };

  handleClearMapItems = () => {
    return this.props.change(AutotaskInterfaces.Modals.MAP_ITEMS.form, 'map_items', []);
  };

  handleClearMapItemsByIndex = (index) => {
    return this.props.change(AutotaskInterfaces.Modals.MAP_ITEMS.form, `map_items.${index}`, null);
  };

  handleUpdateIntagrationInterfaceItems = () => {
    const {integrationInterfaceId} = this.props.integrationInterfaceFormValues;

    if (this.props.interfaceFamily === INTERFACE_FAMILIES.SURVEY_GIZMO) {
      return this.props.fetchNewSGInterface({
        external_id: integrationInterfaceId,
      });
    }

    return this.props.fetchNewUnbounceInterface({
      external_id: integrationInterfaceId,
    });
  };

  mapInterfaceItems = (itemsData) => {
    const {mappingItems} = this.props;

    return itemsData.map((item, index) => {
      const itemData = {
        array: item.array || false,
        kind: item.kind,
        external_id: item.external_id,
        name: item.name,
      };

      if (itemData.kind === 'boolean') {
        itemData.value_as_true = item.value_as_true || '';
      }

      if (mappingItems[index]) {
        itemData.mapping_items = mappingItems[index].map((mappingItem) => {
          let transformedMappingItem = {
            type: mappingItem.type.type,
          };

          switch (mappingItem.type.type) {
            case 'DataMappingItem':
              transformedMappingItem.column_adapter_id = mappingItem.column?.value;
              transformedMappingItem.required = mappingItem.required || false;
              transformedMappingItem.unique = mappingItem.unique || false;
              break;

            case 'TriggerMappingItem':
              transformedMappingItem.scenario_id = mappingItem.column?.value;
              break;

            case 'OptInMappingItem':
              transformedMappingItem.column_adapter_id = mappingItem.type?.value;
              transformedMappingItem.opt_in_text = mappingItem.opt_in_text;
              transformedMappingItem.required = mappingItem.required || false;
              break;

            default:
              transformedMappingItem = {};
          }

          return transformedMappingItem;
        });
      }

      return itemData;
    });
  };

  mapInterfaceFormData = () => {
    const {templateId, autotaskId, interfaceName, interfaceCode} = this.props;
    const {itemsData, updateData} = this.props.interfaceFormValues;

    return {
      name: interfaceName,
      code: interfaceCode,
      automation_task_id: autotaskId,
      update_kind: updateData,
      interface_template_id: templateId,
      interface_items: this.mapInterfaceItems(itemsData),
    };
  };

  mapSurveyInterfaceFormData = () => {
    const {autotaskId, interfaceName, interfaceCode} = this.props;
    const {updateData, itemsData} = this.props.interfaceFormValues;
    const {integrationInterfaceId, integrationInterfaceDescription} = this.props.integrationInterfaceFormValues;

    return {
      name: interfaceName,
      code: interfaceCode,
      automation_task_id: autotaskId,
      update_kind: updateData,
      external_id: integrationInterfaceId,
      description: integrationInterfaceDescription,
      interface_items: this.mapInterfaceItems(itemsData),
    };
  };

  createInterface = () => {
    switch (this.props.interfaceFamily) {
      case INTERFACE_FAMILIES.SURVEY_GIZMO:
        return this.props.createSGInterfaceAction({
          survey_gizmo_interface: this.mapSurveyInterfaceFormData(),
        });
      case INTERFACE_FAMILIES.UNBOUNCE:
        return this.props.createUnbounceInterfaceAction({
          unbounce_interface: this.mapSurveyInterfaceFormData(),
        });
      default:
        return this.props
          .createInternalInterface(
            {
              internal_interface: this.mapInterfaceFormData(),
            },
            {
              include: [
                {
                  interface_items: {
                    mapping_items: {
                      column_adapter: 'opt_in_column',
                    },
                  },
                },
              ],
            },
          )
          .then((data) => {
            const interfaceId = data?.payload?.internal_interface?.id;
            const levelsData = mapLevelsForSave(this.state.levelsValues);
            this.setState({
              interfaceId,
            });
            return this.props.patchInterfaceLevels(interfaceId, levelsData).then(() => {
              this.setState({levelsValues: null});
              return data;
            });
          });
    }
  };

  getDeletedItemsCount = (integrationInterfaceItems = []) => {
    const itemsData = this.props.interfaceFormValues.itemsData || [];

    return integrationInterfaceItems.filter((interfaceItem) => {
      return !itemsData.find((field) => field.external_id === interfaceItem.external_id);
    }).length;
  };

  getCreatedItemsCount = () => {
    const itemsData = this.props.interfaceFormValues.itemsData || [];

    return itemsData.filter((field) => !field.external_id).length;
  };

  getUpdatedItemsNames = (integrationInterfaceItems = []) => {
    const itemsData = this.props.interfaceFormValues.itemsData || [];

    return itemsData.reduce((acc, item) => {
      if (item.external_id) {
        const integrationInterfaceItem = find(integrationInterfaceItems, {external_id: item.external_id});

        if (integrationInterfaceItem && !isEqual(item, integrationInterfaceItem)) {
          acc.push(item.name);
        }
      }

      return acc;
    }, []);
  };

  showMappingModal = (index) =>
    this.setState({
      activeModal: AutotaskInterfaces.Modals.MAP_ITEMS.id,
      activeInterfaceItemIndex: index,
    });

  showMappingModalForSetItemsModal = (index) =>
    this.setState({
      activeModal: AutotaskInterfaces.Modals.MAP_ITEMS.id,
      activeInterfaceItemIndex: index,
    });

  onCancelForMapItems = () => {
    this.props.change(
      AutotaskInterfaces.Modals.MAP_ITEMS.form,
      `map_items.${this.state.activeInterfaceItemIndex}`,
      null,
    );
    this.handleModalOpen(AutotaskInterfaces.Modals.SET_INTERFACE_ITEMS.id);
  };

  onInterfaceLevelsNext = (values) => {
    this.setState({levelsValues: values});
    this.handleSetItemsModalOpen();
  };

  render() {
    const {Modals} = AutotaskInterfaces;
    const {activeModal, activeInterfaceItemIndex, interfaceId} = this.state;
    const {
      autotaskId,
      interfaceName,
      interfaces,
      interfaceItems,
      surveyInterfaceItems,
      unbounceInterfaceItems,
      mappingItems,
      archived,
      meta,
    } = this.props;

    return (
      <AutotaskCard
        title={this.LANGUAGE.INTERFACE_CARD_TITLE}
        header={
          <button
            className={cn('button pull-right button--bg-7', {hidden: archived})}
            onClick={() => this.handleModalOpen(AutotaskInterfaces.Modals.SET_INTERFACE_NAME.id)}
          >
            <Icon name="plus" className="button__icon" />
            <span>{this.LANGUAGE.ADD_INTERFACE_BUTTON}</span>
          </button>
        }
        paginationProps={{
          totalPages: meta?.total_pages,
          currentPage: meta?.current_page,
          onClick: this.handlePageChange,
        }}
      >
        <InterfaceList
          interfaces={interfaces}
          fetchInterfaces={this.fetchInterfaces}
          onDeleteInterfaceConfirm={this.fetchInterfaces}
        />

        <SetInterfaceNameModal
          autotaskId={autotaskId}
          show={activeModal === Modals.SET_INTERFACE_NAME.id}
          onClose={this.handleActiveModalClose}
          onSubmit={() => this.handleModalOpen(Modals.SET_INTERFACE_FAMILY_NAME.id)}
        />

        <SetInterfaceFamilyModal
          show={activeModal === Modals.SET_INTERFACE_FAMILY_NAME.id}
          onClose={this.handleActiveModalClose}
          onCancel={() => this.handleModalOpen(Modals.SET_INTERFACE_NAME.id)}
          onChange={() => this.handleClearMapItems()}
          interfaceName={interfaceName}
          onNext={() => this.handleModalOpen(Modals.SET_INTERFACE_LEVELS.id)}
          openSetProvItemsModal={() => {
            this.handleModalOpen(Modals.SET_INTERFACE_PROVIDER_ITEMS.id);
          }}
        />
        {activeModal === Modals.SET_INTERFACE_LEVELS.id && (
          <SetInterfaceLevelsModal
            interfaceData={{
              name: interfaceName,
              automation_task_id: autotaskId,
              interface_levels: this.state.levelsValues,
            }}
            onClose={this.handleActiveModalClose}
            onCancel={() => this.handleModalOpen(Modals.SET_INTERFACE_FAMILY_NAME.id)}
            onNext={this.onInterfaceLevelsNext}
          />
        )}
        <SetItemsModal
          show={activeModal === Modals.SET_INTERFACE_ITEMS.id}
          onClose={this.handleActiveModalClose}
          onCancel={() => this.handleModalOpen(Modals.SET_INTERFACE_LEVELS.id)}
          onSubmit={() => this.handleModalOpen(Modals.UPDATE_SETTINGS_MODAL.id)}
          onKindChange={this.handleClearMapItemsByIndex}
          interfaceName={interfaceName}
          showMappingModal={this.showMappingModalForSetItemsModal}
          mappingItems={mappingItems}
          items={interfaceItems}
          isInternal={true}
        />

        <SetItemsModal
          show={activeModal === Modals.SET_SURVEY_INTERFACE_ITEMS.id}
          onClose={this.handleActiveModalClose}
          onCancel={() => this.handleModalOpen(Modals.SET_INTERFACE_FAMILY_NAME.id)}
          onSubmit={() => this.handleModalOpen(Modals.UPDATED_SURVEY_ITEMS.id)}
          onUpdate={this.handleUpdateIntagrationInterfaceItems}
          onKindChange={this.handleClearMapItemsByIndex}
          interfaceName={interfaceName}
          showMappingModal={this.showMappingModal}
          mappingItems={mappingItems}
          items={surveyInterfaceItems}
        />

        <SetItemsModal
          show={activeModal === Modals.SET_UNBOUNCE_INTERFACE_ITEMS.id}
          onClose={this.handleActiveModalClose}
          onCancel={() => this.handleModalOpen(Modals.SET_INTERFACE_FAMILY_NAME.id)}
          onSubmit={() => this.handleModalOpen(Modals.UPDATED_UNBOUNCE_ITEMS.id)}
          onUpdate={this.handleUpdateIntagrationInterfaceItems}
          onKindChange={this.handleClearMapItemsByIndex}
          interfaceName={interfaceName}
          showMappingModal={this.showMappingModal}
          mappingItems={mappingItems}
          items={unbounceInterfaceItems}
        />

        <MapItemsModal
          show={activeModal === Modals.MAP_ITEMS.id}
          onCancel={() => this.setState({activeModal: Modals.SET_INTERFACE_ITEMS.id})}
          onSubmit={() => this.handleModalOpen(Modals.SET_INTERFACE_ITEMS.id)}
          interfaceItemIndex={activeInterfaceItemIndex}
          parentFormName={Modals.SET_INTERFACE_ITEMS.form}
        />

        <UpdateSettingsModal
          show={activeModal === Modals.UPDATE_SETTINGS_MODAL.id}
          onClose={this.handleSetItemsModalOpen}
          onConfirm={this.handleConfirmUpdateSettings}
        />

        <InterfaceIdModal
          show={activeModal === Modals.INTERFACE_ID_MODAL.id}
          onClose={this.handleActiveModalClose}
          onConfirm={this.handleActiveModalClose}
          interfaceId={interfaceId}
          interfaceName={interfaceName}
        />
        <UpdatedItemsModal
          show={activeModal === Modals.UPDATED_SURVEY_ITEMS.id}
          deletedItemsCount={this.getDeletedItemsCount(surveyInterfaceItems)}
          createdItemsCount={this.getCreatedItemsCount(surveyInterfaceItems)}
          updatedItemsNames={this.getUpdatedItemsNames(surveyInterfaceItems)}
          onClose={() => this.handleModalOpen(Modals.UPDATE_SETTINGS_MODAL.id)}
        />
        <UpdatedItemsModal
          show={activeModal === Modals.UPDATED_UNBOUNCE_ITEMS.id}
          deletedItemsCount={this.getDeletedItemsCount(unbounceInterfaceItems)}
          createdItemsCount={this.getCreatedItemsCount(unbounceInterfaceItems)}
          updatedItemsNames={this.getUpdatedItemsNames(unbounceInterfaceItems)}
          onClose={() => this.handleModalOpen(Modals.UPDATE_SETTINGS_MODAL.id)}
        />
      </AutotaskCard>
    );
  }
}

const mapStateToProps = (state) => {
  const {MAP_ITEMS, SET_INTERFACE_NAME, SET_INTERFACE_ITEMS, SET_INTERFACE_FAMILY_NAME, UPDATE_SETTINGS_MODAL} =
    AutotaskInterfaces.Modals;

  const interfaceNameFormSelector = formValueSelector(SET_INTERFACE_NAME.form);
  const interfaceFamilyFormSelector = formValueSelector(SET_INTERFACE_FAMILY_NAME.form);
  const interfaceItemsFormSelector = formValueSelector(SET_INTERFACE_ITEMS.form);
  const mapItemsFormSelector = formValueSelector(MAP_ITEMS.form);
  const settingsFormSelector = formValueSelector(UPDATE_SETTINGS_MODAL.form);

  const template = interfaceFamilyFormSelector(state, 'interface_template');
  const interfaceFamily = interfaceFamilyFormSelector(state, 'interface_family');

  return {
    languageState: state.languageState,
    interfaceName: interfaceNameFormSelector(state, 'interface_name') || '',
    interfaceCode: interfaceNameFormSelector(state, 'interface_code') || '',
    interfaceFamily: interfaceFamily?.value || '',
    templateId: template?.value || null,
    interfaces: selectInterfaces(state),
    interfaceItems: selectActiveTemplateItems(state),
    surveyInterfaceItems: selectActiveSGInterfaceItems(state),
    unbounceInterfaceItems: selectActiveUnbounceInterfaceItems(state),
    mappingItems: mapItemsFormSelector(state, 'map_items') || [],
    integrationInterfaceFormValues: {
      integrationInterfaceId: interfaceFamilyFormSelector(state, 'integration_interface_id'),
      integrationInterfaceDescription: interfaceFamilyFormSelector(state, 'integration_interface_description'),
    },
    interfaceFormValues: {
      itemsData: interfaceItemsFormSelector(state, 'items'),
      updateData: settingsFormSelector(state, 'update_kind'),
    },
    meta: selectInterfacesMeta(state),
  };
};

export default connect(mapStateToProps, {
  reset,
  change,
  initialize,
  getOptIns: getOptInsAction,
  createInternalInterface,
  clearInterfacesState,
  createSGInterfaceAction,
  createUnbounceInterfaceAction,
  clearSGInterfacesState,
  clearUnbounceInterfacesState,
  fetchTemplateItems: getInterfaceTemplateItemsAction,
  fetchInterfaces: getInterfacesAction,
  fetchColumnAdapters: getAvailableColumnAdaptersByOperation,
  fetchNewSGInterface: getNewSGInterfaceAction,
  fetchNewUnbounceInterface: getNewUnbounceInterfaceAction,
  patchInterfaceLevels,
})(AutotaskInterfaces);
