export const CHANNEL_TYPES = {
  MINISITE: 'minisite',
  IFRAME: 'iframe',
} as const;

export const MAP_ICONS_INTERACTION_STATUSES: Record<number, string> = {
  5: 'status-closed-finished',
  4: 'status-finished',
  3: 'status-paused',
  2: 'source-in-process',
  1: 'status-new',
};
