import React, {useEffect, useCallback, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import {getCampaignsWithChannels, patchCampaign} from 'client/ducks/campaigns/actions';
import {deleteCampaign} from 'client/ducks/campaigns/actions';
import {selectedClientGames} from 'client/ducks/games/selectors';
import {selectOperation} from 'client/ducks/operations/selectors';

import AppButton from 'client/common/buttons';
import {TextInput} from 'client/common/inputs';

import CampaignChannelDiyModal from 'client/components/campaign/modals/campaign-channel-diy-modal';
import CampaignChannelDiyBlock from 'client/components/campaigns/blocks/campaign-channel-diy-block';
import LotteryPrizeCustomizationModal from 'client/components/lottery/modals/lottery-prize-customization-modal';
import PrizeMapSelectingButton from 'client/components/prizes/controls/prize-map-selecting-button';
import {excludePrizeMaps} from 'client/components/prizes/helpers';
import PrizeCustomizationModal from 'client/components/prizes/modals/prize-customization-modal';
import {PrizeLevel} from 'client/components/prizes/types';
import {Campaign} from 'client/models/campaign/types';
import {Interaction} from 'client/models/interactions/types';
import {GAME_TYPES} from 'client/models/prizes/constants';
import {GameType} from 'client/models/prizes/types';

import './campaign-diy-block.scss';

const b = bem('campaign-diy-block');

const KEYS_CODE = {
  ENTER: 'Enter',
};

type CampaignDiyBlockProps = {
  campaignId: number;
  campaign: Campaign;
  onOpenPrizeCustomization?: (level: PrizeLevel, campaignId: number, channelId?: number) => void;
  fetchOperation: () => Promise<void>;
  fetchCampaign: () => Promise<void>;
  fetchGame: () => Promise<void>;
  disabled: boolean;
};

const CampaignDiyBlock: React.FC<CampaignDiyBlockProps> = (props) => {
  const {campaignId, campaign, fetchOperation, fetchCampaign, fetchGame, disabled} = props;
  const [showModal, setShowModal] = useState(false);
  const [editChannel, setEditChannel] = useState<Interaction | null>(null);

  const [name, setName] = useState<string>(campaign.name);
  const [isEditingName, toggleIsEditingName] = useToggle(false);
  const [showPrizeModal, setShowPrizeModal] = useState<GameType | null>(null);

  const dispatch = useDispatch();
  const lang = useLanguage('DIY_ONLINE.BLOCKS.CAMPAIGN_DIY_BLOCK');
  const operation = useSelector(selectOperation);
  const {instantWinGame, lotteryGame} = useSelector(selectedClientGames);
  const interactions = campaign.interactions || [];

  const hasInteractions = Boolean(campaign?.interactions?.length);

  useEffect(() => {
    setName(campaign.name);
  }, [campaign.name]);

  const onClickEditName = useCallback(() => {
    toggleIsEditingName();
  }, [toggleIsEditingName]);

  const onChangeName = useCallback((event) => {
    setName(event.currentTarget.value);
  }, []);

  const saveEditedName = useCallback(() => {
    if (campaign.name !== name) {
      const body = {
        campaign: {
          name,
        },
      };

      dispatch(patchCampaign(campaignId, body));
    }

    toggleIsEditingName();
  }, [dispatch, name, campaign.name, campaignId, toggleIsEditingName]);

  const handleDeleteCampaign = async () => {
    await dispatch(deleteCampaign(campaignId));
    fetchOperation();
  };

  const onKeyPress = useCallback(
    (event) => {
      if (event.key === KEYS_CODE.ENTER) {
        saveEditedName();
      }
    },
    [saveEditedName],
  );

  const onClickAddChannel = () => {
    setShowModal(true);
  };

  const onClickEditChannel = (channel: Interaction) => {
    setShowModal(true);
    setEditChannel(channel);
  };

  const onCloseChannelModal = () => {
    setShowModal(false);
    setEditChannel(null);
  };

  const onSubmitChannel = async () => {
    onCloseChannelModal();

    if (operation.client_interface?.id) {
      await Promise.all([
        dispatch(getCampaignsWithChannels(operation.client_interface?.id)),
        fetchOperation(),
        fetchGame(),
      ]);
    }
  };

  const onClickDeleteChannel = async () => {
    await fetchOperation();
  };

  return (
    <div className={b()}>
      <table className={b('table')}>
        <thead>
          <th colSpan={3} className={b('th')}>
            <div>
              {isEditingName ? (
                <div className={b('campaign-name-wrap')}>
                  <TextInput
                    value={name}
                    onChange={onChangeName}
                    onKeyPress={onKeyPress}
                    inputClassName={b('campaing-name-input')}
                    className={b('campaing-name-input-div')}
                  />
                </div>
              ) : (
                <div className={b('campaign-name-wrap')}>
                  <h4 className={b('campaign-name')}>{name}</h4>
                  <div className={b('edit-button')}>
                    <AppButton
                      onClick={onClickEditName}
                      iconName="edit"
                      classNameIcon={b('edit-icon')}
                      disabled={name?.length === 0 || disabled}
                      transparent
                    />
                  </div>
                </div>
              )}
            </div>
          </th>
          <th className={b('th')}>
            <div className={b('prize-wrap', {'no-items': !hasInteractions})}>
              <PrizeMapSelectingButton
                budget={campaign.prize_maps_modified?.includes('online_prize_map')}
                exclude={excludePrizeMaps({instantWinGame, lotteryGame}, campaign?.prize_maps_present)}
                onSelect={setShowPrizeModal}
                className={b('prize-icon')}
              />
            </div>
          </th>
          <th colSpan={5} className={b('th')}>
            {!hasInteractions && !disabled && (
              <div className={b('remove-wrap')}>
                <AppButton iconName="circle-cross" onClick={handleDeleteCampaign} transparent />
              </div>
            )}
          </th>
        </thead>
        <tbody>
          {interactions.map((interaction) => (
            <CampaignChannelDiyBlock
              key={interaction.id}
              interaction={interaction}
              onDelete={onClickDeleteChannel}
              onEdit={() => onClickEditChannel(interaction)}
              onFetchPrizeMaps={fetchCampaign}
              disabled={disabled}
            />
          ))}
        </tbody>
      </table>
      <div className={b('button-container')}>
        <AppButton
          onClick={onClickAddChannel}
          label={lang.ADD_CHANNEL}
          iconName="plus-simple"
          transparent
          disabled={disabled}
        />
      </div>
      {showModal && (
        <CampaignChannelDiyModal
          index={interactions.length - 1}
          show={showModal}
          channel={editChannel}
          onClose={onCloseChannelModal}
          campaign={campaign}
          onSubmit={onSubmitChannel}
          disabled={disabled}
        />
      )}
      {showPrizeModal === GAME_TYPES.INSTANT_WIN && instantWinGame && (
        <PrizeCustomizationModal
          onClose={() => setShowPrizeModal(null)}
          level="CAMPAIGN"
          game={instantWinGame}
          levelName={campaign.name}
          sourceIds={[campaignId]}
          onFetch={fetchCampaign}
          disabled={disabled}
        />
      )}
      {showPrizeModal === GAME_TYPES.PRIZE_DRAW && lotteryGame && (
        <LotteryPrizeCustomizationModal
          onClose={() => setShowPrizeModal(null)}
          level="CAMPAIGN"
          game={lotteryGame}
          sourceIds={[campaignId]}
          sourceName={campaign.name}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default CampaignDiyBlock;
