import React, {useCallback, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage, useQueryParams, useReduxFetch} from 'client/services/hooks';

import {getClientMembershipsTeamUsers} from 'client/ducks/client-account/actions';
import {resendClientInvitation} from 'client/ducks/client-users/actions';
import {selectClientMemberships} from 'client/ducks/client-account/selectors';
import {selectClientAccount} from 'client/ducks/client-account/selectors';
import {selectCurrentClient} from 'client/ducks/clients-list/selectors';
import {selectClientAccessLevel, selectClientUserMembership} from 'client/ducks/user-clients/selectors';
import {selectCurrentUser} from 'client/ducks/user/selectors';

import AppButton from 'client/common/buttons';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import SearchInput from 'client/common/inputs/search-input';
import PaginationBar from 'client/common/paginations/pagination-bar';
import PerPageDropdown from 'client/common/selects/per-page-dropdown';
import {useToast} from 'client/common/hooks/useToast';

import ClientUserModal from 'client/components/client-account-parameters/modals/client-user-modal';
import TrackingUserModal from 'client/components/client-account-parameters/modals/tracking-user-modal';
import {Client} from 'client/models/client/types';
import {ACCESS_LEVEL} from 'client/models/common/constants';
import {ACCESS_LEVEL_ORDER, CLIENT_TYPES, MEMBERSHIP_TO_ACCESS_LEVEL, MEMBERSHIPS_TYPES} from 'client/common/config';
import {Membership} from 'client/models/memberships/types';
import {ApiDispatch} from 'client/types';
import {PaginationMeta} from 'client/types/common/index';

import AccountUsersTable from './account-users-table';
import AccountUsersExportAccessModal from './modals/account-users-export-access-modal';

import cssModule from './account-users.module.scss';

const b = bem('account-users', {cssModule});

type AccountUsersProps = {
  client: Client;
  crudUsers?: boolean;
};

const searchParamName = ['client_user_email', 'client_user_first_name', 'client_user_last_name'].join('_or_') + '_cont';

const AccountUsers: React.FC<AccountUsersProps> = (props) => {
  const {client} = props;

  const lang = useLanguage('ACCOUNT_PARAMETERS.USERS');
  const currentClient = useSelector(selectCurrentClient);
  const clientUserMembership = useSelector(selectClientUserMembership);
  const accessLevel = useSelector(selectClientAccessLevel);
  const clientAccount = useSelector(selectClientAccount);
  const currentUser = useSelector(selectCurrentUser);
  const {appendToastNotification} = useToast();
  const dispatch: ApiDispatch = useDispatch();

  const canResendInvitation = useCallback(
    (membership: Membership) => {
      if (membership.client_user?.invitation_accepted_at || membership.prize_tracking_user_id) {
        return false;
      }

      const clientAccessLevelOrder = ACCESS_LEVEL_ORDER[MEMBERSHIP_TO_ACCESS_LEVEL[accessLevel]];
      const membershipAccessLevelOrder = ACCESS_LEVEL_ORDER[MEMBERSHIP_TO_ACCESS_LEVEL[membership.access_level]];
      const allowedByAccessLevel = clientAccessLevelOrder >= membershipAccessLevelOrder;

      if (client.type === CLIENT_TYPES.AGENCY) {
        return client.user_creation_allowed && allowedByAccessLevel;
      }

      if (clientUserMembership.type === MEMBERSHIPS_TYPES.AGENCY) {
        return !!client.agency?.client_management;
      }

      return client.user_creation_allowed && allowedByAccessLevel;
    },
    [accessLevel, client, clientUserMembership],
  );

  const crudUsers =
    currentClient.type === 'Agency' && client.id !== currentClient.id
      ? currentClient.client_management
      : client.user_creation_allowed && accessLevel !== ACCESS_LEVEL.LOCAL;

  const [params, setQueryParams] = useQueryParams(null, {
    page: '1',
    perPage: '25',
    sortField: 'client_user_full_name',
    sortOrder: 'asc',
    search: '',
  });

  const [userExportAccess, setUserExportAccess] = useState<Membership | null>(null);
  const [userResendInvitation, setUserResendInvitation] = useState<Membership | null>(null);
  const [showCreateUser, toggleShowCreateUser] = useToggle(false);
  const [showCreateTrackingUser, toggleCreateTrackingUser] = useToggle(false);
  const [editedUser, setEditedUser] = useState<Membership | null>(null);
  const [editedTrackingUser, setEditedTrackingUser] = useState<Membership | null>(null);

  const {loading, data, fetch} = useReduxFetch<{data: Membership[]; meta: PaginationMeta}>({
    action: getClientMembershipsTeamUsers,
    selector: selectClientMemberships,
    actionArgs: {
      client_id: client.id,
      include_membership_accessible_clients: null,
      include: {
        data_tab_access: null,
        client_user: null,
        region_accesses: null,
        place_accesses: null,
        company_accesses: null,
        prize_tracking_user: null,
      },
      q: {
        client_id_eq: client.id,
        s: `${params.sortField} ${params.sortOrder}`,
        [searchParamName]: params.search,
      },
      page: params.page,
      per_page: params.perPage,
    },
  });

  const handleParamsChange = (nextParams: Record<string, any>) => {
    setQueryParams(nextParams, false);
  };

  const handleSearch = (value = '') => {
    handleParamsChange({
      search: encodeURIComponent(value),
      page: 1,
    });
  };

  const handleCloseExportAccess = () => {
    setUserExportAccess(null);
  };

  const handleOpenExportAccess = (user: Membership) => {
    setUserExportAccess(user);
  };

  const handleCloseUserModal = () => {
    toggleShowCreateUser(false);
    setEditedUser(null);
  };

  const handleCloseTrackingUserModal = () => {
    toggleCreateTrackingUser(false);
    setEditedTrackingUser(null);
  };

  const handleResendInvitation = (membership: Membership) => {
    setUserResendInvitation(membership);
  };

  const handleResendInvitationCancel = () => {
    setUserResendInvitation(null);
  };

  const handleResendInvitationConfirm = async () => {
    if (!userResendInvitation) {
      return;
    }

    const membership = {...userResendInvitation};

    setUserResendInvitation(null);

    const response = await dispatch(resendClientInvitation(membership.client_user_id, client.id));
    if (response.error) {
      appendToastNotification({
        type: 'error',
        title: lang.RESEND_INVITATION.NOTIFICATION.ERROR.MESSAGE,
        description: membership.client_user?.email,
        actions: [
          {
            label: lang.RESEND_INVITATION.NOTIFICATION.ERROR.RESEND,
            className: b('resend-invitation-notification-button'),
            onClick: () => {
              setUserResendInvitation(membership);
            },
          },
        ],
      });
    } else {
      appendToastNotification({
        type: 'success',
        title: lang.RESEND_INVITATION.NOTIFICATION.SUCCESS.MESSAGE,
        description: membership.client_user?.email,
      });
    }
  };

  return (
    <div className={b()}>
      <div className={b('top')}>
        <SearchInput
          className={b('search')}
          onSearch={handleSearch}
          searchDefault={params.search}
          placeholder={lang.SEARCH_PLACEHOLDER}
        />
        {crudUsers && (
          <AppButton
            label={lang.CREATE_USER}
            iconName="plus-simple"
            onClick={() => toggleShowCreateUser()}
            color="light-clients"
          />
        )}
        {Boolean(clientAccount?.client_places?.total && accessLevel !== ACCESS_LEVEL.LOCAL) && (
          <AppButton
            label={lang.PRIZE_TRACKING}
            iconName="plus-simple"
            onClick={() => toggleCreateTrackingUser()}
            color="light-clients"
          />
        )}
        <PerPageDropdown
          value={params.perPage}
          simpleValue
          onChange={(perPage: string) => handleParamsChange({perPage, page: 1})}
          className={b('per-page')}
          options={[25, 50, 100].map((n) => ({value: `${n}`, label: n}))}
        />
      </div>
      <AccountUsersTable
        client={client}
        data={data?.data || []}
        sortField={params.sortField}
        sortOrder={params.sortOrder}
        onSortChange={handleParamsChange}
        onOpenExportAccess={handleOpenExportAccess}
        onEditUser={setEditedUser}
        onEditTrackingUser={setEditedTrackingUser}
        onResendInvitation={handleResendInvitation}
        loading={loading}
        refetchData={fetch}
        crudUsers={crudUsers}
        canResendInvitation={canResendInvitation}
      />
      <PaginationBar
        onPageChange={handleParamsChange}
        data={data?.data}
        totalPages={data?.meta.total_pages}
        totalItems={data?.meta.total_count}
        perPage={+params.perPage}
        currentPage={data?.meta.current_page}
      />

      {userExportAccess && (
        <AccountUsersExportAccessModal
          onClose={handleCloseExportAccess}
          membership={userExportAccess}
          onFetch={fetch}
        />
      )}
      {(showCreateUser || editedUser) && (
        <ClientUserModal onClose={handleCloseUserModal} client={client} onSubmit={fetch} initialData={editedUser} />
      )}
      {(showCreateTrackingUser || editedTrackingUser) && (
        <TrackingUserModal
          onClose={handleCloseTrackingUserModal}
          client={client}
          initialData={editedTrackingUser}
          locale={currentUser.locale}
          onSubmit={fetch}
        />
      )}
      {!!userResendInvitation && (
        <ConfirmationModal
          show={true}
          clientSide={true}
          onConfirm={handleResendInvitationConfirm}
          onClose={handleResendInvitationCancel}
          title={lang.RESEND_INVITATION.CONFIRM.TITLE}
          message={lang.RESEND_INVITATION.CONFIRM.MESSAGE}
          buttonConfirm={{
            label: lang.RESEND_INVITATION.CONFIRM.OK_BUTTON,
          }}
          buttonCancel={{
            label: lang.RESEND_INVITATION.CONFIRM.CANCEL_BUTTON,
            onClick: handleResendInvitationCancel,
          }}
        />
      )}
    </div>
  );
};

export default AccountUsers;
