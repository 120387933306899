import React, {useState} from 'react';

import get from 'lodash/get';
import {useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {capitalizeFirstLetter} from 'client/services/formatters';
import {uid} from 'client/services/helpers';
import {useLanguage, useReduxForm, useVariableInsert} from 'client/services/hooks';
import {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {updateSmsTemplatesBulk} from 'client/ducks/sms-templates/actions';

import AppButton from 'client/common/buttons/app-button';
import RadioButtonGroupField from 'client/common/fields/radio-button-group-field';
import TextField from 'client/common/fields/text-field';
import TextareaField from 'client/common/fields/textarea-field';
import {useToast} from 'client/common/hooks/useToast';
import Modal from 'client/common/modals/modal';
import Offscreen from 'client/common/offscreen';

import SmsTemplateModalCounter from 'client/components/sms-templates/modals/sms-template-modal/sms-template-modal-counter';
import SmsTemplateTesting from 'client/components/sms-templates/modals/sms-template-modal/sms-template-testing';
import {SmsTemplateKind} from 'client/models/sms-templates/types';

import mapFormValues from './mapFormValues';
import mapInitialValues from './mapInitialValues';
import SmsVariableBar from './sms-variable-bar';
import {SmsTemplateFormValues} from './types';
import validateValues from './validateValues';

import cssModule from './sms-template-modal.module.scss';

const b = bem('sms-template-modal', {cssModule});

export const SMS_TEMPLATE_FORM_NAME = 'SmsTemplateForm';

type SmsTemplateModalProps = {
  onClose: () => void;
  kind: SmsTemplateKind;
  fetchData: () => void;
};

const SmsTemplateModal: ReduxFormFC<SmsTemplateModalProps, SmsTemplateFormValues> = (props) => {
  const {onClose, kind, fetchData} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('SMS_TEMPLATES.MODALS.SMS_TEMPLATE_MODAL');
  const {appendToastNotification} = useToast();
  const [isSaving, toggleSaving] = useToggle(false);
  const [addedVariables, setAddedVariables] = useState<Record<string, any>>({});

  const {formValues, invalid, change} = useReduxForm<SmsTemplateFormValues>(SMS_TEMPLATE_FORM_NAME, {
    initialValues: mapInitialValues(kind),
    validate: (values) => validateValues(values, lang.ERRORS),
  });

  const languages = Object.keys(formValues.message);

  const messageFieldName = `message.${formValues.language}`;
  const localMessage = get(formValues, messageFieldName);

  const {onBlur, insertVariable} = useVariableInsert(localMessage, (value: string) => change(messageFieldName, value), {
    variableTemplate: '{{var:VARIABLE_NAME}}',
    initialPosition: 'start',
  });

  const handleSave = async (close: boolean) => {
    toggleSaving();
    const data = mapFormValues(formValues, kind, addedVariables);
    try {
      await dispatch(updateSmsTemplatesBulk(data));
    } catch {
      appendToastNotification({id: uid(), type: 'error', title: lang.ERROR});
    }
    await fetchData();
    toggleSaving();
    if (close) {
      onClose();
    }
  };

  const addVariable = (variable: string) => {
    const newSet = [...new Set([...(addedVariables[formValues.language] || []), variable])];
    setAddedVariables({...addedVariables, [formValues.language]: newSet});
  };

  const template =
    kind.sms_templates.find((item) => item.language_tag === formValues?.language) || kind.sms_templates[0];

  return (
    <Modal className={b()} title={lang.TITLE} onClose={onClose}>
      <form>
        {languages.map((langKey) => (
          <Offscreen hidden={langKey !== formValues.language}>
            <TextField
              key={langKey}
              className={b('name-field')}
              name={`name.${langKey}`}
              label={lang.NAME}
              withWrap={true}
              required={true}
            />
          </Offscreen>
        ))}
        {languages.length > 1 && (
          <RadioButtonGroupField
            legend={lang.CHOOSE_LANGUAGE}
            controlClassName={b('language-control')}
            labelClassName={b('language-label')}
            name="language"
            radioButtons={languages.map((i) => ({value: i, label: capitalizeFirstLetter(i)}))}
            withWrap
          />
        )}
        {kind.sms_template_kind_variables.length > 0 && (
          <SmsVariableBar
            onConfirm={(value: string) => {
              insertVariable(value);
              addVariable(value);
            }}
            variables={kind.sms_template_kind_variables}
          />
        )}
        {languages.map((langKey) => (
          <Offscreen hidden={langKey !== formValues.language}>
            <TextareaField
              label={lang.MESSAGE}
              textareaClassName={b('message-area')}
              name={`message.${langKey}`}
              onBlur={onBlur}
              withWrap={true}
              required={true}
            />
          </Offscreen>
        ))}
        <SmsTemplateModalCounter
          className={b('counter')}
          text={formValues.message[formValues.language]}
          hasVariable={/{{var:/.test(formValues.message[formValues.language])}
        />
        <div className={b('hint')}>
          <p className={b('hint-text')}>{lang.HINT_FIRST}</p>
          <p className={b('hint-text')}>{lang.HINT_SECOND}</p>
        </div>

        <SmsTemplateTesting template={template} message={localMessage} onSend={handleSave} />

        <div className={b('buttons')}>
          <AppButton label={lang.CANCEL} onClick={onClose} transparent />
          <AppButton label={lang.SAVE} onClick={() => handleSave(true)} disabled={invalid} loading={isSaving} />
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<SmsTemplateModalProps, SmsTemplateFormValues>({
  form: SMS_TEMPLATE_FORM_NAME,
})(SmsTemplateModal);
