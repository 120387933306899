import get from 'lodash/get';

import {mapCoupon} from './mappers';
import types from './types';

const initialState = {
  coupons: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case types.GET_COUPONS_SUCCESS:
      return {
        ...state,
        coupons: get(action, 'payload.coupons', initialState.coupons).map(mapCoupon),
      };

    default:
      return state;
  }
}
