import React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {selectPrizeLanguages} from 'client/ducks/operations/selectors';
import {createDiyPrize, updateDiyPrize, deleteDiyPrize} from 'client/ducks/prizes/actions';

import AppButton from 'client/common/buttons/app-button';
import {useToast} from 'client/common/hooks/useToast';
import ConfirmationModal from 'client/common/modals/confirmation-modal';
import Modal from 'client/common/modals/modal';

import {Operation} from 'client/models/operations/types';
import {isGameDrawClosed} from 'client/models/prizes/helpers';
import {Game, Prize} from 'client/models/prizes/types';

import getInitialValues from './getInitialValues';
import MainParamsFieldset from './main-params-fieldset';
import mapFormValues from './mapFormValues';
import SegmentationParamsFieldset from './segmentation-params-fieldset';
import {LotteryPrizeValues} from './types';
import validateValues from './validateValues';
import WinParamsFieldset from './win-params-fieldset';

import cssModule from './lottery-prize-modal.module.scss';

const b = bem('lottery-prize-modal', {cssModule});

export const LotteryPrizeModalFormName = 'LotteryPrizeModalForm';

type DrawParametersModalProps = {
  onClose: () => void;
  onSave: () => Promise<any>;
  game: Game;
  operation: Operation;
  prizes: Prize[];
  editingPrize: Prize | null;
  disabled: boolean;
};

const LotteryPrizeModal: ReduxFormFC<DrawParametersModalProps, LotteryPrizeValues> = (props) => {
  const {handleSubmit, onClose, game, operation, onSave, editingPrize, prizes, disabled} = props;
  const dispatch = useDispatch();
  const lang = useLanguage('LOTTERY.MODALS.LOTTERY_PRIZE_MODAL');
  const [showDeleteModal, toggleDeleteModal] = useToggle(false);
  const [saving, toggleSaving] = useToggle(false);
  const [deleting, toggleDeleting] = useToggle(false);
  const {appendToastNotification} = useToast();
  const languages = useSelector(selectPrizeLanguages);

  const isEdit = !!editingPrize;

  const isClosed = isEdit && isGameDrawClosed(game.game_draws?.[0]);

  const isReadOnly = isClosed || disabled;

  const hasWins = Boolean(
    editingPrize?.total_quantities.total_prize_won_non_validated ||
      editingPrize?.total_quantities.total_prize_won_validated,
  );

  const {invalid} = useReduxForm(LotteryPrizeModalFormName, {
    initialValues: getInitialValues(editingPrize, game, languages),
    validate: (values) => validateValues(values, lang.ERRORS),
  });

  const handleSave = async (values: LotteryPrizeValues) => {
    toggleSaving();

    const mappedValues = await mapFormValues(values);

    const data = {
      prize: {
        ...mappedValues,
        game_id: game.id,
        ...(!isEdit && prizes && {order: prizes.length}),
        ...(!isEdit && {code: operation.code}),
      },
    };

    try {
      if (isEdit) {
        await dispatch(updateDiyPrize(editingPrize.id, data));
      } else {
        await dispatch(createDiyPrize(data));
      }
    } catch {
      appendToastNotification({type: 'error', title: lang.ERRORS.ERROR});
    }
    await onSave();
    toggleSaving();
    onClose();
  };

  const handlePrizeDelete = async () => {
    toggleDeleteModal();
    toggleDeleting();
    try {
      await dispatch(deleteDiyPrize(editingPrize?.id));
    } catch {
      appendToastNotification({type: 'error', title: lang.ERRORS.ERROR});
    }
    await onSave();
    toggleDeleting();
    onClose();
  };

  return (
    <Modal className={b()} title={lang.TITLE} onClose={onClose} isCloseHidden>
      <form onSubmit={handleSubmit(handleSave)}>
        <div className={b('buttons')}>
          {isEdit && (
            <AppButton
              label={lang.DELETE_PRIZE}
              onClick={toggleDeleteModal}
              color="error"
              loading={deleting}
              disabled={saving || hasWins || isReadOnly}
              transparent
            />
          )}
          <ConfirmationModal
            show={showDeleteModal}
            onClose={toggleDeleteModal}
            onConfirm={handlePrizeDelete}
            title={lang.DELETE_PRIZE}
            message={lang.DELETE_MESSAGE}
            buttonCancel={{
              label: lang.CANCEL,
              color: 'devices',
              transparent: true,
              size: 'small',
            }}
            buttonConfirm={{
              label: lang.DELETE,
              color: 'devices',
              size: 'small',
            }}
            clientSide
          />
          <AppButton label={lang.CANCEL} onClick={onClose} transparent />
          <AppButton label={lang.SAVE} className={b('save-button')} disabled={deleting} loading={saving} submit />
          {invalid && <p className={b('save-message')}>{lang.ERRORS.FILL_ALL_FIELDS}</p>}
        </div>
        <div className={b('columns')}>
          <div className={b('column', ['left'])}>
            <WinParamsFieldset disabled={isReadOnly} />
          </div>
          <div className={b('column', ['right'])}>
            <MainParamsFieldset disabled={isReadOnly} />
            <SegmentationParamsFieldset operation={operation} disabled={isReadOnly} />
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default reduxForm<DrawParametersModalProps, LotteryPrizeValues>({
  form: LotteryPrizeModalFormName,
})(LotteryPrizeModal);
