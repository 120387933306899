import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {getFormValues, reduxForm, getFormSyncErrors} from 'redux-form';

import bem from 'client/services/bem';

import {selectAutotask} from 'client/ducks/autotask/selectors';
import {getCoupons} from 'client/ducks/coupons/actions';

import {NumberField, SelectField, TextField} from 'client/common/fields';

import {Field, TextAreaField} from 'client/components/common/fields';

import {GAME_TYPES} from 'client/components/games/game-config-modal/constants';
import PrizeFormSubstitution from 'client/components/games/game-config-modal/prize-form/prize-form-substitution';

import {PRIZE_TYPES} from '../constants';
import {geographicOptions, onlineOptions} from '../helpers';
import {validate} from '../validate';

import './prize-form-main.scss';

const b = bem('prize-form-main');
class PrizeGameFormMain extends React.Component {
  static formName = 'PrizeForm';

  static propTypes = {
    prize: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    prizes: PropTypes.array,
    game: PropTypes.shape({
      game_type: PropTypes.string.isRequired,
      automation_task: PropTypes.shape({
        operation: PropTypes.shape({
          client: PropTypes.shape({
            loyalty_program_id: PropTypes.number,
          }).isRequired,
        }).isRequired,
      }).isRequired,
    }),
    openConfigInstant: PropTypes.func.isRequired,
    isPrizeMap: PropTypes.bool.isRequired,

    // from state:
    lang: PropTypes.object.isRequired,
    autotask: PropTypes.object.isRequired,
    coupons: PropTypes.array.isRequired,
    getCoupons: PropTypes.func.isRequired,
    change: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    formErrors: PropTypes.object.isRequired,
    formValues: PropTypes.object,
    invalid: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    formValues: {},
    countOrder: null,
    prize: null,
    prizes: [],
    game: null,
  };

  componentDidMount() {
    this.getCouponOptions();
  }

  getCouponOptions = async () => {
    const {operation_id} = this.props.autotask;

    if (!operation_id) {
      return [];
    }

    await this.props.getCoupons({
      per_page: 100,
      q: {
        operation_id_eq: operation_id,
      },
    });

    return this.props.coupons;
  };

  getPrizeTypeOptions = () => {
    const options = [
      {label: this.props.lang.COMMON, value: PRIZE_TYPES.COMMON},
      {label: this.props.lang.COUPON, value: PRIZE_TYPES.COUPON},
    ];

    if (this.props.game.automation_task.operation.client.loyalty_program_id) {
      options.push({label: this.props.lang.LOYALTY, value: PRIZE_TYPES.LOYALTY});
    }

    return options;
  };

  onChangeCoupon = (coupon) => {
    this.props.change('internal_name', coupon.internal_name);
    this.props.change('external_name', coupon.external_name);
  };

  handleChangeType = (type) => {
    this.props.change('loyalty_only', type === PRIZE_TYPES.LOYALTY);
  };

  render() {
    const {
      lang,
      formValues = {},
      formErrors,
      prize,
      onCancel,
      onSave,
      openConfigInstant,
      game,
      coupons,
      isPrizeMap,
    } = this.props;
    const [image] = formValues.file || [];
    const imageUrl = image ? URL.createObjectURL(image) : formValues.image && formValues.image.url;
    const isEdit = !!prize;

    const cs = formValues.total_quantities?.total_current_stock || formValues.current_stock || 0;

    const hasErrors = Object.values(formErrors).length;

    return (
      <div className={b('row', ['medium-gap'])}>
        <div className={b('data-block')}>
          <div className={b('row', ['small-gap'])}>
            <div className={b('col', ['first'])}>
              {!isPrizeMap && (
                <div>
                  <SelectField
                    name="type"
                    label={lang.PRIZE_TYPE}
                    options={this.getPrizeTypeOptions()}
                    simpleValue
                    onChange={this.handleChangeType}
                    menuShouldScrollIntoView={false}
                  />

                  <TextField label={lang.INTERNAL_PRIZE_NAME} name="internal_name" />
                </div>
              )}
              <TextField label={lang.EXTERNAL_PRIZE_NAME} name="external_name" />
              <TextField className={b('prize-code')} label={lang.PRIZE_CODE} name="code" />
              {!isPrizeMap && (
                <div>
                  <SelectField
                    className={b('geographic')}
                    label={lang.GEOGRAPHIC}
                    simpleValue
                    options={geographicOptions(lang)}
                    name="geo_level"
                  />
                  <SelectField
                    className={b('online')}
                    label={lang.ONLINE}
                    simpleValue
                    options={onlineOptions(lang)}
                    name="online_level"
                  />
                </div>
              )}
            </div>
            <div className={b('col')}>
              {formValues.type === PRIZE_TYPES.COUPON && !isPrizeMap && (
                <SelectField
                  className={b('toggle-field')}
                  label={lang.INTERNAL_COUPON_CODE}
                  options={coupons}
                  name="coupon"
                  onChange={this.onChangeCoupon}
                  menuShouldScrollIntoView={false}
                />
              )}

              {formValues.type === PRIZE_TYPES.LOYALTY && !isPrizeMap && (
                <div className={b('row', ['small-gap'])}>
                  <NumberField label={lang.AMOUNT} name="loyalty_amount" />
                  <TextField label={lang.UNIT} name="loyalty_unit" />
                </div>
              )}
              <div className={b('stock', {'has-coupon': formValues.coupon_type || isPrizeMap})}>
                <h2 className={cn(b('stock-title'), 'title-h2 text-center')}>{lang.STOCK_TITLE}</h2>
                {!isPrizeMap ? (
                  <div>
                    <div className={b('row', ['align-start'])}>
                      <div className={b('stock-field-wrap')}>
                        <div className={b('stock-label')}>{lang.DEFAULT_INITIAL_STOCK}</div>
                        <div className={b('stock-field')}>
                          <NumberField name="default_initial_stock" min="1" />
                        </div>
                      </div>
                      <div className={b('stock-field-wrap')}>
                        <div className={b('stock-label')}>{lang.TOTAL_INITIAL_STOCK}</div>
                        <div className={b('stock-field', ['text'])}>
                          {(isEdit
                            ? formValues.total_quantities?.total_initial_stock
                            : formValues.default_initial_stock) || 0}
                        </div>
                      </div>
                    </div>

                    <div className={b('row', ['align-start'])}>
                      <div className={b('stock-field-wrap')}>
                        <div className={b('stock-label')}>{lang.DEFAULT_STOCK_OFFSET}</div>
                        <div className={b('stock-field')}>
                          <NumberField name="default_stock_offset" />
                        </div>
                      </div>
                      <div className={b('stock-field-wrap')}>
                        <div className={b('stock-label')}>{lang.TOTAL_STOCK_OFFSET}</div>
                        <div className={b('stock-field', ['text'])}>
                          {(isEdit
                            ? formValues.total_quantities?.total_stock_offset
                            : formValues.default_stock_offset) || 0}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className={b('row', ['align-start'])}>
                      <div className={b('stock-field-wrap')}>
                        <div className={b('stock-label')}>{lang.INITIAL_STOCK}</div>
                        <div className={b('stock-field')}>
                          <NumberField name="initial_stock" />
                        </div>
                      </div>
                    </div>
                    <div className={b('row')}>
                      <div className={b('stock-field-wrap')}>
                        <div className={b('stock-label')}>{lang.STOCK_OFFSET}</div>
                        <div className={b('stock-field')}>
                          <NumberField name="stock_offset" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className={b('stock-field-wrap', ['validated'])}>
                  <div className={b('stock-label', ['validated'])}>{lang.PRIZES_WON_NOT_VALIDATED}</div>
                  <div className={b('stock-field', ['validated'])}>
                    {(isPrizeMap
                      ? prize?.prizes_not_validated
                      : prize?.total_quantities?.total_prize_won_non_validated) || 0}
                  </div>
                </div>

                <div className={b('stock-field-wrap', ['validated'])}>
                  <div className={b('stock-label', ['validated'])}>{lang.PRIZES_WON_VALIDATED}</div>
                  <div className={b('stock-field', ['validated'])}>
                    {(isPrizeMap ? prize?.prizes_validated : prize?.total_quantities?.total_prize_won_validated) || 0}
                  </div>
                </div>

                <div className={b('stock-field-wrap', ['total'])}>
                  <div className={b('stock-label', ['total'])}>{lang.CURRENT_STOCK}</div>
                  <div className={b('stock-field', ['total'])}>{cs}</div>
                </div>
              </div>
            </div>
          </div>

          <TextAreaField label={lang.EXTERNAL_DESCRIPTION} className={b('description')} name="external_description" />

          {!isPrizeMap && <TextAreaField label={lang.COMMENTS} withWrap={false} name="comment" />}
        </div>
        <div className={b('col', ['third'])}>
          <Field
            accept="image/*"
            type="file"
            htmlFor="file"
            buttonText={lang.CHOOSE_FILE}
            cssModifier={b('choose-file')}
            buttonCssClass={cn('button--bg-4')}
            name="file"
          />

          <div className={cn(b('preview'), 'form-field-wrap')}>{imageUrl && <img src={imageUrl} alt="" />}</div>
          {formValues.image && !image && (
            <div className={cn('form-field-wrap', b('field'))}>
              <span className={cn(b('field-label'), 'text-additional')}>{lang.IMAGE_URL}</span>
              <a className={b('field-value', ['url'])} href={formValues.image.url} target="_blank" rel="noreferrer">
                {formValues.image.url}
              </a>
            </div>
          )}

          <div className={b('buttons')}>
            {game.game_type === 'instant_win' && (
              <button
                type="button"
                className={cn('button button--bg-4', b('button', ['instant']))}
                onClick={openConfigInstant}
              >
                {lang.CONFIGURE_INSTANT}
              </button>
            )}
            {game.game_type === GAME_TYPES.ATTRIBUTION && !isPrizeMap && (
              <PrizeFormSubstitution lang={lang} errors={formErrors.substitution} />
            )}

            <div className={b('row', ['small-gap'])}>
              <button type="button" onClick={onCancel} className={cn('button button--bg-4', b('button'))}>
                {lang.DISCARD}
              </button>
              <button
                type="button"
                disabled={hasErrors}
                onClick={onSave}
                className={cn('button button--bg-4', b('button'))}
              >
                {lang.SAVE}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const PrizeFormMain = reduxForm({
  form: PrizeGameFormMain.formName,
  validate,
  destroyOnUnmount: false,
})(PrizeGameFormMain);

export default connect(
  (state) => {
    return {
      lang: state.languageState.payload.GAMES.GAME_CONFIG_MODAL.PRIZE_FORM,
      coupons: state.coupons.coupons?.map((coupon) => ({...coupon, value: coupon.id, label: coupon.internal_code})),
      formValues: getFormValues(PrizeGameFormMain.formName)(state),
      formErrors: getFormSyncErrors(PrizeGameFormMain.formName)(state),
      autotask: selectAutotask(state),
    };
  },
  {
    getCoupons,
  },
)(PrizeFormMain);
