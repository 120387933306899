import React, {Component} from 'react';

import ClassNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {getClientPage} from 'client/services/helpers';

import {patchDiaporamaMappingItems} from 'client/ducks/slideshows/actions';
import {selectIsAdmin} from 'client/ducks/user/selectors';
import {patchVisual, getAutomaticVisualGroups} from 'client/ducks/visuals/actions';

import {CLIENT_PAGES, VISUALS_STATUS_COLORS, POST_PREVIEW_TYPES} from 'client/common/config';

import Checkbox from 'client/components/common/checkbox';
import CustomScrollbars from 'client/components/common/custom-scrollbars';
import Hashtag from 'client/components/common/hashtag';
import Icon from 'client/components/common/icon';

import './post-preview.scss';

class PostPreview extends Component {
  static propTypes = {
    type: PropTypes.string.isRequired,
    checkboxName: PropTypes.string.isRequired,
    languageState: PropTypes.object.isRequired,
    updateMe: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    checked: PropTypes.bool,
    visual: PropTypes.object,
    flexible: PropTypes.bool.isRequired,
    className: PropTypes.string,
    onPreviewClick: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    diaporamaValues: PropTypes.object.isRequired,
    isColAdapterInDiaporama: PropTypes.func.isRequired,
    getArrivingType: PropTypes.func.isRequired,
    getVisualOrDiaporamaValue: PropTypes.func.isRequired,
    patchDiaporamaMappingItems: PropTypes.func.isRequired,
    patchVisual: PropTypes.func.isRequired,
    getAutomaticVisualGroups: PropTypes.func.isRequired,
  };

  static defaultProps = {
    visual: {},
    className: '',
    updateMe: () => {},
    checked: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showDetails: false,
      tags: [],
    };

    this.LANGUAGE = props.languageState.payload.VISUALS.TABLE;
  }

  componentDidMount() {
    document.documentElement.addEventListener('click', this.handleOutsideClick);
  }

  componentWillUnmount() {
    document.documentElement.removeEventListener('click', this.handleOutsideClick);
  }

  handleOutsideClick = (e) => {
    const target = e.target;

    if (!this.detailsPopover.contains(target)) {
      this.setState({
        showDetails: false,
      });
    }
  };

  loadAutomaticGroups = () => this.props.getAutomaticVisualGroups(this.props.visual.id);

  handleShowDetailsClick = () => {
    const tags = this.props.getVisualOrDiaporamaValue('tags', this.props.visual, this.props.diaporamaValues);
    this.loadAutomaticGroups();
    this.setState((prevState) => ({
      showDetails: !prevState.showDetails,
      tags,
    }));
  };

  addTag = (visual) => {
    const newTags = [...this.props.getVisualOrDiaporamaValue('tags', visual, this.props.diaporamaValues), ''];
    this.setState({
      tags: newTags,
    });
  };

  removeTag = (visual, index) => {
    this.saveTags(visual)(index, '');
  };

  saveTags = (visual) => (index, newTagValue) => {
    const newTags = this.props.getVisualOrDiaporamaValue('tags', visual, this.props.diaporamaValues).slice();
    if (newTagValue.trim().length === 0) {
      newTags.splice(index, 1);
    } else {
      newTags[index] = newTagValue.trim();
    }
    this.setState({
      tags: newTags,
    });
    if (this.props.isColAdapterInDiaporama('tags')) {
      this.props
        .patchDiaporamaMappingItems({
          diaporama_mapping_item: {
            id: this.props.diaporamaValues.tags.id,
            value: newTags.join(', '),
          },
        })
        .then(this.props.updateMe);
    } else {
      this.props
        .patchVisual(visual.id, {
          visual: {
            tags: newTags,
          },
        })
        .then(this.props.updateMe);
    }
  };

  renderContent() {
    const {LANGUAGE} = this;
    const {visual, type, diaporamaValues, isColAdapterInDiaporama, getVisualOrDiaporamaValue, isAdmin} = this.props;
    const operation = visual.interaction && visual.interaction.interface.automation_task.operation;
    const client = operation && operation.client;
    const arrivingType = this.props.getArrivingType(visual);
    let expand = false;

    const diaporamaGroupValues = isColAdapterInDiaporama('group') && diaporamaValues.group.value;

    const lastElement = {};

    switch (true) {
      case isColAdapterInDiaporama('group'):
        lastElement.label = LANGUAGE.GROUPS_COLUMN;
        lastElement.value = diaporamaGroupValues[diaporamaGroupValues.length - 1];
        expand = true;
        break;
      case visual.manual_visual_groups.length > 0 && type === POST_PREVIEW_TYPES.MAIN:
        lastElement.label = LANGUAGE.GROUPS_COLUMN;
        lastElement.value = visual.manual_visual_groups[visual.manual_visual_groups.length - 1].name;
        expand = true;
        break;
      case visual.tags.length > 0:
        expand = true;
        break;
      default:
        expand = false;
        break;
    }

    return (
      <div>
        <div className="post-preview__content">
          {type === POST_PREVIEW_TYPES.MAIN && isAdmin && (
            <div className="post-preview__group">
              <div className="post-preview__group-title">{LANGUAGE.STATUS_COLUMN}</div>
              <div className="post-preview__group-content">
                <div
                  style={{
                    color: VISUALS_STATUS_COLORS[getVisualOrDiaporamaValue('status', visual, diaporamaValues)],
                  }}
                  className="status-icon post-preview__status-icon"
                />
              </div>
            </div>
          )}
          {type === POST_PREVIEW_TYPES.MAIN && isAdmin && (
            <div className="post-preview__group">
              <div className="post-preview__group-title">{LANGUAGE.VISIBLE_COLUMN}</div>
              <div className="post-preview__group-content">
                <div
                  style={{
                    color:
                      VISUALS_STATUS_COLORS[getVisualOrDiaporamaValue('visible_by_client', visual, diaporamaValues)],
                  }}
                  className="status-icon post-preview__status-icon"
                />
              </div>
            </div>
          )}
          <div className="post-preview__group">
            <div className="post-preview__group-title">{LANGUAGE.CREATED_COLUMN}</div>
            <div className="post-preview__group-content">
              {moment(getVisualOrDiaporamaValue('created_at', visual, diaporamaValues))
                .format('DD/MM/YYYY HH:mm:ss')
                .split(' ')
                .map((item, i) => (
                  <div key={i}>{item}</div>
                ))}
            </div>
          </div>
          {type === POST_PREVIEW_TYPES.MAIN && (
            <div className="post-preview__group post-preview__group--margin-1">
              <div className="post-preview__group-title">{LANGUAGE.OPERATION_COLUMN}</div>
              <div className="post-preview__group-content">
                {client && isAdmin && (
                  <Link
                    to={`${getClientPage(client)}/${client.id}${CLIENT_PAGES.OPERATIONS}/${operation.id}`}
                    className="link post-preview__link"
                  >
                    <span className="ellipsis-text">{operation.code}</span>
                  </Link>
                )}
                {client && !isAdmin && <span className="post-preview__link ellipsis-text">{operation.code}</span>}
              </div>
            </div>
          )}
          {type === POST_PREVIEW_TYPES.MAIN && (
            <div className="post-preview__group">
              <div className="post-preview__group-title">{LANGUAGE.ARRIVING_TYPE_COLUMN}</div>
              <div className="post-preview__group-content ellipsis-text">{arrivingType}</div>
            </div>
          )}
          {type === POST_PREVIEW_TYPES.HASHTAG_TRACKER && (
            <div className="post-preview__group">
              <div className="post-preview__group-title">{LANGUAGE.AUTHOR_COLUMN}</div>
              <div className="post-preview__group-content ellipsis-text">
                {getVisualOrDiaporamaValue('author', visual, diaporamaValues)}
              </div>
            </div>
          )}
          {type === POST_PREVIEW_TYPES.HASHTAG_TRACKER && (
            <div className="post-preview__group">
              <div className="post-preview__group-title">{LANGUAGE.COMMENTS_COLUMN}</div>
              <div className="post-preview__group-content ellipsis-text">
                {getVisualOrDiaporamaValue('comment', visual, diaporamaValues)}
              </div>
            </div>
          )}
          <div className="post-preview__group">
            <div className="post-preview__group-title">{lastElement.label}</div>
            <div className="post-preview__group-content ellipsis-text">{lastElement.value}</div>
          </div>
        </div>
        {expand && (
          <div className="post-preview__trigger" onClick={this.handleShowDetailsClick}>
            <Icon name="open-details" className="post-preview__trigger-icon theme-color-13" />
          </div>
        )}
      </div>
    );
  }

  renderPopoverContent() {
    const {LANGUAGE} = this;
    const {visual, type, isAdmin, diaporamaValues, isColAdapterInDiaporama} = this.props;
    const diaporamaGroupValues = isColAdapterInDiaporama('group') && diaporamaValues.group.value;

    return (
      <div className="post-preview__popover-inner">
        <CustomScrollbars
          scrollbarProps={{
            autoHeightMax: 130,
            hideTracksWhenNotNeeded: true,
          }}
        >
          <div className="custom-scrollbars__scroll-content theme-color-13">
            {isAdmin &&
              type === POST_PREVIEW_TYPES.MAIN &&
              (visual.manual_visual_groups.length > 0 ||
                (visual.automatic_visual_groups && visual.automatic_visual_groups.length > 0)) && (
                <div className="post-preview__group">
                  <div className="post-preview__group-title">{this.LANGUAGE.GROUPS_COLUMN}</div>
                  <div className="post-preview__group-content main-text main-text--small">
                    {!isColAdapterInDiaporama('group') &&
                      visual.manual_visual_groups.map((group) => (
                        <div key={group.id} className="ellipsis-text post-preview__link">
                          {group.name}
                        </div>
                      ))}
                    {!isColAdapterInDiaporama('group') &&
                      visual.automatic_visual_groups &&
                      visual.automatic_visual_groups.map((group) => (
                        <div key={group.id} className="ellipsis-text post-preview__link">
                          {group.name}
                        </div>
                      ))}
                    {isColAdapterInDiaporama('group') &&
                      diaporamaGroupValues.map((group, key) => (
                        <div key={key} className="ellipsis-text post-preview__link">
                          {group}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            <div>
              <div className="post-preview__tags">
                {this.state.tags.map((tag, key) => (
                  <div className="hashtags-list__item hashtags-list__item--limited fix-ie-hashtag-width" key={key}>
                    <Hashtag
                      appendSharp
                      editable={isAdmin}
                      deletable={isAdmin}
                      className="hashtags-list__item--limited"
                      textClassName="ellipsis-text"
                      name={key}
                      onChange={this.saveTags(visual)}
                      onDelete={(index) =>
                        isColAdapterInDiaporama('tags')
                          ? this.removeTag(diaporamaValues, index)
                          : this.removeTag(visual, index)
                      }
                    >
                      {tag}
                    </Hashtag>
                  </div>
                ))}
              </div>
              {isAdmin && (
                <div className="link post-preview__add-tag" onClick={() => this.addTag(visual)}>
                  {LANGUAGE.ADD_TAG_BUTTON}
                </div>
              )}
            </div>
          </div>
        </CustomScrollbars>
      </div>
    );
  }

  render() {
    const {
      visual,
      flexible,
      className,
      onPreviewClick,
      checkboxName,
      checked,
      isAdmin,
      diaporamaValues,
      getVisualOrDiaporamaValue,
    } = this.props;

    const {showDetails} = this.state;

    const cssClass = ClassNames({
      'post-preview': true,
      'post-preview--flexible': flexible,
    });

    const topClass = ClassNames({
      'post-preview__top': true,
      'post-preview__top--checked': checked,
    });

    let url = '';
    if (visual.kind !== 'text') {
      url = visual.file.url;
    }

    return (
      <div className={`${cssClass} ${className}`}>
        <div className={`${topClass}`}>
          {url ? (
            <div
              className="post-preview__img"
              style={{
                backgroundImage: `url(${url})`,
              }}
            />
          ) : (
            <div className="post-preview__message">
              <div className="post-preview__message-text">
                {getVisualOrDiaporamaValue('comment', visual, diaporamaValues)}
              </div>
            </div>
          )}
          <div className="post-preview__overlay">
            <div className="post-preview__overlay__inner" onClick={() => onPreviewClick(visual)} />
            {isAdmin && (
              <Checkbox
                cssModifier="theme-color-13 post-preview__check"
                name={checkboxName}
                checked={checked}
                onClick={({target}) => this.props.onSelect(this.props.visual.id)(!target.checked)}
              />
            )}
            <button className="post-preview__fullscreen" onClick={() => onPreviewClick(visual)}>
              <Icon name="fullscreen" />
            </button>
          </div>
        </div>
        {this.renderContent()}
        <div
          className={`post-preview__popover anim-slide ${showDetails && 'anim-slide--active'}`}
          ref={(detailsPopover) => {
            this.detailsPopover = detailsPopover;
          }}
        >
          {this.renderPopoverContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({languageState, ...state}) => ({
  languageState,
  isAdmin: selectIsAdmin(state),
});

export default connect(mapStateToProps, {
  patchDiaporamaMappingItems,
  patchVisual,
  getAutomaticVisualGroups,
})(PostPreview);
