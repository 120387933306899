import React from 'react';

import moment from 'moment';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import {useReduxForm} from 'client/services/hooks';

import {selectAnimationAvailableDays} from 'client/ducks/instore-tasks/selectors';

import AnimationDatepicker from 'client/components/animations/components/animation-datepicker';
import {AnimationConfigContainerFormName} from 'client/components/animations/containers/animation-config-container';

const startHour = 11;
const endHour = 19;

const getRange = (days) => {
  const sortedByFrom = sortBy(days, 'from');
  const sortedByTo = sortBy(days, 'to');

  return {
    from: sortedByFrom?.[0]?.from,
    to: sortedByTo?.[sortedByTo?.length - 1]?.to,
  };
};

const AnimationAvailableFieldset = ({readOnly}) => {
  const availableDays = useSelector(selectAnimationAvailableDays);
  const {change, formValues} = useReduxForm(AnimationConfigContainerFormName);

  const kitStartHour =
    (formValues?.kit_mapping?.default_hour_begin && moment.utc(formValues?.kit_mapping?.default_hour_begin).hours()) ??
    startHour;
  const kitEndHour =
    (formValues?.kit_mapping?.default_hour_end && moment.utc(formValues?.kit_mapping?.default_hour_end).hours()) ??
    endHour;

  const onClick = (range) => {
    if (range.to || range.from) {
      const diff = Math.abs(moment(range.to || range.from).diff(moment(range.from), 'days')) + 1;

      const nextDays = Array(diff)
        .fill(null)
        .map((day, index) => {
          return {
            from: moment.utc(range.from).add(index, 'day').hours(kitStartHour).toISOString(),
            to: moment.utc(range.from).add(index, 'day').hours(kitEndHour).toISOString(),
          };
        });
      change('animation_days', nextDays);
    }
  };

  const onChangeMonth = (month) => {
    change('month', month);
  };

  const {from, to} = getRange(formValues?.animation_days || []);

  const selectedMonthDate = formValues?.month || from || formValues?.kit_mapping?.from;

  return (
    <AnimationDatepicker
      onClick={onClick}
      disabled={readOnly}
      availableDays={formValues?.kit_mapping?.id ? availableDays : {}}
      onChangeMonth={onChangeMonth}
      selectedMonth={selectedMonthDate}
      range={{
        // pass utc time as local to select correct day when from  and to doesn't fit into one day
        from: from ? moment.utc(from).local(true).format('YYYY-MM-DD') : from,
        to: to ? moment.utc(to).local(true).format('YYYY-MM-DD') : to,
      }}
    />
  );
};

AnimationAvailableFieldset.propTypes = {
  readOnly: PropTypes.bool.isRequired,
};

export default AnimationAvailableFieldset;
