import escapeHtml from 'escape-html';
import {Editor, Transforms, Text, Element, Descendant} from 'slate';
import {jsx} from 'slate-hyperscript';

import {
  AlignOption,
  BlockFormatType,
  CustomEditor,
  CustomElement,
  CustomPropertyType,
  CustomText,
  DEFAULT_STYLE,
  LIST_ITEM,
  LIST_OPTIONS,
  LIST_TYPES,
  ListOption,
  MarkFormatType,
  TEXT_ALIGN_OPTIONS,
  TYPE_TO_TAG,
  TYPOGRAPHY_TYPES,
  TypographyOption,
} from './constants';

export const editorMethods = {
  isMarkActive(editor: CustomEditor, format: MarkFormatType) {
    const marks = Editor.marks(editor);
    return Boolean(marks && format in marks && marks[format]);
  },

  toggleMark(editor: CustomEditor, format: MarkFormatType) {
    const isActive = this.isMarkActive(editor, format);
    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  },

  applyProperty(editor: CustomEditor, property: CustomPropertyType, value: string) {
    if (editor.selection) {
      Transforms.setNodes(editor, {[property]: value}, {match: Text.isText, split: true});
    }
  },

  getPropertyValue(editor: CustomEditor, property: CustomPropertyType) {
    const [match] = Editor.nodes(editor, {
      match: Text.isText,
      at: editor.selection || Editor.end(editor, []),
    });

    if (match) {
      const [node] = match;

      return node?.[property];
    }

    return '';
  },

  toggleBlock(editor: CustomEditor, format: BlockFormatType) {
    const isActive = this.isBlockActive(
      editor,
      format,
      TEXT_ALIGN_OPTIONS.includes(format as AlignOption) ? 'align' : 'type',
    );

    const isList = LIST_OPTIONS.includes(format as ListOption);

    Transforms.unwrapNodes(editor, {
      match: (n) =>
        !Editor.isEditor(n) &&
        Element.isElement(n) &&
        LIST_OPTIONS.includes(n.type as ListOption) &&
        !TEXT_ALIGN_OPTIONS.includes(format as AlignOption),
      split: true,
    });

    let newProperties: Partial<CustomElement>;
    if (TEXT_ALIGN_OPTIONS.includes(format as AlignOption)) {
      newProperties = {
        align: isActive ? TEXT_ALIGN_OPTIONS[0] : (format as AlignOption),
      };
    } else if (isActive) {
      newProperties = {
        type: TYPOGRAPHY_TYPES.PARAGRAPH,
      };
    } else {
      newProperties = {
        type: isList ? LIST_ITEM : format,
      };
    }

    Transforms.setNodes(editor, newProperties);

    if (!isActive && isList) {
      const block: CustomElement = {type: format as ListOption, children: []};
      Transforms.wrapNodes(editor, block);
    }
  },

  isBlockActive(editor: CustomEditor, format: string, blockType: 'type' | 'align' = 'type') {
    const {selection} = editor;

    if (!selection) {
      return false;
    }

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => !Editor.isEditor(n) && Element.isElement(n) && n[blockType] === format,
      }),
    );

    return !!match;
  },
};

type SerializeFunction = (node: CustomText | CustomElement) => string;

export const serialize: SerializeFunction = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.underline) {
      string = `<u>${string}</u>`;
    }
    if (node['line-through']) {
      string = `<s>${string}</s>`;
    }
    if (node.italic) {
      string = `<em>${string}</em>`;
    }
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.url) {
      string = `<a href="${node.url}" target="_blank">${string}</a>`;
    }

    let styles = '';
    if (node.color) {
      styles += `color: ${node.color};`;
    }
    if (node['font-size']) {
      styles += `font-size: ${node['font-size']};`;
    }
    if (node['font-family']) {
      styles += `font-family: ${node['font-family']};`;
    }
    const styleAttribute = styles ? ` style="${styles}"` : '';

    return styles ? `<span${styleAttribute}>${string}</span>` : string;
  }

  const children = node.children.map(serialize).join('');

  let styles = '';
  if (node.align) {
    styles += `text-align: ${node.align};`;
  }
  const styleAttribute = styles ? ` style="${styles}"` : '';

  const tagName = TYPE_TO_TAG[node.type as TypographyOption];

  return tagName ? `<${tagName}${styleAttribute}>${children}</${tagName}>` : children;
};

export const deserialize: (
  el: HTMLElement,
  attr?: Partial<CustomText>,
) => CustomElement | CustomText | (Descendant | null)[] | null = (el, markAttributes = {}) => {
  if (el.nodeType === Node.TEXT_NODE) {
    // render empty line as an empty paragraph
    if (el.nodeValue === '\n') {
      return jsx('element', {type: TYPOGRAPHY_TYPES.PARAGRAPH}, [jsx('text', {}, '')]);
    }

    // ignore empty text nodes in the root of the file
    if (
      !el.textContent?.trim() &&
      (el.parentNode?.nodeName === 'BODY' || el.parentNode?.nodeName === 'DEFAULT-STYLE')
    ) {
      return null;
    }

    return jsx('text', markAttributes, el.textContent);
  } else if (el.nodeType !== Node.ELEMENT_NODE) {
    return null;
  }

  const nodeAttributes = {...markAttributes};
  // define attributes for text nodes
  switch (el.nodeName) {
    case 'STRONG':
      nodeAttributes.bold = true;
      break;
    case 'EM':
      nodeAttributes.italic = true;
      break;
    case 'S':
      nodeAttributes['line-through'] = true;
      break;
    case 'U':
      nodeAttributes.underline = true;
      break;
    case 'A':
      nodeAttributes.url = el.getAttribute('href') || '';
  }

  const styles = el.getAttribute('style');
  if (styles) {
    styles.split(';').forEach((style: string) => {
      if (style) {
        const [property, value] = style.split(/:\s?/);
        nodeAttributes[property as CustomPropertyType] = value;
      }
    });
  }

  const children = Array.from(el.childNodes)
    .map((node) => deserialize(node as HTMLElement, nodeAttributes))
    .flat();

  if (children.length === 0) {
    children.push(jsx('text', nodeAttributes, ''));
  }

  switch (el.nodeName) {
    case 'BODY':
      return jsx('fragment', {}, children);
    case 'P':
      return jsx('element', {type: TYPOGRAPHY_TYPES.PARAGRAPH, align: el.style.textAlign}, children);
    case 'H1':
      return jsx('element', {type: TYPOGRAPHY_TYPES.HEADING_ONE, align: el.style.textAlign}, children);
    case 'H2':
      return jsx('element', {type: TYPOGRAPHY_TYPES.HEADING_TWO, align: el.style.textAlign}, children);
    case 'H3':
      return jsx('element', {type: TYPOGRAPHY_TYPES.HEADING_THREE, align: el.style.textAlign}, children);
    case 'OL':
      return jsx('element', {type: LIST_TYPES.ORDERED, align: el.style.textAlign}, children);
    case 'UL':
      return jsx('element', {type: LIST_TYPES.UNORDERED, align: el.style.textAlign}, children);
    case 'LI':
      return jsx('element', {type: LIST_ITEM, align: el.style.textAlign}, children);
    case 'DEFAULT-STYLE':
      return jsx('element', {type: DEFAULT_STYLE}, children);

    default:
      return children;
  }
};
