import React, {Component} from 'react';

import cn from 'classnames';
import {map} from 'lodash';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import isUndefined from 'lodash/isUndefined';
import {connect} from 'react-redux';
import {SortableContainer, SortableElement, SortableHandle} from 'react-sortable-hoc';

import bem from 'client/services/bem';

import {CheckboxInput} from 'client/common/inputs';
import Popover from 'client/common/popovers/popover';

import ClientTableErrorBoundary from 'client/components/common/client-table/client-table-error-boundary';
import ClientTableHeader from 'client/components/common/client-table/client-table-header';
import {ClientTableDefaultProps, ClientTableProps} from 'client/components/common/client-table/types';
import Icon from 'client/components/common/icon';
import Spinner from 'client/components/common/spinner';

import './client-table.scss';

const b = bem('client-table');

const CHECKBOX_COLUMN_WIDTH = '60px';

const TableBody = SortableContainer(({children}) => <tbody>{children}</tbody>);

const TableRow = SortableElement(({key, children, title, className}) => (
  <tr key={key} title={title} className={className}>
    {children}
  </tr>
));

const DragHandle = SortableHandle(() => <Icon name="sandwich" />);

class ClientTable extends Component {
  static propTypes = ClientTableProps;

  static defaultProps = ClientTableDefaultProps;

  handleSortChange = (column) => {
    const {sortField, sortOrder, onSortChange} = this.props;
    let newSortField;
    let newSortOrder;

    if (!onSortChange || !column.sortable) {
      return null;
    }

    if (sortField !== column.name) {
      newSortField = column.name;
      newSortOrder = column.initialSortOrder || 'asc';
    } else if (sortOrder === 'desc') {
      newSortField = column.name;
      newSortOrder = 'asc';
    } else {
      newSortField = column.name;
      newSortOrder = 'desc';
    }

    return () =>
      onSortChange({
        sortField: newSortField,
        sortOrder: newSortOrder,
        page: 1,
      });
  };

  renderHeaderRow = (columns, isSub = false) => {
    const {
      checkable,
      checkedAll: manualCheckedAll,
      onCheckAll,
      data,
      checkedRows: rawCheckedRows,
      commonHeaderClassName,
      commonHeaderClassNames,
      sortOrder,
      sortField,
      draggable,
      checkboxProps,
      disableCheck,
      classNames,
    } = this.props;
    const hasSubColumns = columns.some((col) => !!col.subcolumns && col.subcolumns.length);
    const rowSpan = hasSubColumns ? 2 : 1;

    const rowsForCheck = data.filter((row) => !disableCheck?.(row));

    // only rows that are on the current page of the table (about pagination)
    const checkedRows = rawCheckedRows.filter(
      (row) => !disableCheck?.(row) && rowsForCheck.some((fc) => fc.id === row.id),
    );

    const checkedIds = map(checkedRows, 'id');

    const autoCheckedAll = !!rowsForCheck?.length && rowsForCheck.every((r) => checkedIds.includes(r.id));

    const checkedAll = !isUndefined(manualCheckedAll) ? manualCheckedAll : autoCheckedAll;

    const isPartiallyChecked = !!checkedRows?.length && !checkedAll;

    const classNamesObject = {
      th: commonHeaderClassName,
      ...commonHeaderClassNames,
      ...classNames?.header,
    };

    return (
      <tr>
        {draggable && <th className={b('th')} width="20px" />}
        {checkable && !!data.length && !isSub && (
          <th className={cn(b('th'), classNamesObject.th)} width={CHECKBOX_COLUMN_WIDTH} rowSpan={rowSpan}>
            <div className={b('header')} style={{visibility: isSub ? 'hidden' : 'visible'}}>
              <CheckboxInput
                {...checkboxProps}
                name="check"
                checked={checkedAll || isPartiallyChecked}
                onCheck={onCheckAll}
                isPartiallyChecked={isPartiallyChecked}
              />
            </div>
          </th>
        )}
        {columns.map((column) => (
          <ClientTableHeader
            key={column.key || column.name || column.path}
            rowSpan={rowSpan}
            column={column}
            classNames={classNamesObject}
            sortOrder={sortOrder}
            sortField={sortField}
            onSortChange={this.handleSortChange(column)}
            isSub={isSub}
          />
        ))}
      </tr>
    );
  };

  renderValue = ({value, column: noWrap, valueClassName, item}) => {
    if (noWrap) {
      return value;
    }

    const className = isFunction(valueClassName) ? valueClassName(item) : valueClassName;

    return <div className={cn(b('value'), className)}>{value}</div>;
  };

  renderCell = (item, column, index) => {
    const {data, commonCellClassName, oddClassName, evenClassName, fakeEmptyValue, rowKey, classNames} = this.props;
    const {path, render, name, hintRender, formatter, cellClassName = () => ''} = column;
    const cellClassNameModifier = isFunction(cellClassName) ? cellClassName(item) : cellClassName;
    const orderClassName = (index + 1) % 2 === 0 ? classNames?.even || evenClassName : classNames?.odd || oddClassName;

    const key = `${get(item, rowKey)}-${column.key || column.name || column.path}`;

    let value = get(item, path || name, fakeEmptyValue ? ' ' : '');
    value = formatter ? formatter(value) : value;
    value = render ? render({value, item, data, index, column}) : this.renderValue({value, column, item});

    const tdClassName = cn(
      b('td', {
        'zero-paddings': column.zeroPaddings,
        'ellipsis-text': column.ellipsisText,
        [`align-${column.align}`]: !!column.align,
      }),
      classNames?.cell || commonCellClassName,
      cellClassNameModifier,
      orderClassName,
    );

    return (
      <ClientTableErrorBoundary
        fallback={<td className={tdClassName} />}
        key={key}
        message={`column name: ${column.name}`}
      >
        <td
          key={key}
          className={tdClassName}
          style={{maxWidth: column.width, minWidth: column.width, width: column.width}}
          title={column.ellipsisText ? value : null}
        >
          {hintRender ? (
            <Popover position="top" overlay={hintRender(item, {column, value})}>
              {value}
            </Popover>
          ) : (
            value
          )}
        </td>
      </ClientTableErrorBoundary>
    );
  };

  renderRow = (item, index) => {
    const {
      disableCheck,
      checkedRows,
      onCheck,
      checkable,
      checkedAll,
      renderCheckbox,
      commonCellClassName,
      classNames,
      checkboxProps,
      draggable,
      getRowTitle,
      rowKey,
    } = this.props;
    const columns = this.filterColumns();
    const key = get(item, rowKey);
    const checked = checkedAll
      ? !checkedRows?.find((row) => row.id === item.id)
      : !!checkedRows?.find((row) => row.id === item.id);
    const title = getRowTitle ? getRowTitle({item, index}) : null;

    return (
      <TableRow key={key || item.id || index} index={index} title={title} className={classNames?.row}>
        {checkable && (
          <td className={cn(b('td'), classNames?.cell || commonCellClassName)} width={CHECKBOX_COLUMN_WIDTH}>
            {renderCheckbox?.(item) ?? (
              <CheckboxInput
                {...checkboxProps}
                name="check"
                disabled={disableCheck?.(item)}
                checked={checked}
                onCheck={(nextState) => onCheck?.(checkedAll ? !nextState : nextState, item)}
              />
            )}
          </td>
        )}
        {draggable && (
          <td className={b('td')} width="20px">
            <DragHandle />
          </td>
        )}
        {columns.map((column) => {
          const subcolumns = column.subcolumns?.filter(({show}) => show !== false);
          return subcolumns?.length > 0
            ? subcolumns.map((subcolumn) => this.renderCell(item, subcolumn, index))
            : this.renderCell(item, column, index);
        })}
      </TableRow>
    );
  };

  getSubColumns = () => {
    return this.props.columns
      .reduce((acc, item) => {
        if (item.subcolumns) {
          return [...acc, ...item.subcolumns];
        }
        return acc;
      }, [])
      .filter(({show}) => show !== false);
  };

  filterColumns = () => {
    return this.props.columns.filter(({show}) => show !== false);
  };

  getColumnsCount = (columns) => {
    const {draggable} = this.props;
    return columns.reduce(
      (result, item) => {
        return item.subcolumns ? result + item.subcolumns.length : result + 1;
      },
      draggable ? 1 : 0,
    );
  };

  render() {
    const {
      data,
      renderHeaderChildren,
      lang,
      noDataText,
      loading,
      iconClassName,
      classNames,
      className,
      loadingColor,
      showNoData,
      noDataHeight,
      onDragEnd,
    } = this.props;
    const columns = this.filterColumns();
    const subcolumns = this.getSubColumns();

    return (
      <div className={cn(b(), className)}>
        <table className={b('table')}>
          <thead>
            {this.renderHeaderRow(columns, false)}
            {subcolumns.length > 0 && this.renderHeaderRow(subcolumns, true)}
            {renderHeaderChildren}
          </thead>
          <TableBody onSortEnd={onDragEnd} helperClass={b('drag-helper')} useDragHandle>
            {!loading && data.map(this.renderRow)}

            {showNoData && !data.length && !loading && (
              <tr>
                <td colSpan={this.getColumnsCount(columns)}>
                  <div className={b('no-data')} style={{minHeight: noDataHeight}}>
                    <Icon name="table-no-data" className={cn(b('icon'), classNames?.icon || iconClassName)} />
                    <p className={b('no-data-text')}>{noDataText || lang.TABLE_NO_DATA}</p>
                  </div>
                </td>
              </tr>
            )}
          </TableBody>
        </table>
        {loading && (
          <div className={b('spinner-wrap')}>
            <Spinner color={loadingColor} />
          </div>
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  lang: state.languageState.payload.COMMON,
}))(ClientTable);
