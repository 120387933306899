import {Dispatch} from 'redux';
import moment from 'moment';

import {callApi} from 'client/services/call-api';
import {API_METHODS, API_PATH, API_URL_PROD, IS_DEV_ENV} from 'client/common/config';

import {ApiAction} from 'client/types';
import {Language} from 'client/models/language/types';

import types from './types';

export function fetchTranslations(lang: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: types.FETCH_TRANSLATIONS_REQUEST,
    });
    return fetch(`${API_URL_PROD}/translations/${lang}${IS_DEV_ENV ? '-unsafe' : ''}.json`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((data) => {
        dispatch({
          type: types.FETCH_TRANSLATIONS_SUCCESS,
          payload: data,
          meta: {lang},
        });
      })
      .catch((error) => {
        dispatch({
          type: types.FETCH_TRANSLATIONS_ERROR,
          payload: error,
        });
      });
  };
}

export const fetchLanguages: ApiAction<Language[]> = (queryParams) => (dispatch) =>
  callApi(dispatch, {
    endpoint: `${API_PATH}${API_METHODS.LANGUAGES}`,
    method: 'GET',
    noCache: true,
    types: [types.FETCH_LANGUAGES_REQUEST, types.FETCH_LANGUAGES_SUCCESS, types.FETCH_LANGUAGES_ERROR],
    queryParams,
  });

export function setLanguage(settings: {lang: string; store: boolean}) {
  const {lang, store = true} = settings;
  if (store) {
    localStorage.setItem('language', lang);
  }

  moment.locale(lang);

  return (dispatch: Dispatch) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: types.SET_LANGUAGE,
        currentLanguage: lang,
      });

      return resolve();
    });
  };
}
