import React from 'react';

import PropTypes from 'prop-types';

import {useLanguage} from 'client/services/hooks';

import InstoreDonutChart from 'client/components/instore/charts/instore-donut-chart';

import {getDonutChartData} from './helpers';

const InstorePlanningDonutChart = ({data}) => {
  const lang = useLanguage('INSTORE_TASK.CHARTS.INSTORE_DONUT_CHART');
  const langStatusRoad = useLanguage('ANIMATIONS.STATUS_ROAD');

  const graphName = Object.keys(data)[0];
  const chartData = getDonutChartData(data[graphName], langStatusRoad);

  return (
    <div className="pie-chart-diagram">
      <InstoreDonutChart
        data={chartData}
        title={lang[graphName.toUpperCase()]}
        legendOnRight={true}
        legendMaxHeight={200}
        width={108}
        height={108}
      />
    </div>
  );
};

InstorePlanningDonutChart.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InstorePlanningDonutChart;
