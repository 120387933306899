import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import SelectDropdown from 'client/common/selects/select-dropdown';

import './instore-campaign-select.scss';

const b = bem('instore-campaign-select');

const InstoreCampaignSelect = (props) => {
  const {campaigns, onChange, value, showAllOption} = props;
  const lang = useLanguage('INSTORE_TASK.INPUTS.INSTORE_CAMPAIGN_SELECT');

  let options = campaigns.map(({id, name}) => ({value: id, label: name}));

  if (showAllOption) {
    options = [{value: 0, label: lang.All_CAMPAIGNS}, ...options];
  }

  return (
    <SelectDropdown
      className={b()}
      selectClassName={b('select')}
      labelClassName={b('label')}
      label={lang.CAMPAIGN}
      options={options}
      onChange={onChange}
      value={value}
      simpleValue={true}
    />
  );
};

InstoreCampaignSelect.propTypes = {
  campaigns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  showAllOption: PropTypes.bool,
};

InstoreCampaignSelect.defaultProps = {
  value: null,
  showAllOption: false,
};

export default InstoreCampaignSelect;
