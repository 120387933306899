import React from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';

import LayoutPanel from 'client/common/panels/layout-panel';

import InstorePlanningDonutChart from 'client/components/instore/charts/instore-planning-donut-chart';
import {ANIMATION_STATUS_LANG_MAP} from 'client/components/instore/constants';

import './instore-statistics-panel.scss';

const b = bem('instore-statistics-panel');

const InstoreStatisticsPanel = ({statistics, loading}) => {
  const lang = useLanguage('INSTORE_TASK.PANELS.INSTORE_STATISTICS_PANEL');

  const data = statistics.map((item) => {
    if (item.status) {
      return {
        status: Object.entries(item.status).reduce(
          (result, [key, value]) => ({
            ...result,
            [ANIMATION_STATUS_LANG_MAP[key]]: value,
          }),
          {},
        ),
      };
    }

    if (item.area) {
      return {
        area: Object.entries(item.area).reduce(
          (result, [key, value]) => ({
            ...result,
            [key || lang.NO_AREA]: value,
          }),
          {},
        ),
      };
    }

    return item;
  });

  if (loading) {
    return null;
  }

  return (
    <LayoutPanel className={b()}>
      {data.map((chart, idx) => (
        <InstorePlanningDonutChart key={idx} data={chart} />
      ))}
    </LayoutPanel>
  );
};

InstoreStatisticsPanel.propTypes = {
  statistics: PropTypes.array,
  loading: PropTypes.bool.isRequired,
};

InstoreStatisticsPanel.defaultProps = {
  statistics: [],
};
export default InstoreStatisticsPanel;
