import React, {Component} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Field, reduxForm, reset, change as changeFieldValue} from 'redux-form';
import {matches, equals} from 'validator';

import {passwordRegExp} from 'client/services/helpers';
import {EMAIL_REGEX, PHONE_REGEX} from 'client/services/validator';

import {CheckboxField} from 'client/common/fields';
import Icon from 'client/common/icon';
import Popover from 'client/common/popovers/popover';

import fieldTemplate from 'client/components/common/field';

import {TranslationJsx} from 'client/models/language/types';

import './profile-form.scss';

const labelStyle = {
  position: 'relative',
  bottom: '7px',
};

let LANG = {};
let isEditPassword = false;

function validate({password, current_password, password_confirmation, first_name, last_name, phone, email}) {
  let errors = {};

  if (!first_name || !first_name.trim()) {
    errors.first_name = LANG.FIRST_NAME_REQUIRED;
  }

  if (!last_name || !last_name.trim()) {
    errors.last_name = LANG.LAST_NAME_REQUIRED;
  }

  switch (true) {
    case phone && !PHONE_REGEX.test(phone):
      errors.phone = LANG.PHONE_INVALID;
      break;
    default:
    //
  }

  switch (true) {
    case !email || !email.trim():
      errors.email = LANG.EMAIL_REQUIRED;
      break;
    case email && !EMAIL_REGEX.test(email):
      errors.email = LANG.EMAIL_INVALID;
      break;
    default:
    //
  }

  if (isEditPassword) {
    if (!current_password) {
      errors.current_password = LANG.PASSWORD_REQUIRED;
    } else if (matches(current_password, passwordRegExp())) {
      errors.current_password = LANG.PASSWORD_LENGTH;
    }

    if (!password) {
      errors.password = LANG.CONFIRMATION_REQUIRED;
    } else if (matches(password, passwordRegExp())) {
      errors.password = LANG.CONFIRMATION_LENGTH;
    }

    if (!password_confirmation) {
      errors.password_confirmation = LANG.CONFIRMATION_REQUIRED;
    } else if (matches(password_confirmation, passwordRegExp())) {
      errors.password_confirmation = LANG.CONFIRMATION_LENGTH;
    } else if (!equals(password_confirmation, password)) {
      errors.password_confirmation = LANG.PASSWORD_MATCH;
    }
  }

  return errors;
}

class ProfileForm extends Component {
  static propTypes = {
    initialValues: PropTypes.object,
    LANGUAGE: PropTypes.object,
    isClient: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
    edit: PropTypes.bool,
    isLast: PropTypes.bool,
    editPassword: PropTypes.bool,
    memberships: PropTypes.array,
    role: PropTypes.array,
    qualification: PropTypes.array,
    subsidiariesList: PropTypes.array,
    updateSuccess: PropTypes.bool,
    displayDeletePopUp: PropTypes.func.isRequired,
    handleRoleChange: PropTypes.func.isRequired,
    handleEditPasswordChange: PropTypes.func.isRequired,
    onAuthMethodChange: PropTypes.func.isRequired,
    popoverOldPassword: PropTypes.node,
    popoverDoubleFactorAuthNotification: PropTypes.object,
    cancel: PropTypes.func.isRequired,
    save: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    pristine: PropTypes.bool,
    submitFailed: PropTypes.bool,
    error: TranslationJsx,
  };

  static FormName = 'MyProfile';

  static warn({password, password_confirmation}) {
    let warnings = {};

    if (password && password_confirmation && equals(password_confirmation, password)) {
      warnings.password = LANG.PASSWORD_MATCHED;
      warnings.password_confirmation = LANG.PASSWORD_MATCHED;
    }
    return warnings;
  }

  onCancelHandler = () => {
    const {cancel} = this.props;
    this.props.reset();
    cancel();
  };

  render() {
    const {
      LANGUAGE,
      // isClient,
      edit,
      isSuperAdmin,
      isLast,
      editPassword,
      // memberships,
      role,
      qualification,
      subsidiariesList,
      updateSuccess,
      displayDeletePopUp,
      handleRoleChange,
      handleEditPasswordChange,
      // onAuthMethodChange,
      popoverOldPassword,
      popoverDoubleFactorAuthNotification,
      save,
      handleSubmit,
      submitting,
      pristine,
      submitFailed,
      error,
    } = this.props;
    const isElementDisabled =
      (isSuperAdmin && (!edit || editPassword)) ||
      (!isSuperAdmin && !(!edit || editPassword)) ||
      (!isSuperAdmin && (!edit || editPassword));

    return (
      <div>
        {edit && (
          <button
            disabled={isLast}
            className="profile-form__delete-btn button button--bg-1"
            onClick={displayDeletePopUp}
          >
            <Icon className="button__icon" name="trash" width={17} height={19} />
            <span>{LANGUAGE.DELETE_USER_BUTTON}</span>
          </button>
        )}
        <form className="profile-form__form" noValidate="noValidate" onSubmit={handleSubmit(save)}>
          {submitFailed && (
            <div className="input--error">
              <p className="input__message">{error}</p>
            </div>
          )}
          <div className="profile-form__form-cols">
            <div className="profile-form__form-col">
              <div className="form-field-wrap">
                <Field
                  cssModifier="select--disabled-view-1 select--view-1"
                  disabled={!edit || editPassword}
                  label={LANGUAGE.CIVILITY_LABEL}
                  name="title"
                  type="select"
                  searchable={false}
                  options={[
                    {value: 'M.', label: LANGUAGE.CIVILITY_M},
                    {value: 'Mme.', label: LANGUAGE.CIVILITY_MME},
                  ]}
                  component={fieldTemplate}
                />
              </div>
              <div className="form-field-wrap">
                <Field
                  cssModifier="input--disabled-view-1"
                  disabled={!edit || editPassword}
                  label={LANGUAGE.FIRST_NAME_LABEL}
                  name="first_name"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className="form-field-wrap">
                <Field
                  cssModifier="input--disabled-view-1"
                  disabled={!edit || editPassword}
                  label={LANGUAGE.LAST_NAME_LABEL}
                  name="last_name"
                  type="text"
                  component={fieldTemplate}
                />
              </div>
              <div className="form-field-wrap">
                <Field
                  cssModifier="input--disabled-view-1"
                  disabled={isElementDisabled}
                  label={LANGUAGE.EMAIL_LABEL}
                  name="email"
                  type="email"
                  component={fieldTemplate}
                />
              </div>
              {editPassword ? (
                <div>
                  <div className="form-field-wrap">
                    <Field
                      label={LANGUAGE.OLD_PASSWORD_LABEL}
                      name="current_password"
                      type="password"
                      component={fieldTemplate}
                    />
                    <Popover
                      overlayInnerStyle={{
                        width: 200,
                      }}
                      trigger="both"
                      position="right"
                      overlay={popoverOldPassword}
                      className="form-field-wrap__help-icon"
                      shiftLeft={10}
                    >
                      <Icon name="info" />
                    </Popover>
                  </div>
                  <div className="form-field-wrap">
                    <Field
                      label={LANGUAGE.NEW_PASSWORD_LABEL}
                      name="password"
                      type="password"
                      component={fieldTemplate}
                    />
                  </div>
                  <div className="form-field-wrap">
                    <Field
                      label={LANGUAGE.CONFIRM_PASSWORD_LABEL}
                      name="password_confirmation"
                      type="password"
                      component={fieldTemplate}
                    />
                  </div>
                </div>
              ) : (
                <div className="form-field-wrap">
                  <Field
                    cssModifier="input--disabled-view-1"
                    input={{
                      value: 'secret-password',
                    }}
                    disabled
                    label={LANGUAGE.PASSWORD_LABEL}
                    name="password"
                    type="password"
                    component={fieldTemplate}
                  />
                </div>
              )}
            </div>

            <div className="profile-form__form-col">
              <div className="form-field-wrap z_200">
                <Field
                  cssModifier="select--disabled-view-1 select--view-1"
                  disabled={isLast || isElementDisabled}
                  label={LANGUAGE.ROLE_LABEL}
                  name="role"
                  onChange={handleRoleChange}
                  type="select"
                  searchable={false}
                  options={role}
                  component={fieldTemplate}
                />
              </div>

              <div className="form-field-wrap z_100">
                <Field
                  cssModifier="select--disabled-view-1 select--view-1"
                  disabled={isElementDisabled}
                  label={LANGUAGE.QUALIFICATION_LABEL}
                  name="qualification"
                  type="select"
                  searchable={false}
                  options={qualification}
                  component={fieldTemplate}
                />
              </div>

              <div className="form-field-wrap">
                <Field
                  cssModifier="input--disabled-view-1"
                  disabled={!edit || editPassword}
                  label={LANGUAGE.PHONE_LABEL}
                  name="phone"
                  type="text"
                  component={fieldTemplate}
                />
              </div>

              <div className="form-field-wrap">
                <Field
                  cssModifier="select--disabled-view-1 select--view-1"
                  disabled={isElementDisabled}
                  label={LANGUAGE.SUBSIDIARY_LABEL}
                  name="subsidiary_id"
                  type="select"
                  searchable={false}
                  options={subsidiariesList}
                  component={fieldTemplate}
                />
              </div>

              {!edit && !editPassword && (
                <button
                  style={{
                    marginTop: 22,
                  }}
                  className="profile-form__change-password-btn button button--bg-1"
                  onClick={handleEditPasswordChange}
                >
                  {LANGUAGE.CHANGE_PASSWORD_BUTTON}
                </button>
              )}
            </div>

            <div className="profile-form__form-col">
              <div className="field-group__row">
                <div className="form-field-wrap profile-form__form-field-wrap">
                  <CheckboxField
                    color="visuals"
                    name="otp_enabled"
                    label={<span style={labelStyle}>{LANGUAGE.PROFILE_OTP.OTP_MESSAGE}</span>}
                    disabled={isElementDisabled}
                  />
                  <Popover
                    shiftLeft={10}
                    trigger="both"
                    position="right"
                    overlay={popoverDoubleFactorAuthNotification}
                    overlayInnerStyle={{
                      width: 350,
                    }}
                  >
                    <span className="profile-form__question-icon">
                      <Icon name="question" />
                    </span>
                  </Popover>
                </div>
              </div>
            </div>
          </div>

          <div className="profile-form__form-bottom">
            {(edit || editPassword) && (
              <div className="pull-right">
                <button className="profile-form__btn button button--bg-11" type="button" onClick={this.onCancelHandler}>
                  {LANGUAGE.CANCEL_BUTTON}
                </button>
                <button
                  className="profile-form__btn button button--bg-1"
                  type="submit"
                  disabled={submitting || pristine}
                >
                  {LANGUAGE.SAVE_BUTTON}
                </button>
              </div>
            )}
            {updateSuccess && !edit && (
              <div className="pull-right message-success-text uppercase">
                {LANGUAGE.SUCCESSFULLY_UPDATED_PROFILE_MESSAGE}
                <Icon name="status-success" className="profile-form__check-icon" />
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

const ProfileFormWrapper = reduxForm({
  form: ProfileForm.FormName,
  validate: validate,
  warn: ProfileForm.warn,
  enableReinitialize: true,
})(ProfileForm);

export default connect(
  ({profile}) => ({
    initialValues: profile.payload.user,
  }),
  {
    reset,
    changeFieldValue,
  },
)(ProfileFormWrapper);
