import React, {useEffect} from 'react';

import {useDispatch} from 'react-redux';

import {useLanguage} from 'client/services/hooks';

import {set as setMetaAction} from 'client/common/meta/meta.actions';

const AccessDenied = () => {
  const dispatch = useDispatch();
  const lang = useLanguage('ACCESS_DENIED');

  useEffect(() => {
    dispatch(
      setMetaAction({
        title: 'Login',
      }),
    );
  }, [dispatch]);

  return (
    <div className="page page-auth">
      <div className="content-width">
        <p className="text-center">{lang.TITLE}</p>
      </div>
    </div>
  );
};

export default AccessDenied;
