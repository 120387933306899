import React from 'react';

import placeholder from 'assets/icons/colored/empty-media-state.svg';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';

import bem from 'client/services/bem';
import {getMediaType} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import AppButton from 'client/common/buttons/app-button';
import {MEDIA_MIME_TYPES, ACCEPT_MEDIA_FORMATS} from 'client/common/config';
import {ImageInput, MediaInput} from 'client/common/inputs';
import ConfirmationPopover from 'client/common/popovers/confirmation-popover';

import Spinner from 'client/components/common/spinner';

import {getImageRequirementsLabel} from './helpers';

import './image-input-item.scss';

const POSITIONING = {
  horizontal: {
    right: 'right',
    center: 'center',
    left: 'left',
  },
  vertical: {
    top: 'top',
    center: 'center',
    bottom: 'bottom',
  },
};

const b = bem('image-input-item');

const contentElementMap = {
  [MEDIA_MIME_TYPES.jpg]: ImageInput,
  [MEDIA_MIME_TYPES.mp3]: MediaInput,
  [MEDIA_MIME_TYPES.mp4]: MediaInput,
  [MEDIA_MIME_TYPES.pdf]: MediaInput,
};

const ImageInputItem = (props) => {
  const {
    maxHeight,
    horAlign,
    verAlign,
    loading,
    value,
    acceptFormats = ACCEPT_MEDIA_FORMATS,
    requirements,
    ...imageProps
  } = props;

  const lang = useLanguage('DIY_OPERATION.MODALS.CONFIGURATION_MODAL.IMAGE_INPUT_ITEM');
  const formats = !isEmpty(acceptFormats) ? acceptFormats : ACCEPT_MEDIA_FORMATS;
  const style = {};
  if (imageProps.width) {
    style.width = imageProps.width;
  }

  const requirementsComment = getImageRequirementsLabel(requirements, lang);

  const {device, no_image_possible = [], onChange} = imageProps;

  const canDelete =
    value &&
    (no_image_possible.includes(device) || (['by_default', 'forced'].includes(device) && no_image_possible.length > 0));

  const {filename, mediaType} = getMediaType(value);
  const Component = contentElementMap[mediaType] || contentElementMap[MEDIA_MIME_TYPES.jpg];

  return (
    <div className={b({'max-height': maxHeight})}>
      <div
        className={b('image-container', [POSITIONING.horizontal[horAlign], POSITIONING.vertical[verAlign]])}
        style={style}
      >
        {canDelete && (
          <div className={b('delete-wrap')}>
            <ConfirmationPopover
              title={lang.DELETE_THE_IMAGE}
              okButtonProps={{
                label: lang.DELETE_CONFIRM,
                onClick: () => onChange(null),
              }}
            >
              <AppButton
                className={b('delete-button')}
                title={lang.DELETE}
                iconName="close"
                iconConfig={{
                  name: 'close',
                  width: 16,
                  height: 16,
                  className: b('delete-icon'),
                }}
                asWrap
              />
            </ConfirmationPopover>
          </div>
        )}
        {loading ? (
          <Spinner centered color="primary" />
        ) : (
          <Component
            acceptFormats={formats}
            {...imageProps}
            value={value}
            {...(value && {height: ''})}
            disableClassNamePreview={true}
            filename={filename}
            mediaType={mediaType}
            placeholderIcon={placeholder}
          />
        )}
      </div>
      <span className={b('requirements')}>{requirementsComment}</span>
    </div>
  );
};

ImageInputItem.propTypes = {
  maxHeight: PropTypes.bool,
  loading: PropTypes.bool,
  horAlign: PropTypes.oneOf([POSITIONING.horizontal.right, POSITIONING.horizontal.center, POSITIONING.horizontal.left]),
  verAlign: PropTypes.oneOf([POSITIONING.vertical.top, POSITIONING.vertical.center, POSITIONING.vertical.bottom]),
  acceptFormats: PropTypes.string,
  ...ImageInput.propTypes,
};

ImageInputItem.defaultProps = {
  maxHeight: false,
  loading: false,
  horAlign: POSITIONING.horizontal.center,
  verAlign: POSITIONING.vertical.center,
};

export default ImageInputItem;
