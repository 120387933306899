import {dateToString} from 'client/services/helpers';

import {PRIZE_COUPON_TYPES, PRIZE_COUPON_VALIDITY_TYPES} from 'client/components/prizes/constants';

const getCouponValidityType = (coupon) =>
  coupon.validity_duration ? PRIZE_COUPON_VALIDITY_TYPES.DURATION : PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE;

export default (coupon) => {
  return {
    id: coupon?.id,
    internal_name: coupon?.internal_name,
    internal_code: coupon?.internal_code,
    external_name: coupon?.external_name,
    type: coupon?.type || PRIZE_COUPON_TYPES.CLIENT,
    validity_type: coupon ? getCouponValidityType(coupon) : PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE,
    validity_days: coupon?.validity_days?.toString(),
    valid_from: dateToString(coupon?.validity_from),
    valid_to: dateToString(coupon?.validity_to),
    description: coupon?.comment,
    loyalty_amount: coupon?.loyalty_amount?.toString(),
    loyalty_unit: coupon?.loyalty_unit,
    barcode_format: coupon?.barcode_format,
  };
};
