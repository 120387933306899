import React, {useState, useEffect, useMemo, useCallback} from 'react';

import find from 'lodash/find';
import {useSelector, useDispatch} from 'react-redux';
import {useToggle} from 'react-use';

import bem from 'client/services/bem';
import {uid, isEmail} from 'client/services/helpers';
import {useLanguage} from 'client/services/hooks';

import {selectAutotaskParam} from 'client/ducks/autotask/selectors';
import {getPrizesByGamesId, sendTestEmail, parseMjml} from 'client/ducks/email-templates/actions';
import {selectPrizes} from 'client/ducks/email-templates/selectors';
import {selectActiveTemplate} from 'client/ducks/email-templates/selectors';
import {selectCurrentUser} from 'client/ducks/user/selectors';

import RadioButtonGroup from 'client/common/button-group/radio-button-group';
import AppButton from 'client/common/buttons/app-button';
import {useToast} from 'client/common/hooks/useToast';
import Icon from 'client/common/icon';
import TextInput from 'client/common/inputs/text-input';
import Modal from 'client/common/modals/modal';
import SelectDropdown from 'client/common/selects/select-dropdown';

import Spinner from 'client/components/common/spinner';

import {useEditor} from 'client/components/email-template-editor/hooks/useEditor';
import generateMjml from 'client/components/email-template-editor/services/generate-mjml';
import {Translation} from 'client/models/language/types';
import {Game, Prize} from 'client/models/prizes/types';
import {ApiDispatch} from 'client/types';

import {MODES} from './constants';
import {replaceVariables} from './helpers';

import cssModule from './preview-modal.module.scss';

const b = bem('preview-modal', {cssModule});

type PreviewModalProps = {
  onClose: () => void;
  isClient: boolean;
};

const PreviewModal: React.FC<PreviewModalProps> = (props) => {
  const {onClose, isClient} = props;

  const dispatch: ApiDispatch = useDispatch();

  const lang = useLanguage('EMAIL_TEMPLATE_EDITOR.MODALS.PREVIEW_MODAL');
  const [mode, setMode] = useState(MODES.MOBILE);
  const [html, setHtml] = useState('');
  const [prizeOption, setPrizeOption] = useState<number | null>(null);
  const [sendingEmail, toggleSendingEmail] = useToggle(false);
  const [loadingPreview, toggleLoadingPreview] = useToggle(false);
  const currentUser = useSelector(selectCurrentUser);
  const activeTemplate = useSelector(selectActiveTemplate);
  const [email, setEmail] = useState(currentUser.email);
  const [emailError, setEmailError] = useState<Translation>('');
  const prizes = useSelector(selectPrizes);
  const clientGames: Game[] = useSelector(selectAutotaskParam('client_games'));
  const {doc, hasPrize, width} = useEditor();
  const showPrize = useMemo(() => hasPrize(), [hasPrize]);
  const {appendToastNotification} = useToast();

  const preparePreview = useCallback(async () => {
    toggleLoadingPreview();
    let prize;
    if (showPrize && prizeOption) {
      prize = find(prizes, {id: prizeOption}) as Prize;
    }
    const mjml = generateMjml(doc, {
      language: activeTemplate?.language_tag,
      prize,
      width,
      skipPrizeBlock: prizes.length === 0,
      isPreview: true,
    });
    const result = await dispatch(parseMjml({mjml_code: mjml}));
    const newHtml = replaceVariables(result.payload.result, activeTemplate?.email_template_variables || []);
    setHtml(newHtml);
    toggleLoadingPreview();
  }, [width, doc, dispatch, toggleLoadingPreview, activeTemplate, prizes, prizeOption, showPrize]);

  useEffect(() => {
    preparePreview();
  }, [preparePreview]);

  useEffect(() => {
    if (showPrize && clientGames?.length) {
      dispatch(getPrizesByGamesId(clientGames?.map(({id}) => id)));
    }
  }, [showPrize, clientGames, dispatch]);

  useEffect(() => {
    const noPrize = !prizeOption && prizes.length;
    const wrongPrize = prizeOption && prizes.length && !prizes.map((i) => i.id).includes(prizeOption);

    if (noPrize || wrongPrize) {
      setPrizeOption(prizes[0].id);
    }
  }, [prizeOption, prizes]);

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const error = !value?.trim() || !isEmail(value) ? lang.EMAIL_FORMAT_INCORRECT : '';
    setEmail(value);
    setEmailError(error);
  };

  const handleSendEmail = () => {
    toggleSendingEmail();
    const params = {to: email, prize_id: showPrize ? prizeOption : null};
    dispatch(sendTestEmail(activeTemplate?.id, params)).then(({error}) => {
      if (!error) {
        return appendToastNotification({
          id: uid(),
          type: 'success',
          title: lang.EMAIL_SENT,
        });
      }
      return appendToastNotification({
        id: uid(),
        type: 'error',
        title: lang.EMAIL_NOT_SENT,
      });
    });

    toggleSendingEmail();
  };

  return (
    <Modal dialogClassName={b()} title={isClient ? lang.TITLE : lang.TITLE_ADMIN} onClose={onClose} show>
      <div className={b('bar')}>
        <div className={b('left')}>
          <RadioButtonGroup
            name="mode"
            className={b('mode')}
            labelClassName={b('mode-label')}
            legend={lang.PREVIEW_ON}
            value={mode}
            onChange={(e) => setMode(e.target.value)}
            radioButtons={[
              {
                label: <Icon name="phones" width="24" height="24" title={lang.MOBILE?.toString()} />,
                value: MODES.MOBILE,
              },
              {
                label: <Icon name="monitor" width="24" height="24" title={lang.DESKTOP?.toString()} />,
                value: MODES.DESKTOP,
              },
            ]}
          />
          {showPrize && (
            <SelectDropdown
              className={b('prize')}
              label={lang.PRIZE}
              value={prizeOption}
              onChange={setPrizeOption}
              options={prizes.map((i) => ({label: i.external_name, value: i.id}))}
              disabled={prizes.length === 0}
              placeholder={prizes.length === 0 ? lang.NO_PRIZE_AVAILABLE : ''}
              simpleValue
            />
          )}
        </div>
        {isClient && (
          <div className={b('right')}>
            <TextInput
              className={b('email')}
              classNames={{error: b('email-error')}}
              label={lang.SEND_EMAIL_TO}
              value={email}
              onChange={handleChangeEmail}
              errorMessage={emailError}
            />
            <AppButton
              className={b('send')}
              label={lang.SEND}
              onClick={handleSendEmail}
              disabled={Boolean(emailError)}
              loading={sendingEmail}
            />
          </div>
        )}
      </div>
      <hr className={b('line')} />
      <div className={b('content')}>
        {loadingPreview ? (
          <Spinner centered />
        ) : (
          <iframe className={b('preview', {mobile: mode === MODES.MOBILE})} srcDoc={html} />
        )}
      </div>
    </Modal>
  );
};

export default PreviewModal;
