import React from 'react';

import cn from 'classnames';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm from 'client/services/hooks/use-redux-form';

import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import FileButton from 'client/common/buttons/file-button';
import {ImageField} from 'client/common/fields';

import {COMMON_INPUT} from 'client/components/prizes/constants';
import PrizeDiyModal from 'client/components/prizes/modals/prize-diy-modal';

import './prize-diy-win-fieldset.scss';

const b = bem('prize-diy-win-fieldset');

const PrizeDiyWinFieldset = (props) => {
  const {disabled} = props;
  const lang = useLanguage('PRIZES.FIELDSETS.PRIZE_DIY_WIN_FIELDSET');
  const {change, formValues} = useReduxForm(PrizeDiyModal.formName);
  const {language, defaultLanguage} = formValues?.main || {};
  const languages = useSelector(selectPrizeLanguages);
  const formValueLanguage = language === COMMON_INPUT ? defaultLanguage : language;

  const onUpload = (file) => {
    if (language === COMMON_INPUT) {
      const images = languages.reduce((imgs, langKey) => {
        imgs[`win_pop_up_image_${langKey}`] = file;
        return imgs;
      }, {});
      change('win_pop_up', images);
    } else if (language === defaultLanguage) {
      const images = languages.reduce((imgs, langKey) => {
        const currentValue = get(formValues, `win_pop_up.win_pop_up_image_${langKey}`);
        imgs[`win_pop_up_image_${langKey}`] = langKey === defaultLanguage ? file : currentValue || file;
        return imgs;
      }, {});
      change('win_pop_up', images);
    } else {
      change(`win_pop_up.win_pop_up_image_${language}`, file);
    }
  };

  return (
    <div className={cn(b())}>
      <ImageField
        disabled
        placeholderIcon={null}
        name={`win_pop_up.win_pop_up_image_${formValueLanguage}`}
        width={276}
        height={276}
        className={b('preview')}
        label={lang.IMAGE_TITLE}
      />

      <FileButton
        label={lang.CHOOSE_IMAGE}
        link
        key={`win_pop_up.win_pop_up_image_${formValueLanguage}`}
        onChange={onUpload}
        color="devices"
        className={b('button')}
        acceptFormats=".png,.jpg,.jpeg"
        disabled={disabled}
      />
    </div>
  );
};

PrizeDiyWinFieldset.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default PrizeDiyWinFieldset;
