import React, {useMemo} from 'react';

import bem from 'client/services/bem';

import AppButton from 'client/common/buttons';
import Carousel from 'client/common/carousel';

import cssModule from './full-step-progress-bar.module.scss';

const b = bem('full-step-progress-bar', {cssModule});

type titlesProps = {
  title: string;
};

type FullStepProgressBarProps = {
  titles: titlesProps[];
  totalSteps: number;
  currentStep: number;
  setCurrentStep: (currentStep: number) => void;

  disabled?: boolean;
  content?: React.ReactNode[] | string[];
  // if we have to manipulate popovers state
  isPopoverShown?: boolean;
  setIsPopoverShown?: (show: boolean) => void;
};

const FullStepProgressBar: React.FC<FullStepProgressBarProps> = (props) => {
  const {titles, totalSteps, currentStep, setCurrentStep, disabled, isPopoverShown, content, setIsPopoverShown} = props;

  const renderSteps = useMemo(() => {
    const listSteps: React.ReactNode[] = [];

    for (let i = 1; i <= totalSteps; i++) {
      listSteps.push(
        <div className={b('step-container')} key={i}>
          <AppButton
            onClick={() => {
              if (!isPopoverShown) {
                // if we have to manipulate popovers state
                setCurrentStep(i);
              }
              setIsPopoverShown?.(false); // if we have to manipulate popovers state
            }}
            className={b('step', {
              completed: i < currentStep,
              current: i === currentStep,
              disabled,
            })}
            disabled={disabled}
            transparent={true}
            label={
              <>
                {titles[i - 1] ? <p className={b('step-title')}>{titles[i - 1].title}</p> : null}
                <div className={b('step-number')}>
                  <span className={b('step-number-title')}>
                    <span>{i}</span>
                    {content?.[i - 1] ?? null}
                  </span>
                  <span className={b('step-line')} />
                </div>
              </>
            }
          />
        </div>,
      );
    }

    return listSteps;
  }, [content, currentStep, disabled, isPopoverShown, setCurrentStep, setIsPopoverShown, titles, totalSteps]);

  const isCarousel = totalSteps > 7;

  return totalSteps ? (
    <header className={b()}>
      <div className={b('inner')}>
        {isCarousel ? (
          <Carousel className={b('carousel')} hasArrows={true} slides={renderSteps} width="full" slidesPerView={7} />
        ) : (
          <ul className={b('list')}>{renderSteps}</ul>
        )}
      </div>
    </header>
  ) : null;
};

export default FullStepProgressBar;
