import moment from 'moment';

import {PRIZE_COUPON_VALIDITY_TYPES} from 'client/components/prizes/constants';

export default (values) => {
  const isDuration = values.validity_type === PRIZE_COUPON_VALIDITY_TYPES.DURATION;

  return {
    coupon: {
      id: values.id,
      internal_name: values.internal_name,
      internal_code: values.internal_code,
      external_name: values.external_name,
      type: values.type,
      validity_duration: isDuration,
      validity_days: isDuration ? values.validity_days : null,
      validity_from: !isDuration ? moment(values.valid_from, 'DD/MM/YYYY').toISOString() : null,
      validity_to: !isDuration ? moment(values.valid_to, 'DD/MM/YYYY').toISOString() : null,
      comment: values.description,
      loyalty_amount: values.loyalty_amount,
      loyalty_unit: values.loyalty_unit,
      barcode_format: values.barcode_format,
    },
  };
};
