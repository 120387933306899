import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import {RSAA} from 'redux-api-middleware';

import {callApi} from 'client/services/call-api';

import {API_PATH, API_METHODS} from 'client/common/config';

import types from './types';

export function getOperationsAction(queryParams = '') {
  return (dispatch) => {
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.OPERATIONS}?${queryParams}`,
        method: 'GET',
        types: [types.GET_OPERATIONS_REQUEST, types.GET_OPERATIONS_SUCCESS, types.GET_OPERATIONS_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function getOperations(queryParams = {}, isMyOperations = false) {
  const myOperationsActionTypes = [
    types.GET_MY_OPERATIONS_REQUEST,
    types.GET_MY_OPERATIONS_SUCCESS,
    types.GET_MY_OPERATIONS_ERROR,
  ];

  const operationsActionTypes = [
    types.GET_OPERATIONS_REQUEST,
    types.GET_OPERATIONS_SUCCESS,
    types.GET_OPERATIONS_ERROR,
  ];

  const actionTypes = isMyOperations ? myOperationsActionTypes : operationsActionTypes;

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATIONS}`,
      method: 'GET',
      types: actionTypes,
      noCache: true,
      queryParams,
    });
}

export function getActiveOperations(queryParams = {}) {
  const params = cloneDeep(queryParams);
  set(params, 'q.status_eq', 'active');

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATIONS}`,
      method: 'GET',
      types: [
        types.GET_ACTIVE_OPERATIONS_REQUEST,
        types.GET_ACTIVE_OPERATIONS_SUCCESS,
        types.GET_ACTIVE_OPERATIONS_ERROR,
      ],
      queryParams: params,
    });
}

export function getConfigurationOperations(queryParams = {}) {
  const params = cloneDeep(queryParams);
  set(params, 'q.status_eq', 'active');

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATIONS}`,
      method: 'GET',
      types: [
        types.GET_CONFIGURATION_OPERATIONS_REQUEST,
        types.GET_CONFIGURATION_OPERATIONS_SUCCESS,
        types.GET_CONFIGURATION_OPERATIONS_ERROR,
      ],
      queryParams: params,
    });
}

export function getFinishedOperations(queryParams = {}) {
  const params = cloneDeep(queryParams);
  set(params, 'q.status_eq', 'finished');

  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATIONS}`,
      method: 'GET',
      types: [
        types.GET_FINISHED_OPERATIONS_REQUEST,
        types.GET_FINISHED_OPERATIONS_SUCCESS,
        types.GET_FINISHED_OPERATIONS_ERROR,
      ],
      queryParams: params,
    });
}

export function getOperation(id, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATIONS}/${id}`,
      method: 'GET',
      types: [types.GET_OPERATION_REQUEST, types.GET_OPERATION_SUCCESS, types.GET_OPERATION_ERROR],
      noCache: true,
      queryParams,
    });
}

export const setOperation = (operation) => {
  return (dispatch) => {
    return dispatch({
      type: types.GET_OPERATION_SUCCESS,
      payload: {operation},
    });
  };
};

export function updateOperation(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATIONS}/${id}`,
      method: 'PATCH',
      types: [types.UPDATE_OPERATION_REQUEST, types.UPDATE_OPERATION_SUCCESS, types.UPDATE_OPERATION_ERROR],
      body,
      noCache: true,
    });
}

export function archiveOperation(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATIONS}/${id}/archive`,
      method: 'PATCH',
      types: [types.ARCHIVE_OPERATION_REQUEST, types.ARCHIVE_OPERATION_SUCCESS, types.ARCHIVE_OPERATION_ERROR],
      body,
      noCache: true,
    });
}
export function addOperation(body, queryParams = {}) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATIONS}`,
      method: 'POST',
      types: [types.ADD_OPERATION_REQUEST, types.ADD_OPERATION_SUCCESS, types.ADD_OPERATION_ERROR],
      body,
      queryParams,
    });
}

export function getOperationEmailTemplates(operationId) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATION_EMAIL_TEMPLATES}`,
      method: 'GET',
      types: [
        types.GET_OPERATION_EMAIL_TEMPLATES_REQUEST,
        types.GET_OPERATION_EMAIL_TEMPLATES_SUCCESS,
        types.GET_OPERATION_EMAIL_TEMPLATES_ERROR,
      ],
      queryParams: {
        include: ['email_template_variables', 'email_template_images'],
        q: {
          operation_id_eq: operationId,
        },
      },
    });
}

export function updateOperationEmailTemplate(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.OPERATION_EMAIL_TEMPLATES}/${id}`,
      method: 'PATCH',
      types: [
        types.UPDATE_OPERATION_EMAIL_TEMPLATE_REQUEST,
        types.UPDATE_OPERATION_EMAIL_TEMPLATE_SUCCESS,
        types.UPDATE_OPERATION_EMAIL_TEMPLATE_ERROR,
      ],
      body,
      noCache: true,
    });
}

export function updateMasterEmailTemplate(id, body) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.MASTER_EMAIL_TEMPLATES}/${id}`,
      method: 'PATCH',
      types: [
        types.UPDATE_MASTER_EMAIL_TEMPLATE_REQUEST,
        types.UPDATE_MASTER_EMAIL_TEMPLATE_SUCCESS,
        types.UPDATE_MASTER_EMAIL_TEMPLATE_ERROR,
      ],
      body,
      noCache: true,
    });
}

export function setClientParams(clientParams) {
  return {
    type: types.SET_CLIENT_PARAMS,
    clientParams,
  };
}

export function getClientsAction(idPOC) {
  return (dispatch) => {
    let queryParams = '';
    if (idPOC) {
      queryParams = `q[poc_admin_user_id_eq]=${idPOC}`;
    }
    return dispatch({
      [RSAA]: {
        endpoint: `${API_PATH}${API_METHODS.CLIENTS}?${queryParams}`,
        method: 'GET',
        types: [types.GET_CLIENTS_REQUEST, types.GET_CLIENTS_SUCCESS, types.GET_CLIENTS_ERROR],
        isAuth: true,
        noCache: true,
      },
    });
  };
}

export function flushOperationsAllState() {
  return {
    type: types.FLUSH_OPERATIONS_ALL_STATE,
  };
}

export function getRegions(clientId) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.REGIONS}`,
      method: 'GET',
      types: [types.GET_REGIONS_REQUEST, types.GET_REGIONS_SUCCESS, types.GET_REGIONS_ERROR],
      queryParams: {
        q: {
          client_id_eq: clientId,
        },
      },
    });
}

/**
 *
 * @param {number} clientId The id of client
 * @param {number[]} regionsIds The array with IDs of regions to look for places
 * @returns {void}
 */
export function getPlaces(clientId, regionsIds) {
  return (dispatch) =>
    callApi(dispatch, {
      endpoint: `${API_PATH}${API_METHODS.PLACES}`,
      method: 'GET',
      types: [types.GET_PLACES_REQUEST, types.GET_PLACES_SUCCESS, types.GET_PLACES_ERROR],
      queryParams: {
        q: {
          client_id_eq: clientId,
          region_id_in: regionsIds,
        },
      },
    });
}
