import React, {PureComponent} from 'react';

import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getTimelineStatistic} from 'client/ducks/participations/actions';
import {selectTimelineStatistic} from 'client/ducks/participations/selectors';

import DashboardCard from 'client/components/common/cards/dashboard-card';
import TimelineChart from 'client/components/common/charts-custom/timeline-chart';

import './participations-timeline.scss';

class ParticipationsTimeline extends PureComponent {
  static propTypes = {
    lang: PropTypes.object.isRequired,
    autotask: PropTypes.object.isRequired,
    timeline: PropTypes.object.isRequired,
    getTimelineStatistic: PropTypes.func.isRequired,
  };

  fetchTimeline = (params) => {
    return this.props.getTimelineStatistic({
      automation_task_id: this.props.autotask.id,
      ...params,
    });
  };

  render() {
    const {lang, autotask, timeline} = this.props;

    return (
      <DashboardCard
        className="participations-timeline"
        title={lang.TITLE}
        iconName="participation-tunnel-colored"
        contentHeight={290}
        empty={autotask.participations_count < 1}
        emptyText={lang.NOT_AVAILABLE}
      >
        <TimelineChart
          prefix="participation-timeline"
          uid={autotask.id}
          onFetch={this.fetchTimeline}
          initialFrom={autotask.operation.from}
          initialTo={autotask.operation.to}
          points={timeline.points}
          loadingColor="participants"
          lines={[
            {
              name: 'visits',
              label: lang.VISITS,
              borderColor: '#8ED2F8',
              data: timeline.visits,
            },
            {
              name: 'participations',
              label: lang.PARTICIPATIONS,
              borderColor: '#5DC4C4',
              data: timeline.participations,
            },
          ]}
        />
      </DashboardCard>
    );
  }
}

export default connect(
  (state) => ({
    lang: state.languageState.payload.PARTICIPATION_TIMELINE,
    timeline: selectTimelineStatistic(state),
  }),
  {
    getTimelineStatistic,
  },
)(ParticipationsTimeline);
