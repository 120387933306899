export default {
  GET_GAMES_REQUEST: '@@games/GET_GAMES_REQUEST',
  GET_GAMES_SUCCESS: '@@games/GET_GAMES_SUCCESS',
  GET_GAMES_ERROR: '@@games/GET_GAMES_ERROR',

  FETCH_GAME_REQUEST: '@@games/FETCH_GAME_REQUEST',
  FETCH_GAME_SUCCESS: '@@games/FETCH_GAME_SUCCESS',
  FETCH_GAME_ERROR: '@@games/FETCH_GAME_ERROR',

  CREATE_GAME_REQUEST: '@@games/CREATE_GAME_REQUEST',
  CREATE_GAME_SUCCESS: '@@games/CREATE_GAME_SUCCESS',
  CREATE_GAME_ERROR: '@@games/CREATE_GAME_ERROR',

  UPDATE_GAME_REQUEST: '@@games/UPDATE_GAME_REQUEST',
  UPDATE_GAME_SUCCESS: '@@games/UPDATE_GAME_SUCCESS',
  UPDATE_GAME_ERROR: '@@games/UPDATE_GAME_ERROR',

  DELETE_GAME_REQUEST: '@@games/DELETE_GAME_REQUEST',
  DELETE_GAME_SUCCESS: '@@games/DELETE_GAME_SUCCESS',
  DELETE_GAME_ERROR: '@@games/DELETE_GAME_ERROR',

  GET_PRIZES_REQUEST: '@@games/GET_PRIZES_REQUEST',
  GET_PRIZES_SUCCESS: '@@games/GET_PRIZES_SUCCESS',
  GET_PRIZES_ERROR: '@@games/GET_PRIZES_ERROR',

  DELETE_PRIZES_REQUEST: '@@games/DELETE_PRIZES_REQUEST',
  DELETE_PRIZES_SUCCESS: '@@games/DELETE_PRIZES_SUCCESS',
  DELETE_PRIZES_ERROR: '@@games/DELETE_PRIZES_ERROR',

  GET_LEVELS_REQUEST: '@@games/GET_LEVELS_REQUEST',
  GET_LEVELS_SUCCESS: '@@games/GET_LEVELS_SUCCESS',
  GET_LEVELS_ERROR: '@@games/GET_LEVELS_ERROR',

  CREATE_PRIZES_REQUEST: '@@games/CREATE_PRIZES_REQUEST',
  CREATE_PRIZES_SUCCESS: '@@games/CREATE_PRIZES_SUCCESS',
  CREATE_PRIZES_ERROR: '@@games/CREATE_PRIZES_ERROR',

  UPDATE_PRIZES_REQUEST: '@@games/UPDATE_PRIZES_REQUEST',
  UPDATE_PRIZES_SUCCESS: '@@games/UPDATE_PRIZES_SUCCESS',
  UPDATE_PRIZES_ERROR: '@@games/UPDATE_PRIZES_ERROR',

  UPDATE_PRIZE_ORDER: '@@games/UPDATE_PRIZE_ORDER',
  UPDATE_PRIZE_MAP_ORDER: '@@games/UPDATE_PRIZE_MAP_ORDER',

  GET_PRIZE_MAPS_REQUEST: '@@games/GET_PRIZE_MAPS_REQUEST',
  GET_PRIZE_MAPS_SUCCESS: '@@games/GET_PRIZE_MAPS_SUCCESS',
  GET_PRIZE_MAPS_ERROR: '@@games/GET_PRIZE_MAPS_ERROR',

  UPDATE_PRIZE_MAP_REQUEST: '@@games/UPDATE_PRIZE_MAP_REQUEST',
  UPDATE_PRIZE_MAP_SUCCESS: '@@games/UPDATE_PRIZE_MAP_SUCCESS',
  UPDATE_PRIZE_MAP_ERROR: '@@games/UPDATE_PRIZE_MAP_ERROR',

  UPDATE_PRIZE_MAPS_REQUEST: '@@games/UPDATE_PRIZE_MAPS_REQUEST',
  UPDATE_PRIZE_MAPS_SUCCESS: '@@games/UPDATE_PRIZE_MAPS_SUCCESS',
  UPDATE_PRIZE_MAPS_ERROR: '@@games/UPDATE_PRIZE_MAPS_ERROR',

  GET_DIY_PRIZE_MAPS_BY_LEVEL_REQUEST: '@@games/GET_DIY_PRIZE_MAPS_BY_LEVEL_REQUEST',
  GET_DIY_PRIZE_MAPS_BY_LEVEL_SUCCESS: '@@games/GET_DIY_PRIZE_MAPS_BY_LEVEL_SUCCESS',
  GET_DIY_PRIZE_MAPS_BY_LEVEL_ERROR: '@@games/GET_DIY_PRIZE_MAPS_BY_LEVEL_ERROR',
};
