import React from 'react';

import PropTypes from 'prop-types';

import InfoBlock from 'client/components/common/info-block';

import './home-card.scss';

function HomeCard({className, children, title}) {
  return (
    <InfoBlock className={`home-card ${className}`}>
      <InfoBlock.Title>
        <div className="theme-color-0">{title}</div>
      </InfoBlock.Title>
      <InfoBlock.Content className="home-card__content">
        <InfoBlock.Body>{children}</InfoBlock.Body>
      </InfoBlock.Content>
    </InfoBlock>
  );
}

HomeCard.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.any,
};

HomeCard.defaultProps = {
  className: '',
  children: null,
  title: '',
};

export default HomeCard;
