import React from 'react';

import cn from 'classnames';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import bem from 'client/services/bem';
import {useAppMedia} from 'client/services/hooks';

import Breadcrumbs from 'client/components/common/breadcrumbs';

import './page-layout.scss';

const b = bem('page-layout');

function BasePageLayout(props) {
  const {className, isUserLogged, isUserNotAdmin, nobreadcrumbs, style, hasSidebar} = props;
  const {isTablet} = useAppMedia();

  const classNames = cn(
    b({
      logged: isUserLogged,
      auth: !isUserLogged,
      user: isUserNotAdmin,
      'no-sidebar': !hasSidebar,
    }),
    className,
  );

  return (
    <div style={style}>
      <div className={classNames}>
        <div className={b('inner')}>
          {isUserLogged && !isTablet ? (
            <div>
              <div className="page__breadcrumbs">{!nobreadcrumbs && <Breadcrumbs links={props.breadcrumbs} />}</div>
            </div>
          ) : null}
          <div>{props.children}</div>
        </div>
      </div>
      {props.footer}
    </div>
  );
}

BasePageLayout.defaultProps = {
  className: '',
  children: '',
  logged: false,
  breadcrumbs: [],
  nobreadcrumbs: false,
  footer: null,
  style: {},
  isUserLogged: false,
  isUserNotAdmin: false,
};

BasePageLayout.propTypes = {
  className: PropTypes.string,
  breadcrumbs: PropTypes.array,
  nobreadcrumbs: PropTypes.bool,
  footer: PropTypes.node,
  style: PropTypes.object,
  isUserLogged: PropTypes.bool,
  isUserNotAdmin: PropTypes.bool,
  children: PropTypes.node,
  hasSidebar: PropTypes.bool.isRequired,
};

export default connect(({breadcrumbs}) => ({
  breadcrumbs: breadcrumbs.payload,
}))(BasePageLayout);
