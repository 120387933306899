import {isEmptyString, isInvalidDate, isFromLessThenTo} from 'client/services/validator';

import {
  PRIZE_COUPON_VALIDITY_TYPES,
  LOYALTY_COUPON_TYPES,
  PRIZE_BARCODE_FORMAT,
} from 'client/components/prizes/constants';

export default (allValues, {lang, shouldValidate = true, typeCodeIsBarcode}) => {
  const values = allValues?.coupon || {};
  const errors = {};

  if (!shouldValidate) {
    return errors;
  }

  if (typeCodeIsBarcode && (!values.barcode_format || values.barcode_format === PRIZE_BARCODE_FORMAT.NONE)) {
    errors.barcode_format = lang.NEED_RESET_TYPE_CODE;
  }

  if (isEmptyString(values.internal_name)) {
    errors.internal_name = lang.REQUIRED;
  }

  if (isEmptyString(values.internal_code)) {
    errors.internal_code = lang.REQUIRED;
  }

  if (isEmptyString(values.external_name)) {
    errors.external_name = lang.REQUIRED;
  }

  if ([LOYALTY_COUPON_TYPES.OPTIONAL, LOYALTY_COUPON_TYPES.MANDATORY].includes(allValues?.main.loyalty_coupon_type)) {
    if (isEmptyString(values.loyalty_amount)) {
      errors.loyalty_amount = lang.REQUIRED;
    }
    if (isEmptyString(values.loyalty_unit)) {
      errors.loyalty_unit = lang.REQUIRED;
    }
  }

  if (values.validity_type === PRIZE_COUPON_VALIDITY_TYPES.FIXED_VALUE) {
    if (isEmptyString(values.valid_from)) {
      errors.valid_from = lang.REQUIRED;
    } else if (isInvalidDate(values.valid_from)) {
      errors.valid_from = lang.INVALID_DATE;
    }

    if (isEmptyString(values.valid_to)) {
      errors.valid_to = lang.REQUIRED;
    } else if (isInvalidDate(values.valid_to)) {
      errors.valid_to = lang.INVALID_DATE;
    }

    if (!errors.valid_from && !errors.valid_to && isFromLessThenTo(values.valid_from, values.valid_to)) {
      errors.valid_from = lang.BEFORE_TO;
    }
  }

  if (values.validity_type === PRIZE_COUPON_VALIDITY_TYPES.DURATION) {
    if (isEmptyString(values.validity_days)) {
      errors.validity_days = lang.REQUIRED;
    }
  }

  return errors;
};
