import React, {useMemo} from 'react';

import PropTypes from 'prop-types';

import bem from 'client/services/bem';

// components
import ButtonArrow from 'client/common/buttons/button-arrow';

import {TranslationJsx} from 'client/models/language/types';

import './step-progress-bar.scss';

const b = bem('step-progress-bar');

const StepProgressBar = (props) => {
  const {title, totalSteps, currentStep, setCurrentStep, disabled} = props;
  const amount = totalSteps || 1;

  const nextHandler = () => {
    if (currentStep === totalSteps) {
      return;
    }

    setCurrentStep(currentStep + 1);
  };

  const prevHandler = () => {
    if (currentStep < 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  const renderSteps = useMemo(() => {
    let listSteps = [];

    for (let i = 1; i <= totalSteps; i++) {
      listSteps.push(
        <li
          key={i}
          className={b('step', {
            completed: i < currentStep,
            current: i === currentStep,
          })}
        />,
      );
    }

    return listSteps;
  }, [currentStep, totalSteps]);

  return (
    <header className={b()}>
      <div className={b('inner')}>
        <p className={b('title')}>
          <span>{title}&#32;</span>
          <span className={b('step-caption')}>{`${currentStep} of ${amount}`}</span>
        </p>

        <ul className={b('list')}>{renderSteps}</ul>

        <div className={b('button-wrapper')}>
          <ButtonArrow direction="left" onClick={prevHandler} disabled={currentStep === 1 || disabled} />
          <ButtonArrow direction="right" onClick={nextHandler} disabled={currentStep === amount || disabled} />
        </div>
      </div>
    </header>
  );
};

StepProgressBar.propTypes = {
  title: TranslationJsx.isRequired,
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  setCurrentStep: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

StepProgressBar.defaultProps = {
  disabled: false,
};

export default StepProgressBar;
