import React from 'react';

import PropTypes from 'prop-types';

import CustomScrollbar from 'client/components/common/custom-scrollbars';

import './tpl-block.scss';

function TemplateBlock({className, title, content, scrollbarProps}) {
  return (
    <div className={`tpl-block ${className}`}>
      <div className="tpl-block__header">{title}</div>
      <div className="tpl-block__body">
        <CustomScrollbar scrollbarProps={scrollbarProps}>
          <div className="tpl-block__scrollbar-content">{content}</div>
        </CustomScrollbar>
      </div>
    </div>
  );
}

TemplateBlock.propTypes = {
  className: PropTypes.string,
  title: PropTypes.any,
  content: PropTypes.any,
  scrollbarProps: PropTypes.object,
};

TemplateBlock.defaultProps = {
  className: '',
  title: null,
  content: null,
  scrollbarProps: {},
};

export default TemplateBlock;
