import {COMMON_INPUT, PRIZE_CODE_TYPES, PRIZE_TYPES} from 'client/components/prizes/constants';
import validateCouponFieldset from 'client/components/prizes/fieldsets/prize-diy-coupon-fieldset/validate';
import validateCustomListFieldset from 'client/components/prizes/fieldsets/prize-diy-custom-list-fieldset/validate';
import validateEmailFieldset from 'client/components/prizes/fieldsets/prize-diy-email-fieldset/validate';
import {INSTANT_WIN_TABS} from 'client/components/prizes/fieldsets/prize-diy-instant-win-fieldset/constants';
import validateMainFieldset from 'client/components/prizes/fieldsets/prize-diy-main-fieldset/validate';
import validateRatioParticipationFieldset from 'client/components/prizes/fieldsets/prize-diy-ratio-participation-fieldset/validate';
import validateRatioTimeFieldset from 'client/components/prizes/fieldsets/prize-diy-ratio-time-fieldset/validate';
import {SCHEDULE_TABS} from 'client/components/prizes/fieldsets/prize-diy-schedule-fieldset/constants';
import validateSegmentationFieldset from 'client/components/prizes/fieldsets/prize-diy-segmentation-fieldset/validate';
import validateTicketFieldset from 'client/components/prizes/fieldsets/prize-diy-ticket-fieldset/validate';
import validateWeekScheduleFieldset from 'client/components/prizes/fieldsets/prize-diy-week-schedule-fieldset/validator';
import validateWeeklyListFieldset from 'client/components/prizes/fieldsets/prize-diy-weekly-list-fieldset/validator';
import validateWinFieldset from 'client/components/prizes/fieldsets/prize-diy-win-fieldset/validate';

export default (values, {validationLang, autotaskTypes}) => {
  let typeCodeIsBarcode = false;

  if (values.main?.create_ticket && values.ticket?.visual_type === PRIZE_CODE_TYPES.BAR_CODE) {
    typeCodeIsBarcode = true;
  }

  if (values.main?.email_send && values.email?.email_code_type === PRIZE_CODE_TYPES.BAR_CODE) {
    typeCodeIsBarcode = true;
  }

  const config = {
    lang: validationLang,
    isCommon: values.main?.language === COMMON_INPUT,
    defaultLanguage: values.main?.defaultLanguage,
    barcodeFormat: values.coupon?.barcode_format,
    typeCodeIsBarcode,
  };

  const errors = {
    main: validateMainFieldset(values.main || {}, validationLang),
    win_pop_up: validateWinFieldset(values.win_pop_up || {}, config),
    coupon: validateCouponFieldset(values || {}, {
      ...config,
      shouldValidate: values.main?.type === PRIZE_TYPES.COUPON,
    }),
    ratioTime: validateRatioTimeFieldset(
      values.ratioTime || {},
      {lang: validationLang},
      values.instant_win === INSTANT_WIN_TABS.RATIO_TIME,
    ),
    ratioParticipation: validateRatioParticipationFieldset(
      values.ratioParticipation || {},
      {lang: validationLang},
      values.instant_win === INSTANT_WIN_TABS.RATIO_PARTICIPATION,
    ),
    customList: validateCustomListFieldset(
      values.customList,
      {lang: validationLang},
      values.instant_win === INSTANT_WIN_TABS.SCHEDULE && values.schedule_type === SCHEDULE_TABS.CUSTOM_LIST,
    ),
    weeklyList: validateWeeklyListFieldset(
      values.weeklyList,
      validationLang,
      values.instant_win === INSTANT_WIN_TABS.SCHEDULE && values.schedule_type === SCHEDULE_TABS.WEEKLY_LIST,
    ),
    weekSchedule: validateWeekScheduleFieldset(
      values.weekSchedule,
      validationLang,
      values.instant_win === INSTANT_WIN_TABS.SCHEDULE && values.schedule_type === SCHEDULE_TABS.SCHEDULE,
    ),

    segmentation: validateSegmentationFieldset(values.segmentation || {}, {
      lang: validationLang,
      autotaskTypes,
    }),
  };

  if (values.main?.create_ticket) {
    errors.ticket = validateTicketFieldset(values.ticket || {}, config);
  }
  if (values.main?.email_send) {
    errors.email = validateEmailFieldset(values.email || {}, config);
  }

  return errors;
};
