import React, {useState} from 'react';

import {isEqual} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

import bem from 'client/services/bem';
import {useLanguage} from 'client/services/hooks';
import useReduxForm, {reduxForm} from 'client/services/hooks/use-redux-form';
import {ReduxFormFC} from 'client/services/hooks/use-redux-form/types';

import {updatePrizeMap} from 'client/ducks/games/actions';
import {selectPrizeLanguages} from 'client/ducks/operations/selectors';

import AppButton from 'client/common/buttons';
import {useToast} from 'client/common/hooks/useToast';
import TabSwitcher from 'client/common/tab-switcher';

import PrizeMapNameInfo from 'client/components/prizes/elements/prize-map-name-info';
import PrizeDiyCustomListPanel from 'client/components/prizes/panels/prize-diy-custom-list-panel';
import {PrizeMap} from 'client/models/prizes/types';
import {ApiDispatch} from 'client/types';

import initialize from './initialize';
import mapFormValues from './mapFormValues';
import PrizeMapCustomizationFormGeneral from './prizemap-customization-form-general';
import {formValuesProps} from './type';
import validate from './validate';

import cssModule from './prizemap-customization-form.module.scss';

const b = bem('prizemap-customization-form', {cssModule});

export type PrizeMapCustomizationFormForm = PrizeMap;

type PrizeMapCustomizationFormProps = {
  onClose: () => void;
  onSave: (values: PrizeMapCustomizationFormForm) => void;
  editable?: boolean;
  initialValues: PrizeMap;
  title: React.ReactNode;
};

export const PrizeMapCustomizationFormName = 'PrizeMapCustomizationForm';
const GENERAL_TAB = 'general';
const WIN_TAB = 'win';

const PrizeMapCustomizationForm: ReduxFormFC<PrizeMapCustomizationFormProps, PrizeMapCustomizationFormForm> = (
  props,
) => {
  const {title, editable, handleSubmit, initialValues, onSave, onClose} = props;
  const lang = useLanguage('PRIZES.MODALS.PRIZEMAP_CUSTOMIZATION_MODAL');
  const langErrors = useLanguage('PRIZES.FIELDSETS.ERRORS');
  const [activeTab, setActiveTab] = useState<typeof GENERAL_TAB | typeof WIN_TAB>(GENERAL_TAB);

  const languages = useSelector(selectPrizeLanguages);
  const defaultLanguage = languages[0];

  const dispatch: ApiDispatch = useDispatch();
  const {appendToastNotification} = useToast();

  const {formValues, invalid} = useReduxForm<PrizeMap>(PrizeMapCustomizationFormName, {
    initialValues: initialize(initialValues, {defaultLanguage}),
    validate: (values) => validate(values as formValuesProps, {lang: langErrors}),
  });
  const {instant_win_configuration, external_prize_name_translations, external_prize_name} = formValues;
  const {schedule_type, type} = instant_win_configuration || {};
  const prizeName = external_prize_name_translations[defaultLanguage] || external_prize_name;

  const handleSave = (values: PrizeMapCustomizationFormForm) => {
    const body = mapFormValues(values);

    dispatch(
      updatePrizeMap(values.id, body, {
        include: [
          'instant_win_configuration',
          'participation_instant_win_configuration',
          'time_instant_win_configuration',
          'schedule_instant_win_configuration.prize_map_timestamps',
        ],
      }),
    ).then(({error, payload}: {payload: {prize_map: PrizeMap}; error: boolean}) => {
      if (error) {
        appendToastNotification({
          type: 'error',
          title: prizeName,
          description: lang.SAVE_ERROR_MESSAGE,
        });
      } else {
        // don't care about performance here, it is less than 1ms
        if (!isEqual(body, mapFormValues(initialize(payload.prize_map, defaultLanguage)))) {
          // values are inherited from prizes on backend
          appendToastNotification({
            type: 'warning',
            title: prizeName,
            description: lang.SAVE_WARNING_MESSAGE,
          });
        } else {
          appendToastNotification({
            type: 'success',
            title: prizeName,
            description: lang.SAVE_SUCCESS_MESSAGE,
          });
        }
        onSave({...values, ...payload.prize_map});
      }
    });
  };

  return (
    <div className={b()}>
      <div className={b('header')}>
        {title}
        <div className={b('buttons')}>
          <AppButton color="clients" transparent label={lang.CANCEL_BUTTON} onClick={onClose} />
          <AppButton color="clients" label={lang.SAVE_BUTTON} onClick={handleSubmit(handleSave)} disabled={invalid} />
        </div>
      </div>
      <PrizeMapNameInfo />
      {schedule_type === 'manual' && (
        <TabSwitcher
          className={b('tabs')}
          style="title"
          tabs={[
            {
              title: lang.GENERAL_TAB,
              value: GENERAL_TAB,
            },
            {
              title: lang.WIN_TAB,
              value: WIN_TAB,
            },
          ]}
          onTabClick={setActiveTab}
          activeTab={activeTab}
        />
      )}
      {activeTab === GENERAL_TAB && <PrizeMapCustomizationFormGeneral instantWinType={type} editable={editable} />}
      {activeTab === WIN_TAB && <PrizeDiyCustomListPanel disabled={!editable} />}
    </div>
  );
};

export default reduxForm<PrizeMapCustomizationFormProps, PrizeMapCustomizationFormForm>({
  form: PrizeMapCustomizationFormName,
})(PrizeMapCustomizationForm);
